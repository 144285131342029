import axios from 'axios';
import { API_HOST } from '@/config';
import middlewares from './middleware';

export { fileUpload } from './fileUpload';
export * from './facebook';
export * from './types';
export * from './utils';

const client = axios.create({
  baseURL: API_HOST,
  withCredentials: true,
});

middlewares.request.forEach(interceptor => client.interceptors.request.use(interceptor));
middlewares.response.forEach(interceptor =>
  client.interceptors.response.use(interceptor[0], interceptor[1]),
);

export default client;
