import {
  CompanyBasicsView,
  PlatformUsersView,
  PlatformUserInviteView,
  PlatformUserEditView,
} from '@/views';
import { RoutingConfig } from '@/routing';

export const settingsViews: RoutingConfig = [
  {
    path: '/basics',
    exact: true,
    component: CompanyBasicsView,
  },
  {
    path: '/platform-users/invite',
    exact: true,
    component: PlatformUserInviteView,
  },
  {
    path: '/platform-users/:id',
    exact: true,
    component: PlatformUserEditView,
  },
  {
    path: '/platform-users',
    exact: true,
    component: PlatformUsersView,
  },
];

export default settingsViews;
