import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { BILLING_PARAMETERS_QUERY_KEY } from '../constants';
import { BillingParameters } from '../types';

const getBillingParameters = () => {
  return () => {
    return client.get<BillingParameters>(
      `${API_HOST}/${ApiRoutes.Billing}/general/parameters`,
    );
  };
};

export const useGetBillingParameters = () => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    BILLING_PARAMETERS_QUERY_KEY,
    getBillingParameters(),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get billing parameters.'), {
          variant: 'error',
        });
      },
    },
  );

  const billingParameters = data?.data;
  return { billingParameters, isLoading };
};
