import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  Tag,
  BOT_TAGS_QUERY_KEY,
  BotTagsRequest,
} from '@/queries';
import client, {
  ApiRoutes,
  ClientCancelToken,
  createCancelToken,
  wasCancelled,
} from '@/store/client';

let tagsCancelToken: ClientCancelToken | undefined = undefined;

const cancelTagsRequest = (message?: string) => {
  tagsCancelToken && tagsCancelToken.cancel(message);
  tagsCancelToken = createCancelToken();
};

const getBotTags = ({ orgId, botId }: BotTagsRequest) => {
  cancelTagsRequest('New tags request');

  return () => {
    return client.get<Array<Tag>>(
      `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/bots/${botId}/tags`,
      {
        cancelToken: tagsCancelToken?.token,
      },
    );
  };
};

export const useGetBotTags = ({ orgId, botId }: BotTagsRequest) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [BOT_TAGS_QUERY_KEY, orgId, botId],
    getBotTags({ orgId, botId }),
    {
      enabled: !!orgId && !!botId,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        if (wasCancelled(errorResponse)) return;
        toast(extractServerErrorMsg(errorResponse, 'Failed to get bot tags'), {
          variant: 'error',
        });
      },
    },
  );

  const botTags = data?.data || [];
  return { botTags, isLoading };
};
