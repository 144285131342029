import { KnowledgeSearchDocumentPage, KnowledgeSearchPage } from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const knowledgeSearch: RoutingConfig = [
  {
    title: 'Knowledge Search Document',
    icon: 'ChevronIcon',
    path: '/knowledge-search/:documentId',
    component: KnowledgeSearchDocumentPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:AUTOMATE', 'INSIDE:AUTOMATE'],
      permissionArea: BotPermissionCode.KNOWLEDGE_SEARCH_ADMIN,
    },
  },
  {
    title: 'Knowledge Search',
    icon: 'KnowledgeSearchIcon',
    description:
      'Create, schedule and manage announcements that engage with your employees and other teams',
    component: KnowledgeSearchPage,
    path: '/knowledge-search',
    customProps: {
      billingGroupCodes: ['OUTSIDE:AUTOMATE', 'INSIDE:AUTOMATE'],
      permissionArea: BotPermissionCode.KNOWLEDGE_SEARCH_ADMIN,
    },
  },
];

export default knowledgeSearch;
