import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  InvitationSendingSettings,
  INVITATION_SENDING_SETTINGS_QUERY_KEY,
} from '@/queries';

const getInvitationSendingSettings = (featureId?: string) => {
  return () => {
    return client.get<InvitationSendingSettings>(
      `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/settings/invitationsending`,
    );
  };
};

export const useGetInvitationSendingSettings = (featureId?: string) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [INVITATION_SENDING_SETTINGS_QUERY_KEY, featureId],
    getInvitationSendingSettings(featureId),
    {
      enabled: featureId ? true : false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(
            errorResponse,
            'Failed to get invitation sending settings.',
          ),
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const invitationSendingSettings = data?.data;
  return { invitationSendingSettings, isLoading };
};
