import { noop } from '@/utils';
import React, { createContext, useState } from 'react';

// Props
type BotRemoteUsersContextProps = {
  searchFilterQuery?: string | null;
  setSearchFilterQuery: (searchFilterQuery: string) => void;
};

// BotRemoteUsersView context
export const BotRemoteUsersViewContext = createContext<BotRemoteUsersContextProps>({
  searchFilterQuery: undefined,
  setSearchFilterQuery: noop,
});

// BotRemoteUsersView context provider
export const BotRemoteUsersViewContextProvider = ({ children }: { children?: any }) => {
  const [searchFilterQuery, setSearchFilterQuery] = useState<string>('');

  return (
    <BotRemoteUsersViewContext.Provider
      value={{ searchFilterQuery, setSearchFilterQuery }}
    >
      {children}
    </BotRemoteUsersViewContext.Provider>
  );
};
