import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { StatsResponse } from '@/store/stats';
import { AxiosRequestConfig } from 'axios';
import {
  QuestionsStatsPopularQuestionResponse,
  QuestionStatsPerConvoResponse,
} from './types';

const STATS_BASE_URL = `${API_HOST}/${ApiRoutes.Dashboard}`;

const getSummary = (botId: string, config?: AxiosRequestConfig) =>
  client.get<StatsResponse>(`${STATS_BASE_URL}/${botId}/questions/summary`, config);

const getAsked = (botId: string, config?: AxiosRequestConfig) =>
  client.get<StatsResponse>(`${STATS_BASE_URL}/${botId}/questions/asked`, config);

const getPopular = (botId: string, config?: AxiosRequestConfig) =>
  client.get<StatsResponse>(`${STATS_BASE_URL}/${botId}/questions/popular`, config);

const getPopularQuestion = (
  botId: string,
  questionId: string,
  config?: AxiosRequestConfig,
) =>
  client.get<QuestionsStatsPopularQuestionResponse>(
    `${STATS_BASE_URL}/${botId}/questions/popular/${questionId}`,
    config,
  );

const getUnanswered = (botId: string, config?: AxiosRequestConfig) =>
  client.get<StatsResponse>(`${STATS_BASE_URL}/${botId}/questions/unanswered`, config);

const deleteUnanswered = (
  botId: string,
  id: string,
  from: string,
  to: string,
  config?: AxiosRequestConfig,
) =>
  client.put<StatsResponse>(
    `${STATS_BASE_URL}/${botId}/questions/unanswered/${id}/tmp?from=${from}&to=${to}`,
    config,
  );

const getPerConvo = (botId: string, config?: AxiosRequestConfig) =>
  client.get<QuestionStatsPerConvoResponse>(
    `${STATS_BASE_URL}/${botId}/questions/conversation-messages`,
    config,
  );

export default {
  getSummary,
  getAsked,
  getPopular,
  getPopularQuestion,
  getUnanswered,
  getPerConvo,
  deleteUnanswered,
};
