import Typography from '@material-ui/core/Typography';
import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme }) => css`
    background-color: #fff;
    padding: ${theme.spacing(5)};
    border-radius: ${theme.shape.borderRadius}rem;
    text-align: left;
  `,
);

export const Heading = styled(Typography)`
  && {
    margin: 0 0 1.625rem 0;
  }
`;

export const Title = styled(Typography)(
  ({ theme }) => css`
    && {
      font-size: 1.5rem;
      margin-bottom: 1.625rem;
      ${theme.breakpoints.up('md')} {
        max-width: 52.8125rem;
        font-size: 2.625rem;
        margin-bottom: 3.25rem;
      }
    }
  `,
);

export const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  text-align: center;
`;
