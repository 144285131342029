import { all, call, put, takeEvery } from 'typed-redux-saga';
import { fetchLookupValues, lookupValuesComplete } from './actions';
import api from './api';

function* getLookupValuesSaga(
  action: ReturnType<typeof fetchLookupValues.request>,
): Generator {
  const { payload } = action;

  const luvs = Array.isArray(payload) ? payload : [payload];

  try {
    const responses = yield* all(luvs.map(luv => call(() => api.getLookupValues(luv))));

    yield all(
      responses.map((values, i) =>
        put(fetchLookupValues.success({ type: luvs[i], values: values.data })),
      ),
    );

    yield put(lookupValuesComplete());
  } catch (e) {
    yield put(fetchLookupValues.failure());
  }
}

export default [takeEvery(fetchLookupValues.request, getLookupValuesSaga)];
