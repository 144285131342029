import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, SEARCH_CRITERIA_QUERY_KEY } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { SearchCriteriaRequest, SearchCriteriaResponse } from '../types';

const getSearchCriteria = ({ botId }: SearchCriteriaRequest) => {
  return () => {
    return client.get<SearchCriteriaResponse>(
      `${API_HOST}/${ApiRoutes.Core}/transcripts/${botId}`,
    );
  };
};

export const useGetSearchCriteria = ({ botId }: SearchCriteriaRequest) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [botId, SEARCH_CRITERIA_QUERY_KEY],
    getSearchCriteria({ botId }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get search criteria'), {
          variant: 'error',
        });
      },
    },
  );

  const searchCriteria = data?.data;
  return { searchCriteria, isLoading };
};
