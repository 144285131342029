import { AppState } from '@/store';
import { createSelector } from 'reselect';
import { FormsSelectorProps } from './types';
import { prop } from 'lodash/fp';

export const getForms = (state: AppState) => state.forms;

export const getFormId = (state: AppState, props: FormsSelectorProps) => props?.formId;

export const getForm = createSelector(getFormId, getForms, (id, forms) =>
  id ? prop(id, forms) : null,
);
