import { prop } from 'lodash/fp';
import { createSelector } from 'reselect';
import { getForm } from '@/store/forms';
import { getLength } from '@/store/utils';
import { AppState } from '@/store';
import { Question, QuestionFormSelectorProps, QuestionsFilterProps } from './types';
import { filterByDraft, filterByFeatured, filterByPublished, statusMap } from './utils';

export * from './stats/selectors';

export const getQuestionsState = (state: AppState) => state.questions;

export const getQuestionId = (
  state: AppState,
  { isNew, questionId }: QuestionFormSelectorProps,
) => (isNew ? getForm(state, { formId: 'question' }) : questionId);

export const getFeatureKey = (
  state: AppState,
  { featureKey }: QuestionFormSelectorProps,
) => featureKey;

export const getQuestionsFilter = (state: AppState, props?: QuestionsFilterProps) =>
  statusMap?.[props?.filter || 'all'];

export const getQuestionsData = createSelector(
  getQuestionsState,
  questions => questions.data.questions,
);

export const getQuestion = createSelector(getQuestionId, getQuestionsData, prop);
export const getQuestionsStatuses = createSelector(
  getQuestionsState,
  questions => questions.status,
);
export const getQuestions = getQuestionsData;

export const getAllQuestionsCount = createSelector(
  getQuestionsData,
  getLength<Question>(),
);

export const getFeaturedQuestionsCount = createSelector(
  getQuestionsData,
  getLength<Question>(filterByFeatured),
);

export const getDraftQuestionsCount = createSelector(
  getQuestionsData,
  getLength<Question>(filterByDraft),
);

export const getActiveQuestionsCount = createSelector(
  getQuestionsData,
  getLength<Question>(filterByPublished),
);

export const getQuestionsSearchQuery = createSelector(
  getQuestionsState,
  questions => questions.searchQuery,
);
