import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import countries from '@/config/countries.json';
import { SelectValue } from '@/components/formElements/Select';
import { fetchLookupValues, Sector, LookupValuesTypes, getLuvs } from '@/store/luv';
import { AppState } from '@/store';

const luvs: Array<LookupValuesTypes> = ['sectors'];

export const useSectorsAndEmployeeOptions = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLookupValues.request(luvs));
  }, [dispatch]);

  const { sectors } = useSelector((state: AppState) => getLuvs(state, { type: luvs }));

  const sectorsOptions = useMemo<Array<SelectValue>>(
    () =>
      Object.values((sectors || {}) as Record<string, Sector>).map(sector => ({
        label: sector.name || '',
        value: sector.id || '',
      })),
    [sectors],
  );

  const countriesOptions = useMemo(
    () =>
      Object.entries(countries).map(([code, name]) => ({
        label: name,
        value: code,
      })),
    [],
  );

  const getCountrySelectValue = useCallback((code: string) => {
    const country = Object.entries(countries).find(
      ([countryCode]) => countryCode.toLowerCase() === code.toLowerCase(),
    );

    // Because we know it's a tuple of [code, name], using the index numbers
    // is safe here (TS would freak out if not).
    return country
      ? {
          label: country[1],
          value: country[0],
        }
      : {};
  }, []);

  return {
    getCountrySelectValue,
    countriesOptions,
    sectorsOptions,
  };
};
