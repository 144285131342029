import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, WEB_SEARCH_SETTINGS_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { UpdateWebSearchSettings } from '../types';

export const useUpdateWebSearchSettings = () => {
  const { toast } = useToast();

  const updateWebSearchSiteMutation = useMutation(
    ({ featureId, toggleSwitch }: UpdateWebSearchSettings) =>
      client.patch(`${API_HOST}/${ApiRoutes.WebSearch}/${featureId}/settings/general`, {
        enabled: toggleSwitch,
      }),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update websearch site'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(WEB_SEARCH_SETTINGS_QUERY_KEY);
      },
    },
  );

  const { mutateAsync: updateWebSearchSite, isLoading } = updateWebSearchSiteMutation;
  return { isLoading, updateWebSearchSite };
};
