import React, { useContext } from 'react';
import { UserProfileContext } from '@/contexts';
import { ONE_SIGNAL_APP_ID } from '@/config';
import Helmet from 'react-helmet';

export const BrowserNotifications = () => {
  const profile = useContext(UserProfileContext);
  const shouldInitiate = profile?.id && ONE_SIGNAL_APP_ID;

  return (
    <>
      {shouldInitiate && (
        <Helmet>
          <script src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" async></script>
          <script>
            {`var OneSignal = window.OneSignal || [];
            OneSignal.push(function() {
            OneSignal.init({
            appId: "${ONE_SIGNAL_APP_ID}",
            });
            OneSignal.setExternalUserId("${profile?.id}");
            }); `}
          </script>
        </Helmet>
      )}
    </>
  );
};

export default BrowserNotifications;
