import { ClientMiddlewares } from '@/store/client';
import headersMiddleware from './headersMiddleware';
import authMiddleware from './authMiddleware';
import apiMiddleware from './apiMiddleware';
import { unauthorizedMiddleware } from './unauthorizedMiddleware';

const middlewares: ClientMiddlewares = {
  request: [headersMiddleware, apiMiddleware, authMiddleware],
  response: [unauthorizedMiddleware],
};

export default middlewares;
