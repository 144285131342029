import React from 'react';
import { Color } from '@material-ui/core';

interface RectangleProps {
  color: number | string | Partial<Color>;
}

const Rectangle = (props: RectangleProps) => {
  const { color: baseColor } = props;
  const color = String(baseColor);

  return (
    <svg viewBox="0 0 307.79 64.07">
      <title>Rectangle</title>
      <path fill={color} d="M0 0h307.79v64.07H0z" />
    </svg>
  );
};

export default Rectangle;
