import {
  LiveChatConsolePage,
  LiveChatPage,
  LiveChatSettingsPage,
  LiveVideoSettingsListPage,
  LiveVideoSettingsPage,
} from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const dashboard: RoutingConfig = [
  {
    title: 'Live Video Settings',
    description: 'Manage the features and support times of your live video chat services',
    icon: 'SettingsIcon',
    component: LiveVideoSettingsListPage,
    exact: true,
    path: '/live-chat/video-settings',
    customProps: {
      billingGroupCodes: ['OUTSIDE:LIVECHAT', 'INSIDE:LIVECHAT'],
      permissionArea: BotPermissionCode.LIVE_CHAT_AGENT,
    },
  },
  {
    title: 'Live Video Settings',
    description: 'Manage the features and support times of your live video chat services',
    icon: 'ChevronIcon',
    component: LiveVideoSettingsPage,
    exact: true,
    path: '/live-chat/video-settings/:settingsId?',
    customProps: {
      billingGroupCodes: ['OUTSIDE:LIVECHAT', 'INSIDE:LIVECHAT'],
      permissionArea: BotPermissionCode.LIVE_CHAT_AGENT,
    },
  },
  {
    title: 'Live Chat Console',
    description: 'Manage your support team and help plan resource requirements',
    icon: 'LiveChatIcon',
    component: LiveChatConsolePage,
    path: '/live-chat/console',
    customProps: {
      billingGroupCodes: ['OUTSIDE:LIVECHAT', 'INSIDE:LIVECHAT'],
      permissionArea: BotPermissionCode.LIVE_CHAT_AGENT,
    },
  },
  {
    title: 'Live Chat Settings',
    description: 'Manage the features and support times of your live chat service',
    icon: 'SettingsIcon',
    component: LiveChatSettingsPage,
    path: '/live-chat/settings',
    customProps: {
      billingGroupCodes: ['OUTSIDE:LIVECHAT', 'INSIDE:LIVECHAT'],
      permissionArea: BotPermissionCode.LIVE_CHAT_ADMIN,
    },
  },
  {
    title: 'Live Chat',
    description: 'Live chat to your staff and resolve issues',
    icon: 'LiveChatIcon',
    component: LiveChatPage,
    path: '/live-chat/:userId?',
    customProps: {
      billingGroupCodes: ['OUTSIDE:LIVECHAT', 'INSIDE:LIVECHAT'],
      permissionArea: BotPermissionCode.LIVE_CHAT_AGENT,
    },
  },
];

export default dashboard;
