import { CSSProperties } from 'react';
import Colors from '@/styles/Colors';
import styled, { css, DefaultTheme } from 'styled-components';

export const CreatableMultiValueLabel = styled.div(
  ({ theme }) => css`
    padding-right: ${theme.spacing(1)};
  `,
);

export const CreatableMultiValueContainer = styled.div(
  ({ theme }) => css`
    font-size: ${theme.typography.h4.fontSize};
    display: flex;
    align-items: center;
    padding: ${theme.spacing(2)} ${theme.spacing(3)};
    border-radius: 25px;
    background: ${theme.palette.grey[300]};
    margin: ${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(0)} 0;
  `,
);

export const DownIcon = styled.div`
  width: 0.6875rem;
  margin-right: 0.875rem;
`;

const getControlBorder = (
  theme: DefaultTheme,
  focusedStyle: CSSProperties,
  isFocused?: boolean,
  error?: boolean,
): string => {
  if (error) {
    return `${focusedStyle.borderWidth} solid ${theme.palette.error.main}`;
  } else if (isFocused) {
    return `${focusedStyle.borderWidth} solid ${focusedStyle.borderColor}`;
  }
  return `${focusedStyle.borderWidth} solid ${theme?.colors?.midPaleGrey}`;
};

export const baseColorStyles = (theme: DefaultTheme, error?: boolean) => {
  const focused = theme?.overrides?.MuiOutlinedInput?.root?.['&$focused $notchedOutline'];
  return {
    control: (styles, { isFocused }) => {
      return {
        ...styles,
        ...theme?.overrides?.MuiOutlinedInput?.root,
        border: getControlBorder(theme, focused, isFocused, error),
        boxShadow: isFocused && !error ? focused.boxShadow : 0,
        padding: '0.25rem 0.5rem',
        '&:hover': {
          borderColor: error ? theme.palette.error.main : styles['&:hover'].borderColor,
        },
      };
    },
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        color: theme?.colors?.darkGrey,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: isFocused ? '#fde6e7' : 'transparent',
        },
      };
    },
    menu: styles => {
      return {
        ...styles,
        marginTop: '0.0625rem',
        zIndex: 2,
      };
    },
    placeholder: styles => {
      return {
        ...styles,
        ...theme?.overrides?.MuiOutlinedInput?.root,
        color: Colors.midGrey,
      };
    },
  };
};
