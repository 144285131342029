import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useQuery } from 'react-query';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, SURVEY_RESULTS_QUERY_KEY } from '@/queries';
import { SurveyResults } from '../types';
import { AxiosError } from 'axios';

const getSurveyResults = (featureId: string | null, surveyId: string) => {
  return () => {
    return client.get<SurveyResults>(
      `${API_HOST}/${ApiRoutes.Surveys}/${featureId}/surveys/${surveyId}/results`,
    );
  };
};

export const useGetSurveyResults = (featureId: string | null, surveyId: string) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [SURVEY_RESULTS_QUERY_KEY, featureId, surveyId],
    getSurveyResults(featureId, surveyId),
    {
      enabled: featureId && featureId !== null && surveyId ? true : false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        toast(extractServerErrorMsg(error, 'Failed to get survey results.'), {
          variant: 'error',
        });
      },
    },
  );

  const surveyResults = data?.data || ({} as SurveyResults);
  return { surveyResults, isLoading };
};
