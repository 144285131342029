import { useHistory } from 'react-router-dom';
import { useLocationPath } from './useLocationPath';
import { useCallback, useMemo } from 'react';
import { negate, isEmpty } from 'lodash/fp';

/**
 * useGoBack hook
 *
 * Hook that will fallback to the parent path if there is
 * no history to go back to
 *
 * @param {boolean} viaHistory Whether to ignore the history
 * @param {number} threshold Number of levels to ignore before falling back
 */
export const useGoBack = (viaHistory = true, threshold = 2) => {
  const history = useHistory();
  const parts = useLocationPath();
  const parentPath = useMemo(() => {
    // remove the last part (i.e where we currently are)
    parts.pop();
    return `/${parts.filter(negate(isEmpty)).join('/')}`;
  }, [parts]);

  return useCallback(() => {
    if (history.length > threshold && viaHistory) return history.goBack();

    // Since there is nowhere to go, push forward to parent path
    history.push(parentPath);
  }, [history, parentPath, viaHistory, threshold]);
};
