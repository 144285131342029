import { update } from 'lodash/fp';
import { ClientMiddleware } from '@/store/client';

const headersMiddleware = update('headers', headers => ({
  ...headers,
  'Content-Type': 'application/json',
  Accept: 'application/json',
})) as ClientMiddleware;

export default headersMiddleware;
