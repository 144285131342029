import React from 'react';
import { Color } from '@material-ui/core';

interface CircleProps {
  color: number | string | Partial<Color>;
}

const Circle = (props: CircleProps) => {
  const { color: baseColor } = props;
  const color = String(baseColor);

  return (
    <svg viewBox="0 0 827.21 827.19">
      <title>Circle</title>
      <path
        d="M0 413.6c0 228.43 185.16 413.59 413.6 413.59S827.2 642.03 827.2 413.6 642.04 0 413.6 0 0 185.15 0 413.6"
        fill={color}
      />
    </svg>
  );
};

export default Circle;
