export enum StateStatus {
  INITIATED = 'INITIATED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
}

export interface StatusError extends Error {
  status?: string | number;
  response?: Response;
}

export type RootStatus = Record<string, string>;
