import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import { enqueueToast, removeToast } from './actions';
import { EnqueueToast } from './types';

export const enqueueToastReducer = createReducer<Array<EnqueueToast>>([])
  .handleAction(enqueueToast, (state, { payload }) => {
    const toast: EnqueueToast = {
      ...payload,
      options: {
        key: uuidv4(),
        ...payload.options,
      },
    };

    return [...state, toast];
  })
  .handleAction(removeToast, (state, { payload }) => {
    const toastIndex = state.findIndex(({ options }) => options?.key === payload);

    // Uuid isn't in array
    if (toastIndex < 0) return state;

    state.splice(toastIndex, 1);
    return [...state];
  });

export default combineReducers({
  data: enqueueToastReducer,
});
