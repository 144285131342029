import { ClientCancelToken, createCancelToken, wasCancelled } from '@/store/client';
import { setQuestionForm } from '@/store/forms';
import { enqueueToast } from '@/store/toast';
import { equals } from 'lodash/fp';
import { all, call, put, takeEvery, takeLatest } from 'typed-redux-saga';
import {
  addQuestionTag,
  createAnswer,
  createQuestion,
  createUtterance,
  deleteAnswer,
  deleteQuestion,
  deleteUtterance,
  featuredOrderChanged,
  fetchQuestion,
  fetchQuestions,
  publishQuestion,
  draftQuestion,
  questionsBulkUpload,
  removeQuestionTag,
  reorderAnswers,
  setFeaturedQuestion,
  syncQuestion,
  updateAnswer,
  updateQuestion,
  updateUtterance,
  unpublishQuestion,
} from './actions';
import api from './api';
import { AnswerRequest, AnswerTypes } from './types';
import statsSagas from './stats/sagas';

const isImage = equals('IMAGE' as AnswerTypes);
const isAttachment = equals('ATTACHMENT' as AnswerTypes);

let questionsCancelToken: ClientCancelToken | undefined = undefined;
const questionCancelTokens: Record<string, ClientCancelToken> = {};
const syncCancelTokens: Record<string, ClientCancelToken> = {};

const featuredCancelTokens: Record<string, ClientCancelToken> = {};
let featuredOrderCancelToken: ClientCancelToken | undefined = undefined;

let createQuestionCancelToken: ClientCancelToken | undefined = undefined;
const updateQuestionCancelTokens: Record<string, ClientCancelToken> = {};
const deleteQuestionCancelTokens: Record<string, ClientCancelToken> = {};
const publishQuestionCancelTokens: Record<string, ClientCancelToken> = {};
const unpublishQuestionCancelTokens: Record<string, ClientCancelToken> = {};
const draftQuestionCancelTokens: Record<string, ClientCancelToken> = {};

const utteranceCancelTokens: Record<string, ClientCancelToken> = {};
const answerCancelTokens: Record<string, ClientCancelToken> = {};
const reorderAnswerCancelTokens: Record<string, ClientCancelToken> = {};
const tagsCancelTokens: Record<string, ClientCancelToken> = {};

const cancelQuestionsRequest = (message?: string) => {
  questionsCancelToken?.cancel(message);
  questionsCancelToken = createCancelToken();
};

const cancelQuestionRequest = (id: string, message?: string) => {
  questionCancelTokens?.[id]?.cancel(message);
  questionCancelTokens[id] = createCancelToken();
};

const cancelSyncRequest = (id: string, message?: string) => {
  syncCancelTokens?.[id]?.cancel(message);
  syncCancelTokens[id] = createCancelToken();
};

const cancelPublishRequest = (id: string, message?: string) => {
  publishQuestionCancelTokens?.[id]?.cancel(message);
  publishQuestionCancelTokens[id] = createCancelToken();
};

const cancelUnpublishRequest = (id: string, message?: string) => {
  publishQuestionCancelTokens?.[id]?.cancel(message);
  publishQuestionCancelTokens[id] = createCancelToken();
};

const cancelDraftRequest = (id: string, message?: string) => {
  draftQuestionCancelTokens?.[id]?.cancel(message);
  draftQuestionCancelTokens[id] = createCancelToken();
};

const cancelFeaturedRequest = (id: string, message?: string) => {
  questionCancelTokens?.[id]?.cancel(message);
  questionCancelTokens[id] = createCancelToken();
};

const cancelFeaturedOrderRequest = (message?: string) => {
  featuredOrderCancelToken?.cancel(message);
  featuredOrderCancelToken = createCancelToken();
};

const cancelCreateQuestionRequest = (message?: string) => {
  createQuestionCancelToken?.cancel(message);
  createQuestionCancelToken = createCancelToken();
};

const cancelUpdateQuestionRequest = (id: string, message?: string) => {
  updateQuestionCancelTokens?.[id]?.cancel(message);
  updateQuestionCancelTokens[id] = createCancelToken();
};

const cancelDeleteQuestionRequest = (id: string, message?: string) => {
  deleteQuestionCancelTokens?.[id]?.cancel(message);
  deleteQuestionCancelTokens[id] = createCancelToken();
};

const cancelUtteranceRequest = (id: string, message?: string) => {
  utteranceCancelTokens?.[id]?.cancel(message);
  utteranceCancelTokens[id] = createCancelToken();
};

const cancelAnswersRequest = (id: string, message?: string) => {
  answerCancelTokens?.[id]?.cancel(message);
  answerCancelTokens[id] = createCancelToken();
};

const cancelReorderAnswersRequest = (id: string, message?: string) => {
  answerCancelTokens?.[id]?.cancel(message);
  answerCancelTokens[id] = createCancelToken();
};

const cancelTagsRequest = (id: string, message?: string) => {
  tagsCancelTokens?.[id]?.cancel(message);
  tagsCancelTokens[id] = createCancelToken();
};

function* handleToastableError<TCallback extends (...args: any[]) => any>(
  e: Error,
  callback: TCallback,
) {
  if (wasCancelled(e)) return;

  yield put(callback(e));
}

/** Fetch Sagas */

export function* getAllQuestionsSaga({
  payload,
}: ReturnType<typeof fetchQuestions.request>) {
  cancelQuestionsRequest('New question request');

  try {
    if (!payload) throw new Error('Feature ID not present');

    const response = yield* call(() =>
      api.getQuestions(payload, {
        cancelToken: questionsCancelToken?.token,
      }),
    );

    yield put(fetchQuestions.success(response.data));
  } catch (e) {
    if (wasCancelled(e)) return;
    yield put(fetchQuestions.failure(e));
  }
}

export function* getSingleQuestionSaga({
  payload,
}: ReturnType<typeof fetchQuestion.request>) {
  const { featureId, questionId } = payload;

  if (!questionId) return;

  cancelQuestionRequest(questionId, 'New question request');

  if (!featureId) return;

  try {
    if (!questionId) throw new Error('Question ID not present');

    const response = yield* call(() =>
      api.getQuestion(featureId, questionId, {
        cancelToken: questionCancelTokens[questionId]?.token,
      }),
    );

    yield put(fetchQuestion.success(response.data));
  } catch (e) {
    if (wasCancelled(e)) return;
    yield put(fetchQuestion.failure(e));
  }
}

export function* syncQuestionSaga({ payload }: ReturnType<typeof syncQuestion.request>) {
  const { featureId, questionId } = payload;

  if (!questionId) return;

  cancelSyncRequest(questionId, 'New sync request');
  try {
    if (!featureId) throw new Error('Feature ID not present');

    const response = yield* call(() =>
      api.getQuestion(featureId, questionId, {
        cancelToken: syncCancelTokens[questionId]?.token,
      }),
    );

    yield put(syncQuestion.success(response.data));
  } catch (e) {
    if (wasCancelled(e)) return;
    yield put(syncQuestion.failure(e));
  }
}

export function* setFeaturedQuestionSaga({
  payload,
}: ReturnType<typeof setFeaturedQuestion.request>) {
  const { featureId, id, featured } = payload;

  cancelFeaturedRequest(id, 'New featured request');

  try {
    if (!featureId) throw new Error('Feature ID not present');

    yield* call(() =>
      api.setFeatured(featureId, id, featured, {
        cancelToken: featuredCancelTokens[id]?.token,
      }),
    );

    yield put(fetchQuestions.request(featureId));
    yield put(setFeaturedQuestion.success(payload));
    yield put(
      enqueueToast({
        message: `Question is ${featured ? 'now featured' : 'no longer featured'}`,
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (e) {
    if (wasCancelled(e)) return;
    yield put(setFeaturedQuestion.failure(e));
  }
}

export function* featuredOrderChangedSaga({
  payload,
}: ReturnType<typeof featuredOrderChanged.request>) {
  const { next, featureId } = payload;

  cancelFeaturedOrderRequest('New order sent');

  try {
    if (!featureId) throw new Error('Feature ID not present');

    yield* call(() =>
      api.reorderQuestions(featureId, next, {
        cancelToken: featuredOrderCancelToken?.token,
      }),
    );

    yield put(fetchQuestions.request(featureId));
    yield put(featuredOrderChanged.success(next));
  } catch (e) {
    if (wasCancelled(e)) return;
    yield put(featuredOrderChanged.failure(e));
  }
}

/** Question Sagas */

export function* createQuestionSaga({
  payload,
}: ReturnType<typeof createQuestion.request>) {
  const { featureId, ...body } = payload;

  cancelCreateQuestionRequest('New create request sent');

  try {
    if (!featureId || !payload) throw new Error('Feature ID not present');

    const response = yield* call(() =>
      api.createQuestion(featureId, body, {
        cancelToken: createQuestionCancelToken?.token,
      }),
    );

    yield put(setQuestionForm(response.data.id));
    yield put(createQuestion.success(response.data));
  } catch (e) {
    yield handleToastableError<typeof createQuestion.failure>(e, createQuestion.failure);
  }
}

export function* updateQuestionSaga({
  payload,
}: ReturnType<typeof updateQuestion.request>) {
  const { featureId, ...body } = payload;
  const { id } = body;

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!id) throw new Error('Question ID not present');

    cancelUpdateQuestionRequest(id, 'New update question requested');

    const response = yield* call(() =>
      api.updateQuestion(featureId, id, body, {
        cancelToken: updateQuestionCancelTokens[id]?.token,
      }),
    );
    yield put(updateQuestion.success(response.data));
  } catch (e) {
    yield handleToastableError<typeof updateQuestion.failure>(e, updateQuestion.failure);
  }
}

export function* deleteQuestionSaga({
  payload,
}: ReturnType<typeof deleteQuestion.request>) {
  const { featureId, ...body } = payload;
  const { id } = body;

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!id) throw new Error('Question ID not present');

    cancelDeleteQuestionRequest(id, 'New delete question requested');

    yield* call(() =>
      api.deleteQuestion(featureId, id, {
        cancelToken: deleteQuestionCancelTokens[id]?.token,
      }),
    );
    yield put(deleteQuestion.success({ id }));
  } catch (e) {
    yield handleToastableError<typeof deleteQuestion.failure>(e, deleteQuestion.failure);
  }
}

export function* publishQuestionSaga({
  payload,
}: ReturnType<typeof publishQuestion.request>) {
  const { featureId, questionId } = payload;

  cancelPublishRequest(questionId, 'New publish request');

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!questionId) throw new Error('Message ID not present');

    const response = yield* call(() =>
      api.publishQuestion(featureId, questionId, {
        cancelToken: publishQuestionCancelTokens[questionId]?.token,
      }),
    );

    yield put(publishQuestion.success(response.data));
    yield put(
      enqueueToast({
        message: 'Question published successfully',
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (e) {
    yield handleToastableError<typeof publishQuestion.failure>(
      e,
      publishQuestion.failure,
    );
  }
}

export function* unpublishQuestionSaga({
  payload,
}: ReturnType<typeof unpublishQuestion.request>) {
  const { featureId, questionId } = payload;

  cancelUnpublishRequest(questionId, 'New unpublish request');

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!questionId) throw new Error('Message ID not present');

    const response = yield* call(() =>
      api.unpublishQuestion(featureId, questionId, {
        cancelToken: unpublishQuestionCancelTokens[questionId]?.token,
      }),
    );

    yield put(unpublishQuestion.success(response.data));
    yield put(
      enqueueToast({
        message: 'Question unpublished successfully',
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (e) {
    yield handleToastableError<typeof unpublishQuestion.failure>(
      e,
      unpublishQuestion.failure,
    );
  }
}

export function* draftQuestionSaga({
  payload,
}: ReturnType<typeof draftQuestion.request>) {
  const { featureId, questionId } = payload;

  cancelDraftRequest(questionId, 'New draft request');

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!questionId) throw new Error('Message ID not present');

    const response = yield* call(() =>
      api.draftQuestion(featureId, questionId, {
        cancelToken: draftQuestionCancelTokens[questionId]?.token,
      }),
    );

    yield put(draftQuestion.success(response.data));
  } catch (e) {
    yield handleToastableError<typeof draftQuestion.failure>(e, draftQuestion.failure);
  }
}

/** Utterance Sagas */

export function* createUtteranceSaga({
  payload,
}: ReturnType<typeof createUtterance.request>) {
  const { featureId, questionId, ...body } = payload;

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!questionId) throw new Error('Question ID not present');

    const response = yield* call(() => api.createUtterance(featureId, questionId, body));

    yield put(createUtterance.success({ questionId, ...response.data }));

    // Call to keep data in sync. We update the state directly, so that
    // the changes appear immediately to the HOCs, but we make a call out to get
    // the question so that the data in redux isn't stale
    yield put(syncQuestion.request({ featureId, questionId }));
  } catch (e) {
    yield handleToastableError<typeof createUtterance.failure>(
      e,
      createUtterance.failure,
    );
  }
}

export function* updateUtteranceSaga({
  payload,
}: ReturnType<typeof updateUtterance.request>) {
  const { featureId, questionId, ...body } = payload;
  const { id } = body;

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!questionId) throw new Error('Question ID not present');
    if (!id) throw new Error('Utterance ID not present');

    cancelUtteranceRequest(id, 'New update utterance requested');

    const response = yield* call(() =>
      api.updateUtterance(featureId, questionId, id, body, {
        cancelToken: utteranceCancelTokens[id]?.token,
      }),
    );
    yield put(updateUtterance.success({ questionId, ...response.data }));
  } catch (e) {
    yield handleToastableError<typeof updateUtterance.failure>(
      e,
      updateUtterance.failure,
    );
  }
}

export function* deleteUtteranceSaga({
  payload,
}: ReturnType<typeof deleteUtterance.request>) {
  const { featureId, questionId, id } = payload;

  cancelUtteranceRequest(id, 'New delete utterance requested');

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!questionId) throw new Error('Question ID not present');
    if (!id) throw new Error('Utterance ID not present');

    yield* call(() =>
      api.deleteUtterance(featureId, questionId, id, {
        cancelToken: utteranceCancelTokens[id]?.token,
      }),
    );
    yield put(deleteUtterance.success(payload));

    // Call to keep data in sync. We update the state directly, so that
    // the changes appear immediately to the HOCs, but we make a call out to get
    // the question so that the data in redux isn't stale
    yield put(syncQuestion.request({ featureId, questionId }));
  } catch (e) {
    yield handleToastableError<typeof deleteUtterance.failure>(
      e,
      deleteUtterance.failure,
    );
  }
}

/** Answer sagas */

const handleAnswerUpload = async (payload: AnswerRequest) => {
  const { type, featureId, messageId } = payload;
  const newPayload = payload;

  if (!featureId) throw new Error('No Feature ID provided');
  if (!messageId) throw new Error('No Message ID provided');

  if (isImage(type) && payload?.fileData) {
    const response = await api.uploadImage(featureId, messageId, payload?.fileData);
    if (response?.fileId) newPayload.fileId = response.fileId;
    delete newPayload.file;
  }
  if (isAttachment(type) && payload?.fileData) {
    const response = await api.uploadattachment(featureId, messageId, payload?.fileData);
    if (response?.fileId) newPayload.fileId = response.fileId;
    delete newPayload.file;
  }

  return newPayload;
};

export function* createAnswerSaga({ payload }: ReturnType<typeof createAnswer.request>) {
  const { featureId, messageId, questionId } = payload;

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!messageId) throw new Error('Message ID not present');

    const body = yield* call(() => handleAnswerUpload(payload));

    const response = yield* call(() => api.createAnswer(featureId, messageId, body));

    yield put(createAnswer.success({ messageId, ...response.data }));

    // Call to keep data in sync. We update the state directly, so that
    // the changes appear immediately to the HOCs, but we make a call out to get
    // the question so that the data in redux isn't stale
    yield put(syncQuestion.request({ featureId, questionId }));
  } catch (e) {
    yield handleToastableError<typeof createAnswer.failure>(e, createAnswer.failure);
  }
}

export function* updateAnswerSaga({ payload }: ReturnType<typeof updateAnswer.request>) {
  const { featureId, messageId, questionId, ...body } = payload;
  const { id } = body;

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!messageId) throw new Error('Question ID not present');
    if (!id) throw new Error('Answer ID not present');

    cancelAnswersRequest(id, 'New update answer requested');

    const responseBody = yield* call(() => handleAnswerUpload(payload));

    const response = yield* call(() =>
      api.updateAnswer(featureId, messageId, id, responseBody, {
        cancelToken: answerCancelTokens[id]?.token,
      }),
    );
    yield put(updateAnswer.success({ messageId, ...response.data }));

    // Call to keep data in sync. We update the state directly, so that
    // the changes appear immediately to the HOCs, but we make a call out to get
    // the question so that the data in redux isn't stale
    yield put(syncQuestion.request({ featureId, questionId }));
  } catch (e) {
    yield handleToastableError<typeof updateAnswer.failure>(e, updateAnswer.failure);
  }
}

export function* deleteAnswerSaga({ payload }: ReturnType<typeof deleteAnswer.request>) {
  const { featureId, messageId, id, questionId } = payload;

  cancelAnswersRequest(id, 'New delete answer requested');

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!messageId) throw new Error('Message ID not present');
    if (!id) throw new Error('Answer ID not present');

    yield* call(() =>
      api.deleteAnswer(featureId, messageId, id, {
        cancelToken: answerCancelTokens[id]?.token,
      }),
    );
    yield put(deleteAnswer.success(payload));

    // Call to keep data in sync. We update the state directly, so that
    // the changes appear immediately to the HOCs, but we make a call out to get
    // the question so that the data in redux isn't stale
    yield put(syncQuestion.request({ featureId, questionId }));
  } catch (e) {
    yield handleToastableError<typeof deleteAnswer.failure>(e, deleteAnswer.failure);
  }
}

export function* reorderAnswersSaga({
  payload,
}: ReturnType<typeof reorderAnswers.request>) {
  const { featureId, messageId, answers, questionId } = payload;

  cancelReorderAnswersRequest(messageId, 'New reorder answers requested');

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!messageId) throw new Error('Message ID not present');

    const response = yield* call(() =>
      api.reorderAnswers(featureId, messageId, answers, {
        cancelToken: reorderAnswerCancelTokens[messageId]?.token,
      }),
    );
    yield put(reorderAnswers.success({ questionId, answers: response.data }));
  } catch (e) {
    yield handleToastableError<typeof reorderAnswers.failure>(e, reorderAnswers.failure);
  }
}

/** Question Tag sagas */

export function* addQuestionTagSaga({
  payload,
}: ReturnType<typeof addQuestionTag.request>) {
  const { featureId, questionId, ...body } = payload;

  if (!body?.value) return;

  const { value } = body;

  cancelTagsRequest(value, 'New add question tag requested');

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!questionId) throw new Error('Question ID not present');

    const response = yield* call(() =>
      api.addQuestionTag(featureId, questionId, body, {
        cancelToken: tagsCancelTokens[value]?.token,
      }),
    );
    yield put(addQuestionTag.success({ questionId, ...response.data }));

    // Call to keep data in sync. We update the state directly, so that
    // the changes appear immediately to the HOCs, but we make a call out to get
    // the question so that the data in redux isn't stale
    yield put(syncQuestion.request({ featureId, questionId }));
  } catch (e) {
    yield handleToastableError<typeof addQuestionTag.failure>(e, addQuestionTag.failure);
  }
}

export function* removeQuestionTagSaga({
  payload,
}: ReturnType<typeof removeQuestionTag.request>) {
  const { featureId, questionId, id, ...body } = payload;

  cancelTagsRequest(id, 'New remove question tag requested');

  try {
    if (!featureId) throw new Error('Feature ID not present');
    if (!questionId) throw new Error('Question ID not present');
    if (!id) throw new Error('Tag ID not present');

    yield* call(() =>
      api.removeQuestionTag(featureId, questionId, id, {
        cancelToken: tagsCancelTokens[id]?.token,
      }),
    );
    yield put(removeQuestionTag.success({ questionId, id, ...body }));

    // Call to keep data in sync. We update the state directly, so that
    // the changes appear immediately to the HOCs, but we make a call out to get
    // the question so that the data in redux isn't stale
    yield put(syncQuestion.request({ featureId, questionId }));
  } catch (e) {
    yield handleToastableError<typeof removeQuestionTag.failure>(
      e,
      removeQuestionTag.failure,
    );
  }
}

function* questionsBulkUploadSaga(
  action: ReturnType<typeof questionsBulkUpload.request>,
): Generator {
  // Request payload passes the orgId
  const { payload } = action;
  const { featureId, files } = payload;

  try {
    if (!featureId) throw new Error('No feature ID provided');
    if (!files.length) throw new Error('No files provided');

    yield* all([...files].map((file: File) => api.uploadBulkTemplate(featureId, file)));

    yield put(questionsBulkUpload.success());
  } catch (e) {
    yield put(questionsBulkUpload.failure());
  }
}

export default [
  takeEvery(fetchQuestions.request, getAllQuestionsSaga),
  takeEvery(fetchQuestion.request, getSingleQuestionSaga),
  takeEvery(syncQuestion.request, syncQuestionSaga),

  takeLatest(featuredOrderChanged.request, featuredOrderChangedSaga),
  takeLatest(setFeaturedQuestion.request, setFeaturedQuestionSaga),

  takeLatest(createQuestion.request, createQuestionSaga),
  takeLatest(updateQuestion.request, updateQuestionSaga),
  takeLatest(deleteQuestion.request, deleteQuestionSaga),
  takeLatest(publishQuestion.request, publishQuestionSaga),
  takeLatest(unpublishQuestion.request, unpublishQuestionSaga),
  takeLatest(draftQuestion.request, draftQuestionSaga),

  takeLatest(createUtterance.request, createUtteranceSaga),
  takeLatest(updateUtterance.request, updateUtteranceSaga),
  takeLatest(deleteUtterance.request, deleteUtteranceSaga),

  takeLatest(createAnswer.request, createAnswerSaga),
  takeLatest(updateAnswer.request, updateAnswerSaga),
  takeLatest(deleteAnswer.request, deleteAnswerSaga),
  takeLatest(reorderAnswers.request, reorderAnswersSaga),

  takeLatest(addQuestionTag.request, addQuestionTagSaga),
  takeLatest(removeQuestionTag.request, removeQuestionTagSaga),

  takeLatest(questionsBulkUpload.request, questionsBulkUploadSaga),
  ...statsSagas,
];
