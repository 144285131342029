import styled from 'styled-components';
import { IconWrapperProps } from './types';

export const IconWrapper = styled.span<IconWrapperProps>`
  svg {
    object-fit: contain;

    fill: ${({ theme, color }) => color && theme?.palette?.[color]?.main};

    ellipse,
    circle,
    path {
      fill: ${({ theme, color }) => color && theme?.palette?.[color]?.main};
    }
  }
`;
