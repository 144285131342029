import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { ApiRoutes, fileUpload } from '@/store/client';
import { UploadOrganisationLogoRequest } from '../types';

export const useUploadOrganisationLogo = () => {
  const { toast } = useToast();

  const uploadOrganisationLogoMutation = useMutation(
    ({ orgId, logoFile }: UploadOrganisationLogoRequest) => {
      return fileUpload(
        `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/logo`,
        logoFile,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(errorResponse, 'Failed to upload organisation logo'),
          {
            variant: 'error',
          },
        );
      },
      onSuccess: () => {
        toast('organisation logo uploaded successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: uploadOrganisationLogo,
    isLoading,
    error,
  } = uploadOrganisationLogoMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, error, uploadOrganisationLogo, serverErrors };
};
