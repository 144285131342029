import React, { FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { withAuth } from '@/hoc';
import { renderRoute } from '@/routing';
import { PlatformRoleCode } from '@/store/auth/types';
import { AuthenticatedRouteProps } from './types';

export const AuthenticatedRoute: FunctionComponent<AuthenticatedRouteProps> = ({
  authenticated,
  profile,
  ...props
}) => {
  const { pathname, search } = useLocation();

  if (!authenticated)
    return <Redirect to={`/login?redirect=${pathname}${encodeURIComponent(search)}`} />;
  if (
    authenticated &&
    pathname?.search('/onboarding') === -1 &&
    profile?.platformRole?.code === PlatformRoleCode.FirstTime
  ) {
    return <Redirect to={`/login?redirect=${encodeURIComponent('/onboarding/')}`} />;
  }
  return <>{renderRoute({ ...props })}</>;
};

export default withAuth(AuthenticatedRoute);
