import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import {
  LIVE_VIDEO_SETTINGS_LIST_QUERY_KEY,
  LIVE_VIDEO_SETTINGS_QUERY_KEY,
} from '../constants';
import { UpdateLiveVideoSettingsRequest } from '../types';

export const useUpdateLiveVideoSettings = () => {
  const { toast } = useToast();

  const updateLiveVideoSettingsMutation = useMutation(
    ({ botId, settingsId, payload }: UpdateLiveVideoSettingsRequest) =>
      client.patch<any>(
        `${API_HOST}/${ApiRoutes.Video}/bots/${botId}/settings/${settingsId}`,
        payload,
      ),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update settings.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { botId, settingsId }) => {
        queryClient.invalidateQueries([LIVE_VIDEO_SETTINGS_QUERY_KEY, botId, settingsId]);
        queryClient.invalidateQueries([LIVE_VIDEO_SETTINGS_LIST_QUERY_KEY, botId]);
        toast('Successfully updated settings.', { variant: 'success' });
      },
    },
  );

  const {
    mutateAsync: updateLiveVideoSettings,
    isLoading: isUpdatingLiveVideoSettings,
    error,
  } = updateLiveVideoSettingsMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isUpdatingLiveVideoSettings, updateLiveVideoSettings, serverErrors };
};
