import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  SendInvitationPayload,
  SendInvitationData,
  INVITATION_SENDING_SETTINGS_QUERY_KEY,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';

export const useSendInvitation = () => {
  const { toast } = useToast();

  const sendInvitationMutation = useMutation(
    ({ featureId, data }: SendInvitationPayload) => {
      return client.post<SendInvitationData>(
        `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/invitations`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError, { featureId }) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to send invitation'), {
          variant: 'error',
        });
        if (errorResponse.response?.status !== 422) {
          queryClient.invalidateQueries([
            INVITATION_SENDING_SETTINGS_QUERY_KEY,
            featureId,
          ]);
        }
      },
      onSuccess: () => {
        toast('Invitation sent successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: sendInvitation, isLoading, error } = sendInvitationMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, sendInvitation };
};
