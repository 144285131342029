import { withStats } from '@/hoc';
import { WithStatsProps } from '@/hoc/withStats';
import { Statistic } from '@/store/stats';
import {
  fetchQuestionsStatsPerConvo,
  getQuestionsStatsPerConvo,
  getQuestionsStatuses,
} from '@/store/questions';
import { ComponentType } from 'react';

export type WithQuestionsStatsPerConvoShape = {
  questions: Array<Statistic>;
  average?: Statistic;
};

export const withQuestionsStatsPerConvo = <
  TProps extends WithQuestionsStatsPerConvoProps
>(
  Component: ComponentType<TProps>,
) =>
  withStats<
    TProps,
    typeof fetchQuestionsStatsPerConvo.request,
    WithQuestionsStatsPerConvoShape
  >(
    Component,
    fetchQuestionsStatsPerConvo.request,
    getQuestionsStatsPerConvo,
    getQuestionsStatuses,
  );

export interface WithQuestionsStatsPerConvoProps extends WithStatsProps {
  stats: WithQuestionsStatsPerConvoShape;
  fetchStats: typeof fetchQuestionsStatsPerConvo.request;
}
