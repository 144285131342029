import { useCallback, useContext, useMemo } from 'react';
import { keyBy } from 'lodash';
import { propEq } from 'lodash/fp';

import { SelectValue } from '@/components/formElements/Select';
import { BotIdContext, OrgContext } from '@/contexts';
import { Tag, useGetBotTags } from '@/queries';

const isNewTag = propEq('__isNew__', true);

export const useBotTags = () => {
  const { orgId } = useContext(OrgContext);
  const botId = useContext(BotIdContext);

  const { botTags, isLoading: isFetchingBotTags } = useGetBotTags({ orgId, botId });
  const tags = keyBy(botTags, 'id');

  const selectOptionToTag = useCallback(
    (option: SelectValue): Tag => ({
      id: isNewTag(option) ? '' : option?.value,
      value: option?.label,
    }),
    [],
  );

  const selectOptionsToTags = useCallback(
    (selectOptions: Array<SelectValue>) =>
      selectOptions.reduce<Array<Tag>>(
        (accum, data) => (data ? [...accum, selectOptionToTag?.(data)] : accum),
        [],
      ),
    [selectOptionToTag],
  );

  const tagsToSelectOptions = useCallback(
    (tagsArray: Array<Tag>) =>
      tagsArray
        ? tagsArray.map<SelectValue>(tag => ({ label: tag.value, value: tag.id }))
        : [],
    [],
  );

  const tagOptions = useMemo(() => tagsToSelectOptions(Object.values(tags || {})), [
    tagsToSelectOptions,
    tags,
  ]);

  return {
    isNewTag,
    tagOptions,
    selectOptionsToTags,
    selectOptionToTag,
    tagsToSelectOptions,
    isFetchingBotTags,
  };
};
