import { useMemo, useContext } from 'react';
import { keyBy } from 'lodash';
import { FeatureContext } from '@/contexts';
import { useGetSurveyTemplates } from '@/queries';

export const useSurveyTemplates = () => {
  const { id: featureId } = useContext(FeatureContext) ?? { id: '' };

  const { surveyTemplates: rawSurveyTemplates, isLoading } = useGetSurveyTemplates(
    featureId,
  );

  const surveyTemplates = useMemo(() => {
    return {
      [featureId]: {
        ...keyBy(rawSurveyTemplates, 'id'),
      },
    };
  }, [rawSurveyTemplates]);

  return { surveyTemplates, isLoading };
};
