import React, { FunctionComponent } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { TextInputProps } from './types';
import styled from 'styled-components';

export type TextareaProps = TextInputProps & TextFieldProps;

const StyledTextArea = styled(TextField)`
  background: ${({ theme }) => theme.palette.background.paper};
`;

export const Textarea: FunctionComponent<TextareaProps> = props => (
  <StyledTextArea
    {...props}
    inputProps={
      props?.name
        ? {
            'data-testid': `input-${props.name}`,
          }
        : {}
    }
    FormHelperTextProps={
      props?.name
        ? {
            // @ts-ignore
            'data-testid': `form-helper-${props.name}`,
          }
        : {}
    }
  />
);

export default Textarea;
