import { useMemo, useCallback } from 'react';
import countries from '@/config/countries.json';

export const useCountriesList = () => {
  const countriesOptions = useMemo(
    () =>
      Object.entries(countries).map(([code, name]) => ({
        label: name,
        value: code,
      })),
    [],
  );

  const getCountrySelectValue = useCallback((code: string) => {
    const country = Object.entries(countries).find(
      ([countryCode]) => countryCode.toLowerCase() === code.toLowerCase(),
    );

    // Because we know it's a tuple of [code, name], using the index numbers
    // is safe here (TS would freak out if not).
    return country
      ? {
          label: country[1],
          value: country[0],
        }
      : {};
  }, []);

  return { countriesOptions, getCountrySelectValue };
};
