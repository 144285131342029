import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import {
  ReorderMessageBubbleRequest,
  ErrorMessage,
  extractServerErrorMsg,
  getReorderMessageContextUrl,
  MessageBubble,
} from '@/queries';
import client, { ClientCancelToken, wasCancelled } from '@/store/client';

const reorderMessagesCancelTokens: Record<string, ClientCancelToken> = {};

export const useReorderMessageBubble = () => {
  const { toast } = useToast();

  const reorderMessageBubbleMutation = useMutation(
    ({ botId, featureId, messageId, bubblesIds }: ReorderMessageBubbleRequest) => {
      return client.post<MessageBubble>(
        getReorderMessageContextUrl(messageId, botId, featureId),
        bubblesIds,
        {
          cancelToken: reorderMessagesCancelTokens[messageId]?.token,
        },
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        if (wasCancelled(errorResponse)) return;
        toast(extractServerErrorMsg(errorResponse, 'Failed to reorder'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast('Message bubble order saved.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: reorderMessageBubble,
    isLoading,
    error,
  } = reorderMessageBubbleMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, reorderMessageBubble };
};
