import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, INVOICES_QUERY_KEY, Invoices } from '@/queries';
import client, {
  ApiRoutes,
  ClientCancelToken,
  createCancelToken,
  wasCancelled,
} from '@/store/client';

let invoicesCancelToken: ClientCancelToken | undefined = undefined;

const cancelInvoicesRequest = (message?: string) => {
  invoicesCancelToken && invoicesCancelToken.cancel(message);
  invoicesCancelToken = createCancelToken();
};

const getInvoices = (orgId?: string | null) => {
  cancelInvoicesRequest('New invoices requested');

  return () => {
    return client.get<Invoices>(
      `${API_HOST}/${ApiRoutes.Billing}/organisations/${orgId}/billing/invoices`,
      {
        cancelToken: invoicesCancelToken?.token,
      },
    );
  };
};

export const useGetInvoices = (orgId?: string | null) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery([INVOICES_QUERY_KEY, orgId], getInvoices(orgId), {
    // disable the query from automatically
    // running when 'enabled' is false
    enabled: orgId ? true : false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onError: (errorResponse: AxiosError) => {
      if (wasCancelled(errorResponse)) return;
      toast(
        extractServerErrorMsg(errorResponse, 'Failed to get organisation invoices.'),
        {
          variant: 'error',
        },
      );
    },
  });

  const orgInvoices = data?.data;
  return { orgInvoices, isLoading };
};
