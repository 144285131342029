import { pipe, props, reject, isEmpty, join } from 'lodash/fp';
import { OrganisationAddress } from '@/queries';

export const getDefaultAddressString = pipe(
  props<OrganisationAddress>([
    'addressLine1',
    'addressLine2',
    'city',
    'county',
    'country',
    'postcode',
  ]),
  reject(isEmpty),
  join(', '),
);
