import { createSelector } from 'reselect';
import { pick, prop } from 'lodash/fp';
import { AppState } from '@/store';
import { LuvSelectorProps } from './types';

const getLuvSelectorType = (state: AppState, props?: LuvSelectorProps) => props?.type;

export const getLuvState = (state: AppState) => state.luv;

export const getLuvData = createSelector(getLuvState, luv => luv.data);
export const getLuvs = createSelector(getLuvData, getLuvSelectorType, (data, filter) => {
  if (!data) return {};
  if (!filter) return data;

  return Array.isArray(filter) ? pick(filter, data) : prop(filter, data);
});
