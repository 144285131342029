import React, { useState, createContext } from 'react';
import { noop } from '@/utils';

type PlatformUsersContextProps = {
  searchFilterQuery: string;
  setSearchFilterQuery: (searchFilterQuery: string) => void;
};

// PlatformUsers context
export const PlatformUsersContext = createContext<PlatformUsersContextProps>({
  searchFilterQuery: '',
  setSearchFilterQuery: noop,
});

// PlatformUsers context provider
export const PlatformUsersContextProvider = ({ children }: { children?: any }) => {
  const [searchFilterQuery, setSearchFilterQuery] = useState<string>('');

  return (
    <PlatformUsersContext.Provider value={{ searchFilterQuery, setSearchFilterQuery }}>
      {children}
    </PlatformUsersContext.Provider>
  );
};
