import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  AnswerDeleteRequest,
  AnswerDeleteResult,
  AnswerReorderResult,
  AnswerRequest,
  AnswerResult,
  AnswersReorderRequest,
  Question,
  QuestionBulkUploadRequest,
  QuestionDeleteResponse,
  QuestionGetRequest,
  QuestionPublishRequest,
  QuestionDraftRequest,
  QuestionRequest,
  QuestionsFeaturedOrderChangeRequest,
  QuestionsFeaturedOrderChangeResult,
  QuestionsSetFeaturedRequest,
  QuestionsSetFeaturedResponse,
  QuestionTagAddRequest,
  QuestionTagAddResult,
  QuestionTagRemoveRequest,
  QuestionTagRemoveResult,
  UtteranceDeleteRequest,
  UtteranceDeleteResult,
  UtteranceRequest,
  UtteranceResult,
  QuestionUnPublishRequest,
} from './types';

export const QUESTIONS_GET_ALL_QUESTIONS = 'QUESTIONS_GET_ALL_QUESTIONS';
export const QUESTIONS_GET_ALL_QUESTIONS_INITIATED =
  'QUESTIONS_GET_ALL_QUESTIONS_INITIATED';
export const QUESTIONS_GET_ALL_QUESTIONS_SUCCESS = 'QUESTIONS_GET_ALL_QUESTIONS_SUCCESS';
export const QUESTIONS_GET_ALL_QUESTIONS_FAILED = 'QUESTIONS_GET_ALL_QUESTIONS_FAILED';

export const QUESTIONS_GET_QUESTION = 'QUESTIONS_GET_QUESTION';
export const QUESTIONS_GET_QUESTION_INITIATED = 'QUESTIONS_GET_QUESTION_INITIATED';
export const QUESTIONS_GET_QUESTION_SUCCESS = 'QUESTIONS_GET_QUESTION_SUCCESS';
export const QUESTIONS_GET_QUESTION_FAILED = 'QUESTIONS_GET_QUESTION_FAILED';

export const QUESTIONS_SYNC_QUESTION = 'QUESTIONS_SYNC_QUESTION';
export const QUESTIONS_SYNC_QUESTION_INITIATED = 'QUESTIONS_SYNC_QUESTION_INITIATED';
export const QUESTIONS_SYNC_QUESTION_SUCCESS = 'QUESTIONS_SYNC_QUESTION_SUCCESS';
export const QUESTIONS_SYNC_QUESTION_FAILED = 'QUESTIONS_SYNC_QUESTION_FAILED';

export const QUESTIONS_SET_FEATURED_QUESTION = 'QUESTIONS_SET_FEATURED_QUESTION';
export const QUESTIONS_SET_FEATURED_QUESTION_INITIATED =
  'QUESTIONS_SET_FEATURED_QUESTION_INITIATED';
export const QUESTIONS_SET_FEATURED_QUESTION_SUCCESS =
  'QUESTIONS_SET_FEATURED_QUESTION_SUCCESS';
export const QUESTIONS_SET_FEATURED_QUESTION_FAILED =
  'QUESTIONS_SET_FEATURED_QUESTION_FAILED';

export const QUESTIONS_CHANGE_FEATURED_ORDER = 'QUESTIONS_CHANGE_FEATURED_ORDER';
export const QUESTIONS_CHANGE_FEATURED_ORDER_INITIATED =
  'QUESTIONS_CHANGE_FEATURED_ORDER_INITIATED';
export const QUESTIONS_CHANGE_FEATURED_ORDER_SUCCESS =
  'QUESTIONS_CHANGE_FEATURED_ORDER_SUCCESS';
export const QUESTIONS_CHANGE_FEATURED_ORDER_FAILED =
  'QUESTIONS_CHANGE_FEATURED_ORDER_FAILED';

export const QUESTIONS_CREATE_QUESTION = 'QUESTIONS_CREATE_QUESTION';
export const QUESTIONS_CREATE_QUESTION_INITIATED = 'QUESTIONS_CREATE_QUESTION_INITIATED';
export const QUESTIONS_CREATE_QUESTION_SUCCESS = 'QUESTIONS_CREATE_QUESTION_SUCCESS';
export const QUESTIONS_CREATE_QUESTION_FAILED = 'QUESTIONS_CREATE_QUESTION_FAILED';

export const QUESTIONS_UPDATE_QUESTION = 'QUESTIONS_UPDATE_QUESTION';
export const QUESTIONS_UPDATE_QUESTION_INITIATED = 'QUESTIONS_UPDATE_QUESTION_INITIATED';
export const QUESTIONS_UPDATE_QUESTION_SUCCESS = 'QUESTIONS_UPDATE_QUESTION_SUCCESS';
export const QUESTIONS_UPDATE_QUESTION_FAILED = 'QUESTIONS_UPDATE_QUESTION_FAILED';

export const QUESTIONS_DELETE_QUESTION = 'QUESTIONS_DELETE_QUESTION';
export const QUESTIONS_DELETE_QUESTION_INITIATED = 'QUESTIONS_DELETE_QUESTION_INITIATED';
export const QUESTIONS_DELETE_QUESTION_SUCCESS = 'QUESTIONS_DELETE_QUESTION_SUCCESS';
export const QUESTIONS_DELETE_QUESTION_FAILED = 'QUESTIONS_DELETE_QUESTION_FAILED';

export const QUESTIONS_CREATE_UTTERANCE = 'QUESTIONS_CREATE_UTTERANCE';
export const QUESTIONS_CREATE_UTTERANCE_INITIATED =
  'QUESTIONS_CREATE_UTTERANCE_INITIATED';
export const QUESTIONS_CREATE_UTTERANCE_SUCCESS = 'QUESTIONS_CREATE_UTTERANCE_SUCCESS';
export const QUESTIONS_CREATE_UTTERANCE_FAILED = 'QUESTIONS_CREATE_UTTERANCE_FAILED';

export const QUESTIONS_UPDATE_UTTERANCE = 'QUESTIONS_UPDATE_UTTERANCE';
export const QUESTIONS_UPDATE_UTTERANCE_INITIATED =
  'QUESTIONS_UPDATE_UTTERANCE_INITIATED';
export const QUESTIONS_UPDATE_UTTERANCE_SUCCESS = 'QUESTIONS_UPDATE_UTTERANCE_SUCCESS';
export const QUESTIONS_UPDATE_UTTERANCE_FAILED = 'QUESTIONS_UPDATE_UTTERANCE_FAILED';

export const QUESTIONS_DELETE_UTTERANCE = 'QUESTIONS_DELETE_UTTERANCE';
export const QUESTIONS_DELETE_UTTERANCE_INITIATED =
  'QUESTIONS_DELETE_UTTERANCE_INITIATED';
export const QUESTIONS_DELETE_UTTERANCE_SUCCESS = 'QUESTIONS_DELETE_UTTERANCE_SUCCESS';
export const QUESTIONS_DELETE_UTTERANCE_FAILED = 'QUESTIONS_DELETE_UTTERANCE_FAILED';

export const QUESTIONS_CREATE_ANSWER = 'QUESTIONS_CREATE_ANSWER';
export const QUESTIONS_CREATE_ANSWER_INITIATED = 'QUESTIONS_CREATE_ANSWER_INITIATED';
export const QUESTIONS_CREATE_ANSWER_SUCCESS = 'QUESTIONS_CREATE_ANSWER_SUCCESS';
export const QUESTIONS_CREATE_ANSWER_FAILED = 'QUESTIONS_CREATE_ANSWER_FAILED';

export const QUESTIONS_UPDATE_ANSWER = 'QUESTIONS_UPDATE_ANSWER';
export const QUESTIONS_UPDATE_ANSWER_INITIATED = 'QUESTIONS_UPDATE_ANSWER_INITIATED';
export const QUESTIONS_UPDATE_ANSWER_SUCCESS = 'QUESTIONS_UPDATE_ANSWER_SUCCESS';
export const QUESTIONS_UPDATE_ANSWER_FAILED = 'QUESTIONS_UPDATE_ANSWER_FAILED';

export const QUESTIONS_DELETE_ANSWER = 'QUESTIONS_DELETE_ANSWER';
export const QUESTIONS_DELETE_ANSWER_INITIATED = 'QUESTIONS_DELETE_ANSWER_INITIATED';
export const QUESTIONS_DELETE_ANSWER_SUCCESS = 'QUESTIONS_DELETE_ANSWER_SUCCESS';
export const QUESTIONS_DELETE_ANSWER_FAILED = 'QUESTIONS_DELETE_ANSWER_FAILED';

export const QUESTIONS_REORDER_ANSWERS = 'QUESTIONS_REORDER_ANSWERS';
export const QUESTIONS_REORDER_ANSWERS_INITIATED = 'QUESTIONS_REORDER_ANSWERS_INITIATED';
export const QUESTIONS_REORDER_ANSWERS_SUCCESS = 'QUESTIONS_REORDER_ANSWERS_SUCCESS';
export const QUESTIONS_REORDER_ANSWERS_FAILED = 'QUESTIONS_DELETE_ANSWER_FAILED';

export const QUESTIONS_ADD_TAG = 'QUESTIONS_ADD_TAG';
export const QUESTIONS_ADD_TAG_INITIATED = 'QUESTIONS_ADD_TAG_INITIATED';
export const QUESTIONS_ADD_TAG_SUCCESS = 'QUESTIONS_ADD_TAG_SUCCESS';
export const QUESTIONS_ADD_TAG_FAILED = 'QUESTIONS_ADD_TAG_FAILED';

export const QUESTIONS_REMOVE_TAG = 'QUESTIONS_REMOVE_TAG';
export const QUESTIONS_REMOVE_TAG_INITIATED = 'QUESTIONS_REMOVE_TAG_INITIATED';
export const QUESTIONS_REMOVE_TAG_SUCCESS = 'QUESTIONS_REMOVE_TAG_SUCCESS';
export const QUESTIONS_REMOVE_TAG_FAILED = 'QUESTIONS_REMOVE_TAG_FAILED';

export const QUESTIONS_PUBLISH = 'QUESTIONS_PUBLISH';
export const QUESTIONS_PUBLISH_INITIATED = 'QUESTIONS_PUBLISH_INITIATED';
export const QUESTIONS_PUBLISH_SUCCESS = 'QUESTIONS_PUBLISH_SUCCESS';
export const QUESTIONS_PUBLISH_FAILED = 'QUESTIONS_PUBLISH_FAILED';

export const QUESTIONS_UNPUBLISH = 'QUESTIONS_UNPUBLISH';
export const QUESTIONS_UNPUBLISH_INITIATED = 'QUESTIONS_UNPUBLISH_INITIATED';
export const QUESTIONS_UNPUBLISH_SUCCESS = 'QUESTIONS_UNPUBLISH_SUCCESS';
export const QUESTIONS_UNPUBLISH_FAILED = 'QUESTIONS_UNPUBLISH_FAILED';

export const QUESTIONS_DRAFT = 'QUESTIONS_DRAFT';
export const QUESTIONS_DRAFT_INITIATED = 'QUESTIONS_DRAFT_INITIATED';
export const QUESTIONS_DRAFT_SUCCESS = 'QUESTIONS_DRAFT_SUCCESS';
export const QUESTIONS_DRAFT_FAILED = 'QUESTIONS_DRAFT_FAILED';

export const QUESTIONS_BULK_UPLOAD = 'QUESTIONS_BULK_UPLOAD';
export const QUESTIONS_BULK_UPLOAD_INITIATED = 'QUESTIONS_BULK_UPLOAD_INITIATED';
export const QUESTIONS_BULK_UPLOAD_SUCCESS = 'QUESTIONS_BULK_UPLOAD_SUCCESS';
export const QUESTIONS_BULK_UPLOAD_FAILED = 'QUESTIONS_BULK_UPLOAD_FAILED';

export const QUESTIONS_SET_SEARCH_QUERY = 'QUESTIONS_SET_SEARCH_QUERY';

export * from './stats/actions';

export const fetchQuestions = createAsyncAction(
  QUESTIONS_GET_ALL_QUESTIONS_INITIATED,
  QUESTIONS_GET_ALL_QUESTIONS_SUCCESS,
  QUESTIONS_GET_ALL_QUESTIONS_FAILED,
)<string, Array<Question>, Error | void>();

export const fetchQuestion = createAsyncAction(
  QUESTIONS_GET_QUESTION_INITIATED,
  QUESTIONS_GET_QUESTION_SUCCESS,
  QUESTIONS_GET_QUESTION_FAILED,
)<QuestionGetRequest, Question, Error | void>();

export const syncQuestion = createAsyncAction(
  QUESTIONS_SYNC_QUESTION_INITIATED,
  QUESTIONS_SYNC_QUESTION_SUCCESS,
  QUESTIONS_SYNC_QUESTION_FAILED,
)<QuestionGetRequest, Question, Error | void>();

export const setFeaturedQuestion = createAsyncAction(
  QUESTIONS_SET_FEATURED_QUESTION_INITIATED,
  QUESTIONS_SET_FEATURED_QUESTION_SUCCESS,
  QUESTIONS_SET_FEATURED_QUESTION_FAILED,
)<QuestionsSetFeaturedRequest, QuestionsSetFeaturedResponse, Error | void>();

export const featuredOrderChanged = createAsyncAction(
  QUESTIONS_CHANGE_FEATURED_ORDER_INITIATED,
  QUESTIONS_CHANGE_FEATURED_ORDER_SUCCESS,
  QUESTIONS_CHANGE_FEATURED_ORDER_FAILED,
)<
  QuestionsFeaturedOrderChangeRequest,
  QuestionsFeaturedOrderChangeResult,
  Error | void
>();

export const createQuestion = createAsyncAction(
  QUESTIONS_CREATE_QUESTION_INITIATED,
  QUESTIONS_CREATE_QUESTION_SUCCESS,
  QUESTIONS_CREATE_QUESTION_FAILED,
)<QuestionRequest, Question, Error | void>();

export const updateQuestion = createAsyncAction(
  QUESTIONS_UPDATE_QUESTION_INITIATED,
  QUESTIONS_UPDATE_QUESTION_SUCCESS,
  QUESTIONS_UPDATE_QUESTION_FAILED,
)<QuestionRequest, Question, Error | void>();

export const deleteQuestion = createAsyncAction(
  QUESTIONS_DELETE_QUESTION_INITIATED,
  QUESTIONS_DELETE_QUESTION_SUCCESS,
  QUESTIONS_DELETE_QUESTION_FAILED,
)<QuestionRequest, QuestionDeleteResponse, Error | void>();

export const createUtterance = createAsyncAction(
  QUESTIONS_CREATE_UTTERANCE_INITIATED,
  QUESTIONS_CREATE_UTTERANCE_SUCCESS,
  QUESTIONS_CREATE_UTTERANCE_FAILED,
)<UtteranceRequest, UtteranceResult, Error | void>();

export const updateUtterance = createAsyncAction(
  QUESTIONS_UPDATE_UTTERANCE_INITIATED,
  QUESTIONS_UPDATE_UTTERANCE_SUCCESS,
  QUESTIONS_UPDATE_UTTERANCE_FAILED,
)<UtteranceRequest, UtteranceResult, Error | void>();

export const deleteUtterance = createAsyncAction(
  QUESTIONS_DELETE_UTTERANCE_INITIATED,
  QUESTIONS_DELETE_UTTERANCE_SUCCESS,
  QUESTIONS_DELETE_UTTERANCE_FAILED,
)<UtteranceDeleteRequest, UtteranceDeleteResult, Error | void>();

export const createAnswer = createAsyncAction(
  QUESTIONS_CREATE_ANSWER_INITIATED,
  QUESTIONS_CREATE_ANSWER_SUCCESS,
  QUESTIONS_CREATE_ANSWER_FAILED,
)<AnswerRequest, AnswerResult, Error | void>();

export const updateAnswer = createAsyncAction(
  QUESTIONS_UPDATE_ANSWER_INITIATED,
  QUESTIONS_UPDATE_ANSWER_SUCCESS,
  QUESTIONS_UPDATE_ANSWER_FAILED,
)<AnswerRequest, AnswerResult, Error | void>();

export const deleteAnswer = createAsyncAction(
  QUESTIONS_DELETE_ANSWER_INITIATED,
  QUESTIONS_DELETE_ANSWER_SUCCESS,
  QUESTIONS_DELETE_ANSWER_FAILED,
)<AnswerDeleteRequest, AnswerDeleteResult, Error | void>();

export const reorderAnswers = createAsyncAction(
  QUESTIONS_REORDER_ANSWERS_INITIATED,
  QUESTIONS_REORDER_ANSWERS_SUCCESS,
  QUESTIONS_REORDER_ANSWERS_FAILED,
)<AnswersReorderRequest, AnswerReorderResult, Error | void>();

export const addQuestionTag = createAsyncAction(
  QUESTIONS_ADD_TAG_INITIATED,
  QUESTIONS_ADD_TAG_SUCCESS,
  QUESTIONS_ADD_TAG_FAILED,
)<QuestionTagAddRequest, QuestionTagAddResult, Error | void>();

export const removeQuestionTag = createAsyncAction(
  QUESTIONS_REMOVE_TAG_INITIATED,
  QUESTIONS_REMOVE_TAG_SUCCESS,
  QUESTIONS_REMOVE_TAG_FAILED,
)<QuestionTagRemoveRequest, QuestionTagRemoveResult, Error | void>();

export const publishQuestion = createAsyncAction(
  QUESTIONS_PUBLISH_INITIATED,
  QUESTIONS_PUBLISH_SUCCESS,
  QUESTIONS_PUBLISH_FAILED,
)<QuestionPublishRequest, Question, Error | void>();

export const unpublishQuestion = createAsyncAction(
  QUESTIONS_UNPUBLISH_INITIATED,
  QUESTIONS_UNPUBLISH_SUCCESS,
  QUESTIONS_UNPUBLISH_FAILED,
)<QuestionUnPublishRequest, Question, Error | void>();

export const draftQuestion = createAsyncAction(
  QUESTIONS_DRAFT_INITIATED,
  QUESTIONS_DRAFT_SUCCESS,
  QUESTIONS_DRAFT_FAILED,
)<QuestionDraftRequest, Question, Error | void>();

export const questionsBulkUpload = createAsyncAction(
  QUESTIONS_BULK_UPLOAD_INITIATED,
  QUESTIONS_BULK_UPLOAD_SUCCESS,
  QUESTIONS_BULK_UPLOAD_FAILED,
)<QuestionBulkUploadRequest, void, Error | void>();

export const setSearchQuery = createAction(QUESTIONS_SET_SEARCH_QUERY)<string>();
