import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { AxiosRequestConfig } from 'axios';
import {
  MessagesResponse,
  Ended,
  UserHistory,
  ConversationTagsResponseData,
} from './types';

export const getMessages = (botId: string, userId: string, locale: string) =>
  client.get<MessagesResponse>(
    `${API_HOST}/${ApiRoutes.Core}/transcripts/${botId}/${userId}/${locale}`,
  );

export const getEnded = (botId: string, agentId: string, config?: AxiosRequestConfig) =>
  client.get<Array<Ended>>(
    `${API_HOST}/${ApiRoutes.Core}/transcripts/${botId}/${agentId}`,
    config,
  );

export const updateUser = (
  featureId: string,
  userId: string,
  payload: UserHistory,
  config?: AxiosRequestConfig,
) =>
  client.patch<UserHistory>(
    `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/history/users/${userId}`,
    payload,
    config,
  );

export const getConversationTags = (featureId: string, userId: string) =>
  client.get<ConversationTagsResponseData>(
    `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/history/users/${userId}/lastconversationtags`,
  );

export const updateConversationTags = (
  featureId: string,
  userId: string,
  payload: ConversationTagsResponseData,
  config?: AxiosRequestConfig,
) =>
  client.put<ConversationTagsResponseData>(
    `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/history/users/${userId}/lastconversationtags`,
    payload,
    config,
  );

export const getUser = (featureId: string, userId: string, config?: AxiosRequestConfig) =>
  client.get<UserHistory>(
    `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/history/users/${userId}`,
    config,
  );

export const getUsers = (featureId: string, config?: AxiosRequestConfig) =>
  client.get<UserHistory>(
    `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/history/users`,
    config,
  );

export default {
  getMessages,
  getEnded,
  updateUser,
  getUser,
  getUsers,
  getConversationTags,
  updateConversationTags,
};
