type StatsValue = {
  value: number;
  label: string;
};

export type AgentStatus = {
  code: string;
  description: string;
};

export type LiveChatConsoleAgentStats = {
  agentId: string;
  displayName: string;
  activeConversations: StatsValue;
  numberOfConversations: StatsValue;
  averageCallLength: StatsValue;
  averageWaitTime: StatsValue;
  averageMessageCount: StatsValue;
  status: AgentStatus;
  connectedOn: Date;
  disconnectedOn: Date;
  locale: string;
};

type CallStats = {
  count: number;
  label: string;
  proportion: number;
};

type ConversationCalls = {
  active: CallStats;
  closed: CallStats;
  dropouts: CallStats;
  inQueue: CallStats;
  notDropped: CallStats;
  total: CallStats;
  blocked: CallStats;
};

export type StatsIconType = {
  url: string;
  backgroundColor: string;
};

export type GeneralStatistics = {
  value: string;
  icon: StatsIconType;
  title: string;
};

export type ConversationTags = {
  id: string;
  value: string;
  conversations: CallStats;
};

export enum LiveChatServiceStatus {
  ONLINE = 'online',
  TAKEN_OFFLINE = 'takenOffline',
  OUT_OF_OFFICE = 'outOfOffice',
}

export type LiveChatConsole = {
  agentStatistics: Array<LiveChatConsoleAgentStats>;
  calls: { values: ConversationCalls };
  generalStatistics: Array<GeneralStatistics>;
  locations: { values: Array<[number, number]> };
  serviceStatus: { status: LiveChatServiceStatus };
  statisticsDate: string;
  tags: { values: Array<ConversationTags> };
};
