import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  InvitePlatformUserRequest,
  PlatformUser,
  PLATFORM_USERS_QUERY_KEY,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useInvitePlatformUser = () => {
  const { toast } = useToast();

  const invitePlatformUserMutation = useMutation(
    ({ orgId, ...body }: InvitePlatformUserRequest) => {
      return client.post<PlatformUser>(
        `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/users`,
        body,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to invite user'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { orgId }) => {
        queryClient.invalidateQueries([PLATFORM_USERS_QUERY_KEY, orgId]);
        toast('User invited successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: invitePlatformUser,
    isLoading,
    error,
  } = invitePlatformUserMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, invitePlatformUser };
};
