import { ComponentProps, ComponentType, FunctionComponent } from 'react';
import connect, { WithQuestionsStatsProps } from './withQuestionsStats.state';

export const withQuestionsStats = <TProps extends {}>(
  Component: ComponentType<TProps>,
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `WithQuestionsStats(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent as FunctionComponent<
    Omit<ComponentProps<typeof Component>, keyof WithQuestionsStatsProps>
  >;
};
