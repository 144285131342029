import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { UpdateUserProfileRequest, UserProfile } from '../types';
import { USER_PROFILE_QUERY_KEY } from '../constants';

export const useUpdateUserProfile = () => {
  const { toast } = useToast();

  const updateUserProfileMutation = useMutation(
    ({ ...body }: UpdateUserProfileRequest) => {
      return client.patch<UserProfile>(`${API_HOST}/${ApiRoutes.Core}/profile`, body);
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update user profile.'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(USER_PROFILE_QUERY_KEY);
        toast('User profile updated successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: updateUserProfile, isLoading, error } = updateUserProfileMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateUserProfile };
};
