import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  TRANSCRIPTS_SEARCH_QUERY_KEY,
  TranscriptUser,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

const getTranscripts = (botId: string) => {
  return () => {
    return client.get<TranscriptUser[]>(
      `${API_HOST}/${ApiRoutes.Core}/transcripts/${botId}`,
    );
  };
};

export const useGetTranscripts = (botId: string) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [TRANSCRIPTS_SEARCH_QUERY_KEY, botId],
    getTranscripts(botId),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get transcript messages'), {
          variant: 'error',
        });
      },
    },
  );

  const transcripts = data?.data || [];
  return { transcripts, isLoading };
};

// refresh transcripts cache for a given botId
export const invalidateTranscriptsQuery = (botId?: string) =>
  queryClient.invalidateQueries([TRANSCRIPTS_SEARCH_QUERY_KEY, botId]);
