import { createAsyncAction } from 'typesafe-actions';
import { StatsRequest } from '@/store/stats';
import {
  QuestionsStatsPopularQuestionRequest,
  QuestionsStatsPopularQuestionReducedResponse,
  QuestionStatsPerConvoReducedResponse,
  DeleteQuestionStatsUnansweredRequest,
} from './types';

export const QUESTIONS_STATS_GET_SUMMARY = 'QUESTIONS_STATS_GET_SUMMARY';
export const QUESTIONS_STATS_GET_SUMMARY_INITIATED =
  'QUESTIONS_STATS_GET_SUMMARY_INITIATED';
export const QUESTIONS_STATS_GET_SUMMARY_SUCCESS = 'QUESTIONS_STATS_GET_SUMMARY_SUCCESS';
export const QUESTIONS_STATS_GET_SUMMARY_FAILED = 'QUESTIONS_STATS_GET_SUMMARY_FAILED';

export const QUESTIONS_STATS_GET_ASKED = 'QUESTIONS_STATS_GET_ASKED';
export const QUESTIONS_STATS_GET_ASKED_INITIATED = 'QUESTIONS_STATS_GET_ASKED_INITIATED';
export const QUESTIONS_STATS_GET_ASKED_SUCCESS = 'QUESTIONS_STATS_GET_ASKED_SUCCESS';
export const QUESTIONS_STATS_GET_ASKED_FAILED = 'QUESTIONS_STATS_GET_ASKED_FAILED';

export const QUESTIONS_STATS_GET_POPULAR = 'QUESTIONS_STATS_GET_POPULAR';
export const QUESTIONS_STATS_GET_POPULAR_INITIATED =
  'QUESTIONS_STATS_GET_POPULAR_INITIATED';
export const QUESTIONS_STATS_GET_POPULAR_SUCCESS = 'QUESTIONS_STATS_GET_POPULAR_SUCCESS';
export const QUESTIONS_STATS_GET_POPULAR_FAILED = 'QUESTIONS_STATS_GET_POPULAR_FAILED';

export const QUESTIONS_STATS_GET_POPULAR_QUESTION =
  'QUESTIONS_STATS_GET_POPULAR_QUESTION';
export const QUESTIONS_STATS_GET_POPULAR_QUESTION_INITIATED =
  'QUESTIONS_STATS_GET_POPULAR_QUESTION_INITIATED';
export const QUESTIONS_STATS_GET_POPULAR_QUESTION_SUCCESS =
  'QUESTIONS_STATS_GET_POPULAR_QUESTION_SUCCESS';
export const QUESTIONS_STATS_GET_POPULAR_QUESTION_FAILED =
  'QUESTIONS_STATS_GET_POPULAR_QUESTION_FAILED';

export const QUESTIONS_STATS_GET_UNANSWERED = 'QUESTIONS_STATS_GET_UNANSWERED';
export const QUESTIONS_STATS_GET_UNANSWERED_INITIATED =
  'QUESTIONS_STATS_GET_UNANSWERED_INITIATED';
export const QUESTIONS_STATS_GET_UNANSWERED_SUCCESS =
  'QUESTIONS_STATS_GET_UNANSWERED_SUCCESS';
export const QUESTIONS_STATS_GET_UNANSWERED_FAILED =
  'QUESTIONS_STATS_GET_UNANSWERED_FAILED';

export const QUESTIONS_STATS_DELETE_UNANSWERED = 'QUESTIONS_STATS_DELETE_UNANSWERED';
export const QUESTIONS_STATS_DELETE_UNANSWERED_INITIATED =
  'QUESTIONS_STATS_DELETE_UNANSWERED_INITIATED';
export const QUESTIONS_STATS_DELETE_UNANSWERED_SUCCESS =
  'QUESTIONS_STATS_DELETE_UNANSWERED_SUCCESS';
export const QUESTIONS_STATS_DELETE_UNANSWERED_FAILED =
  'QUESTIONS_STATS_DELETE_UNANSWERED_FAILED';

export const QUESTIONS_STATS_GET_PER_CONVO = 'QUESTIONS_STATS_GET_PER_CONVO';
export const QUESTIONS_STATS_GET_PER_CONVO_INITIATED =
  'QUESTIONS_STATS_GET_PER_CONVO_INITIATED';
export const QUESTIONS_STATS_GET_PER_CONVO_SUCCESS =
  'QUESTIONS_STATS_GET_PER_CONVO_SUCCESS';
export const QUESTIONS_STATS_GET_PER_CONVO_FAILED =
  'QUESTIONS_STATS_GET_PER_CONVO_FAILED';

export const fetchQuestionsStatsSummary = createAsyncAction(
  QUESTIONS_STATS_GET_SUMMARY_INITIATED,
  QUESTIONS_STATS_GET_SUMMARY_SUCCESS,
  QUESTIONS_STATS_GET_SUMMARY_FAILED,
)<StatsRequest, Array<string>, void | Error>();

export const fetchQuestionsStatsAsked = createAsyncAction(
  QUESTIONS_STATS_GET_ASKED_INITIATED,
  QUESTIONS_STATS_GET_ASKED_SUCCESS,
  QUESTIONS_STATS_GET_ASKED_FAILED,
)<StatsRequest, Array<string>, void | Error>();

export const fetchQuestionsStatsPopular = createAsyncAction(
  QUESTIONS_STATS_GET_POPULAR_INITIATED,
  QUESTIONS_STATS_GET_POPULAR_SUCCESS,
  QUESTIONS_STATS_GET_POPULAR_FAILED,
)<StatsRequest, Array<string>, void | Error>();

export const fetchQuestionsStatsPopularQuestion = createAsyncAction(
  QUESTIONS_STATS_GET_POPULAR_QUESTION_INITIATED,
  QUESTIONS_STATS_GET_POPULAR_QUESTION_SUCCESS,
  QUESTIONS_STATS_GET_POPULAR_QUESTION_FAILED,
)<
  QuestionsStatsPopularQuestionRequest,
  QuestionsStatsPopularQuestionReducedResponse,
  void | Error
>();

export const fetchQuestionsStatsUnanswered = createAsyncAction(
  QUESTIONS_STATS_GET_UNANSWERED_INITIATED,
  QUESTIONS_STATS_GET_UNANSWERED_SUCCESS,
  QUESTIONS_STATS_GET_UNANSWERED_FAILED,
)<StatsRequest, Array<string>, void | Error>();

export const deleteQuestionsStatsUnanswered = createAsyncAction(
  QUESTIONS_STATS_DELETE_UNANSWERED_INITIATED,
  QUESTIONS_STATS_DELETE_UNANSWERED_SUCCESS,
  QUESTIONS_STATS_DELETE_UNANSWERED_FAILED,
)<DeleteQuestionStatsUnansweredRequest, Array<string>, void | Error>();

export const fetchQuestionsStatsPerConvo = createAsyncAction(
  QUESTIONS_STATS_GET_PER_CONVO_INITIATED,
  QUESTIONS_STATS_GET_PER_CONVO_SUCCESS,
  QUESTIONS_STATS_GET_PER_CONVO_FAILED,
)<StatsRequest, QuestionStatsPerConvoReducedResponse, void | Error>();
