import { useCallback } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { QueryObserverResult, useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useDashboardParams } from '@/hooks/useDashboardParams';
import { useDashboardProxy } from '@/proxies';
import { BotTypeCode } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { StatsResponse } from '@/store/stats';
import { APIErrorResponse } from '@/types/error';

export const STATS_BASE_URL = `${API_HOST}/${ApiRoutes.Dashboard}`;

interface DashboardParams {
  from: string;
  to: string;
  selected: string;
  channel: string;
  test: boolean;
  type: BotTypeCode;
  botId: string;
  agentId: string;
}

type GetStatsResponse<T> = DashboardParams & T;

type GetStatsQueryResponse<T> = QueryObserverResult<
  AxiosResponse<T>,
  AxiosError<APIErrorResponse>
>;

export function useGetStatsV1<TStatsResponse = StatsResponse>(
  urlPath: string,
  queryKey: string,
): GetStatsResponse<GetStatsQueryResponse<TStatsResponse>> {
  const { incrementQueryStep } = useDashboardProxy();
  const paramValues = useDashboardParams();
  const botId = paramValues.botId;
  const QUERY_KEY = [queryKey, paramValues];

  const query = useQuery<AxiosResponse<TStatsResponse>, AxiosError<APIErrorResponse>>(
    QUERY_KEY,
    useCallback(() => {
      return client.get<TStatsResponse>(`${STATS_BASE_URL}/${botId}/${urlPath}`, {
        params: { ...paramValues },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botId, urlPath, paramValues]),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onSettled: () => {
        // Increment the step so that the next dashboard component loads
        incrementQueryStep();
      },
    },
  );

  return {
    ...paramValues,
    ...query,
  };
}
