import { OrganisationAddress } from '@/queries';

export enum BillingMethod {
  Card = 'CARD',
  Invoice = 'INVOICE',
}

export type BillingCardDetails = {
  lastFourDigits: string;
  cardNumber?: string;
  cardHolderName: string;
  cvvNumber?: string;
  expiryDate: string;
  billingPostCode: string;
};

export type BillingInvoiceDetails = {
  recipientName: string;
  email: string;
  vatNumber: string;
};

export type Billing = {
  organisationId: string;
  billingMethod: null | BillingMethod;
  cardDetails?: null | BillingCardDetails;
  invoiceDetails?: null | BillingInvoiceDetails;
  useOrgAddress: boolean;
  billingAddress?: null | OrganisationAddress;
  nextInvoiceAmount: number;
  nextInvoiceDate: string;
  activeInsideBots: number;
  activeOutsideBots: number;
  invoicedInsideBots: number;
  invoicedOutsideBots: number;
};

type BillingUpdatePayload = Partial<
  Omit<Billing, 'billingAddress' | 'invoiceDetails' | 'cardDetails'>
> & {
  billingAddress?: Partial<OrganisationAddress>;
  invoiceDetails?: Partial<BillingInvoiceDetails> | null;
  cardDetails?: Partial<BillingCardDetails> | null;
};

export type BillingUpdateRequest = {
  orgId?: string | null;
  payload: BillingUpdatePayload;
};

export type Invoice = {
  organisationId: string;
  invoiceNumber: string;
  invoiceDate: string;
  description: string;
  total: string;
  downloadUrl: string;
};

export type Invoices = Array<Invoice>;

export type InvoicesData = Record<string, Invoice>;

export type BillingDashboardPayload = {
  billing: Billing;
  invoices: Invoices;
};

export type BillingBand = {
  code: string;
  label: string;
  description: string;
  id: string;
  order: number;
  billingGroupPrices: string[];
};

export type BillingGroup = {
  id: string;
  code: string;
  order: number;
  label: string;
  description: string;
  iconUrl: string;
  uiMeta: UiMeta;
};

export type BillingGroupUpdateResponse = {
  id: string;
  code: string;
  order: number;
  label: string;
  description: string;
  iconUrl: string;
  enabled: boolean;
};

export type BillingGroupUpdateRequest = {
  orgId?: string | null;
  botId?: string | null;
  billingGroupId?: string;
  enabled?: boolean;
};

export type BillingParameters = {
  inside: BillingParameterSide;
  outside: BillingParameterSide;
};

export type BillingParameterSide = {
  billingBands: BillingBand[];
  billingGroups: BillingGroup[];
};

export type UiMeta = {
  detailedDescriptionColumns?: UiMetaDetailedDescriptionColumn[];
  featureDetails?: UiMetaDetailedDescriptionColumn[];
};

export type UiMetaDetailedDescriptionColumn = {
  section: string;
  headings: UiMetaHeading[];
};

export type UiMetaHeading = {
  heading: string;
  features: string[];
  subheading: string;
};

export type BillingBotStatus = {
  id: string;
  enabled: string;
  billingBand: BillingBandStatus;
  trialExpiryDate: Date;
  botId: string;
};

export type BillingBandStatus = {
  id: string;
  code: string;
  label: string;
  description: string;
};

export type BillingRequest = {
  botId?: string | null;
  orgId?: string | null;
};
