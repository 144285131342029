import React, { useCallback, useRef } from 'react';
import { SnackbarProvider, SnackbarKey } from 'notistack';
import {
  ErrorIcon,
  InfoIcon,
  WarningIcon,
  SuccessIcon,
  CloseIcon,
} from './ToastProvider.styled';
import { SnackbarOrigin } from '@material-ui/core';

const anchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const iconVariant = {
  error: <ErrorIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
};

const ToastProvider = ({ children }) => {
  const notistackRef = useRef<
    HTMLDivElement & {
      closeSnackbar: (key?: SnackbarKey) => void;
    }
  >(null);

  const onClickDismiss = useCallback(
    key => {
      notistackRef?.current?.closeSnackbar(key);
    },
    [notistackRef],
  );

  const action = useCallback(key => <CloseIcon onClick={() => onClickDismiss(key)} />, [
    onClickDismiss,
  ]);

  return (
    <SnackbarProvider
      action={action}
      anchorOrigin={anchorOrigin}
      iconVariant={iconVariant}
      ref={notistackRef}
      classes={{
        variantSuccess: 'variantSuccess',
        variantError: 'variantError',
        variantWarning: 'variantWarning',
        variantInfo: 'variantInfo',
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
