import { AppState } from '@/store';
import { Statistic } from '@/store/stats';
import { connect } from 'react-redux';
import { WithStatsSelector, WithStatusesSelector } from './types';

const createMapStateToProps = <TProps = {}, TStatsShape = Array<Statistic>>(
  selector: WithStatsSelector<TProps, TStatsShape>,
  statusSelector: WithStatusesSelector<TProps>,
) => (state: AppState, props: TProps) => ({
  stats: selector(state, props),
  statuses: statusSelector(state, props),
});

const createMapDispatchToProps = <TFetcherType>(fetcher: TFetcherType) => ({
  fetchStats: fetcher,
});

const createConnect = <TFetcherType, TStatsShape = Array<Statistic>, TProps = {}>(
  fetcher: TFetcherType,
  dataSelector: WithStatsSelector<TProps, TStatsShape>,
  statusSelector: WithStatusesSelector<TProps>,
) =>
  connect(
    createMapStateToProps<TProps, TStatsShape>(dataSelector, statusSelector),
    createMapDispatchToProps<TFetcherType>(fetcher),
  );

export default createConnect;

export type WithStatsProps = ReturnType<ReturnType<typeof createMapStateToProps>> &
  ReturnType<typeof createMapDispatchToProps>;
