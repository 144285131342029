import axios from 'axios';
import { getFileArrayBuffer } from '@/utils';
import client from './';
import { UploadFinalResponse, UploadInitResponse, FileUploadType } from './types';

// File upload needs a clean client with only one header
const uploadClient = axios.create({
  headers: {
    'X-MS-Blob-Type': 'BlockBlob',
  },
});

export const fileUpload = async (
  contextUploadUrl: string,
  file: File,
  type: FileUploadType = 'STANDARD',
) => {
  const initResponse = await client.post<UploadInitResponse>(contextUploadUrl, {
    filename: file.name,
  });

  const { fileId, uploadUrl } = initResponse?.data || {};

  if (!uploadUrl) return;

  // Read the file data
  const data = await getFileArrayBuffer(file);

  // Upload the file data
  await uploadClient.put(uploadUrl, data, {
    headers: {
      'Content-Type': file.type,
    },
  });

  // Uploads for bubbles don't need the final PUT
  if (type === 'BUBBLE') return { fileId };

  // Put the file id
  const finalResponse = await client.put<UploadFinalResponse>(contextUploadUrl, {
    fileId,
  });

  return { ...finalResponse.data, fileId };
};

export default fileUpload;
