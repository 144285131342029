import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { WEB_SEARCH_QUERY_KEY, ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { AddWebSearch } from '../types';

export const useAddWebSearchSite = () => {
  const { toast } = useToast();

  const addWebSearchSiteMutation = useMutation(
    ({ featureId, url }: AddWebSearch) =>
      client.post(`${API_HOST}/${ApiRoutes.WebSearch}/${featureId}/sites`, {
        url,
      }),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to add websearch site'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(WEB_SEARCH_QUERY_KEY);
        toast('successfully added website.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: addWebSearchSite,
    isLoading,
    isSuccess,
    error,
  } = addWebSearchSiteMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, addWebSearchSite, serverErrors, isSuccess };
};
