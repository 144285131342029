import { snakeCase } from 'lodash/fp';
import { Options, parse } from 'json2csv';
import { AxiosResponse } from 'axios';
import { Charts } from '@/queries';

const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });
  const anchorTag = document.createElement('a');

  anchorTag.download = fileName;
  anchorTag.href = window.URL.createObjectURL(blob);

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  anchorTag.dispatchEvent(clickEvent);
  anchorTag.remove();
};

export const downloadCsv = (csvData: unknown, title: string) => {
  downloadFile({
    data: csvData,
    fileName: `${snakeCase(title)}.csv`,
    fileType: 'text/csv',
  });
};

export const downloadJson = (jsonData: unknown, title: string) => {
  downloadFile({
    data: JSON.stringify(jsonData),
    fileName: `${snakeCase(title)}.json`,
    fileType: 'text/json',
  });
};

// first two letters should be PK for a normal zip file
export const downloadZip = (responseData: AxiosResponse<Charts>, fileName: string) => {
  downloadFile({
    data: responseData?.data,
    fileName,
    fileType: responseData?.headers['content-type'],
  });
};

export const convertJsonToCsv = (
  columnTitles: Array<string>,
  csvData: Readonly<unknown> | readonly unknown[],
) => {
  const csvOptions: Options<unknown> = { fields: columnTitles };
  try {
    return parse(csvData, csvOptions);
  } catch (err) {
    throw new Error('Unable to transform CSV data');
  }
};
