import { useContext } from 'react';
import { LocaleContext } from '@/contexts';

export const useCurrency = (options?: Intl.NumberFormatOptions) => {
  const { locale, currency } = useContext(LocaleContext) || {};

  const formatOptions = {
    style: 'currency',
    currency,
    ...options,
  };

  return Intl.NumberFormat
    ? new Intl.NumberFormat(locale, formatOptions).format
    : (number: number) => number.toLocaleString(locale, formatOptions);
};
