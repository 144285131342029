import sanitizeHtmlBase from 'sanitize-html';

export const allowedLinkSchemes = ['http', 'https', 'ftp', 'mailto', 'tel'];

export const sanitizeHtml = (html: string) => {
  const options = {
    allowedAttributes: {
      a: ['href', 'target'],
    },
    allowedTags: ['b', 'strong', 'u', 'em', 'a', 'br', 'i'],
    allowedSchemesByTag: {
      a: allowedLinkSchemes,
    },
  };
  return sanitizeHtmlBase(html, options);
};
