import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, EXPORT_CHARTS_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import { Charts, ExportChartsRequest } from '../types';

export const useExportCharts = () => {
  const { toast } = useToast();
  const axiosOptions = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
    },
  } as AxiosRequestConfig;

  const exportChartsMutation = useMutation(
    async ({ botId, from, to, test, format, ...body }: ExportChartsRequest) => {
      return client.post<Charts>(
        `${API_HOST}/${ApiRoutes.Dashboard}/${botId}/export?from=${from}&to=${to}&test=${test}&format=${format}`,
        body,
        axiosOptions,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to export selected charts.'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(EXPORT_CHARTS_QUERY_KEY);
      },
    },
  );

  const { mutateAsync: exportCharts, isLoading } = exportChartsMutation;

  return { exportCharts, isLoading };
};
