import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { DOCUMENTS_QUERY_KEY } from '../constants';
import { KnowledgeSearchDocument, GetAllDocumentsRequest } from '../types';

const getDocuments = ({ featureId }: GetAllDocumentsRequest) => {
  return () => {
    return client.get<Array<KnowledgeSearchDocument>>(
      `${API_HOST}/${ApiRoutes.Search}/${featureId}/documents`,
    );
  };
};

export const useGetDocuments = ({ featureId }: GetAllDocumentsRequest) => {
  const { toast } = useToast();

  const { data, isLoading: isFetchingDocuments } = useQuery(
    [DOCUMENTS_QUERY_KEY, featureId],
    getDocuments({ featureId }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(
            errorResponse,
            'Failed to retrieve knowledge search documents.',
          ),
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const documents = data?.data;
  return { documents, isFetchingDocuments };
};
