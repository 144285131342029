import React, { FunctionComponent } from 'react';
import MinimalLayout from '@/components/MinimalLayout';
import { useQueryParams } from '@/hooks/useQueryParams';
import SignUpSuccessPage from '@/pages/SignUpSuccessPage/SignUpSuccessPage';

export const SignupCallbackScreen: FunctionComponent = () => {
  const query = useQueryParams();
  const invitation = query.get('invitation');

  const decodedInvitation = invitation ? JSON.parse(atob(invitation)) : {};

  return (
    <MinimalLayout>
      <SignUpSuccessPage invitation={invitation} {...decodedInvitation} />
    </MinimalLayout>
  );
};
