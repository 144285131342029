import styled, { css } from 'styled-components';
import { Theme, Typography } from '@material-ui/core';
import { IconWrapperProps } from './types';
import { size } from '@/styles/theme/defaultTheme/size';

export const ParentContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

export const StyledMainContentHeader = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 4rem;
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    width: 100%;
    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(4)} ${theme.spacing(5)};
    }
  `,
);

export const Title = styled.div`
  padding: ${({ theme }) => theme.spacing(2)} 0;
  display: flex;
  flex: 1;
  align-items: center;
  color: ${({ theme }) => theme.palette.grey['800']};
`;

const getIconWrapperBackStyle = (theme: Theme) => {
  return css`
    border-radius: 0.25rem;
    border: 2px solid ${theme.palette.grey['400']};
    padding: ${theme.spacing(0.3)};
    cursor: pointer;
    height: 30px;
    text-align: center;

    svg {
      path {
        fill: ${theme.palette.grey['500']};
      }
    }
  `;
};

export const IconWrapper = styled.div<IconWrapperProps>(
  ({ theme, back }) => css`
    :not(:empty) {
      margin-right: ${theme.spacing(3)};
      width: 30px;

      ${back && getIconWrapperBackStyle(theme)};
    }

    svg {
      height: 100%;
      max-width: 100%;
      height: ${size(2.125)};

      ${theme.breakpoints.up('sm')} {
        height: ${size(1.9375)};
      }
    }
  `,
);

export const Description = styled.div`
  padding: ${({ theme }) => theme.spacing(2)} 0;
`;

export const TitleText = styled(Typography)`
  height: 1em;
`;
