import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  ORG_QUERY_KEY,
  Organisation,
  UpdateOrganisationRequest,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useUpdateOrganisation = () => {
  const { toast } = useToast();

  const updateOrganisationMutation = useMutation(
    ({ orgId, ...data }: UpdateOrganisationRequest) => {
      return client.patch<Organisation>(
        `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update organisation.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { orgId = '' }) => {
        queryClient.invalidateQueries([ORG_QUERY_KEY, orgId]);
        toast('Organisation updated successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateOrganisation,
    isLoading,
    error,
  } = updateOrganisationMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateOrganisation };
};
