import { WithLiveChatProps } from '@/hoc';
import { Connection } from '@/store/liveChat';
import { proxy, useSnapshot } from 'valtio';

type LiveMessages = Pick<WithLiveChatProps, 'livechatMessages'>;

type LiveChatState = {
  livechatMessages: LiveMessages;
  setLivechatMessages: (messages: LiveMessages) => void;
  userId: string | null;
  setUserId: (userId: string | null) => void;
  agentId: string | null;
  setAgentId: (agentId: string | null) => void;
  botId: string | null;
  setBotId: (botId: string | null) => void;
  featureId?: string;
  setFeatureId: (featureId?: string) => void;
  messagesId: string | null;
  setMessagesId: (messagesId: string | null) => void;
  connection?: Connection | null;
  setConnection: (connection: Connection | null) => void;
};

const state = proxy<LiveChatState>({
  livechatMessages: {},
  setLivechatMessages(messages) {
    state.livechatMessages = messages;
  },
  userId: null,
  setUserId(userId) {
    state.userId = userId;
  },
  agentId: null,
  setAgentId(agentId) {
    state.agentId = agentId;
  },
  botId: null,
  setBotId(botId) {
    state.botId = botId;
  },
  featureId: undefined,
  setFeatureId(featureId) {
    state.featureId = featureId;
  },
  messagesId: null,
  setMessagesId(messagesId) {
    state.messagesId = messagesId;
  },
  connection: null,
  setConnection(connection) {
    state.connection = connection;
  },
});

export const useLiveChatProxy = () => {
  return useSnapshot(state);
};
