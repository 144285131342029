import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, SEARCH_CRITERIA_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { SearchTranscriptsRequest, TranscriptUser } from '../types';

export const useSearchTranscripts = () => {
  const { toast } = useToast();

  const searchTranscriptsMutation = useMutation(
    async ({ botId, ...data }: SearchTranscriptsRequest) => {
      return client.post<Array<TranscriptUser>>(
        `${API_HOST}/${ApiRoutes.Core}/transcripts/${botId}`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to run search on criteria.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { botId }) => {
        queryClient.invalidateQueries([botId, SEARCH_CRITERIA_QUERY_KEY]);
      },
    },
  );

  const { mutateAsync: searchTranscripts, isLoading } = searchTranscriptsMutation;
  return { isLoading, searchTranscripts };
};
