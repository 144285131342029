import { useMemo } from 'react';
import { format, fromUnixTime, isToday } from 'date-fns';

export function useMessageDateTime(timestamp: number): string {
  return useMemo(() => {
    if (!timestamp) throw Error('Timestamp not provided');

    const timestampIsSeconds = timestamp.toString().length === 10;
    let time = +timestamp;

    if (!timestampIsSeconds) {
      time = timestamp / 1000;
    }

    const date = fromUnixTime(time);
    const formatString = isToday(date) ? 'HH:mm' : 'dd/MM/yyyy HH:mm';
    return format(date, formatString);
  }, [timestamp]);
}
