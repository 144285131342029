import styled from 'styled-components';
import ErrorIconBase from '@material-ui/icons/Error';
import CheckCircleOutlineIconBase from '@material-ui/icons/CheckCircleOutline';
import CloseIconBase from '@material-ui/icons/Close';

export const ErrorIcon = styled(ErrorIconBase)`
  margin-right: ${({ theme }) => theme.spacing(3)};
  * {
    fill: ${({ theme }) => theme.palette.error.main};
  }
`;

export const InfoIcon = styled(ErrorIconBase)`
  margin-right: ${({ theme }) => theme.spacing(3)};
  * {
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const WarningIcon = styled(ErrorIconBase)`
  margin-right: ${({ theme }) => theme.spacing(3)};
  * {
    fill: ${({ theme }) => theme.palette.error.main};
  }
`;

export const SuccessIcon = styled(CheckCircleOutlineIconBase)`
  margin-right: ${({ theme }) => theme.spacing(3)};
  * {
    fill: ${({ theme }) => theme.palette.success.main};
  }
`;

export const CloseIcon = styled(CloseIconBase)`
  && {
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }
`;
