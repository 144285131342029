import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useToast } from '@/hooks/useToast';
import {
  Channel,
  ChannelSettingsResponse,
  CHANNEL_SETTINGS_QUERY_KEY,
  extractServerErrorMsg,
} from '@/queries';
import client, {
  ClientCancelToken,
  createCancelToken,
  wasCancelled,
} from '@/store/client';

let channelCancelToken: ClientCancelToken | undefined = undefined;

const cancelChannelRequest = (message?: string) => {
  channelCancelToken && channelCancelToken.cancel(message);
  channelCancelToken = createCancelToken();
};

const getEnabled = (channel?: Channel) => {
  if (channel) {
    return true;
  }
  return false;
};

const getChannelSettings = (channel?: Channel) => {
  cancelChannelRequest('New channel request');

  return () => {
    return client.get<ChannelSettingsResponse>(
      `${channel?.managementApiUrl}${channel?.id}/settings`,
      {
        cancelToken: channelCancelToken?.token,
      },
    );
  };
};

export const useGetChannelSettings = (channel: Channel | undefined) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [CHANNEL_SETTINGS_QUERY_KEY, channel?.id],
    getChannelSettings(channel),
    {
      // disable the query from automatically
      // running when 'enabled' is false
      enabled: getEnabled(channel),
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        if (wasCancelled(errorResponse)) return;
        toast(extractServerErrorMsg(errorResponse, 'Failed to get channel settings'), {
          variant: 'error',
        });
      },
    },
  );

  const channelSettings = data?.data?.settings;
  const channelStatus = data?.data?.status;

  return { channelSettings, channelStatus, isLoading };
};
