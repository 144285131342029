import { connect } from 'react-redux';
import { AppState } from '@/store';
import { getOnboardingStages, getOnboardingData } from '@/store/onboarding';

const mapStateToProps = (state: AppState) => ({
  onboardingData: getOnboardingData(state),
});

const mapDispatchToProps = {
  getOnboardingStages: getOnboardingStages.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type WithOnboardingProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
