import { lazy } from 'react';

export const BotScreen = lazy(() => import('./BotScreen'));
export const BotsScreen = lazy(() => import('./BotsScreen'));
export const LoginScreen = lazy(() => import('./LoginScreen'));
export const LogoutScreen = lazy(() => import('./LogoutScreen'));
export const ForgotPasswordScreen = lazy(() => import('./ForgotPasswordScreen'));
export const ResetPasswordScreen = lazy(() => import('./ResetPasswordScreen'));
export const NotFoundScreen = lazy(() => import('./NotFoundScreen'));
export const OnboardingScreen = lazy(() => import('./OnboardingScreen'));
export const SettingsScreen = lazy(() => import('./SettingsScreen'));
export const BillingScreen = lazy(() => import('./BillingScreen'));
export const UserProfileScreen = lazy(() => import('./UserProfileScreen'));
export const OrganisationScreen = lazy(() => import('./OrganisationScreen'));
export const CreateBotScreen = lazy(() => import('./CreateBotScreen'));
export const SignupScreen = lazy(() => import('./SignupScreen'));
export const VideoCallScreen = lazy(() => import('./VideoCallScreen'));
export const OrgLiveChatConsoleScreen = lazy(() => import('./OrgLiveChatConsoleScreen'));
