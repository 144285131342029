import { withStats } from '@/hoc';
import { WithStatsProps } from '@/hoc/withStats';
import { Statistic } from '@/store/stats';
import {
  fetchQuestionsStatsSummary,
  getQuestionsStatsSummary,
  getQuestionsStatuses,
} from '@/store/questions';
import { ComponentType } from 'react';

export const withQuestionsStatsSummary = <TProps extends WithQuestionsStatsSummaryProps>(
  Component: ComponentType<TProps>,
) =>
  withStats<TProps, typeof fetchQuestionsStatsSummary.request>(
    Component,
    fetchQuestionsStatsSummary.request,
    getQuestionsStatsSummary,
    getQuestionsStatuses,
  );

export interface WithQuestionsStatsSummaryProps extends WithStatsProps {
  stats: Array<Statistic>;
  fetchStats: typeof fetchQuestionsStatsSummary.request;
}
