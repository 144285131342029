import { filter, propEq } from 'lodash/fp';
import { Survey, SurveyStatusCode, SurveyStatusMap } from './types';

export const statusMap: SurveyStatusMap = Object.freeze({
  all: [...Object.values(SurveyStatusCode)],
  upcoming: [SurveyStatusCode.Upcoming],
  active: [SurveyStatusCode.Active],
  draft: [SurveyStatusCode.Draft],
  archived: [SurveyStatusCode.Archived],
});

export const filterByUpcoming = filter<Survey>(
  propEq<SurveyStatusCode>('status.code', SurveyStatusCode.Upcoming),
);

export const filterByActive = filter<Survey>(
  propEq<SurveyStatusCode>('status.code', SurveyStatusCode.Active),
);

export const filterByDraft = filter<Survey>(
  propEq<SurveyStatusCode>('status.code', SurveyStatusCode.Draft),
);

export const filterByArchived = filter<Survey>(
  propEq<SurveyStatusCode>('status.code', SurveyStatusCode.Archived),
);

export const mapSentToActive = (survey: Survey) => {
  return {
    ...survey,
    ...(survey?.status?.code === SurveyStatusCode.Sent
      ? { status: { code: SurveyStatusCode.Active, description: 'Active' } }
      : {}),
  };
};
