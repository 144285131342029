import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { DOCUMENT_QUERY_KEY } from '../constants';
import { CreateOrUpdateDocumentRequest, KnowledgeSearchDocument } from '../types';

export const useUpdateDocument = () => {
  const { toast } = useToast();

  const updateDocumentMutation = useMutation(
    ({ featureId, documentId, ...data }: CreateOrUpdateDocumentRequest) => {
      return client.patch<KnowledgeSearchDocument>(
        `${API_HOST}/${ApiRoutes.Search}/${featureId}/documents/${documentId}`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update document.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { documentId }) => {
        queryClient.invalidateQueries([DOCUMENT_QUERY_KEY, documentId]);
        toast('Successfully updated document.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateDocument,
    isLoading,
    error: responseError,
  } = updateDocumentMutation;
  const serverErrors: ErrorMessage[] = responseError?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateDocument };
};
