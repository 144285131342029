import OrganisationSwitch from '@/components/OrganisationSwitch';
import { OrgContext } from '@/contexts';
import { withAuth } from '@/hoc';
import React, { Fragment, FunctionComponent, useContext, useCallback } from 'react';
import { SuperAdminOverlayViewProps } from './types';

export const SuperAdminOverlayView: FunctionComponent<SuperAdminOverlayViewProps> = ({
  children,
  profile,
  isSuperAdmin,
}) => {
  const { orgId } = useContext(OrgContext);

  const showOrgOverlay = profile && !orgId;

  const renderChildren = useCallback(
    () => <Fragment key={orgId || 'default'}>{children}</Fragment>,
    [children, orgId],
  );

  return isSuperAdmin ? (
    <>
      {renderChildren()}
      {showOrgOverlay && (
        <OrganisationSwitch
          hideElement={true}
          showOverlay={showOrgOverlay}
          canClose={false}
        />
      )}
    </>
  ) : (
    renderChildren()
  );
};

export default withAuth(SuperAdminOverlayView);
