import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  UpdateSupportTimesSettingsPayload,
  SupportTimesSettings,
  SUPPORT_TIMES_SETTINGS_QUERY_KEY,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useUpdateSupportTimesSettings = () => {
  const { toast } = useToast();

  const updateSupportTimesSettingsMutation = useMutation(
    ({ featureId, data }: UpdateSupportTimesSettingsPayload) => {
      return client.patch<SupportTimesSettings>(
        `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/settings/supporttimes`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update settings'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([SUPPORT_TIMES_SETTINGS_QUERY_KEY, featureId]);
        toast('Settings updated successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateSupportTimesSettings,
    isLoading,
    error,
  } = updateSupportTimesSettingsMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateSupportTimesSettings };
};
