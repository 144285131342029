import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import {
  UpdateMessageBubbleRequest,
  ErrorMessage,
  extractServerErrorMsg,
  getUpdateMessageContextUrl,
  MessageBubble,
} from '@/queries';
import client, {
  ClientCancelToken,
  createCancelToken,
  wasCancelled,
} from '@/store/client';
import { uploadAnswerFile } from './uploadAnswerFile';

const answerCancelTokens: Record<string, ClientCancelToken> = {};

const cancelAnswersRequest = (id: string, message?: string) => {
  answerCancelTokens?.[id]?.cancel(message);
  answerCancelTokens[id] = createCancelToken();
};

export const useUpdateMessageBubble = () => {
  const { toast } = useToast();

  const updateMessageBubbleMutation = useMutation(
    async ({ botId, featureId, messageId, bubble }: UpdateMessageBubbleRequest) => {
      const { id } = bubble;

      if (!id) throw new Error('Message Bubble ID not present');

      cancelAnswersRequest(id, 'New update message requested');

      const body = await uploadAnswerFile({ botId, featureId, messageId, bubble });

      return client.patch<MessageBubble>(
        getUpdateMessageContextUrl(id, messageId, botId, featureId),
        body,
        {
          cancelToken: answerCancelTokens[id]?.token,
        },
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        if (wasCancelled(errorResponse)) return;
        toast(extractServerErrorMsg(errorResponse, 'Failed to update bubble'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast('Message bubble updated.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateMessageBubble,
    isLoading,
    error,
  } = updateMessageBubbleMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateMessageBubble };
};
