import { IconAllTypes } from '@/components/Icon';
import { isString } from 'lodash/fp';

const replacePattern = /(>|<)/gi;
const youtubePattern = /(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/;
const idSplitPattern = /[^0-9a-z_-]/i;

const fileIconList = Object.freeze({
  'application/pdf': 'PdfIcon',
});

/**
 * Reads a file and returns the data url
 *
 * @param {File} file
 */
export const getFileDataUrl = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!file) reject(new Error('No file supplied'));

    const fileReader = new FileReader();

    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      resolve(e?.target?.result as string);
    };

    fileReader.readAsDataURL(file);
  });

/**
 * Reads a file and returns the raw binary string
 *
 * @param {File} file
 */
export const getFileArrayBuffer = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!file) reject(new Error('No file supplied'));

    const fileReader = new FileReader();

    fileReader.onload = (e: ProgressEvent<FileReader>) => {
      resolve(e?.target?.result as string);
    };

    fileReader.readAsArrayBuffer(file);
  });

export const isYoutubeVideo = (url: string) => !!youtubePattern.test(url);

export const getYoutubeVideoId = (url: string) => {
  const [a, , b] = url.replace(replacePattern, '').split(youtubePattern);
  if (b !== undefined) {
    return b.split(idSplitPattern)[0];
  }

  return a;
};

export const getDocumentIcon = (file: File | string): IconAllTypes =>
  isString(file)
    ? 'AttachFileOutlined'
    : fileIconList?.[file?.type] || 'AttachFileOutlined';
