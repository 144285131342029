import { ComponentProps, ComponentType, FunctionComponent } from 'react';
import connect, { WithOrgProps } from './withOrg.state';

export const withOrg = <TProps extends {}>(Component: ComponentType<TProps>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `WithOrg(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent as FunctionComponent<
    Omit<ComponentProps<typeof Component>, keyof WithOrgProps>
  >;
};
