import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { BILLING_GROUPS_QUERY_KEY } from '../constants';
import { BillingGroup, BillingRequest } from '../types';

const getBillingGroups = ({ orgId, botId }: BillingRequest) => {
  return () => {
    return client.get<Array<BillingGroup>>(
      `${API_HOST}/${ApiRoutes.Billing}/organisations/${orgId}/bots/${botId}/billinggroups`,
    );
  };
};

export const useGetBillingGroups = ({ orgId, botId }: BillingRequest) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [BILLING_GROUPS_QUERY_KEY, orgId, botId],
    getBillingGroups({ orgId, botId }),
    {
      enabled: !!orgId && !!botId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get billing groups.'), {
          variant: 'error',
        });
      },
    },
  );

  const billingGroups = data?.data;
  return { billingGroups, isLoading };
};
