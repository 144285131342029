import { ComponentProps, ComponentType, FunctionComponent } from 'react';
import createConnect, { WithQuestionStatsProps } from './withQuestionStats.state';
import { WithQuestionStatsAwareProps } from './types';

export const withQuestionStats = <TProps extends WithQuestionStatsAwareProps>(
  Component: ComponentType<TProps>,
) => {
  const connect = createConnect<TProps>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `WithQuestionStats(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent as FunctionComponent<
    WithQuestionStatsAwareProps &
      Omit<ComponentProps<typeof Component>, keyof WithQuestionStatsProps>
  >;
};
