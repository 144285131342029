import React from 'react';
import { Redirect } from 'react-router-dom';
import { SettingsPage } from '@/pages';
import { RoutingConfig } from '@/routing';

export const settings: RoutingConfig = [
  {
    title: 'Company Basics',
    path: '/basics',
    exact: true,
    component: SettingsPage,
    icon: 'CompanyBasicsIcon',
  },
  {
    title: 'Invite New User',
    path: '/platform-users/invite',
    exact: true,
    component: SettingsPage,
    icon: 'ChevronIcon',
  },
  {
    title: 'Update User',
    path: '/platform-users/:id',
    exact: true,
    component: SettingsPage,
    icon: 'ChevronIcon',
  },
  {
    title: 'Platform Users',
    path: '/platform-users',
    exact: true,
    component: SettingsPage,
    icon: 'UsersIcon',
  },

  // This must be last
  {
    path: '',
    exact: true,
    render: ({ match }) => <Redirect to={`${match.url}/basics`} />,
  },
];

export default settings;
