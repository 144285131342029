import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  getRemoveMessageContextUrl,
  RemoveMessageBubbleRequest,
} from '@/queries';
import client, { ClientCancelToken, wasCancelled } from '@/store/client';

const answerCancelTokens: Record<string, ClientCancelToken> = {};

export const useRemoveMessageBubble = () => {
  const { toast } = useToast();

  const removeMessageBubbleMutation = useMutation(
    ({ botId, featureId, messageId, bubbleId }: RemoveMessageBubbleRequest) =>
      client.delete(getRemoveMessageContextUrl(bubbleId, messageId, botId, featureId), {
        cancelToken: answerCancelTokens[bubbleId]?.token,
      }),
    {
      onError: (errorResponse: AxiosError) => {
        if (wasCancelled(errorResponse)) return;
        toast(extractServerErrorMsg(errorResponse, 'Failed to remove message bubble.'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast('successfully removed message bubble.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: removeMessageBubble,
    isLoading,
    error,
  } = removeMessageBubbleMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, removeMessageBubble };
};
