import React from 'react';
import Button from '@/components/formElements/Button';
import MinimalLayout from '@/components/MinimalLayout';
import { Alert, GridContainer } from './ResetPassword.styled';
import { Container, Content, Title } from '../Onboarding.styled';
import { ActionGridContainer } from '@/components/core/ActionGrid';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

export function ResetPasswordTokenExpired() {
  return (
    <MinimalLayout>
      <Content>
        <Title data-testid="main-title" variant="h2" gutterBottom>
          Reset Password
        </Title>

        <GridContainer>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Container>
                <Alert data-testid="token-expired-alert" severity="error">
                  Forgotten password token has expired. Please request a new one and try
                  again
                </Alert>

                <Grid item xs={12}>
                  <ActionGridContainer justify="center">
                    <Link to="/forgot-password">
                      <Button data-testid="request-new-password-button">
                        Request New Password
                      </Button>
                    </Link>
                  </ActionGridContainer>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </GridContainer>
      </Content>
    </MinimalLayout>
  );
}
