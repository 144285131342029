import { useEffect } from 'react';

export const usePreventWindowUnload = (enable: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault();
      return (event.returnValue =
        'You have unsaved changed. Are you sure you want to close?');
    };
    if (!enable) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      return;
    }
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [enable]);
};
