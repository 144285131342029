import React, { createContext, useState } from 'react';
import { noop } from '@/utils';

type BotGridViewContextProps = {
  searchFilterQuery: string;
  setSearchFilterQuery: (searchFilterQuery: string) => void;
};

// BotGridView context
export const BotGridViewContext = createContext<BotGridViewContextProps>({
  searchFilterQuery: '',
  setSearchFilterQuery: noop,
});

// BotGridView context provider
export const BotGridViewContextProvider = ({ children }: { children?: any }) => {
  const [searchFilterQuery, setSearchFilterQuery] = useState<string>('');

  return (
    <BotGridViewContext.Provider value={{ searchFilterQuery, setSearchFilterQuery }}>
      {children}
    </BotGridViewContext.Provider>
  );
};
