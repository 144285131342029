import {
  BotRemoteUserBulkUploadPage,
  BotRemoteUserInvitePage,
  BotSettingsPage,
} from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const settings: RoutingConfig = [
  {
    title: 'Invite new user',
    icon: 'ChevronIcon',
    path: '/bot-settings/remote-users/invite',
    exact: true,
    component: BotRemoteUserInvitePage,
    customProps: { permissionArea: BotPermissionCode.BOT_SETTINGS_ADMIN },
  },
  {
    title: 'Bulk upload users',
    icon: 'ChevronIcon',
    path: '/bot-settings/remote-users/upload',
    exact: true,
    component: BotRemoteUserBulkUploadPage,
    customProps: {
      type: 'templates/remoteworkers',
      permissionArea: BotPermissionCode.BOT_SETTINGS_ADMIN,
    },
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    description: 'Adjust your settings within users, bots, channels and other basics.',
    path: '/bot-settings',
    component: BotSettingsPage,
    customProps: { permissionArea: BotPermissionCode.BOT_SETTINGS_ADMIN },
  },
];

export default settings;
