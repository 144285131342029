import { ChannelsPage, ChannelSettingsPage } from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const channels: RoutingConfig = [
  {
    title: 'Channels',
    icon: 'ChannelsIcon',
    component: ChannelsPage,
    path: '/channels',
    customProps: {
      billingGroupCodes: ['OUTSIDE:INTEGRATE', 'INSIDE:INTEGRATE'],
      permissionArea: BotPermissionCode.BOT_CHANNELS_ADMIN,
    },
  },
  {
    title: 'Channel settings',
    icon: 'ChevronIcon',
    component: ChannelSettingsPage,
    path: '/channel/:channelId',
    customProps: {
      billingGroupCodes: ['OUTSIDE:INTEGRATE', 'INSIDE:INTEGRATE'],
      permissionArea: BotPermissionCode.BOT_CHANNELS_ADMIN,
    },
  },
];

export default channels;
