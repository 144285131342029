import { TextFieldProps, FormLabelProps, InputProps } from '@material-ui/core';
import { ChangeEvent, KeyboardEvent } from 'react';

export interface TextInputChangeHandler {
  (e: string): void;
  (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void;
}

/*  
  This is a union type, between a type that allows TOptional, TRequired with an indexer 
  that forces TOptional, TRequired to both be present if TOptional is present.

  With this we're trying trying to implement a conditional optional property.
*/
type RequireAll<TOptional, TRequired> =
  | (TOptional & TRequired)
  | (Partial<Record<keyof TOptional, never>> & TRequired);

// rich property here is our conditional optional property.
type RichTextProps = RequireAll<
  {
    rich: boolean;
    richActions: Array<'emoji' | 'bold' | 'underline' | 'italic' | 'link'>;
  },
  { [n: string]: any }
>;

export type TextInputProps = Omit<TextFieldProps, 'onChange'> &
  RichTextProps & {
    onChange?: TextInputChangeHandler;
    onKeyPress?: (e: KeyboardEvent<HTMLDivElement>) => void;
    label?: string;
    formLabelProps?: FormLabelProps;
    richBackground?: string;
    richHeight?: string;
    richBorder?: boolean;
    richPadding?: boolean;
    richMaxLength?: number;
    InputProps?: InputProps;
  };

export type PellContainerProps = {
  $richBackground?: string;
  $richHeight: string;
  $richBorder: boolean;
  $richPadding: boolean;
  $placeholder?: string;
  $disabled?: boolean;
};

export enum RichActionsEnum {
  EMOJI = 'emoji',
  BOLD = 'bold',
  UNDERLINE = 'underline',
  ITALIC = 'italic',
  LINK = 'link',
}
