import { all } from 'redux-saga/effects';
import authSagas from './auth/sagas';
import luvSagas from './luv/sagas';
import onboardingSagas from './onboarding/sagas';
import questionsSagas from './questions/sagas';
import toastSagas from './toast/sagas';
import liveChatSagas from './liveChat/sagas';
import messagesSagas from './messages/sagas';

function* rootSaga() {
  yield all([
    ...authSagas,
    ...luvSagas,
    ...onboardingSagas,
    ...questionsSagas,
    ...toastSagas,
    ...liveChatSagas,
    ...messagesSagas,
  ]);
}

export default rootSaga;
