import React from 'react';
import {
  StyledViewContainer,
  PagePermissionDisallowedMessage,
  DefaultMessageText,
  PagePermissionDisallowedLogo,
  IconContainer,
} from './PagePermissionDisallowed.styled';
import Icon from '@/components/Icon';
import { Typography } from '@material-ui/core';

export const PagePermissionDisallowed: React.FC = () => {
  return (
    <StyledViewContainer>
      <PagePermissionDisallowedMessage>
        <PagePermissionDisallowedLogo>
          <IconContainer>
            <Icon type="FutrIconSad" />
          </IconContainer>
        </PagePermissionDisallowedLogo>

        <Typography variant="h1" style={{ marginBottom: '2rem' }}>
          You do not have permission to view this page.
        </Typography>

        <DefaultMessageText variant="body1">
          Please contact your administrator to request access.
        </DefaultMessageText>
      </PagePermissionDisallowedMessage>
    </StyledViewContainer>
  );
};
