import styled, { css } from 'styled-components';

import Typography from '@material-ui/core/Typography';

export const Container = styled.div(
  ({ theme }) => `
    background-color: #fff;
    padding: ${theme.spacing(5)};
    border-radius: 0.5rem;
    text-align: left;
    border-radius: 0.5rem;
  `,
);

export const Title = styled(Typography)(
  ({ theme }) => css`
    && {
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
      ${theme.breakpoints.up('md')} {
        max-width: 52.8125rem;
        font-size: 2.625rem;
      }
    }
  `,
);

export const SubTitle = styled(Typography)(
  ({ theme }) => css`
    && {
      margin-bottom: 1.625rem;
      font-size: 1.125rem;
      max-width: 35rem;
      ${theme.breakpoints.up('md')} {
        margin-bottom: 2.1875rem;
        font-size: 1.75rem;
        max-width: 52.8125rem;
      }
    }
  `,
);

export const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  text-align: center;
`;
