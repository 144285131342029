import { useState, useCallback } from 'react';

// Hook
// Parameter is the boolean, with default "false" value
export const useToggle = (initialState = false): [boolean, () => void] => {
  // Initialize the state
  const [state, setState] = useState<boolean>(initialState);
  // Define and memorize toggler function in case we pass down the comopnent,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback((): void => setState(currentState => !currentState), []);
  return [state, toggle];
};
