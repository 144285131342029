export const getValueAsStrength = (
  value: number,
  min: number,
  max: number,
  threshold = 5,
) => {
  // Will always be the top if the same min and max
  if (min === max) return threshold;

  const diff = max - min;
  const quintile = diff / Math.max(1, threshold);

  const strength = (value - min) / quintile;
  return Math.ceil(Math.max(strength, 1));
};
