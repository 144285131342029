import { createSelector } from 'reselect';
import { prop, equals } from 'lodash/fp';
import { OrganisationRoleCode } from '@/queries';
import { AppState } from '@/store';

const equalsAdmin = equals(OrganisationRoleCode.Admin);
const equalsSuperAdmin = equals(OrganisationRoleCode.SuperAdmin);

const codeProp = prop('code');

export const getAuthState = (state: AppState) => state.auth;

export const getAuthStatuses = createSelector(getAuthState, auth => auth.status || {});
export const getAuthUserProfile = createSelector(getAuthState, auth => auth.profile);
export const getIdToken = createSelector(getAuthState, auth => auth.idToken);
export const isAuthenticated = createSelector(getAuthState, auth => auth.authenticated);

export const getAuthProfilePlatformRole = createSelector(
  getAuthUserProfile,
  prop('platformRole'),
);

export const getAuthProfilePlatformRoleCode = createSelector(
  getAuthProfilePlatformRole,
  codeProp,
);
export const getAuthProfileOrganisationRole = createSelector(
  getAuthUserProfile,
  prop('organisationRole'),
);

export const getAuthProfileOrganisationRoleCode = createSelector(
  getAuthProfileOrganisationRole,
  codeProp,
);

export const isAdmin = createSelector(
  getAuthProfilePlatformRoleCode,
  getAuthProfileOrganisationRoleCode,
  (platformRole, orgRole) => equalsSuperAdmin(platformRole) || equalsAdmin(orgRole),
);

export const isSuperAdmin = createSelector(
  getAuthProfilePlatformRoleCode,
  equalsSuperAdmin,
);
