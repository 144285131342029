import React, { FunctionComponent } from 'react';
import { TextInputProps } from './types';
import RichInput from './RichInput';
import Textarea, { TextareaProps } from './Textarea';
import { FormLabel } from '@/components/formElements/FormLabel';

const TextInput: FunctionComponent<TextInputProps> = ({
  rich,
  label,
  formLabelProps,
  ...props
}) => {
  const id = props.id || props.name;
  return (
    <>
      {label && (
        <FormLabel htmlFor={id} {...formLabelProps}>
          {label}
        </FormLabel>
      )}
      {rich ? (
        <RichInput id={id} {...(props as TextInputProps)} />
      ) : (
        <Textarea id={id} {...(props as TextareaProps)} />
      )}
    </>
  );
};

TextInput.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
};

export default TextInput;
