import { useQuery } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { useDashboardParams } from '@/hooks/useDashboardParams';
import client from '@/store/client';
import { APIErrorResponse } from '@/types/error';
import { useCallback } from 'react';
import { STATS_BASE_URL } from './useGetStatsV1';
import { SelectValue } from '@/components/formElements';

export function useGetAgentsV1(queryKey: string) {
  const paramValues = useDashboardParams();
  const botId = paramValues.botId;

  const query = useQuery<AxiosResponse<SelectValue[]>, AxiosError<APIErrorResponse>>(
    [queryKey, paramValues.test],
    useCallback(() => {
      return client.get<SelectValue[]>(
        `${STATS_BASE_URL}/${botId}/livechat/agents?test=${paramValues.test}`,
      );
    }, [botId, paramValues.test]),
    { refetchOnMount: true, refetchOnWindowFocus: false },
  );

  return {
    botId,
    ...query,
  };
}
