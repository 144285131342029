import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, ANNOUNCEMENT_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { Announcement, GetAnnouncementRequest } from '../types';

const getAnnouncement = ({ featureId, announcementId }: GetAnnouncementRequest) => {
  return () => {
    return client.get<Announcement>(
      `${API_HOST}/${ApiRoutes.Announcements}/${featureId}/announcements/${announcementId}`,
    );
  };
};

export const useGetAnnouncement = ({
  featureId,
  announcementId,
}: GetAnnouncementRequest) => {
  const { toast } = useToast();
  const { data, isLoading } = useQuery(
    [ANNOUNCEMENT_QUERY_KEY, featureId, announcementId],
    getAnnouncement({ featureId, announcementId }),
    {
      enabled: !!featureId && !!announcementId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get announcement.'), {
          variant: 'error',
        });
      },
    },
  );

  const announcement = data?.data || {};
  return { announcement, isLoading };
};

// refresh announcement cache for a given featureId & announcementId
export const invalidateAnnouncementQuery = (featureId: string, announcementId?: string) =>
  queryClient.invalidateQueries([ANNOUNCEMENT_QUERY_KEY, featureId, announcementId]);
