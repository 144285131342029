import { WebSearchPage } from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const webSearch: RoutingConfig = [
  {
    title: 'Web Search',
    // To-DO: get icons from bot features API
    icon: 'KnowledgeSearchIcon',
    path: '/web-search',
    description:
      "Enable to allow your chatbot to source answers to your users' questions directly from your own web content.",
    component: WebSearchPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:AUTOMATE', 'INSIDE:AUTOMATE'],
      permissionArea: BotPermissionCode.WEB_SEARCH_ADMIN,
    },
  },
];

export default webSearch;
