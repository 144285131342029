import { isEmpty, pick, pipe, reject, split } from 'lodash/fp';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AsyncComponent from '@/components/AsyncComponent';
import { AuthenticatedRoute } from '@/components/AuthenticatedRoute';
import { CustomRouteProps, RouteConfig, RoutingConfig } from '@/routing';
import { AllRouteProps } from './types';
import { PagePermissionCheck } from '@/components/PagePermissionCheck/PagePermissionCheck';
type RoutePropKeys = keyof RouteProps;
type RouteConfigKeys = keyof CustomRouteProps;

const extractRouteProps = pick<RouteConfig, RoutePropKeys>([
  'children',
  'exact',
  'location',
  'sensitive',
  'strict',
]);

const extractConfigProps = pick<RouteConfig, RouteConfigKeys>([
  'title',
  'icon',
  'description',
]);

/**
 * Takes all the routing props and determines what to render for the route
 *
 * @param {AllRouteProps} props All routing configuration, component and render props
 */
export const renderRoute = (props: AllRouteProps) => {
  const { render, component: Component, customProps } = props;
  const configProps = extractConfigProps(props);

  return render
    ? render({ ...props, ...customProps, ...configProps })
    : (Component && <Component {...props} {...configProps} {...customProps} />) || null;
};

/**
 * Generates a route given a base path and a routing config
 *
 * @param {string} basePath
 */
export const generateRoute = (basePath = '', withPermissions = false) => (
  route: RouteConfig,
) => {
  const { open, path, customProps } = route;
  const routeProps = extractRouteProps(route);

  const paths = Array.isArray(path)
    ? path.map(rootPath => `${basePath}${rootPath}`)
    : `${basePath}${path}`;

  const renderRouteOrAuthenticatedRoute = (props: AllRouteProps) => {
    if (!open) {
      return <AuthenticatedRoute {...route} {...props} {...customProps} />;
    }
    return renderRoute({ ...route, ...props });
  };

  return (
    <Route
      {...routeProps}
      key={path?.toString() ?? uuidv4()}
      path={paths}
      render={props =>
        withPermissions ? (
          <PagePermissionCheck permissionArea={customProps?.permissionArea}>
            <AsyncComponent>{renderRouteOrAuthenticatedRoute(props)}</AsyncComponent>
          </PagePermissionCheck>
        ) : (
          <AsyncComponent>{renderRouteOrAuthenticatedRoute(props)}</AsyncComponent>
        )
      }
    />
  );
};

/**
 * Generates all routes for an array of routing config and given base path
 *
 * @param {RoutingConfig} routes
 * @param {string} basePath
 */
export const generateRoutes = (routes: RoutingConfig, basePath = '') =>
  routes.map(generateRoute(basePath));

export const generateRoutesWithPermissions = (routes: RoutingConfig, basePath = '') =>
  routes.map(generateRoute(basePath, true));

/**
 * Extracts all the parts into an array, removing empties
 *
 * @param {string}
 */
export const extractPathParts = pipe(split('/'), reject(isEmpty));
