import { enqueueToast } from '@/store/toast';
import { FacebookPage, facebookPageListResponse } from './types';

export const facebookScopes = Object.freeze([
  'public_profile',
  'manage_pages',
  'pages_manage_cta',
  'pages_manage_instant_articles',
  'pages_show_list',
  'pages_messaging',
  'pages_messaging_phone_number',
  'pages_messaging_subscriptions',
]);

export const logout = async (callback: () => void) => {
  try {
    await new Promise(resolve => FB.logout(resolve));
  } catch (e) {
    enqueueToast({
      message: 'Could not log you out of Facebook, please try again',
      options: { variant: 'error' },
    });
  }
  callback();
};

export const login = (
  onSuccess: (authResponse: fb.AuthResponse) => void,
  reauthenticate?: boolean,
) => {
  FB.login(
    (data: fb.StatusResponse) => {
      if (data.status === 'connected') {
        enqueueToast({
          message: 'Successfully logged in to Facebook',
          options: { variant: 'success' },
        });

        return onSuccess(data.authResponse);
      }

      enqueueToast({
        message: 'Could not log you in to Facebook',
        options: { variant: 'error' },
      });
    },
    {
      /* Typings mismatch the docs / actual functionality
       (ref: https://developers.facebook.com/docs/facebook-login/reauthentication/) */
      auth_type: reauthenticate ? ('reauthenticate' as 'rerequest') : undefined,
      scope: facebookScopes.join(','),
    },
  );
};

export const getFacebookPages = (callback: (data: Array<FacebookPage>) => void) => {
  FB.api('/me/accounts', function(response: facebookPageListResponse) {
    callback(response.data);
  });
};
