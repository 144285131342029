import { AppState } from '@/store';
import { createSelector } from 'reselect';
import { props, prop } from 'lodash/fp';
import { getStatsData } from '@/store/stats';
import { QuestionStatsFilter } from './types';

export const getQuestionsStatsState = (state: AppState) => state.questions.data.stats;
export const getQuestionId = (
  state: AppState,
  questionStatsFilterProps?: QuestionStatsFilter,
) => questionStatsFilterProps?.questionId || '';

export const getQuestionsStatsSummaryIds = createSelector(
  getQuestionsStatsState,
  stats => stats.summary ?? [],
);

export const getQuestionsStatsAskedIds = createSelector(
  getQuestionsStatsState,
  stats => stats.asked ?? [],
);

export const getQuestionsStatsPopularIds = createSelector(
  getQuestionsStatsState,
  stats => stats.popular ?? [],
);

export const getQuestionsStatsUnansweredIds = createSelector(
  getQuestionsStatsState,
  stats => stats.unanswered ?? [],
);

export const getPopularQuestions = createSelector(
  getQuestionsStatsState,
  stats => stats.question ?? {},
);

export const getQuestionsStatsSummary = createSelector(
  getQuestionsStatsSummaryIds,
  getStatsData,
  (ids, stats) => props(ids, stats),
);

export const getQuestionsStatsAsked = createSelector(
  getQuestionsStatsAskedIds,
  getStatsData,
  (ids, stats) => props(ids, stats),
);

export const getQuestionsStatsUnanswered = createSelector(
  getQuestionsStatsUnansweredIds,
  getStatsData,
  (ids, stats) => props(ids, stats),
);

export const getQuestionsStatsPopular = createSelector(
  getQuestionsStatsPopularIds,
  getStatsData,
  (ids, stats) => props(ids, stats),
);

export const getQuestionStats = createSelector(
  getQuestionId,
  getPopularQuestions,
  (id, questions) => prop(id, questions),
);

export const getQuestionsStatsPerConvoIds = createSelector(
  getQuestionsStatsState,
  stats =>
    stats.perConvo ?? {
      questions: [],
      average: '',
    },
);

export const getQuestionsStatsPerConvo = createSelector(
  getQuestionsStatsPerConvoIds,
  getStatsData,
  (perConvo, stats) => ({
    questions: props(perConvo.questions, stats),
    average: prop(perConvo.average, stats),
  }),
);

export const getQuestionsStatsPopularQuestion = createSelector(
  getQuestionStats,
  getStatsData,
  (questionStats, stats) => ({
    metrics: questionStats?.metrics ? props(questionStats?.metrics, stats) : [],
    alternatives: questionStats?.alternatives
      ? props(questionStats?.alternatives, stats)
      : [],
  }),
);
