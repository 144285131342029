import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, CONVERSATION_METADATA_QUERY_KEY } from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getConversationMetadataSettings = (botId: string | null) => {
  return () => {
    return client.get<any>(
      `${API_HOST}/${ApiRoutes.LiveChat}/bots/${botId}/settings/conversations/metadata`,
    );
  };
};

export const useGetConversationMetadataSettings = (botId: string | null) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [CONVERSATION_METADATA_QUERY_KEY, botId],
    getConversationMetadataSettings(botId),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      retry: 0,
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(
            errorResponse,
            'Failed to get conversation properties settings',
          ),
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const conversationMetadataSettings = data?.data || [];
  return { conversationMetadataSettings, isLoading };
};
