export type ChatamoSettings = {
  dev?: boolean;
  noInit?: boolean;
  startOpened?: boolean;
  locale?: string;
  userId?: string;
  showCallout?: boolean;
  calloutLabel?: string;
  calloutTitle?: string;
  calloutExplainer?: string;
};

export enum ChatamoEvents {
  DOMEVENT_INIT = 'chatamo-popup-init',
  DOMEVENT_OPEN = 'chatamo-popup-open',
  DOMEVENT_SEND = 'chatamo-popup-send',
  DOMEVENT_RELOAD_LOCAL = 'chatamo-popup-reload-local',
  DOMEVENT_RELOAD_PERSISTED = 'chatamo-popup-reload-persisted',
  DOMEVENT_CLOSE = 'chatamo-popup-close',
  DOMEVENT_KILL = 'chatamo-popup-kill',
}

export type ChatamoWindow = {
  chatamoSettings?: ChatamoSettings;
  chatamoInitialised?: boolean;
};
