import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  CONVERSATIONS_QUERY_KEY,
  ConversationsRequest,
  ConversationDetails,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getConversations = ({ botId, userId }: ConversationsRequest) => {
  return () => {
    return client.get<ConversationDetails[]>(
      `${API_HOST}/${ApiRoutes.LiveChat}/bots/${botId}/history/users/${userId}/conversations`,
    );
  };
};

export const useGetConversations = ({ botId, userId }: ConversationsRequest) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [CONVERSATIONS_QUERY_KEY, botId, userId],
    getConversations({ botId, userId }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get conversations'), {
          variant: 'error',
        });
      },
    },
  );

  const conversations = data?.data || [];
  return { conversations, isLoading };
};
