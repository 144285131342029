import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  VideoAuthResponse,
  VIDEO_AUTH_QUERY_KEY,
} from '@/queries';
import { LocalStorageKeys, setLocalStorageItem } from '@/storage';
import client, { ApiRoutes } from '@/store/client';

const getVideoAuth = () => {
  return () => {
    return client.get<VideoAuthResponse>(`${API_HOST}/${ApiRoutes.Call}/token`);
  };
};

export const useGetVideoAuth = () => {
  const { toast } = useToast();

  const { isSuccess, isLoading: isFetchingVideoAuth } = useQuery(
    [VIDEO_AUTH_QUERY_KEY],
    getVideoAuth(),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get video auth'), {
          variant: 'error',
        });
      },
      onSuccess(data) {
        setLocalStorageItem(LocalStorageKeys.userId, data?.data?.userId);
        setLocalStorageItem(LocalStorageKeys.token, data?.data?.token);
      },
    },
  );

  return { isFetchingVideoAuth, isSuccess };
};
