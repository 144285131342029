import React from 'react';
import { Redirect } from 'react-router-dom';
import { BotFilters } from '@/queries';
import { RoutingConfig } from '@/routing';
import { BotsGridView } from '@/views';

export const bots: RoutingConfig = [
  {
    path: '/all',
    exact: true,
    component: BotsGridView,
    customProps: {
      botType: BotFilters.All,
    },
  },
  {
    path: '/inside',
    exact: true,
    component: BotsGridView,
    customProps: {
      botType: BotFilters.Inside,
    },
  },
  {
    path: '/outside',
    exact: true,
    component: BotsGridView,
    customProps: {
      botType: BotFilters.Outside,
    },
  },

  // This must be last
  {
    path: '',
    exact: true,
    render: ({ match }) => <Redirect to={`${match.url}/all`} />,
  },
];

export default bots;
