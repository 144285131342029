import React, {
  ComponentProps,
  ComponentType,
  FunctionComponent,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AppThemeProvider from '@/components/AppThemeProvider';
import ToastProvider from '@/components/ToastProvider';
import { LocaleContext, OrgContext, UserProfileContext } from '@/contexts';
import { withAuth, WithAuthProps } from '@/hoc';
import { getLocalStorageItem, OrganisationLocalStorageKeys } from '@/storage';
import { createTheme } from '@/styles/theme';
import { getDateLocale } from '@/utils';

export const withProviders = (
  Component: ComponentType,
): FunctionComponent<ComponentProps<typeof Component>> => {
  const WrappedComponent: FunctionComponent<ComponentProps<typeof Component> &
    Partial<WithAuthProps>> = ({ profile, ...props }) => {
    // This is static for now, but eventually will be data driven by user
    // profile locale and currency
    const locale = useContext(LocaleContext);

    const id = getLocalStorageItem(OrganisationLocalStorageKeys.orgId) as string;
    const [orgId, setOrgId] = useState(id ?? profile?.organisationId);

    useEffect(() => {
      setOrgId(id ?? profile?.organisationId);
    }, [id, profile]);

    const theme = useMemo(() => createTheme(), []);
    const dateFnsLocale = useMemo(() => getDateLocale(locale?.locale), [locale]);
    const orgContext = useMemo(() => ({ orgId, setOrgId }), [orgId]);

    return (
      <AppThemeProvider theme={theme}>
        <LocaleContext.Provider value={locale}>
          <UserProfileContext.Provider value={profile}>
            <OrgContext.Provider value={orgContext}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsLocale}>
                <ToastProvider>
                  <Component {...props} />
                </ToastProvider>
              </MuiPickersUtilsProvider>
            </OrgContext.Provider>
          </UserProfileContext.Provider>
        </LocaleContext.Provider>
      </AppThemeProvider>
    );
  };

  WrappedComponent.displayName = `WithProviders(${WrappedComponent.displayName ||
    'Component'})`;

  return withAuth(WrappedComponent);
};
