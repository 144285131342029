import { NavLinkInterface } from '@/types';

export const botSettingsLinks: NavLinkInterface[] = [
  { label: 'Bot Profile', url: '/bot-settings/bot-profile' },
  { label: 'Bot Basics', url: '/bot-settings/bot-basics' },
  { label: 'Remote Users', url: '/bot-settings/remote-users' },
];

export const liveChatSettingsLinks: NavLinkInterface[] = [
  { label: 'General Settings', url: '/live-chat/settings/general' },
  { label: 'Support Times', url: '/live-chat/settings/support-times' },
  { label: 'Take Offline', url: '/live-chat/settings/take-offline' },
  { label: 'Block Users', url: '/live-chat/settings/block-users' },
];

export const getQALinks = (
  allCount: number,
  activeCount: number,
  draftCount: number,
): NavLinkInterface[] => [
  { label: `All (${allCount})`, url: '/questions-and-answers' },
  { label: `Drafts (${draftCount})`, url: '/questions-and-answers/draft' },
  { label: `Active (${activeCount})`, url: '/questions-and-answers/active' },
];

export const getSurveysLinks = (
  allCount: number,
  draftCount: number,
  upcomingCount: number,
  activeCount: number,
  archivedCount: number,
): NavLinkInterface[] => [
  { label: `All (${allCount})`, url: '/surveys' },
  { label: `Drafts (${draftCount})`, url: '/surveys/draft' },
  { label: `Upcoming (${upcomingCount})`, url: '/surveys/upcoming' },
  { label: `Active (${activeCount})`, url: '/surveys/active' },
  { label: `Archived (${archivedCount})`, url: '/surveys/archived' },
];

export const getAnnouncementsLinks = (
  allCount: number,
  draftCount: number,
  upcomingCount: number,
  activeCount: number,
  archivedCount: number,
): NavLinkInterface[] => [
  { label: `All (${allCount})`, url: '/announcements' },
  { label: `Drafts (${draftCount})`, url: '/announcements/draft' },
  { label: `Upcoming (${upcomingCount})`, url: '/announcements/upcoming' },
  { label: `Active (${activeCount})`, url: '/announcements/active' },
  { label: `Archived (${archivedCount})`, url: '/announcements/archived' },
];

export const getDashboardLinks = (search = ''): NavLinkInterface[] => [
  { label: 'Users', url: `/dashboard/users${search}` },
  { label: 'Q&As', url: `/dashboard/questions-and-answers${search}` },
  { label: 'Knowledge Search', url: `/dashboard/knowledge-search${search}` },
  { label: 'Admin', url: `/dashboard/admin${search}` },
  { label: 'Live Chat', url: `/dashboard/live-chat${search}` },
];

export const botsGridLinks: NavLinkInterface[] = [
  { label: 'All bots', url: '/bots/all' },
  { label: 'Inside - For staff', url: '/bots/inside' },
  { label: 'Outside - For customers', url: '/bots/outside' },
];
