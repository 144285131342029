import { ThemeColorOptions } from '@/styles/theme';

export default {
  // Depricated
  primary: '#ef5a5e', // primary red
  secondary: '#4082bc', // Windows '98 blue

  // Action colors
  // Depricated
  success: '#52bfbb',
  error: '#fb9c33',

  // Depricated. Hardcode or move into Material-UI palette
  botOnlineColor: '#dcf2f1',
  botOfflineColor: '#f3f2f2',

  // Depricated. Hardcode or move into Material-UI palette
  greyishBrown: '#3d383b',

  // Greyscale
  // Depricated. Use Material-UI palette
  darkerGrey: '#848484',
  darkGrey: '#484848',
  midGrey: '#787878',
  paleGrey: '#e9e8e8',
  white: '#ffffff',

  // Almost Greyscale
  // Depricated. Use Material-UI palette
  veryLightAccent: '#f3f2f2', // pink
  veryLightAccent2: '#e9e8e8', // pink
  veryLightAccent3: '#f5f9fb', // blue
  veryLightAccent4: '#a3a3a3', // blue
} as ThemeColorOptions;
