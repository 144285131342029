import React, { ComponentProps, ComponentType, FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import createStore from '@/store';

const store = createStore();

export const withStore = (
  Component: ComponentType,
): FunctionComponent<ComponentProps<typeof Component>> => {
  const WrappedComponent: FunctionComponent<ComponentProps<typeof Component>> = props => (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );

  WrappedComponent.displayName = `WithStore(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent;
};
