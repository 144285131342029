import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, LIVE_VIDEO_TIMES_QUERY_KEY } from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getLiveVideoTimes = (botId: string) => {
  return () => {
    return client.get<{ expirationTimes: Array<any> }>(
      `${API_HOST}/${ApiRoutes.Video}/bots/${botId}/settings/times`,
    );
  };
};

export const useGetLiveVideoTimes = (botId: string) => {
  const { toast } = useToast();

  const { data, isLoading: isFetchingLiveVideoTimes } = useQuery(
    [LIVE_VIDEO_TIMES_QUERY_KEY],
    getLiveVideoTimes(botId),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get live video times.'), {
          variant: 'error',
        });
      },
    },
  );

  const liveVideoTimes = data?.data?.expirationTimes || [];
  return { liveVideoTimes, isFetchingLiveVideoTimes };
};
