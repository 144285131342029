import { update } from 'lodash/fp';
import { getLocalIdToken } from '@/storage';
import { ClientMiddleware } from '@/store/client';

const authMiddleware = update('headers', headers => {
  const token = getLocalIdToken();
  return {
    ...headers,
    ...(token && {
      Authorization: token,
    }),
  };
}) as ClientMiddleware;

export default authMiddleware;
