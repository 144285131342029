import React from 'react';
import { Redirect } from 'react-router-dom';
import { RoutingConfig } from '@/routing';
import {
  ChooseBotTypeView,
  OnboardingCreateBotView,
  CreateOrganisationView,
} from '@/views';

export const onboarding: RoutingConfig = [
  {
    path: '/organisation',
    exact: true,
    component: CreateOrganisationView,
    open: false,
    customProps: {
      type: ['sectors', 'employeeranges'],
    },
  },
  {
    path: '/type',
    exact: true,
    component: ChooseBotTypeView,
    open: false,
  },
  {
    path: '/name',
    exact: true,
    component: OnboardingCreateBotView,
    open: false,
  },

  // This must be last
  {
    path: '',
    exact: true,
    render: () => <Redirect to={'/signup'} />,
    open: true,
  },
];

export default onboarding;
