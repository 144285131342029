import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { StateType } from 'typesafe-actions';
import { NODE_ENV } from '@/config';
import { RootAction } from './rootAction';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import createWebsocketMiddleware from './middleware/websocket';

export * from './utils';

declare module 'typesafe-actions' {
  interface Types {
    RootAction: RootAction;
  }
}

export type AppState = StateType<typeof rootReducer>;

export default () => {
  const composeEnhancers =
    (NODE_ENV === 'development' &&
      // eslint-disable-next-line no-underscore-dangle
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const sagaMiddleware = createSagaMiddleware();

  const websocketMiddleware = createWebsocketMiddleware({
    prefix: 'LIVE_CHAT_WEBSOCKET',
  });

  const enhancers = composeEnhancers(
    applyMiddleware(sagaMiddleware, websocketMiddleware),
  );

  // Redux complains about undefined here. See note in rootReducer
  // eslint-disable-next-line
  // @ts-ignore
  const store = createStore(rootReducer, enhancers);

  sagaMiddleware.run(rootSaga);

  return store;
};
