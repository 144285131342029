import { connect } from 'react-redux';
import { AppState } from '@/store';
import { WithQuestionStatsAwareProps } from './types';
import {
  fetchQuestionsStatsPopularQuestion,
  getQuestionsStatuses,
} from '@/store/questions';

const createMapStateToProps = <_TProps extends WithQuestionStatsAwareProps>() => (
  state: AppState,
) => ({
  questionsStatuses: getQuestionsStatuses(state),
});

const mapDispatchToProps = {
  getPopularQuestionStats: fetchQuestionsStatsPopularQuestion.request,
};

const createConnect = <TProps extends WithQuestionStatsAwareProps>() =>
  connect(createMapStateToProps<TProps>(), mapDispatchToProps);

export default createConnect;

export type WithQuestionStatsProps = WithQuestionStatsAwareProps &
  ReturnType<ReturnType<typeof createMapStateToProps>> &
  typeof mapDispatchToProps;
