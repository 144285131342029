export type MessagesRequest = {
  botId: string | null;
  userId: string | null;
  locale?: string;
};

export type SearchCriteriaRequest = {
  botId?: string;
};

export type SearchCriteriaResponse = {
  botId: string;
  agents: string[];
  tags: string[];
  outcomes: string[];
};

export type Search = {
  userId: string;
  botId: string;
  channel: string;
  displayName: string;
  startDate: number;
  endDate: number;
  messageCount: number;
  avgConfidence: number;
  blocked: boolean;
};

export type SelectOption = {
  id: string;
};

export type SearchTranscriptsRequest = {
  botId?: string;
  wordSearch?: string;
  channelId?: string;
  hasAttachments?: boolean;
  hasProfanities?: boolean;
  blocked?: boolean;
  startDate?: string;
  endDate?: string;
  agents?: Array<SelectOption>;
  tags?: Array<SelectOption>;
  outcomes?: Array<SelectOption>;
};

export type TranscriptUser = {
  userId: string;
  botId: string;
  channel: string;
  dateStart: number;
  dateEnd: number;
  messageCount: number;
  avgConfidence: number;
  blocked: boolean;
  displayName?: string;
};

export interface MessageContentButton {
  text: string;
  type: string;
  value: string;
}
export interface MessageContent {
  type:
    | 'text'
    | 'button'
    | 'buttons'
    | 'list'
    | 'image'
    | 'video'
    | 'map'
    | 'attachment'
    | '';
  title: string;
  subtitle: string;
  text: string;
  image: string;
  video: string;
  map: string;
  buttons: MessageContentButton[] | null;
  list: MessageContent[] | null;
}

export enum MessageType {
  Event = 'event',
  Receiver = 'receiver',
  Sender = 'sender',
}
export interface Message {
  iconUrl: string;
  timestamp: string;
  title: string;
  type: MessageType;
  locale: string;
  content: MessageContent[];
}

export type MessagesResponse = Message[];
