import React from 'react';
import { Color } from '@material-ui/core';

interface LoyaltyFaceUpperProps {
  color: number | string | Partial<Color>;
}

const LoyaltyFaceUpper = (props: LoyaltyFaceUpperProps) => {
  const { color: baseColor } = props;
  const color = String(baseColor);

  return (
    <svg viewBox="0 0 561.41 545.45">
      <title>Loyalty Face Upper</title>
      <path
        d="M83.77 419.34h170.89v-83.77H83.77v-40c0-116.79 95-211.79 211.76-211.79a211.79 211.79 0 01190.55 119.24l75.33-36.65A295.54 295.54 0 00295.53 0C132.57 0 0 132.58 0 295.54v249.91h83.77z"
        fill={color}
      />
    </svg>
  );
};

export default LoyaltyFaceUpper;
