export type MessageTypes = 'TEXT' | 'IMAGE' | 'VIDEO' | 'ATTACHMENT' | 'MAP' | 'BUTTONS';

export enum MessageBubbleType {
  Text = 'TEXT',
  Image = 'IMAGE',
  Video = 'VIDEO',
  Attachment = 'ATTACHMENT',
  Buttons = 'BUTTONS',

  /** Unsupported */
  Map = 'MAP',
  List = 'LIST',
}

export type MessageBubbleButton = {
  type: ActionTypes;
  text: string;
  value: string;
  action?: ActionTypes;
  url?: string;
  attributes: string[];
};

export enum ActionTypes {
  LIVE_CHAT = 'connectLiveChat',
  URL = 'openUrl',
}
export type MessageBubble = {
  id?: string;
  order?: number;
  type?: MessageTypes;
  title?: string;
  text?: string;
  url?: string;
  fileId?: string;
  buttons?: Array<MessageBubbleButton>;
  file?: {
    filename: string;
    size: string;
    description: string;
  };
  fileData?: File;
  value?: string;
};

export type Message = {
  id: string;
  bubbles: Array<MessageBubble>;
};

export type CreateBubbleRequest = {
  botId: string;
  messagesId: string;
  bubble: MessageBubble;
  onComplete?: () => void;
};

export type UpdateBubbleRequest = {
  botId: string;
  messagesId: string;
  bubbleId: string;
  bubble: MessageBubble;
  onComplete?: () => void;
};

export type BubbleResponse = {
  messagesId: string;
  data: MessageBubble;
};

export type DeleteBubbleRequest = {
  botId: string;
  messagesId: string;
  bubbleId: string;
};

export type DeleteBubbleResponse = {
  messagesId: string;
  bubbleId: string;
};

export type GetBubblesRequest = {
  botId: string;
  messagesId: string;
  onComplete?: () => void;
};

export type GetBubblesResponse = {
  id: string;
  bubbles: Array<MessageBubble>;
};

export type SaveBubblesRequest = {
  botId: string;
  messagesId: string;
  bubbles: Array<MessageBubble>;
  order?: Array<{ id: string }>;
};

export type ReorderBubblesRequest = {
  botId: string;
  messagesId: string;
  bubblesIds: Array<{ id: string }>;
  onComplete?: () => void;
};
