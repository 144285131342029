import { connect } from 'react-redux';
import { AppState } from '@/store';
import { fetchLookupValues, getLuvs } from '@/store/luv';
import { WithLuvAwareProps } from './types';

const createMapStateToProps = <TProps extends WithLuvAwareProps>() => (
  state: AppState,
  props: TProps,
) => ({
  luv: getLuvs(state, props),
});

const mapDispatchToProps = {
  getLookupValues: fetchLookupValues.request,
};

const createConnect = <TProps extends WithLuvAwareProps>() =>
  connect(createMapStateToProps<TProps>(), mapDispatchToProps);

export default createConnect;

export type WithLuvProps = WithLuvAwareProps &
  ReturnType<ReturnType<typeof createMapStateToProps>> &
  typeof mapDispatchToProps;
