import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { augmentColor } from '@/styles/theme/utils';

export default {
  augmentColor,
  primary: {
    main: '#ef5a5e',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#4082bc',
  },
  success: {
    main: '#52bfbb',
    light: '#dcf2f1',
    contrastText: '#ffffff',
  },
  error: {
    main: '#fb9c33',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#484848',
    secondary: '#484848',
    disabled: 'rgba(72, 72, 72, 0.38)',
    hint: 'rgba(72, 72, 72, 0.38)',
  },
  grey: {
    '50': '#fafafa',
    '100': '#f2f2f2',
    '200': '#eeeeee',
    '300': '#e8e8e8',
    '400': '#bdbdbd',
    '500': '#848484',
    '600': '#787878',
    '700': '#616161',
    '800': '#525252',
    '900': '#484848',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  background: {
    default: '#f2f2f2',
    paper: '#FFFFFF',
  },
  divider: '#e9e8e8',
} as PaletteOptions;
