import {
  any,
  equals,
  identity,
  last,
  LodashFilter2x1,
  pipe,
  values,
  intersection,
} from 'lodash/fp';
import { StateStatus } from './types';

export const isInitiated = equals(StateStatus.INITIATED);
export const isSuccess = equals(StateStatus.SUCCESS);
export const isFailed = equals(StateStatus.FAILED);
export const isComplete = equals(StateStatus.COMPLETE);
export const isActive = pipe(values, any(isInitiated));

export const splitStatus = (type: string) => {
  const typeSplit = type.split('_');
  const typeStatus = last(typeSplit);
  typeSplit.pop();

  if (!typeStatus) return [];

  if (![...(Object.values(StateStatus) as Array<string>)].includes(typeStatus)) {
    throw new Error('Wrong status in action');
  }

  return [typeSplit.join('_'), typeStatus];
};

export const filterValuesByFilter = <
  TData extends {},
  TFilter extends string | null | undefined = string
>(
  filterSelector: (data: TData) => TFilter | string | null | undefined,
  data: Array<TData>,
  filter: Array<TFilter>,
) =>
  filter
    ? data.filter(item => {
        const result = filterSelector(item);
        return result && filter?.includes(result as TFilter);
      })
    : data;

export const filterValuesByResults = <TData extends {}>(
  idSelector: (data: TData) => string,
  data: Array<TData>,
  results: Array<string>,
) =>
  results?.length
    ? data.filter(item => (results?.length ? results.includes(idSelector(item)) : true))
    : [];

export const filterValues = <
  TData extends {},
  TFilter extends string | null | undefined = string
>(
  idSelector: (data: TData) => string,
  filterSelector: (data: TData) => TFilter | string | null | undefined,
  data: Record<string, TData>,
  results: Array<string>,
  filter: Array<TFilter>,
) => {
  const dataArr = Object.values(data ?? ({} as TData));

  const resultsItems = filterValuesByResults<TData>(idSelector, dataArr, results);
  const filterItems = filterValuesByFilter<TData, TFilter>(
    filterSelector,
    dataArr,
    filter,
  );

  return intersection(resultsItems, filterItems);
};

export const getLength = <TData extends {}>(filterer?: LodashFilter2x1<TData>) =>
  pipe(values, filterer ?? identity, data => data?.length ?? 0);
