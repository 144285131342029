import { API_HOST } from '@/config';
import client, { ApiRoutes, fileUpload } from '@/store/client';
import {
  ChangePasswordRequest,
  LoginUserRequest,
  UserCreateRequest,
  LoginUserResponse,
  UpdateUserProfileRequest,
  UserProfile,
} from './types';

const uploadProfilePhoto = (file: File) =>
  fileUpload(`${API_HOST}/${ApiRoutes.Core}/profile/photo`, file);

const signIn = (payload: LoginUserRequest) =>
  client.post<LoginUserResponse>(`${API_HOST}/${ApiRoutes.Auth}/signin`, payload);

const forgotPassword = () => client.get(`${API_HOST}/`);

const userProfile = () =>
  client.get<UserProfile>(`${API_HOST}/${ApiRoutes.Core}/profile`);

const createUser = (payload: UserCreateRequest) =>
  client.post<UserProfile>(`${API_HOST}/${ApiRoutes.Auth}/users/signup`, payload);

const updateUser = (payload: UpdateUserProfileRequest) =>
  client.patch<UserProfile>(`${API_HOST}/${ApiRoutes.Core}/profile`, payload);

const changePassword = (payload: ChangePasswordRequest) =>
  client.patch<typeof payload, void>(
    `${API_HOST}/${ApiRoutes.Core}/changepassword`,
    payload,
  );

export default {
  uploadProfilePhoto,
  signIn,
  forgotPassword,
  userProfile,
  createUser,
  updateUser,
  changePassword,
};
