import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import React, {
  forwardRef,
  FunctionComponent,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import { Link } from 'react-router-dom';
import Icon, { IconTypes } from '@/components/Icon';
import { ButtonBase, IconContainer } from './Button.styled';
import {
  ButtonContainerProps,
  ButtonProps,
  IconContainerColors,
  LinkedComponentProps,
} from './types';

const LinkedComponentFactory = (href: string, text: ReactNode) =>
  forwardRef<Link, LinkedComponentProps>((props, ref) => (
    <Link ref={ref} to={href} {...props}>
      {text}
    </Link>
  ));

const Button: FunctionComponent<ButtonProps> = ({
  children,
  startIcon: startIconBase,
  endIcon: endIconBase,
  isLoading,
  loadingLabel,
  variant,
  color,
  className,
  disabled,
  href,
  type,
  ...rest
}) => {
  const text = isLoading ? loadingLabel : children;

  const icon = useCallback(
    iconBase => {
      const buttonContainerProps: ButtonContainerProps = {
        color: color as IconContainerColors,
        contrast: variant === 'contained',
        fillIcon: variant !== 'text',
      };

      if (iconBase && typeof iconBase === 'string') {
        return (
          <IconContainer {...buttonContainerProps}>
            <Icon type={iconBase as IconTypes} />
          </IconContainer>
        );
      }

      if (iconBase && React.isValidElement(iconBase)) {
        return <IconContainer {...buttonContainerProps}>{iconBase}</IconContainer>;
      }
    },
    [color, variant],
  );

  const startIcon = useMemo(() => {
    return icon(startIconBase);
  }, [startIconBase, icon]);

  const endIcon = useMemo(() => {
    return icon(endIconBase);
  }, [endIconBase, icon]);

  const linkedComponent = useMemo(() => {
    if (href && href.charAt(0) === '/') {
      return { component: LinkedComponentFactory(href, text) };
    }
    return null;
  }, [href, text]);

  return (
    <ButtonBase
      className={className}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled || isLoading}
      variant={variant}
      color={color}
      type={type}
      disableFocusRipple={true}
      role="button"
      href={href}
      {...({
        ...rest,
        ...(href && linkedComponent),
      } as MuiButtonProps)}
    >
      {text}
    </ButtonBase>
  );
};

Button.defaultProps = {
  startIcon: null,
  endIcon: null,
  isLoading: false,
  loadingLabel: 'Submitting',
  disabled: false,
  variant: 'contained',
  color: 'primary',
  type: 'submit',
};

export default Button;
