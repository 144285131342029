import { CSSProperties } from 'react';
import styled from 'styled-components';

type FormActionsProps = Partial<Pick<CSSProperties, 'flexDirection'>> & {
  compact?: boolean;
  align?: Pick<CSSProperties, 'alignItems'>;
};

export const FormActions = styled.div<FormActionsProps>`
  padding: ${({ theme, compact }) => (compact ? 0 : theme.spacing(3))} 0;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row-reverse'};
  justify-content: space-between;
  align-items: ${({ align }) => align || 'center'};

  ${({ theme }) => `${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }`}
`;
