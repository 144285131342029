import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { Bot, ErrorMessage, extractServerErrorMsg, BOT_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { UpdateBotRequest } from '../types';

export const useUpdateBot = () => {
  const { toast } = useToast();

  const updateBotMutation = useMutation(
    ({ orgId, botId, ...body }: UpdateBotRequest) => {
      return client.patch<Bot>(
        `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/bots/${botId}`,
        body,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update Bot'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { orgId, botId }) => {
        queryClient.invalidateQueries([BOT_QUERY_KEY, orgId, botId]);
        toast('Bot updated.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: updateBot, isLoading, error } = updateBotMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateBot };
};
