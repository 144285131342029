import { connect } from 'react-redux';
import { AppState } from '@/store';
import { getForms, setOnboardingForm, setQuestionForm } from '@/store/forms';

const mapStateToProps = (state: AppState) => ({
  forms: getForms(state),
});

const mapDispatchToProps = {
  setOnboardingForm,
  setQuestionForm,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type WithFormsProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
