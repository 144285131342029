import React, { FunctionComponent } from 'react';

import { StyledBaseTooltip } from './Tooltip.Styled';
import { TooltipProps } from './types';

export const Tooltip: FunctionComponent<TooltipProps> = ({
  title,
  children,
  placement = 'bottom',
  hide,
  ...rest
}) =>
  hide ? (
    children
  ) : (
    <StyledBaseTooltip
      enterDelay={0.2}
      leaveDelay={0}
      title={title}
      placement={placement}
      {...rest}
    >
      {children}
    </StyledBaseTooltip>
  );

export default Tooltip;
