import { WithQuestionAwareProps } from '@/hoc';
import { MessageBubble } from '@/store/messages';
import { Tag } from '@/queries';

/** Answer types will eventually be deprecated for MessageTypes */
export type AnswerTypes = 'TEXT' | 'IMAGE' | 'VIDEO' | 'ATTACHMENT' | 'MAP' | 'BUTTONS';

export type Answer = MessageBubble;

export type Utterance = {
  id: string;
  utterance: string;
};

export enum QuestionStatusCode {
  Draft = 'DRAFT',
  DraftPublished = 'DRAFT & PUBLISHED',
  Published = 'PUBLISHED',
}

export type QuestionStatus = {
  code: QuestionStatusCode;
  description: string;
};

export type QuestionStatistics = {
  timesAsked: number;
};

export type QuestionFeaturedOrder = 1 | 2 | 3 | 4 | 5 | null | number;

export type Question = {
  id: string;
  messageId: string;
  question: string;
  utterances: Array<Utterance>;
  featured: boolean;
  featuredOrder: QuestionFeaturedOrder;
  tags: Array<Tag>;
  answers: Array<Answer>;
  answer?: {
    bubbles?: Array<MessageBubble>;
  };
  status: QuestionStatus;
  statistics: QuestionStatistics;
  createdOn: string;
  publishedOn: string;
  publishedBy: {
    displayName: string;
  };
};

export type Questions = Record<string, Question>;

export type QuestionsStatType =
  | 'answered'
  | 'savings'
  | 'response'
  | 'confidence'
  | 'asked';

export type QuestionViewType = 'all' | 'active' | 'draft';

export type QuestionsFilterProps = {
  filter?: QuestionViewType;
};

export type QuestionFormSelectorProps = WithQuestionAwareProps & {
  isNew?: boolean;
  formQuestionId?: string;
  featureKey?: string;
};

export type QuestionStatusMap = Record<QuestionViewType, Array<QuestionStatusCode>>;

export type QuestionsStat = {
  type?: QuestionsStatType;
  label?: string;
  value?: string;
  date?: string;
};

/** Request/Response Types */

export type IdRequest = {
  id: string;
};

export type FeatureRequest = {
  featureId?: string;
};

export type WithQuestionAndFeatureIds<T = {}> = T &
  Required<WithQuestionAwareProps> &
  FeatureRequest;

export type QuestionGetRequest = { questionId: string } & FeatureRequest;

export type QuestionRequest = Partial<Question> & FeatureRequest;

export type QuestionsSetFeaturedRequest = FeatureRequest & {
  id: string;
  featured: boolean;
};
export type QuestionsSetFeaturedResponse = QuestionsSetFeaturedRequest;

export type QuestionsFeaturedOrderChangeRequest = FeatureRequest & {
  prev: Array<IdRequest>;
  next: Array<IdRequest>;
};
export type QuestionsFeaturedOrderChangeResult = Array<IdRequest>;

export type QuestionDeleteResponse = IdRequest;

export type UtteranceRequest = WithQuestionAndFeatureIds<Partial<Utterance>>;
export type UtteranceResult = Omit<WithQuestionAndFeatureIds<Utterance>, 'featureId'>;

export type UtteranceDeleteRequest = {
  featureId: string;
  questionId: string;
  id: string;
};

export type UtteranceDeleteResult = UtteranceDeleteRequest;

export type AnswerRequest = Answer & {
  orgId?: string;
  questionId: string;
  messageId: string;
  featureId?: string;
  fileData?: File;
};
export type AnswerResult = Answer & { messageId: string };

export type AnswerDeleteRequest = {
  featureId: string;
  messageId: string;
  questionId: string;
  id: string;
};

export type AnswerDeleteResult = AnswerDeleteRequest;

export type AnswersReorderRequest = {
  answers: IdRequest[];
  messageId: string;
  featureId: string;
  questionId: string;
};

export type AnswerReorderResult = {
  questionId: string;
  answers: Array<Answer>;
};

export type QuestionTagAddRequest = WithQuestionAndFeatureIds<Partial<Tag>>;
export type QuestionTagAddResult = Omit<QuestionTagAddRequest, 'featureId'>;

export type QuestionTagRemoveRequest = WithQuestionAndFeatureIds<Tag>;
export type QuestionTagRemoveResult = Omit<QuestionTagRemoveRequest, 'featureId'>;

export type QuestionPublishRequest = WithQuestionAndFeatureIds;

export type QuestionUnPublishRequest = WithQuestionAndFeatureIds;

export type QuestionDraftRequest = {
  featureId: string;
  questionId: string;
};

export type QuestionBulkUploadRequest = FeatureRequest & {
  featureId: string;
  files: FileList;
};

export type QuestionIndexData = Omit<Question, 'utterances'> & {
  utterances?: string;
};
