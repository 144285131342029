import { connect } from 'react-redux';
import { AppState } from '@/store';
import {
  getQuestion,
  getQuestionsStatuses,
  createQuestion,
  updateQuestion,
  publishQuestion,
  draftQuestion,
  createUtterance,
  updateUtterance,
  deleteUtterance,
  createAnswer,
  updateAnswer,
  deleteAnswer,
  removeQuestionTag,
  addQuestionTag,
  fetchQuestion,
  reorderAnswers,
  unpublishQuestion,
} from '@/store/questions';
import { getForm } from '@/store/forms';
import { WithQuestionAwareProps } from './types';

const createMapStateToProps = <TProps extends WithQuestionAwareProps>() => (
  state: AppState,
  props: TProps,
) => ({
  questionsStatuses: getQuestionsStatuses(state),
  question: getQuestion(state, props),
  formQuestionId: getForm(state, { formId: 'question' }),
});

const mapDispatchToProps = {
  addQuestionTag: addQuestionTag.request,
  createAnswer: createAnswer.request,
  createQuestion: createQuestion.request,
  createUtterance: createUtterance.request,
  deleteAnswer: deleteAnswer.request,
  deleteUtterance: deleteUtterance.request,
  getQuestion: fetchQuestion.request,
  publishQuestion: publishQuestion.request,
  unpublishQuestion: unpublishQuestion.request,
  draftQuestion: draftQuestion.request,
  removeQuestionTag: removeQuestionTag.request,
  reorderAnswers: reorderAnswers.request,
  updateAnswer: updateAnswer.request,
  updateQuestion: updateQuestion.request,
  updateUtterance: updateUtterance.request,
};

const createConnect = <TProps extends WithQuestionAwareProps>() =>
  connect(createMapStateToProps<TProps>(), mapDispatchToProps);

export default createConnect;

export type WithQuestionProps = WithQuestionAwareProps &
  ReturnType<ReturnType<typeof createMapStateToProps>> &
  typeof mapDispatchToProps;
