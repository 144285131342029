import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { AxiosRequestConfig } from 'axios';
import { NegotiateResponse } from './types';

export const negotiateSignalR = (expiredToken?: string, config?: AxiosRequestConfig) => {
  const query = expiredToken ? `?renewToken=${expiredToken}` : '';
  return client.get<NegotiateResponse>(
    `${API_HOST}/${ApiRoutes.UserInterface}/negotiate${query}`,
    config,
  );
};
