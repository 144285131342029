import { useCallback } from 'react';
import { ChatamoEvents, ChatamoWindow } from './types';

export const useChatamoEvents = () => {
  const dispatchChatamoEvent = useCallback((event: ChatamoEvents) => {
    const chatamoEvent = new CustomEvent(event);

    document && document.dispatchEvent(chatamoEvent);
  }, []);

  const initChat = useCallback(() => {
    dispatchChatamoEvent(ChatamoEvents.DOMEVENT_INIT);
  }, [dispatchChatamoEvent]);

  const killChat = useCallback(() => {
    dispatchChatamoEvent(ChatamoEvents.DOMEVENT_KILL);
  }, [dispatchChatamoEvent]);

  const isInitialised = (window as ChatamoWindow)?.chatamoInitialised;

  return {
    isInitialised,
    initChat,
    killChat,
    dispatchChatamoEvent,
  };
};
