import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import { ANNOUNCEMENTS_QUERY_KEY, ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import { DeleteRequest, Announcement } from '../types';
import { AxiosError } from 'axios';

export const useDeleteAnnouncement = () => {
  const { toast } = useToast();

  const deleteAnnouncementMutation = useMutation(
    ({ featureId, announcementId }: DeleteRequest) => {
      return client.delete<Array<Announcement>>(
        `${API_HOST}/${ApiRoutes.Announcements}/${featureId}/announcements/${announcementId}`,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to delete announcement.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([ANNOUNCEMENTS_QUERY_KEY, featureId]);
        toast('Announcement deleted successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: deleteAnnouncement,
    isLoading,
    error,
  } = deleteAnnouncementMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, deleteAnnouncement };
};
