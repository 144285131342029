import { replace } from 'lodash/fp';

// This function doesn't care about whats inside so can be array of any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const moveIndex = <TData extends Array<any>>(
  fromIndex: number,
  toIndex: number,
  data: TData,
): TData => {
  const newData = [...data] as TData;
  newData.splice(toIndex, 0, newData.splice(fromIndex, 1)[0]);
  return newData;
};

export const noop = () => {
  // This is intentional - This function performs no operation
};

export const stripHtml = replace(/(<([^>]+)>)/gi, '');
