import memoize from 'memoize-one';
import React, { FunctionComponent } from 'react';
import { IconWrapper } from './Icon.styled';
import { IconAllTypes, IconProps } from './types';

const getIcon = memoize((type: IconAllTypes) => {
  try {
    // eslint-disable-next-line global-require
    return require('./icons')[type] || null;
  } catch (e) {
    return null;
  }
});

export const Icon: FunctionComponent<IconProps> = ({ type, color, ...props }) => {
  const Component = getIcon(type);

  return Component ? (
    <IconWrapper color={color}>
      <Component color={color} preserveAspectRatio="xMinYMin" {...props} />
    </IconWrapper>
  ) : null;
};

export default Icon;
