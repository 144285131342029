import { combineReducers } from 'redux';
import { keyBy } from 'lodash';
import { createReducer } from 'typesafe-actions';
import { splitStatus } from '@/store/utils';
import { RootStatus } from '@/store/types';
import {
  createBubble,
  updateBubble,
  deleteBubble,
  getBubbles,
  saveBubbles,
} from './actions';
import { MessageBubble, DeleteBubbleResponse } from './types';

const statusReducer = createReducer<RootStatus>({}).handleAction(
  [
    getBubbles.request,
    getBubbles.success,
    getBubbles.failure,
    createBubble.request,
    createBubble.success,
    createBubble.failure,
    updateBubble.request,
    updateBubble.success,
    updateBubble.failure,
    saveBubbles.request,
    saveBubbles.success,
    saveBubbles.failure,
  ],
  (state, { type }) => {
    const [actionKey, status] = splitStatus(type);
    return {
      ...state,
      [actionKey]: status,
    };
  },
);

const messagesReducer = createReducer<null | MessageBubble | DeleteBubbleResponse>(null)
  .handleAction([getBubbles.success], (state, { payload }) => {
    if (!payload?.bubbles || !payload?.id) return state;
    const { bubbles, id: messagesId } = payload;

    return {
      ...state,
      [messagesId]: keyBy(bubbles, 'id'),
    };
  })
  .handleAction([createBubble.success, updateBubble.success], (state, { payload }) => {
    const { messagesId, data } = payload;

    if (!data?.id) return state;

    return {
      ...state,
      [messagesId]: {
        ...(state?.[messagesId] ? state?.[messagesId] : {}),
        [data?.id]: data,
      },
    };
  })
  .handleAction([deleteBubble.success], (state, { payload }) => {
    const { messagesId, bubbleId } = payload;
    const nextMessages = Object.keys(state?.[messagesId])
      .filter(key => key !== bubbleId)
      .map(key => state?.[messagesId][key]);

    return {
      ...state,
      [messagesId]: keyBy(nextMessages, 'id'),
    };
  });

export default combineReducers({
  data: messagesReducer,
  status: statusReducer,
});
