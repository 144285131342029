import { lazy } from 'react';

export const BillingPage = lazy(() => import('./BillingPage'));
export const BotRemoteUserBulkUploadPage = lazy(() =>
  import('./BotRemoteUserBulkUploadPage'),
);
export const BotRemoteUserInvitePage = lazy(() => import('./BotRemoteUserInvitePage'));
export const BotSettingsPage = lazy(() => import('./BotSettingsPage'));
export const BotsPage = lazy(() => import('./BotsPage'));
export const ChannelsPage = lazy(() => import('./ChannelsPage'));
export const ChannelSettingsPage = lazy(() => import('./ChannelSettingsPage'));
export const DashboardPage = lazy(() => import('./DashboardPage'));
export const TranscriptsPage = lazy(() => import('./TranscriptsPage'));
export const KnowledgeSearchDocumentPage = lazy(() =>
  import('./KnowledgeSearchDocumentPage'),
);
export const KnowledgeSearchPage = lazy(() => import('./KnowledgeSearchPage'));
export const NewSurveyPage = lazy(() => import('./NewSurveyPage'));
export const NotFoundPage = lazy(() => import('./NotFoundPage'));
export const QuestionCreatePage = lazy(() => import('./QuestionCreatePage'));
export const QuestionPage = lazy(() => import('./QuestionPage'));
export const QuestionsBulkUploadPage = lazy(() => import('./QuestionsBulkUploadPage'));
export const QuestionsPage = lazy(() => import('./QuestionsPage'));
export const SurveysPage = lazy(() => import('./SurveysPage'));
export const SettingsPage = lazy(() => import('./SettingsPage'));
export const UserProfilePage = lazy(() => import('./UserProfilePage'));
export const LiveChatConsolePage = lazy(() => import('./LiveChatConsolePage'));
export const OrgLiveChatConsolePage = lazy(() => import('./OrgLiveChatConsolePage'));
export const LiveChatPage = lazy(() => import('./LiveChatPage'));
export const FeaturesPage = lazy(() => import('./FeaturesPage'));
export const CreateBotPage = lazy(() => import('./CreateBotPage'));
export const AnnouncementsPage = lazy(() => import('./AnnouncementsPage'));
export const LiveChatSettingsPage = lazy(() => import('./LiveChatSettingsPage'));
export const LiveVideoSettingsListPage = lazy(() =>
  import('./LiveVideoSettingsListPage'),
);
export const LiveVideoSettingsPage = lazy(() => import('./LiveVideoSettingsPage'));
export const SurveyBasicsPage = lazy(() => import('./SurveyBasicsPage'));
export const SurveySchedulePage = lazy(() => import('./SurveySchedulePage'));
export const SurveyReportPage = lazy(() => import('./SurveyReportPage'));
export const SurveyQuestionsPage = lazy(() => import('./SurveyQuestionsPage'));
export const AnnouncementMessagePage = lazy(() => import('./AnnouncementMessagePage'));
export const AnnouncementSchedulePage = lazy(() => import('./AnnouncementSchedulePage'));
export const WebSearchPage = lazy(() => import('./WebSearchPage'));
