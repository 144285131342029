export type GenericMessage = {
  content: unknown[];
};

export type ExtractedMessage<T extends GenericMessage> = Omit<T, 'content'> & {
  content: T['content'] extends Array<infer C> ? C : never;
};

export function extractMessages<T extends GenericMessage>(
  messageArray: T[],
): ExtractedMessage<T>[] {
  return messageArray.reduce<ExtractedMessage<T>[]>((acc, cur) => {
    const { content, ...rest } = cur;

    const newObjs = content.map(
      c =>
        ({
          ...rest,
          content: c,
        } as ExtractedMessage<T>),
    );

    return [...acc, ...newObjs];
  }, []);
}
