import { ClientCancelToken, createCancelToken } from '@/store/client';
import { call, put, takeEvery } from 'typed-redux-saga';
import { orderBy } from 'lodash/fp';
import {
  fetchQuestionsStatsSummary,
  fetchQuestionsStatsAsked,
  fetchQuestionsStatsPopular,
  fetchQuestionsStatsPopularQuestion,
  fetchQuestionsStatsUnanswered,
  fetchQuestionsStatsPerConvo,
  deleteQuestionsStatsUnanswered,
} from './actions';
import api from './api';
import hash from 'object-hash';
import { setStats, Statistics } from '@/store/stats';

let summaryStatsCancelToken: ClientCancelToken | undefined = undefined;
let askedStatsCancelToken: ClientCancelToken | undefined = undefined;
let popularStatsCancelToken: ClientCancelToken | undefined = undefined;
let popularQuestionStatsCancelToken: ClientCancelToken | undefined = undefined;
let unansweredStatsCancelToken: ClientCancelToken | undefined = undefined;
let perConvoStatsCancelToken: ClientCancelToken | undefined = undefined;

const cancelSummaryStatsRequest = (message: string) => {
  summaryStatsCancelToken?.cancel(message);
  summaryStatsCancelToken = createCancelToken();
};

const cancelAskedStatsRequest = (message: string) => {
  askedStatsCancelToken?.cancel(message);
  askedStatsCancelToken = createCancelToken();
};

const cancelPopularStatsRequest = (message: string) => {
  popularStatsCancelToken?.cancel(message);
  popularStatsCancelToken = createCancelToken();
};

const cancelPopularQuestionStatsRequest = (message: string) => {
  popularQuestionStatsCancelToken?.cancel(message);
  popularQuestionStatsCancelToken = createCancelToken();
};

const cancelUnansweredStatsRequest = (message: string) => {
  unansweredStatsCancelToken?.cancel(message);
  unansweredStatsCancelToken = createCancelToken();
};

const cancelPerConvoStatsRequest = (message: string) => {
  perConvoStatsCancelToken?.cancel(message);
  perConvoStatsCancelToken = createCancelToken();
};

export function* fetchQuestionsStatsSummarySaga({
  payload,
}: ReturnType<typeof fetchQuestionsStatsSummary.request>) {
  const { botId, ...params } = payload;

  yield call(() => cancelSummaryStatsRequest('New summary request'));

  try {
    if (!botId) throw new Error('Bot ID not present');

    const response = yield* call(() =>
      api.getSummary(botId, {
        params,
        cancelToken: summaryStatsCancelToken?.token,
      }),
    );

    const data = response.data.data.reduce<Statistics>(
      (accum, stat) => ({
        ...accum,
        [hash(`questions-summary-${botId}-${stat.label}`)]: stat,
      }),
      {},
    );

    yield put(setStats(data));
    yield put(fetchQuestionsStatsSummary.success(Object.keys(data)));
  } catch (e) {
    fetchQuestionsStatsSummary.failure(e);
  }
}

export function* fetchQuestionsStatsAskedSaga({
  payload,
}: ReturnType<typeof fetchQuestionsStatsAsked.request>) {
  const { botId, ...params } = payload;

  yield call(() => cancelAskedStatsRequest('New asked request'));

  try {
    if (!botId) throw new Error('Bot ID not present');

    const response = yield* call(() =>
      api.getAsked(botId, {
        params,
        cancelToken: askedStatsCancelToken?.token,
      }),
    );

    const data = response.data.data.reduce<Statistics>(
      (accum, stat) => ({
        ...accum,
        [hash(`questions-asked-${botId}-${stat.label}`)]: stat,
      }),
      {},
    );

    yield put(setStats(data));
    yield put(fetchQuestionsStatsAsked.success(Object.keys(data)));
  } catch (e) {
    fetchQuestionsStatsAsked.failure(e);
  }
}

export function* fetchQuestionsStatsPopularSaga({
  payload,
}: ReturnType<typeof fetchQuestionsStatsPopular.request>) {
  const { botId, ...params } = payload;

  yield call(() => cancelPopularStatsRequest('New popular request'));

  try {
    if (!botId) throw new Error('Bot ID not present');

    const response = yield* call(() =>
      api.getPopular(botId, {
        params,
        cancelToken: popularStatsCancelToken?.token,
      }),
    );

    const data = orderBy(
      ({ value }) => parseInt(value),
      ['desc'],
      response.data.data,
    ).reduce<Statistics>(
      (accum, stat) => ({
        ...accum,
        [hash(`questions-popular-${botId}-${stat.label}`)]: stat,
      }),
      {},
    );

    yield put(setStats(data));
    yield put(fetchQuestionsStatsPopular.success(Object.keys(data)));
  } catch (e) {
    fetchQuestionsStatsPopular.failure(e);
  }
}

export function* fetchQuestionsStatsPopularQuestionSaga({
  payload,
}: ReturnType<typeof fetchQuestionsStatsPopularQuestion.request>) {
  const { botId, questionId, ...params } = payload;

  yield call(() => cancelPopularQuestionStatsRequest('New popular question request'));

  try {
    if (!botId) throw new Error('Bot ID not present');
    if (!questionId) throw new Error('Question ID not present');

    const response = yield* call(() =>
      api.getPopularQuestion(botId, questionId, {
        params,
        cancelToken: popularQuestionStatsCancelToken?.token,
      }),
    );

    const metrics = response.data.data.metrics.reduce<Statistics>(
      (accum, stat) => ({
        ...accum,
        [hash(`${botId}-${questionId}-${stat.label}`)]: stat,
      }),
      {},
    );

    yield put(setStats({ ...metrics }));
    yield put(
      fetchQuestionsStatsPopularQuestion.success({
        questionId,
        metrics: Object.keys(metrics),
        alternatives: [],
      }),
    );
  } catch (e) {
    fetchQuestionsStatsPopularQuestion.failure(e);
  }
}

export function* fetchQuestionsStatsUnansweredSaga({
  payload,
}: ReturnType<typeof fetchQuestionsStatsUnanswered.request>) {
  const { botId, ...params } = payload;

  yield call(() => cancelUnansweredStatsRequest('New unanswered request'));

  try {
    if (!botId) throw new Error('Bot ID not present');

    const response = yield* call(() =>
      api.getUnanswered(botId, {
        params,
        cancelToken: unansweredStatsCancelToken?.token,
      }),
    );

    const data = orderBy(
      ({ value }) => parseFloat(value),
      ['desc'],
      response.data.data,
    ).reduce<Statistics>(
      (accum, stat) => ({
        ...accum,
        [hash(`questions-unanswered-${botId}-${stat.label}`)]: stat,
      }),
      {},
    );

    yield put(setStats(data));
    yield put(fetchQuestionsStatsUnanswered.success(Object.keys(data)));
  } catch (e) {
    fetchQuestionsStatsUnanswered.failure(e);
  }
}

export function* fetchQuestionsStatsPerConvoSaga({
  payload,
}: ReturnType<typeof fetchQuestionsStatsPerConvo.request>) {
  const { botId, ...params } = payload;

  yield call(() => cancelPerConvoStatsRequest('New per convo request'));

  try {
    if (!botId) throw new Error('Bot ID not present');

    const response = yield* call(() =>
      api.getPerConvo(botId, {
        params,
        cancelToken: perConvoStatsCancelToken?.token,
      }),
    );

    const questions = orderBy(
      ({ value }) => parseFloat(value),
      ['desc'],
      response.data.data.questions,
    ).reduce<Statistics>(
      (accum, stat) => ({
        ...accum,
        [hash(`questions-per-convo-questions-${botId}-${stat.label}`)]: stat,
      }),
      {},
    );

    const averageKey = hash(`questions-per-convo-${botId}`);

    yield put(
      setStats({
        ...questions,
        [averageKey]: response.data.data.average,
      }),
    );
    yield put(
      fetchQuestionsStatsPerConvo.success({
        questions: Object.keys(questions),
        average: averageKey,
      }),
    );
  } catch (e) {
    fetchQuestionsStatsPerConvo.failure(e);
  }
}

export function* deleteQuestionsStatsUnansweredSaga({
  payload,
}: ReturnType<typeof deleteQuestionsStatsUnanswered.request>) {
  const { botId, id, from, to } = payload;

  try {
    if (!botId) throw new Error('Bot ID not present');
    if (!id) throw new Error('ID not present');

    const response = yield* call(() => api.deleteUnanswered(botId, id, from, to));

    const data = orderBy(
      ({ value }) => parseFloat(value),
      ['desc'],
      response.data.data,
    ).reduce<Statistics>(
      (accum, stat) => ({
        ...accum,
        [hash(`questions-unanswered-${botId}-${stat.label}`)]: stat,
      }),
      {},
    );

    yield put(setStats(data));
    yield put(deleteQuestionsStatsUnanswered.success(Object.keys(data)));
  } catch (e) {
    deleteQuestionsStatsUnanswered.failure(e);
  }
}

export default [
  takeEvery(fetchQuestionsStatsSummary.request, fetchQuestionsStatsSummarySaga),
  takeEvery(fetchQuestionsStatsAsked.request, fetchQuestionsStatsAskedSaga),
  takeEvery(fetchQuestionsStatsPopular.request, fetchQuestionsStatsPopularSaga),
  takeEvery(
    fetchQuestionsStatsPopularQuestion.request,
    fetchQuestionsStatsPopularQuestionSaga,
  ),
  takeEvery(fetchQuestionsStatsUnanswered.request, fetchQuestionsStatsUnansweredSaga),
  takeEvery(fetchQuestionsStatsPerConvo.request, fetchQuestionsStatsPerConvoSaga),
  takeEvery(deleteQuestionsStatsUnanswered.request, deleteQuestionsStatsUnansweredSaga),
];
