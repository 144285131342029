import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  GeneralSettings,
  LIVE_CHAT_GENERAL_SETTINGS_QUERY_KEY,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getGeneralSettings = (featureId?: string) => {
  return () => {
    return client.get<GeneralSettings>(
      `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/settings/general`,
    );
  };
};

export const useGetGeneralSettings = (featureId?: string) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [LIVE_CHAT_GENERAL_SETTINGS_QUERY_KEY, featureId],
    getGeneralSettings(featureId),
    {
      enabled: featureId ? true : false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get general settings.'), {
          variant: 'error',
        });
      },
    },
  );

  const liveChatGeneralSettings = data?.data;
  return { liveChatGeneralSettings, isLoading };
};
