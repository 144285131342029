import { TranscriptsPage } from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const transcripts: RoutingConfig = [
  {
    title: 'Transcripts',
    description: 'View your previous customer interactions',
    icon: 'LiveChatIcon',
    component: TranscriptsPage,
    path: '/transcripts/:userId?',
    customProps: {
      permissionArea: BotPermissionCode.TRANSCRIPTS_VIEW,
    },
  },
];

export default transcripts;
