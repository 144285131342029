import { withStats } from '@/hoc';
import { WithStatsProps } from '@/hoc/withStats';
import { Statistic } from '@/store/stats';
import {
  fetchQuestionsStatsAsked,
  getQuestionsStatsAsked,
  getQuestionsStatuses,
} from '@/store/questions';
import { ComponentType } from 'react';

export const withQuestionsStatsTotals = <TProps extends WithQuestionsStatsTotalsProps>(
  Component: ComponentType<TProps>,
) =>
  withStats<TProps, typeof fetchQuestionsStatsAsked.request>(
    Component,
    fetchQuestionsStatsAsked.request,
    getQuestionsStatsAsked,
    getQuestionsStatuses,
  );

export interface WithQuestionsStatsTotalsProps extends WithStatsProps {
  stats: Array<Statistic>;
  fetchStats: typeof fetchQuestionsStatsAsked.request;
}
