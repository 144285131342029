import { noop } from '@/utils';
import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';

type Callback = {
  id: number;
  callback: () => void;
  runEveryNthMs?: number;
};

type Callbacks = { [id: string]: Callback };

type TickerContextValue = {
  msPassed: number;
  setCallback: (Callback) => void;
};

const TIMER_MS = 1000;

export const TickerContext = createContext<TickerContextValue>({
  msPassed: 0,
  setCallback: noop,
});

export const TickerProvider = ({ children }) => {
  const [msPassed, setMsPassed] = useState<number>(0);
  const [timerId, setTimerId] = useState<number | null>(null);
  const [callbacks, setCallbacks] = useState<Callbacks>({});
  const isMounted = useRef(false);

  const setCallback = useCallback(({ id, ...rest }: Callback) => {
    setCallbacks(prevState => ({ ...prevState, [id]: rest }));
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (timerId) return;

    const timer = setInterval(() => {
      if (isMounted.current) {
        setMsPassed(prevState => prevState + TIMER_MS);
      }
    }, TIMER_MS);

    if (timer) {
      setTimerId(timer);
    }

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [timerId, callbacks]);

  useEffect(() => {
    Object.values(callbacks).forEach(
      ({ callback, runEveryNthMs = TIMER_MS }: Omit<Callback, 'id'>) => {
        if (runEveryNthMs < TIMER_MS) {
          throw Error(`runEveryNthMs must be equal to or more than ${TIMER_MS}`);
        }
        if (msPassed % runEveryNthMs === 0) {
          callback();
        }
      },
    );
  }, [callbacks, msPassed, timerId]);

  return (
    <TickerContext.Provider value={{ msPassed, setCallback }}>
      {children}
    </TickerContext.Provider>
  );
};
