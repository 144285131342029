import { API_HOST } from '@/config';
import { ApiRoutes } from '@/store/client';

export const getUploadImageContextUrl = (
  botId?: string,
  featureId?: string,
  messageId?: string,
) => {
  if (botId) {
    return `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messageId}/image`;
  }

  if (featureId) {
    return `${API_HOST}/${ApiRoutes.Messages}/botfeatures/${featureId}/messages/${messageId}/image`;
  }

  // fileUpload() usage expects a string type
  return '';
};

export const getUploadAttachmentContextUrl = (
  botId?: string,
  featureId?: string,
  messageId?: string,
) => {
  if (botId) {
    return `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messageId}/attachment`;
  }

  if (featureId) {
    return `${API_HOST}/${ApiRoutes.Messages}/botfeatures/${featureId}/messages/${messageId}/attachment`;
  }

  // fileUpload() usage expects a string type
  return '';
};

export const getCreateMessageContextUrl = (
  messageId: string,
  botId?: string,
  featureId?: string,
) => {
  if (botId) {
    return `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messageId}/bubbles`;
  }

  if (featureId) {
    return `${API_HOST}/${ApiRoutes.Messages}/botfeatures/${featureId}/messages/${messageId}/bubbles`;
  }

  // client.post expects a string type
  return '';
};

export const getRemoveMessageContextUrl = (
  bubbleId: string,
  messageId: string,
  botId?: string | null,
  featureId?: string,
) => {
  if (botId) {
    return `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messageId}/bubbles/${bubbleId}`;
  }

  if (featureId) {
    return `${API_HOST}/${ApiRoutes.Messages}/botfeatures/${featureId}/messages/${messageId}/bubbles/${bubbleId}`;
  }

  // client.delete expects a string type
  return '';
};

export const getReorderMessageContextUrl = (
  messageId: string,
  botId?: string | null,
  featureId?: string,
) => {
  if (botId) {
    return `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messageId}/bubbles/reorder`;
  }

  if (featureId) {
    return `${API_HOST}/${ApiRoutes.Messages}/botfeatures/${featureId}/messages/${messageId}/bubbles/reorder`;
  }

  // client.post expects a string type
  return '';
};

export const getUpdateMessageContextUrl = (
  bubbleId: string,
  messageId: string,
  botId?: string | null,
  featureId?: string,
) => {
  if (botId) {
    return `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messageId}/bubbles/${bubbleId}`;
  }

  if (featureId) {
    return `${API_HOST}/${ApiRoutes.Messages}/botfeatures/${featureId}/messages/${messageId}/bubbles/${bubbleId}`;
  }

  // client.patch expects a string type
  return '';
};
