import { useCallback, useEffect, useState } from 'react';
import {
  CustomFacebookStatic,
  FacebookPageListResponse,
  InstagramAccountListResponse,
} from './types';

declare const FB: CustomFacebookStatic;

export const useFacebookAuth = ({ scopes }: { scopes: Array<string> }) => {
  const [authStatus, setAuthStatus] = useState<fb.StatusResponse>();

  const facebookInstance = window?.FB;

  const handleStatusChange = useCallback(data => {
    setAuthStatus(data);
  }, []);

  const getAccessToken = useCallback(() => {
    if (!FB) return;
    return FB?.getAccessToken?.();
  }, []);

  const logout = useCallback(() => {
    if (!FB) throw new Error('FB is not defined');
    const accessToken = getAccessToken();
    if (!accessToken) return;
    FB.logout((data: fb.StatusResponse) => {
      setAuthStatus(data);
    });
  }, [getAccessToken]);

  const login = useCallback(() => {
    return new Promise<fb.StatusResponse>((resolve, reject) => {
      if (!FB) return reject('FB is not defined');
      FB.login(
        (data: fb.StatusResponse) => {
          setAuthStatus(data);
          resolve(data);
        },
        {
          scope: scopes.join(','),
        },
      );
    });
  }, [scopes]);

  const handleFacebookPromiseCallback = (response, resolve, reject) => {
    if (response?.error) {
      reject(response?.error);
    }
    if (response?.data) {
      resolve(response.data);
    }
  };

  const getUser = useCallback(() => {
    return new Promise<FacebookPageListResponse['data']>((resolve, reject) => {
      if (!FB) return reject('FB is not defined');

      const accessToken = getAccessToken();

      FB.api(
        '/me',
        'get',
        {
          access_token: accessToken,
        },
        (response: FacebookPageListResponse) =>
          handleFacebookPromiseCallback(response, resolve, reject),
      );
    });
  }, [getAccessToken]);

  const getPages = useCallback(() => {
    return new Promise<FacebookPageListResponse['data']>((resolve, reject) => {
      if (!FB) return reject('FB is not defined');

      const accessToken = getAccessToken();

      FB.api(
        '/me/accounts',
        'get',
        {
          access_token: accessToken,
        },
        (response: FacebookPageListResponse) =>
          handleFacebookPromiseCallback(response, resolve, reject),
      );
    });
  }, [getAccessToken]);

  const getInstagramAccounts = useCallback(() => {
    return new Promise<InstagramAccountListResponse['data']>((resolve, reject) => {
      if (!FB) return reject('FB is not defined');

      const accessToken = getAccessToken();

      FB.api(
        '/me/accounts?fields=connected_instagram_account{profile_picture_url}',
        'get',
        {
          access_token: accessToken,
        },
        (response: InstagramAccountListResponse) =>
          handleFacebookPromiseCallback(response, resolve, reject),
      );
    });
  }, [getAccessToken]);

  const getLoginStatus = useCallback(() => {
    return new Promise<fb.StatusResponse>(resolve => {
      FB?.getLoginStatus((data: fb.StatusResponse) => {
        setAuthStatus(data);
        resolve(data);
      });
    });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!window?.FB) return;
      getLoginStatus();
      window?.FB?.Event.subscribe('auth.statusChange', handleStatusChange);
      clearInterval(intervalId);
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [handleStatusChange, facebookInstance, getLoginStatus]);

  return {
    authStatus,
    login,
    logout,
    getAccessToken,
    getInstagramAccounts,
    getPages,
    getUser,
    getLoginStatus,
  };
};
