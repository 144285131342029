import React from 'react';
import { Grid, GridProps } from '@material-ui/core';

const ActionGridItem = ({ children, ...rest }: GridProps) => {
  return (
    <Grid item {...rest}>
      {children}
    </Grid>
  );
};

ActionGridItem.defaultProps = {
  xs: 12,
  sm: 'auto',
};

export default ActionGridItem;
