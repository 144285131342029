import { AxiosError } from 'axios';
import { isForbiddenError } from '@/queries/utils/isForbiddenError';

export const useLogoutUser = () => {
  const handleLogout = () => {
    if (window) {
      window.location.href = '/logout';
    }
  };

  const logoutIfForbidden = (errorResponse: AxiosError) => {
    // log the user on user gets forbidden error (403)
    if (isForbiddenError(errorResponse)) {
      handleLogout();
    }
  };

  return { logoutIfForbidden };
};
