import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  LiveVideoSettings,
  LIVE_VIDEO_SETTINGS_LIST_QUERY_KEY,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getLiveVideoSettingsList = (botId?: string) => {
  return () => {
    return client.get<Array<LiveVideoSettings>>(
      `${API_HOST}/${ApiRoutes.Video}/bots/${botId}/settings`,
    );
  };
};

export const useGetLiveVideoSettingsList = (botId?: string) => {
  const { toast } = useToast();

  const { data, isLoading: isFetchingLiveVideoSettings } = useQuery(
    [LIVE_VIDEO_SETTINGS_LIST_QUERY_KEY, botId],
    getLiveVideoSettingsList(botId),
    {
      enabled: !!botId,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(errorResponse, 'Failed to get live video settings.'),
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const liveVideoSettingsList = data?.data || [];
  return { liveVideoSettingsList, isFetchingLiveVideoSettings };
};
