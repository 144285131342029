import { useContext, useMemo } from 'react';
import { OrgContext } from '@/contexts';
import { Bot, useGetOrganisation } from '@/queries';

export const useOrg = (_botId?: string) => {
  const { orgId = '' } = useContext(OrgContext);
  const { organisation } = useGetOrganisation(orgId);

  const keyedBots = useMemo(() => {
    return organisation?.bots?.reduce((accum, bot) => {
      return { ...accum, [bot.id]: bot };
    }, {}) as { [id: string]: Bot };
  }, [organisation?.bots]);

  return { keyedBots };
};
