import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  RemoteUser,
  REMOTE_USERS_QUERY_KEY,
  RemoteUserRequest,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const USERS_API_URL = `${API_HOST}/${ApiRoutes.Core}/organisations`;

const getRemoteUsers = ({ orgId, botId }: RemoteUserRequest) => {
  return () => {
    return client.get<RemoteUser[]>(
      `${USERS_API_URL}/${orgId}/bots/${botId}/remoteusers`,
    );
  };
};

export const useGetRemoteUsers = ({ orgId, botId }: RemoteUserRequest) => {
  const { toast } = useToast();

  const { data, isLoading: isFetchingRemoteUsers } = useQuery(
    REMOTE_USERS_QUERY_KEY,
    getRemoteUsers({ orgId, botId }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get remote users'), {
          variant: 'error',
        });
      },
    },
  );

  const remoteUsers = data?.data;
  return { remoteUsers, isFetchingRemoteUsers };
};
