import React from 'react';
import { Redirect } from 'react-router-dom';
import { RoutingConfig } from '@/routing';

export const organisation: RoutingConfig = [
  // This must be last
  {
    path: '',
    exact: true,
    render: ({ match }) => <Redirect to={`${match.url}`} />,
  },
];

export default organisation;
