import hash from 'object-hash';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { RootStatus, StateStatus } from '@/store/types';
import { splitStatus } from '@/store/utils';
import { fetchLookupValues, lookupValuesComplete, LOOKUP_VALUES_FETCH } from './actions';
import { LookupValues } from './types';

const statusReducer = createReducer<RootStatus>({})
  .handleAction(
    [fetchLookupValues.request, fetchLookupValues.failure],
    (state, { type }) => {
      const [actionKey, status] = splitStatus(type);

      if (!actionKey) return state;

      return {
        ...state,
        [actionKey]: status,
      };
    },
  )
  .handleAction(lookupValuesComplete, state => ({
    ...state,
    [LOOKUP_VALUES_FETCH]: StateStatus.SUCCESS,
  }));

export const luvReducer = createReducer<null | LookupValues>(null).handleAction(
  fetchLookupValues.success,
  (state, { payload }) => {
    const newValues = payload.values.reduce(
      (accum, value) => ({ ...accum, [hash(value)]: value }),
      {},
    );

    return {
      ...state,
      [payload.type]: {
        ...(state ? state[payload.type] : {}),
        ...newValues,
      },
    } as LookupValues;
  },
);

export default combineReducers({
  data: luvReducer,
  status: statusReducer,
});
