import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg, NOTES_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { CreateNoteRequest, Note } from '../types';

export const useCreateNote = () => {
  const { toast } = useToast();

  const createNoteMutation = useMutation(
    ({ botId, userId, note, channelName }: CreateNoteRequest) => {
      return client.post<Note[]>(
        `${API_HOST}/${ApiRoutes.Notes}/bots/${botId}/channels/${channelName}/users/${userId}/notes`,
        { note },
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to create note'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { botId, userId }) => {
        queryClient.invalidateQueries([NOTES_QUERY_KEY, botId, userId]);
        toast('Note created.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: createNote, isLoading, error } = createNoteMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, createNote };
};
