import { equals } from 'lodash/fp';
import {
  CreateMessageBubbleRequest,
  getUploadAttachmentContextUrl,
  getUploadImageContextUrl,
  MessageTypes,
} from '@/queries';
import { fileUpload } from '@/store/client';

const isImage = equals('IMAGE' as MessageTypes);
const isAttachment = equals('ATTACHMENT' as MessageTypes);

const uploadImage = (
  file: File,
  botId?: string,
  featureId?: string,
  messageId?: string,
) => fileUpload(getUploadImageContextUrl(botId, featureId, messageId), file, 'BUBBLE');

const uploadattachment = (
  file: File,
  botId?: string,
  featureId?: string,
  messageId?: string,
) =>
  fileUpload(getUploadAttachmentContextUrl(botId, featureId, messageId), file, 'BUBBLE');

export const uploadAnswerFile = async (payload: CreateMessageBubbleRequest) => {
  const { botId, featureId, messageId, bubble } = payload;
  const newPayload = payload.bubble;

  if (!botId && !featureId) throw new Error('No Bot ID or Feature ID provided');
  if (!messageId) throw new Error('No Messages ID provided');

  if (isImage(bubble.type) && bubble?.fileData) {
    const response = await uploadImage(bubble.fileData, botId, featureId, messageId);
    if (response?.fileId) newPayload.fileId = response.fileId;
    delete newPayload.file;
  }
  if (isAttachment(bubble.type) && bubble?.fileData) {
    const response = await uploadattachment(bubble.fileData, botId, featureId, messageId);
    if (response?.fileId) newPayload.fileId = response.fileId;
    delete newPayload.file;
  }

  return newPayload;
};
