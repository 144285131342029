import React, { useState, createContext } from 'react';
import { noop } from '@/utils';

type AnnouncementsContextProps = {
  searchFilterQuery: string;
  setSearchFilterQuery: (searchFilterQuery: string) => void;
};

// Announcements context
export const AnnouncementsContext = createContext<AnnouncementsContextProps>({
  searchFilterQuery: '',
  setSearchFilterQuery: noop,
});

// Announcements context provider
export const AnnouncementsContextProvider = ({ children }: { children?: any }) => {
  const [searchFilterQuery, setSearchFilterQuery] = useState<string>('');

  return (
    <AnnouncementsContext.Provider value={{ searchFilterQuery, setSearchFilterQuery }}>
      {children}
    </AnnouncementsContext.Provider>
  );
};
