import { lazy } from 'react';

export const AccessDeniedView = lazy(() => import('./AccessDeniedView'));
export const BillingCardDetailsView = lazy(() => import('./BillingCardDetailsView'));
export const BillingInvoiceDetailsView = lazy(() =>
  import('./BillingInvoiceDetailsView'),
);
export const BillingView = lazy(() => import('./BillingView'));
export const BotBasicsView = lazy(() => import('./BotBasicsView'));
export const BotProfileView = lazy(() => import('./BotProfileView'));
export const BotRemoteUserInviteView = lazy(() => import('./BotRemoteUserInviteView'));
export const BotRemoteUsersView = lazy(() => import('./BotRemoteUsersView'));
export const BotsGridView = lazy(() => import('./BotsGridView'));
export const BulkUploadView = lazy(() => import('./BulkUploadView'));
export const ChooseBotTypeView = lazy(() => import('./ChooseBotTypeView'));
export const CompanyBasicsView = lazy(() => import('./CompanyBasicsView'));
export const CreateBotView = lazy(() => import('./CreateBotView'));
export const CreateOrganisationView = lazy(() => import('./CreateOrganisationView'));
export const DashboardAdminView = lazy(() =>
  import('./DashboardViews/DashboardAdminView/DashboardAdminView'),
);
export const DashboardKnowledgeSearchView = lazy(() =>
  import('./DashboardViews/DashboardKnowledgeSearchView/DashboardKnowledgeSearchView'),
);
export const DashboardLiveChatView = lazy(() =>
  import('./DashboardViews/DashboardLiveChatView'),
);
export const DashboardQuestionsView = lazy(() =>
  import('./DashboardViews/DashboardQuestionsView/DashboardQuestionsView'),
);
export const DashboardUsersView = lazy(() =>
  import('./DashboardViews/DashboardUsersView/DashboardUsersView'),
);
export const FeatureDisabledView = lazy(() => import('./FeatureDisabledView'));
export const FeaturesView = lazy(() => import('./FeaturesView'));
export const KnowledgeSearchDocumentView = lazy(() =>
  import('./KnowledgeSearchDocumentView'),
);
export const KnowledgeSearchView = lazy(() => import('./KnowledgeSearchView'));
export const TranscriptsView = lazy(() => import('./TranscriptsView'));
export const LiveChatConsoleView = lazy(() => import('./LiveChatConsoleView'));
export const OrgLiveChatConsoleView = lazy(() => import('./OrgLiveChatConsoleView'));
export const LiveChatView = lazy(() => import('./LiveChatView'));
export const NotFoundView = lazy(() => import('./NotFoundView'));
export const OnboardingCreateBotView = lazy(() => import('./OnboardingCreateBotView'));
export const PlatformUserInviteView = lazy(() => import('./PlatformUserInviteView'));
export const PlatformUserEditView = lazy(() => import('./PlatformUserEditView'));
export const PlatformUsersView = lazy(() => import('./PlatformUsersView'));
export const QuestionCreateView = lazy(() => import('./QuestionCreateView'));
export const QuestionEditView = lazy(() => import('./QuestionEditView'));
export const QuestionsView = lazy(() => import('./QuestionsView'));
export const SelectSurveyView = lazy(() => import('./SelectSurveyView'));
export const SuperAdminOverlayView = lazy(() => import('./SuperAdminOverlayView'));
export const SurveysView = lazy(() => import('./SurveysView'));
export const UserProfileView = lazy(() => import('./UserProfileView'));
export const LiveChatGeneralSettingsView = lazy(() =>
  import('./LiveChatGeneralSettingsView'),
);
export const LiveChatSupportTimesView = lazy(() => import('./LiveChatSupportTimesView'));
export const LiveChatTakeOfflineView = lazy(() => import('./LiveChatTakeOfflineView'));
export const SurveyBasicsView = lazy(() => import('./SurveyBasicsView'));
export const SurveyScheduleView = lazy(() => import('./SurveyScheduleView'));
export const SurveyQuestionsView = lazy(() => import('./SurveyQuestionsView'));
export const LiveChatBlockUsersView = lazy(() => import('./LiveChatBlockUsersView'));
export const LiveVideoSettingsListView = lazy(() =>
  import('./LiveVideoSettingsListView'),
);
export const LiveVideoSettingsView = lazy(() => import('./LiveVideoSettingsView'));
export const SurveyReportView = lazy(() => import('./SurveyReportView'));
export const AnnouncementsView = lazy(() => import('./AnnouncementsView'));
export const AnnouncementMessageView = lazy(() => import('./AnnouncementMessageView'));
export const AnnouncementScheduleView = lazy(() => import('./AnnouncementScheduleView'));
export const WebSearchView = lazy(() => import('./WebSearchView'));
