import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { API_HOST } from '@/config';
import { useLogoutUser } from '@/hooks/useLogoutUser';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, ORG_QUERY_KEY, Organisation } from '@/queries';
import { OrganisationLocalStorageKeys, setLocalStorageItem } from '@/storage';
import client, {
  ApiRoutes,
  ClientCancelToken,
  createCancelToken,
  wasCancelled,
} from '@/store/client';

let orgCancelToken: ClientCancelToken | undefined = undefined;

const cancelOrgRequest = (message?: string) => {
  orgCancelToken && orgCancelToken.cancel(message);
  orgCancelToken = createCancelToken();
};

const getOrganisation = (orgId?: string | null) => {
  cancelOrgRequest('New org request');

  return () => {
    return client.get<Organisation>(
      `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}`,
      {
        cancelToken: orgCancelToken?.token,
        params: {
          relations: 'bots.permissionGroups',
        },
      },
    );
  };
};

export const useGetOrganisation = (orgId?: string | null) => {
  const { toast } = useToast();
  const { logoutIfForbidden } = useLogoutUser();

  const { data, isLoading, isError, isSuccess } = useQuery(
    [ORG_QUERY_KEY, orgId],
    getOrganisation(orgId),
    {
      enabled: !!orgId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        if (wasCancelled(errorResponse)) return;
        toast(extractServerErrorMsg(errorResponse, 'Failed to get organisation'), {
          variant: 'error',
        });

        logoutIfForbidden(errorResponse);
      },
      onSuccess: ({ data: org }) => {
        setLocalStorageItem(OrganisationLocalStorageKeys.orgId, org?.id);
      },
    },
  );

  const organisation = data?.data;
  return { organisation, isLoading, isError, isSuccess };
};
