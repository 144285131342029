import { call, put, takeLatest } from 'typed-redux-saga';
import { format, subMonths } from 'date-fns';
import { invalidateTranscriptsQuery } from '@/queries';
import { enqueueToast } from '@/store/toast';
import {
  LIVE_CHAT_GET_MESSAGES_INITIATED,
  getMessages,
  getEnded,
  websocketError,
  updateUser,
  getUser,
  getUsers,
  getConversationTags,
  updateConversationTags,
} from './actions';
import api from './api';

export function* getMessagesSaga(
  action: ReturnType<typeof getMessages.request>,
): Generator {
  const {
    payload: { botId, userId, locale },
  } = action;

  try {
    const response = yield* call(() => api.getMessages(botId, userId, locale));
    yield put(getMessages.success({ data: response.data, botId, userId }));
  } catch (e) {
    yield put(getMessages.failure(e));
  }
}

export function* getEndedSaga(action: ReturnType<typeof getEnded.request>): Generator {
  const {
    payload: { botId, agentId },
  } = action;
  try {
    const response = yield* call(() =>
      api.getEnded(botId, agentId, {
        params: {
          from: format(subMonths(new Date(), 6), 'dd-LLL-yyyy'),
          to: format(new Date(), 'dd-LLL-yyyy'),
        },
      }),
    );
    yield put(getEnded.success({ botId, data: response.data }));
  } catch (e) {
    yield put(getEnded.failure(e));
  }
}

export function* websocketErrorSaga(action) {
  const {
    meta: { message },
  } = action;

  yield put(
    enqueueToast({
      message: `Connection Error${message ? `: ${message}` : ''}`,
      options: { variant: 'error' },
    }),
  );
}

export function* updateUserSaga(
  action: ReturnType<typeof updateUser.request>,
): Generator {
  const {
    payload: { featureId, userId, data, botId },
  } = action;

  if (!userId) throw new Error('No user id provided');
  if (!featureId) throw new Error('No feature id provided');

  try {
    const response = yield* call(() => api.updateUser(featureId, userId, data));
    yield put(updateUser.success({ data: response.data, featureId }));
    yield put(
      enqueueToast({
        message: 'Saved',
        options: { variant: 'success' },
      }),
    );
    if (botId) {
      // update transcripts query cache
      invalidateTranscriptsQuery(botId);
    }
  } catch (e) {
    yield put(updateUser.failure(e));
  }
}

export function* getUserSaga(action: ReturnType<typeof getUser.request>): Generator {
  const {
    payload: { featureId, userId },
  } = action;

  if (!userId) throw new Error('No user id provided');
  if (!featureId) throw new Error('No feature id provided');

  try {
    const response = yield* call(() => api.getUser(featureId, userId));
    yield put(getUser.success({ data: response.data, featureId }));
  } catch (e) {
    yield put(getUser.failure(e));
  }
}

export function* getUsersSaga(action: ReturnType<typeof getUsers.request>): Generator {
  const {
    payload: { featureId },
  } = action;

  if (!featureId) throw new Error('No feature id provided');

  try {
    const response = yield* call(() => api.getUsers(featureId));
    yield put(getUsers.success({ data: response.data, featureId }));
  } catch (e) {
    yield put(getUsers.failure(e));
  }
}

export function* getConversationTagsSaga(
  action: ReturnType<typeof getConversationTags.request>,
): Generator {
  const {
    payload: { featureId, userId },
  } = action;

  try {
    const response = yield* call(() => api.getConversationTags(featureId, userId));
    yield put(getConversationTags.success({ data: response.data, featureId, userId }));
  } catch (e) {
    yield put(getConversationTags.failure(e));
  }
}

export function* updateConversationTagsSaga(
  action: ReturnType<typeof updateConversationTags.request>,
): Generator {
  const {
    payload: { botId, featureId, userId, data },
  } = action;

  try {
    const response = yield* call(() =>
      api.updateConversationTags(featureId, userId, data),
    );
    yield put(updateConversationTags.success({ data: response.data, featureId, userId }));
    yield put(
      enqueueToast({
        message: 'Saved',
        options: { variant: 'success' },
      }),
    );
    yield* call(() => {
      if (botId) {
        return getMessagesSaga({
          type: LIVE_CHAT_GET_MESSAGES_INITIATED,
          payload: { botId, userId, locale: 'en' },
        });
      }
    });
  } catch (e) {
    yield put(updateConversationTags.failure(e));
  }
}

export default [
  takeLatest(getMessages.request, getMessagesSaga),
  takeLatest(getEnded.request, getEndedSaga),
  takeLatest(updateUser.request, updateUserSaga),
  takeLatest(getUser.request, getUserSaga),
  takeLatest(getUsers.request, getUsersSaga),
  takeLatest(getConversationTags.request, getConversationTagsSaga),
  takeLatest(updateConversationTags.request, updateConversationTagsSaga),
  takeLatest(websocketError, websocketErrorSaga),
];
