import { ComponentProps, ComponentType, FunctionComponent } from 'react';
import createConnect, { WithFormProps } from './withForm.state';
import { WithFormAwareProps } from './types';

export const withForm = <TProps extends WithFormAwareProps>(
  Component: ComponentType<TProps>,
) => {
  const connect = createConnect<TProps>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `WithForm(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent as FunctionComponent<
    Omit<ComponentProps<typeof Component>, keyof WithFormProps> & WithFormAwareProps
  >;
};
