import { useState, useCallback } from 'react';

export const useSaveQueue = <TType extends {} | string, TKey = string | number>() => {
  const [saveQueue, setSaveQueue] = useState(new Map<TKey, TType>());

  const addToSaveQueue = useCallback(
    (key: TKey, value: TType) => {
      saveQueue.set(key, value);
      setSaveQueue(saveQueue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveQueue],
  );

  const removeFromSaveQueue = useCallback(
    (key: TKey) => {
      saveQueue.delete(key);
      setSaveQueue(saveQueue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saveQueue],
  );

  return {
    addToSaveQueue,
    removeFromSaveQueue,
    saveQueue,
  };
};
