import { noop } from '@/utils';
import React, { useState, createContext } from 'react';

type NotesContextValueTypes = {
  botId?: string | null;
  userId?: string | null;
  featureId?: string | null;
  userChannel?: string;
  selectedNoteId?: string;
  selectedConversationId?: string;
  isEditingConversation?: boolean;
  isEditingNote?: boolean;
  hasEditedNote?: boolean;
  isCreatingNewNote?: boolean;
  hasSwitchedToConversationTab?: boolean;
};

// Props
type NotesContextProps = {
  notesContextValues: NotesContextValueTypes;
  updateNotesContextValues: (values: NotesContextValueTypes) => void;
};

const defaultContextValues = {
  botId: '',
  userId: '',
  featureId: '',
  userChannel: '',
  selectedNoteId: '',
  selectedConversationId: '',
  isEditingConversation: false,
  isEditingNote: false,
  hasEditedNote: false,
  isCreatingNewNote: false,
  hasSwitchedToConversationTab: false,
};

// Notes context
export const NotesContext = createContext<NotesContextProps>({
  notesContextValues: {},
  updateNotesContextValues: noop,
});

// Notes context provider
export const NotesContextProvider = ({ children }: { children?: any }) => {
  const [notesContextValues, setNotesValues] = useState<NotesContextValueTypes>(
    defaultContextValues,
  );

  const updateNotesContextValues = (newValues: NotesContextValueTypes) => {
    setNotesValues({ ...notesContextValues, ...newValues });
  };

  return (
    <NotesContext.Provider
      value={{
        notesContextValues,
        updateNotesContextValues,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};
