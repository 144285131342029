import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import BaseSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ThemeContext } from 'styled-components';
import { FormHelperText } from '@material-ui/core';
import { FormLabel } from '@/components/formElements/FormLabel';
import Icon from '@/components/Icon';
import {
  baseColorStyles,
  CreatableMultiValueContainer,
  CreatableMultiValueLabel,
  DownIcon,
} from './Select.styled';
import { SelectProps } from './types';

const Select: FunctionComponent<SelectProps> = ({
  creatable,
  error,
  formLabelProps,
  helperText,
  hideIcon,
  label,
  ...props
}) => {
  const theme = useContext(ThemeContext);

  const IndicatorSeparator = useCallback(() => null, []);
  const DropdownIndicator = useCallback(
    () =>
      !hideIcon ? (
        <DownIcon>
          <Icon type="TriangleIcon" />
        </DownIcon>
      ) : null,
    [hideIcon],
  );

  const MultiValueLabel = useCallback(
    props => <CreatableMultiValueLabel>{props.children}</CreatableMultiValueLabel>,
    [],
  );

  const MultiValueContainer = useCallback(
    props => (
      <CreatableMultiValueContainer>{props.children}</CreatableMultiValueContainer>
    ),
    [],
  );

  const components = useMemo(
    () => ({
      IndicatorSeparator,
      DropdownIndicator,
      MultiValueContainer,
      MultiValueLabel,
    }),
    [IndicatorSeparator, DropdownIndicator, MultiValueContainer, MultiValueLabel],
  );

  const colorStyles = useMemo(() => baseColorStyles(theme, error), [theme, error]);

  return (
    <>
      {label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
      {creatable ? (
        <CreatableSelect components={components} styles={colorStyles} {...props} />
      ) : (
        <BaseSelect components={components} styles={colorStyles} {...props} />
      )}
      {helperText && (
        <FormHelperText error={error} variant="outlined">
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default Select;
