import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import { ApiRoutes, fileUpload } from '@/store/client';
import { DOCUMENTS_QUERY_KEY } from '../constants';
import { UploadDocumentRequest } from '../types';

export const useUploadDocument = () => {
  const { toast } = useToast();

  const uploadDocumentMutation = useMutation(
    ({ featureId, file }: UploadDocumentRequest) => {
      return fileUpload(
        `${API_HOST}/${ApiRoutes.Search}/${featureId}/documents/upload`,
        file,
        'BUBBLE',
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to upload document'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([DOCUMENTS_QUERY_KEY, featureId]);
        toast('Document uploaded successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: uploadDocument, isLoading, error } = uploadDocumentMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, uploadDocument, serverErrors };
};
