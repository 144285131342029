import {
  connect,
  sendMessage,
  sendTyping,
  disconnect,
  acceptLiveAgentRequest,
  agentEndChat,
  message,
  typingStart,
  typingEnd,
  transferAgent,
} from './actions';
import createMiddleware from './createMiddleware';

export * from './actionTypes';

export {
  connect,
  createMiddleware as default,
  disconnect,
  sendMessage,
  sendTyping,
  acceptLiveAgentRequest,
  agentEndChat,
  message,
  typingStart,
  typingEnd,
  transferAgent,
};
