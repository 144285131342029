import styled, { createGlobalStyle } from 'styled-components';

export const GlobalErrorBoundaryStyles = createGlobalStyle`
  font-family: arial;

  html, body {
    height: 100%;
  }

  #root {
    height: 100%
  }
`;

export const StyledErrorBoundary = styled.div`
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

export const ErrorBoundaryTitle = styled.h1``;

export const ErrorBoundaryPre = styled.pre`
  max-height: 300px;
  overflow: auto;
`;
