export {
  AddBoxRounded,
  AlarmOutlined,
  ArrowForward,
  Assistant,
  Check,
  CheckCircle,
  ChevronRight,
  Close,
  CloudUploadOutlined,
  Code,
  ControlPoint,
  CreditCardOutlined,
  Done,
  DescriptionOutlined,
  Email,
  Facebook,
  FilterList,
  FormatListBulleted,
  GetApp,
  HelpOutline,
  Instagram,
  Language,
  Launch,
  ListAltOutlined,
  LocalOfferOutlined,
  Menu,
  MenuBookOutlined,
  MoreHoriz,
  MoreVert,
  Person,
  Publish,
  ReportProblem,
  Search,
  Sms,
  SmsOutlined,
  StarBorderOutlined,
  Twitter,
  TrendingUp,
  UpdateOutlined,
  WhatsApp,
} from '@material-ui/icons';

export { default as AddIcon } from '@/assets/icons/icon-add.svg';
export { default as AmexIcon } from '@/assets/icons/icon-amex.svg';
export { default as AnswerIcon } from '@/assets/icons/icon-answer.svg';
export { default as ArchiveIcon } from '@/assets/icons/icon-archive.svg';
export { default as AttachmentIcon } from '@/assets/icons/icon-attachment.svg';
export { default as BarGraphIcon } from '@/assets/icons/icon-bargraph.svg';
export { default as ButtonIcon } from '@/assets/icons/icon-button.svg';
export { default as ChannelsIcon } from '@/assets/icons/icon-channels.svg';
export { default as CheckIcon } from '@/assets/icons/icon-check.svg';
export { default as ChevronIcon } from '@/assets/icons/icon-chevron.svg';
export { default as CloseIcon } from '@/assets/icons/icon-close.svg';
export { default as CompanyBasicsIcon } from '@/assets/icons/icon-company-basics.svg';
export { default as ConfidenceIcon } from '@/assets/icons/icon-confidence.svg';
export { default as DashboardIcon } from '@/assets/icons/icon-dashboard.svg';
export { default as DeleteIcon } from '@/assets/icons/icon-delete.svg';
export { default as DiscoverIcon } from '@/assets/icons/icon-discover.svg';
export { default as DocIcon } from '@/assets/icons/icon-doc.svg';
export { default as DownloadIcon } from '@/assets/icons/icon-download.svg';
export { default as DuplicateIcon } from '@/assets/icons/icon-duplicate.svg';
export { default as EditIcon } from '@/assets/icons/icon-edit.svg';
export { default as EmojiIcon } from '@/assets/icons/icon-emoji.svg';
export { default as EmptyStartIcon } from '@/assets/icons/icon-empty-star.svg';
export { default as ExcelIcon } from '@/assets/icons/icon-excel.svg';
export { default as FeaturedIcon } from '@/assets/icons/icon-featured.svg';
export { default as FutrIcon } from '@/assets/icons/icon-futr.svg';
export { default as FutrIconSad } from '@/assets/icons/icon-futr-sad.svg';
export { default as FutrLogo } from '@/assets/icons/logo-futr.svg';
export { default as GifIcon } from '@/assets/icons/icon-gif.svg';
export { default as ImageIcon } from '@/assets/icons/icon-image.svg';
export { default as JcbIcon } from '@/assets/icons/icon-jcb.svg';
export { default as KnowledgeSearchIcon } from '@/assets/icons/icon-knowledge-search.svg';
export { default as LinkedinColouredIcon } from '@/assets/icons/icon-linkedin-coloured.svg';
export { default as LinkIcon } from '@/assets/icons/icon-link.svg';
export { default as LiveChatIcon } from '@/assets/icons/icon-livechat.svg';
export { default as MasterCardIcon } from '@/assets/icons/icon-mastercard.svg';
export { default as MicrosoftColouredIcon } from '@/assets/icons/icon-microsoft-coloured.svg';
export { default as MoreIcon } from '@/assets/icons/icon-more.svg';
export { default as PdfIcon } from '@/assets/icons/icon-pdf.svg';
export { default as QAndAsIcon } from '@/assets/icons/icon-q-as.svg';
export { default as QALibraryIcon } from '@/assets/icons/icon-qa-library.svg';
export { default as QuestionIcon } from '@/assets/icons/icon-question.svg';
export { default as QuestionBlankIcon } from '@/assets/icons/icon-question-blank.svg';
export { default as ReorderIcon } from '@/assets/icons/icon-reorder.svg';
export { default as SearchIcon } from '@/assets/icons/icon-search.svg';
export { default as SendIcon } from '@/assets/icons/icon-send.svg';
export { default as SettingsIcon } from '@/assets/icons/icon-settings.svg';
export { default as SliderIcon } from '@/assets/icons/icon-slider.svg';
export { default as StarIcon } from '@/assets/icons/icon-star.svg';
export { default as SurveysIcon } from '@/assets/icons/icon-surveys.svg';
export { default as TextIcon } from '@/assets/icons/icon-text.svg';
export { default as TriangleIcon } from '@/assets/icons/icon-triangle.svg';
export { default as UploadIcon } from '@/assets/icons/icon-upload.svg';
export { default as UserIcon } from '@/assets/icons/icon-user.svg';
export { default as UsersFrontIcon } from '@/assets/icons/icon-users-front.svg';
export { default as UsersIcon } from '@/assets/icons/icon-users.svg';
export { default as VideoIcon } from '@/assets/icons/icon-video.svg';
export { default as ViewActive } from '@/assets/icons/icon-view-active.svg';
export { default as VisaIcon } from '@/assets/icons/icon-visa.svg';
