import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import { ArchiveRequest, Announcement } from '../types';
import { ANNOUNCEMENTS_QUERY_KEY, ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import { AxiosError } from 'axios';

export const useArchiveAnnouncement = () => {
  const { toast } = useToast();

  const archiveAnnouncementMutation = useMutation(
    ({ featureId, announcementId }: ArchiveRequest) => {
      return client.post<Announcement>(
        `${API_HOST}/${ApiRoutes.Announcements}/${featureId}/announcements/${announcementId}/archive`,
        {},
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to archived announcement.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([ANNOUNCEMENTS_QUERY_KEY, featureId]);
        toast('Announcement archived successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: archiveAnnouncement,
    isLoading,
    error,
  } = archiveAnnouncementMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, archiveAnnouncement };
};
