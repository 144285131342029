import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { getOnboardingStages } from './actions';
import { OnboardingDetailsItem } from './types';
import { splitStatus } from '@/store/utils';
import { RootStatus } from '@/store/types';

const statusReducer = createReducer<RootStatus>({}).handleAction(
  [getOnboardingStages.request, getOnboardingStages.failure, getOnboardingStages.success],
  (state, { type }) => {
    const [actionKey, status] = splitStatus(type);

    if (!actionKey) return state;

    return {
      ...state,
      [actionKey]: status,
    };
  },
);

export const onboardingReducer = createReducer<null | Record<
  string,
  OnboardingDetailsItem
>>(null).handleAction(getOnboardingStages.success, (state, { payload }) =>
  payload.reduce(
    (accum, item) => (item?.type ? { ...accum, [item.type]: item } : accum),
    state,
  ),
);

export default combineReducers({
  data: onboardingReducer,
  status: statusReducer,
});
