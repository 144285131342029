import styled from 'styled-components';
import Select from '@/components/formElements/Select';
import { MoreHorizOutlined, CloseOutlined } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledSelect = styled(Select)`
  display: block;
  width: 100%;
  text-align: left !important;
`;

export const MoreIcon = styled(MoreHorizOutlined)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.grey['500']};
`;

export const CloseIcon = styled(CloseOutlined)`
  position: fixed;
  top: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
`;

export const SwitcherOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: ${({ theme }) => theme.palette.background.paper};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const SwitcherSelectWrapper = styled.div`
  display: block;
  max-width: 600px;
`;

export const SwitcherOverlayHeader = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))(Typography);
