import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg, SURVEYS_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import { SurveyRequest, Survey } from '../types';
import { AxiosError } from 'axios';

export const useArchiveSurvey = () => {
  const { toast } = useToast();

  const archiveSurveyMutation = useMutation(
    ({ featureId, surveyId }: SurveyRequest) => {
      return client.post<Survey>(
        `${API_HOST}/${ApiRoutes.Surveys}/${featureId}/surveys/${surveyId}/archive`,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to archive survey.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([SURVEYS_QUERY_KEY, featureId]);
        toast('Survey archived successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: archiveSurvey, isLoading, error } = archiveSurveyMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, archiveSurvey };
};
