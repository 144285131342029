import { ComponentProps, ComponentType, FunctionComponent } from 'react';
import connect, { WithOnboardingProps } from './withOnboarding.state';

export const withOnboarding = <TProps extends {}>(Component: ComponentType<TProps>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `WithOnboarding(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent as FunctionComponent<
    Omit<ComponentProps<typeof Component>, keyof WithOnboardingProps>
  >;
};
