import { lookup } from 'mime-types';

export const NOTES_ANSWER_MAX_LENGTH = 10000;
export const MAX_FEATURED_QUESTION_COUNT = 5;
export const ANSWER_MAX_LENGTH = 500;
export const ROWS_PER_PAGE = 20;

export const ID_TOKEN_KEY = 'id_token';

export const MIME_TYPES = Object.freeze({
  DOC: lookup('.doc'),
  DOCX: lookup('.docx'),
  XLSX: lookup('.xlsx'),
  XLS: lookup('.xls'),
  PDF: lookup('.pdf'),
  JPG: lookup('.jpg'),
  JPEG: lookup('.jpeg'),
  PNG: lookup('.png'),
});

// Live Chat Console Agent status options
export const agentStatusOptions = [
  {
    label: 'No Filter',
    value: '',
  },
  {
    label: 'Available',
    value: 'AVAILABLE',
  },
  {
    label: 'In a call',
    value: 'inCall',
  },
  {
    label: 'Offline',
    value: 'offline',
  },
];
