import React, { FunctionComponent } from 'react';
import { ResetPassword } from '@/components/onboarding/ResetPassword/ResetPassword';
import { useQueryParams } from '@/hooks/useQueryParams';

export const ResetPasswordScreen: FunctionComponent = () => {
  const query = useQueryParams();
  const token = query.get('token');
  return <ResetPassword token={token} />;
};

export default ResetPasswordScreen;
