import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  SurveyTemplate,
  SURVEY_TEMPLATES_QUERY_KEY,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getSurveyTemplates = (featureId?: string) => {
  return () => {
    return client.get<Array<SurveyTemplate>>(
      `${API_HOST}/${ApiRoutes.Surveys}/${featureId}/templates`,
      {},
    );
  };
};

export const useGetSurveyTemplates = (featureId?: string) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [SURVEY_TEMPLATES_QUERY_KEY, featureId],
    getSurveyTemplates(featureId),
    {
      enabled: featureId ? true : false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        toast(extractServerErrorMsg(error, 'Failed to get survey templates.'), {
          variant: 'error',
        });
      },
    },
  );

  const surveyTemplates = data?.data || [];
  return { surveyTemplates, isLoading };
};
