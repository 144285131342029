import { DefaultTheme } from 'styled-components';
import { createMuiTheme } from '@material-ui/core/styles';
import defaultTheme from './defaultTheme';
import { CustomTheme } from './types';

export * from './types';
export * from './utils';

/**
 * Create a new theme given options, or default to defaultTheme
 *
 * @param themeProps
 */
export const createTheme = (themeProps?: CustomTheme): DefaultTheme =>
  createMuiTheme({ ...defaultTheme, ...themeProps }) as DefaultTheme;

export default { createTheme };
