import { DashboardPage } from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const dashboard: RoutingConfig = [
  {
    title: 'Dashboard',
    icon: 'DashboardIcon',
    path: '/dashboard',
    component: DashboardPage,
    customProps: { permissionArea: BotPermissionCode.DASHBOARDS_VIEW_ALL },
  },
];

export default dashboard;
