import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { BILLING_DETAILS_QUERY_KEY } from '../constants';
import { Billing } from '../types';

const getBilling = (orgId?: string | null) => {
  return () => {
    return client.get<Billing>(
      `${API_HOST}/${ApiRoutes.Billing}/organisations/${orgId}/billing`,
    );
  };
};

export const useGetBilling = (orgId?: string | null) => {
  const { toast } = useToast();

  const { data, isLoading, isError } = useQuery(
    [BILLING_DETAILS_QUERY_KEY, orgId],
    getBilling(orgId),
    {
      // disable the query from automatically
      // running when 'enabled' is false
      enabled: !!orgId,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get billing details.'), {
          variant: 'error',
        });
      },
    },
  );

  const billingDetails = data?.data;
  return { billingDetails, isLoading, isError };
};
