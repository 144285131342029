import { filter, propEq } from 'lodash/fp';
import { Announcement, AnnouncementStatusCode, AnnouncementStatusMap } from './types';

export const statusMap: AnnouncementStatusMap = Object.freeze({
  all: [...Object.values(AnnouncementStatusCode)],
  upcoming: [AnnouncementStatusCode.Upcoming],
  active: [AnnouncementStatusCode.Active],
  draft: [AnnouncementStatusCode.Draft],
  archived: [AnnouncementStatusCode.Archived],
});

export const filterByUpcoming = filter<Announcement>(
  propEq<AnnouncementStatusCode>('status.code', AnnouncementStatusCode.Upcoming),
);

export const filterByActive = filter<Announcement>(
  propEq<AnnouncementStatusCode>('status.code', AnnouncementStatusCode.Active),
);

export const filterByDraft = filter<Announcement>(
  propEq<AnnouncementStatusCode>('status.code', AnnouncementStatusCode.Draft),
);

export const filterByArchived = filter<Announcement>(
  propEq<AnnouncementStatusCode>('status.code', AnnouncementStatusCode.Archived),
);

export const mapSentToActive = (announcement?: Announcement) => {
  return {
    ...announcement,
    ...(announcement?.status?.code === AnnouncementStatusCode.Sent
      ? { status: { code: AnnouncementStatusCode.Active, description: 'Active' } }
      : {}),
  };
};
