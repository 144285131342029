import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDashboardProxy } from '@/proxies';
import { useStatic } from './useStatic';

export const useDashboards = () => {
  const { queryStep, resetQueryStep } = useDashboardProxy();
  const { pathname } = useLocation();
  const staticPath = useStatic(pathname);

  const canRender = staticPath === pathname;

  useEffect(() => {
    if (!canRender && queryStep > 0) {
      resetQueryStep();
    }
  }, [staticPath, pathname, queryStep]);

  return { canRender, queryStep };
};
