import { connect } from 'react-redux';
import { AppState } from '@/store';
import {
  getMessagesData,
  getBubbles,
  createBubble,
  updateBubble,
  deleteBubble,
  getMessagesStatuses,
  saveBubbles,
  reorderBubbles,
} from '@/store/messages';

const mapStateToProps = (state: AppState) => ({
  messages: getMessagesData(state),
  messagesStatuses: getMessagesStatuses(state),
});

const mapDispatchToProps = {
  getBubbles: getBubbles.request,
  createBubble: createBubble.request,
  updateBubble: updateBubble.request,
  deleteBubble: deleteBubble.request,
  saveBubbles: saveBubbles.request,
  reorderBubbles: reorderBubbles.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type WithMessagesProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
