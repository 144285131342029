import React, { FunctionComponent, useContext, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import { compose } from 'lodash/fp';
import * as Sentry from '@sentry/browser';
import { ReactQueryDevtools } from 'react-query/devtools';

import AsyncComponent from '@/components/AsyncComponent';
import BrowserNotifications from '@/components/BrowserNotifications';
import { Notifier } from '@/components/ToastProvider';
import { SENTRY_DSN, NODE_ENV } from '@/config';

import { GlobalStyle } from './App.styled';
import { Loader } from './components/core/Loader';
import ViewContainer from './components/core/ViewContainer';
import { OrgContext } from './contexts';
import { withAuth, WithAuthProps, withErrorBoundary } from './hoc';
import { useGetOrganisation } from './queries';
import { generateRoutes, root } from './routing';
import { isFailed, isInitiated, isSuccess } from './store';
import { AUTH_GET_USER_PROFILE } from './store/auth';
import { NotFoundView } from './views';
import SuperAdminOverlayView from './views/SuperAdminOverlayView';

if (NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: NODE_ENV,
  });
  Sentry.configureScope(scope => {
    scope.setLevel(Sentry.Severity.Debug);
  });
}

const routes = generateRoutes(root);

export const App: FunctionComponent<WithAuthProps> = ({
  authStatuses,
  populateUser,
  profile,
  isSuperAdmin,
}) => {
  const { orgId } = useContext(OrgContext);
  const {
    organisation: org,
    isLoading: isFetchingOrg,
    isError: isGetOrgError,
    isSuccess: isGetOrgSuccess,
  } = useGetOrganisation(orgId);

  const loadingText = useMemo(() => {
    switch (true) {
      case !profile &&
        isInitiated(authStatuses[AUTH_GET_USER_PROFILE]) &&
        !isSuccess(authStatuses[AUTH_GET_USER_PROFILE]):
        return 'Loading your profile...';
      case !org && isFetchingOrg && !isSuccess(authStatuses[AUTH_GET_USER_PROFILE]):
        return 'Loading your organisation...';
      default:
        return 'Loading...';
    }
  }, [authStatuses, profile, org, isFetchingOrg]);

  const pathName = document?.location?.pathname;

  useEffect(() => {
    populateUser();
  }, [populateUser]);

  const isInitialising =
    (!profile && isInitiated(authStatuses[AUTH_GET_USER_PROFILE])) || isFetchingOrg;

  const isFailure = isFailed(authStatuses[AUTH_GET_USER_PROFILE]) || isGetOrgError;

  const isSuccessful = isGetOrgSuccess && isSuccess(authStatuses[AUTH_GET_USER_PROFILE]);

  const incompleteOnboarding =
    isSuccessful && !isSuperAdmin && (org?.bots?.length === 0 || !orgId);

  // @INFO - ReactQueryDevtools will not be bundled with the production build.
  // See: https://react-query.tanstack.com/devtools#import-the-devtools

  // However, we can use SHOW_REACT_QUERY_DEV_TOOLS to also
  // manage the bundle on other environments such as Dev, UAT etc..
  const renderReactQueryDevTools = () => {
    if (process.env.SHOW_REACT_QUERY_DEV_TOOLS === 'true') {
      return <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />;
    }
    return null;
  };

  return (
    <>
      <AsyncComponent>
        <GlobalStyle />
        <Notifier />
        <BrowserNotifications />
        {isFailure && pathName !== '/login' && (
          <ViewContainer fillBackground>
            <NotFoundView>
              {isFailed(authStatuses[AUTH_GET_USER_PROFILE]) && (
                <p>We could not load your profile</p>
              )}
              {isGetOrgError && <p>We could not load your organisation</p>}
              <p>Please refresh the page and try again</p>
            </NotFoundView>
          </ViewContainer>
        )}

        {(!isFailure || pathName === '/login') && (
          <Loader active={isInitialising} text={loadingText} gracePeriod={0}>
            <Router>
              <SuperAdminOverlayView>
                <Switch>
                  {routes}
                  <Redirect to="/not-found" />
                </Switch>

                {incompleteOnboarding &&
                  pathName.search('/onboarding') === -1 &&
                  pathName.search('/signin/callback') === -1 && <Redirect to="/signup" />}
              </SuperAdminOverlayView>
            </Router>
          </Loader>
        )}
      </AsyncComponent>
      {renderReactQueryDevTools()}
    </>
  );
};

const ComposedApp = compose(withAuth, withErrorBoundary)(App);

export default ComposedApp;
