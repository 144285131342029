import { createAsyncAction } from 'typesafe-actions';
import { OnboardingDetails } from './types';

export const ONBOARDING_GET_STAGES = 'ONBOARDING_GET_STAGES';
export const ONBOARDING_GET_STAGES_INITIATED = 'ONBOARDING_GET_STAGES_INITIATED';
export const ONBOARDING_GET_STAGES_SUCCESS = 'ONBOARDING_GET_STAGES_SUCCESS';
export const ONBOARDING_GET_STAGES_FAILED = 'ONBOARDING_GET_STAGES_FAILED';

export const ONBOARDING_POST_FORM = 'ONBOARDING_POST_FORM';
export const ONBOARDING_POST_FORM_INITIATED = 'ONBOARDING_POST_FORM_INITIATED';
export const ONBOARDING_POST_FORM_SUCCESS = 'ONBOARDING_POST_FORM_SUCCESS';
export const ONBOARDING_POST_FORM_FAILED = 'ONBOARDING_POST_FORM_FAILED';

export const getOnboardingStages = createAsyncAction(
  ONBOARDING_GET_STAGES_INITIATED,
  ONBOARDING_GET_STAGES_SUCCESS,
  ONBOARDING_GET_STAGES_FAILED,
)<void, OnboardingDetails, void>();
