import { proxy, useSnapshot } from 'valtio';

type DashboardState = {
  queryStep: number;
  resetQueryStep: () => void;
  incrementQueryStep: () => void;
};

const state = proxy<DashboardState>({
  queryStep: 0,
  resetQueryStep() {
    state.queryStep = 0;
  },
  incrementQueryStep() {
    ++state.queryStep;
  },
});

export const useDashboardProxy = () => {
  return useSnapshot(state);
};
