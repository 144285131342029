import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  NOTES_QUERY_KEY,
  UpdateNoteRequest,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useUpdateNote = () => {
  const { toast } = useToast();

  const updateNoteMutation = useMutation(
    ({ botId, userId, note, noteId, channelName }: UpdateNoteRequest) => {
      return client.patch<any>(
        `${API_HOST}/${ApiRoutes.Notes}/bots/${botId}/channels/${channelName}/users/${userId}/notes/${noteId}`,
        { note },
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update note'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { botId, userId }) => {
        queryClient.invalidateQueries([NOTES_QUERY_KEY, botId, userId]);
        toast('Note updated.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: updateNote, isLoading, error } = updateNoteMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateNote };
};
