// eslint-disable-next-line
import { Overrides } from '@material-ui/core/styles/overrides';
import { SimplePaletteColorOptions } from '@material-ui/core';
import { DeepPartial } from 'redux';
import { hexToRgba } from '@/styles/theme/utils';
import { size } from './size';
import typography from './typography';
import palette from './palette';

export default {
  MuiAvatar: {
    root: {
      '&$colorDefault': {
        backgroundColor: '#e0e0e0',
      },
    },
  },
  /** Form */
  MuiFormControl: {
    fullWidth: {
      width: '100% !important',
    },
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: '0.25rem',
      fontSize: '0.9375rem',
      color: palette?.grey?.['900'],
      padding: 0,
      '&$focused $notchedOutline': {
        borderWidth: '0.0625rem',
        borderColor: '#3d82ba',
        boxShadow: '0 0 0',
      },
    },
    input: {
      padding: '0.6937rem 1.125rem',
    },
  },
  MuiFilledInput: {
    root: {
      borderRadius: '0.25rem',
      borderColor: '#3d82ba',
      boxShadow: '0 0 0 0.1875rem #d8e6f1',
      fontSize: '0.9375rem',
      color: '#484848',
      padding: 0,
    },
    input: {
      padding: '0.6937rem 1.125rem',
    },
  },
  MuiButton: {
    root: {
      borderRadius: 999,
      fontWeight: 600,
      fontSize: '0.9375rem',
      letterSpacing: '-0.01em',
      '&$contained': {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      '&$text': {
        padding: '0.375rem 1rem',
      },
      '&$outlinedPrimary': {
        boxShadow: 'none',
        borderColor: (palette.primary as SimplePaletteColorOptions).main,
        backgroundColor: palette.background?.paper,
        '&:hover': {
          boxShadow: 'none',
        },
      },
      '&$outlinedSecondary': {
        boxShadow: 'none',
        borderColor: (palette.secondary as SimplePaletteColorOptions).main,
        backgroundColor: palette.background?.paper,
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: 'inherit',
      display: 'block',
      fontSize: '0.9375rem',
      fontWeight: 500,
      marginBottom: '0.625rem',
    },
  },
  MuiFormHelperText: {
    root: {
      letterSpacing: '0.02em',
      fontSize: typography?.body2?.fontSize,
      lineHeight: 'normal',
      fontWeight: typography?.body2?.fontWeight,
    },
    contained: {
      marginLeft: '0.125rem',
      '&$error': {
        margin: '-3px 0 0',
        padding: '0.5rem 1rem',
        background: (palette.error as SimplePaletteColorOptions).main,
        color: (palette.error as SimplePaletteColorOptions).contrastText,
        borderRadius: '0 0 3px 3px',
        marginTop: 0,
      },
    },
  },

  /** Table */
  MuiTableCell: {
    head: {
      color: palette?.grey?.['500'],
      fontSize: size(0.875),
    },
    stickyHeader: {
      backgroundColor: palette?.background?.paper,
    },
    root: {
      color: palette?.grey?.['50'],
      fontSize: size(0.938),
      fontWeight: typography?.fontWeightMedium,
      border: 0,
      borderBottom: `1px solid ${palette.divider}`,
    },
  },

  MuiSnackbarContent: {
    root: {
      color: `${palette?.grey?.['500']} !important`,
      borderLeftWidth: '0.375rem',
      borderLeftStyle: 'solid',
      padding: '0.4375rem 1.75rem',
      '&[class*="variantError"]': {
        backgroundColor: '#ffffff !important',
        borderColor: `${(palette.error as SimplePaletteColorOptions).main} !important`,
        borderRadius: '0.25rem !important',
      },
      '&[class*="variantWarning"]': {
        backgroundColor: '#ffffff !important',
        borderColor: `${(palette.error as SimplePaletteColorOptions).main} !important`,
        borderRadius: '0.25rem',
      },
      '&[class*="variantSuccess"]': {
        backgroundColor: '#ffffff !important',
        borderColor: `${(palette.success as SimplePaletteColorOptions).main} !important`,
        borderRadius: '0.25rem !important',
      },
      '&[class*="variantInfo"]': {
        backgroundColor: '#ffffff !important',
        borderColor: `${
          (palette.secondary as SimplePaletteColorOptions).main
        } !important`,
        borderRadius: '0.25rem !important',
      },
    },
    MuiCollapse: {
      SnackbarItem: {
        padding: '0 20px',
      },
    },
  },

  /** Dialog */
  MuiDialog: {
    paper: {
      borderRadius: 5,
      overflowY: 'visible',
    },
  },

  MuiDialogContent: {
    root: {
      overflowY: 'visible',
    },
  },

  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
  },

  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiListItem: {
    root: {
      paddingTop: '1.5rem',
      paddingBottom: '1.5rem',
      '&$gutters': {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
      },
      '&$selected': {
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#ffffff',
        },
      },
      '&:hover': {
        backgroundColor: palette?.grey ? palette.grey['50'] : undefined,
      },
    },
    button: {
      '&:hover': {
        backgroundColor: hexToRgba(
          (palette.primary as SimplePaletteColorOptions).main,
          0.15,
        ),
      },
    },
  },
  MuiListItemText: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  MuiTabs: {
    root: {
      minHeight: 0,
      padding: '0',
    },
    scrollButtons: {
      marginTop: '-0.5em',
    },
  },
  MuiTab: {
    root: {
      margin: '0 0.75rem 0 0',
      minHeight: 0,
      minWidth: 0,
      padding: '0 0 0.5625rem 0',
      textTransform: 'inherit',
      fontSize: typography?.body1 ? typography.body1.fontSize : undefined,
      fontWeight: typography?.body1 ? typography.body1.fontWeight : undefined,
      lineHeight: '1em',
      '@media (min-width: 600px)': {
        minWidth: 0,
      },
      '&$selected': {
        fontWeight: 500,
      },
    },
  },
  PrivateTabIndicator: {
    root: {
      height: '0.1875rem',
    },
  },
  MuiSwitch: {
    root: {
      width: '2.5625rem',
      height: '1.4375rem',
      padding: 0,
      margin: '1rem',
    },
    switchBase: {
      padding: '0.0625rem 0.125rem',
      '&$checked': {
        transform: 'translateX(1.1875rem)',
        color: '#ffffff',
        padding: '0.0625rem',
        '& + $track': {
          opacity: 1,
          border: 'none',
        },
        '&$disabled': {
          '& + $track': {
            backgroundColor: '#eeeeee',
          },
        },
      },
    },
    thumb: {
      width: '1.1875rem',
      height: '1.1875rem',
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      top: '0.0625rem',
      position: 'relative',
    },
    track: {
      borderRadius: '999rem',
      backgroundColor: palette?.grey ? palette.grey[400] : 'transparent',
      opacity: 1,
    },
    checked: {},
  },
  MuiFormControlLabel: {
    labelPlacementStart: {
      marginLeft: 0,
    },
  },
} as DeepPartial<Overrides>;
