import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  Organisation,
  CreateOrganisationRequest,
} from '@/queries';
import { OrganisationLocalStorageKeys, setLocalStorageItem } from '@/storage';
import client, { ApiRoutes } from '@/store/client';

export const useCreateOrganisation = () => {
  const { toast } = useToast();

  const createOrganisationMutation = useMutation(
    (payload: CreateOrganisationRequest) => {
      return client.post<Organisation>(
        `${API_HOST}/${ApiRoutes.Core}/organisations`,
        payload,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to create organisation.'), {
          variant: 'error',
        });
      },
      onSuccess: ({ data: org }) => {
        setLocalStorageItem(OrganisationLocalStorageKeys.orgId, org?.id);
        toast('Organisation created successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: createOrganisation,
    isLoading,
    error,
  } = createOrganisationMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, createOrganisation };
};
