import styled, { css } from 'styled-components';

export const OuterContainer = styled.div`
  background: ${({ theme }) => theme?.colors?.veryLightAccent};
  padding: 1rem;
  position: relative;
  min-height: 100vh;
`;

export const Content = styled.div(
  ({ theme }) => css`
    margin-top: 3.4375rem;
    ${theme.breakpoints.up('md')} {
      margin-top: 5.5rem;
    }
  `,
);

export const CircleContainer = styled.div(
  ({ theme }) => css`
    width: 4.5rem;
    height: 4.5rem;
    left: -2.25rem;
    top: 20rem;
    ${theme.breakpoints.up('sm')} {
      width: 8.25rem;
      height: 8.25rem;
      left: 12rem;
      bottom: 14rem;
      top: auto;
    }
    position: fixed;
    z-index: 0;
  `,
);

export const CurveContainer = styled.div(
  ({ theme }) => css`
    width: 43.25rem;
    height: 17rem;
    position: fixed;
    transform: rotate(137deg);
    left: -28rem;
    top: 18.75rem;
    z-index: 0;
    display: none;
    ${theme.breakpoints.up('sm')} {
      display: block;
    }
  `,
);

export const RectangleContainer = styled.div(
  ({ theme }) => css`
    width: 22rem;
    height: 17rem;
    position: fixed;
    transform: rotate(41deg);
    right: 2rem;
    top: 5.5rem;
    z-index: 0;
    display: none;
    ${theme.breakpoints.up('sm')} {
      display: block;
    }
  `,
);

export const LoyaltyFaceUpperContainer = styled.div(
  ({ theme }) => css`
    width: 15rem;
    height: 15rem;
    right: -4.5rem;
    top: 18rem;
    position: fixed;
    z-index: 0;
    transform: rotate(213deg);
    ${theme.breakpoints.up('sm')} {
      width: 32rem;
      height: 32rem;
      right: -3.5rem;
      bottom: -1.25rem;
      top: auto;
    }
  `,
);

export const LogoContainer = styled.div(
  ({ theme }) => css`
    svg * {
      fill: ${theme.palette.grey[900]};
    }
    position: absolute;
    z-index: 1;
    width: 4.6875rem;
    top: 0;
    left: 1rem;
    ${theme.breakpoints.up('md')} {
      width: 8.125rem;
      top: 0;
      left: 5rem;
    }
  `,
);
