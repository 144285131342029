import { ComponentProps, ComponentType, FunctionComponent } from 'react';
import createConnect, { WithQuestionsProps } from './withQuestions.state';

export const withQuestions = <TProps extends {}>(Component: ComponentType<TProps>) => {
  const connect = createConnect<TProps>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `WithQuestions(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent as FunctionComponent<
    Omit<ComponentProps<typeof Component>, keyof WithQuestionsProps>
  >;
};
