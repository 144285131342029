import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { getLocalStorageItem } from '@/storage';
import { RootStatus } from '@/store/types';
import { splitStatus } from '@/store/utils';
import {
  userLogout,
  fetchUserProfile,
  updateUserProfile,
  setUserAuthenticated,
  setUserIdToken,
  loginUser,
  changePassword,
  createUser,
} from './actions';
import { User, UserProfile, UserLocalStorageKeys } from './types';

const statusReducer = createReducer<RootStatus>({}).handleAction(
  [
    fetchUserProfile.request,
    fetchUserProfile.failure,
    fetchUserProfile.success,
    loginUser.request,
    loginUser.failure,
    loginUser.success,
    changePassword.request,
    changePassword.failure,
    changePassword.success,
    createUser.request,
    createUser.failure,
    createUser.success,
  ],
  (state, { type }) => {
    const [actionKey, status] = splitStatus(type);

    if (!actionKey) return state;

    return {
      ...state,
      [actionKey]: status,
    };
  },
);

export const idTokenReducer = createReducer<null | string>(null)
  .handleAction(setUserIdToken, (state, { payload }) => payload)
  .handleAction(userLogout, () => null);

export const authenticatedReducer = createReducer<boolean>(false)
  .handleAction(userLogout, () => false)
  .handleAction(setUserAuthenticated, (state, { payload }) => payload);

export const userReducer = createReducer<null | User>(null).handleAction(
  userLogout,
  () => null,
);

export const profileReducer = createReducer<null | UserProfile>(
  // Pre-populate from local storage
  getLocalStorageItem<UserProfile>(UserLocalStorageKeys.Profile),
)
  .handleAction(fetchUserProfile.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(updateUserProfile.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(userLogout, () => null);

export default combineReducers({
  idToken: idTokenReducer,
  authenticated: authenticatedReducer,
  profile: profileReducer,
  status: statusReducer,
});
