import { createSelector } from 'reselect';
import { AppState } from '@/store';

export const getOnboarding = (state: AppState) => state.onboarding;

export const getOnboardingData = createSelector(
  getOnboarding,
  onboarding => onboarding.data,
);

export const getOnboardingStatuses = createSelector(
  getOnboarding,
  onboarding => onboarding.status || {},
);
