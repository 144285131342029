import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, TRANSCRIPTS_QUERY_KEY } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { MessagesRequest, MessagesResponse } from '../types';

const getMessages = ({ botId, userId, locale }: MessagesRequest) => {
  return () => {
    return client.get<MessagesResponse>(
      `${API_HOST}/${ApiRoutes.Core}/transcripts/${botId}/${userId}/${locale}`,
    );
  };
};

export const useGetMessages = ({ botId, userId, locale }: MessagesRequest) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [botId, userId, TRANSCRIPTS_QUERY_KEY],
    getMessages({ botId, userId, locale }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get transcript messages'), {
          variant: 'error',
        });
      },
    },
  );

  const messagesData = data?.data;
  return { messagesData, isLoading };
};
