import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { DOCUMENTS_QUERY_KEY } from '../constants';
import { DeleteDocumentRequest, KnowledgeSearchDocument } from '../types';

export const useDeleteDocument = () => {
  const { toast } = useToast();

  const deleteDocumentMutation = useMutation(
    ({ featureId, documentId }: DeleteDocumentRequest) =>
      client.delete<Array<KnowledgeSearchDocument>>(
        `${API_HOST}/${ApiRoutes.Search}/${featureId}/documents/${documentId}`,
      ),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to delete document.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([DOCUMENTS_QUERY_KEY, featureId]);
        toast('Successfully deleted document.', { variant: 'success' });
      },
    },
  );

  const {
    mutateAsync: deleteDocument,
    isLoading: isDeletingDocument,
  } = deleteDocumentMutation;

  return { isDeletingDocument, deleteDocument };
};
