import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  DeletePlatformUserRequest,
  extractServerErrorMsg,
  PLATFORM_USERS_QUERY_KEY,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useDeletePlatformUser = () => {
  const { toast } = useToast();

  const deletePlatformUserMutation = useMutation(
    ({ orgId, userId }: DeletePlatformUserRequest) =>
      client.delete(
        `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/users/${userId}`,
      ),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to delete user'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { orgId }) => {
        queryClient.invalidateQueries([PLATFORM_USERS_QUERY_KEY, orgId]);
        toast('User deleted successfully', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: deletePlatformUser, isLoading } = deletePlatformUserMutation;
  return { isLoading, deletePlatformUser };
};
