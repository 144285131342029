import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useLogoutUser } from '@/hooks/useLogoutUser';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, ORGS_QUERY_KEY } from '@/queries';
import client, {
  ApiRoutes,
  ClientCancelToken,
  createCancelToken,
  wasCancelled,
} from '@/store/client';
import { Organisation } from '../types';

let orgsCancelToken: ClientCancelToken | undefined = undefined;

const cancelBotRequest = (message?: string) => {
  orgsCancelToken && orgsCancelToken.cancel(message);
  orgsCancelToken = createCancelToken();
};

const getOrgs = () => {
  cancelBotRequest('New orgs request');

  return () => {
    return client.get<Array<Organisation>>(
      `${API_HOST}/${ApiRoutes.Core}/organisations`,
      {
        cancelToken: orgsCancelToken?.token,
      },
    );
  };
};

export const useGetOrganisations = () => {
  const { toast } = useToast();
  const { logoutIfForbidden } = useLogoutUser();

  const { data, isLoading } = useQuery(ORGS_QUERY_KEY, getOrgs(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    onError: (errorResponse: AxiosError) => {
      if (wasCancelled(errorResponse)) return;
      toast(extractServerErrorMsg(errorResponse, 'Failed to get organisations'), {
        variant: 'error',
      });

      logoutIfForbidden(errorResponse);
    },
  });

  const organisations = data?.data;
  return { organisations, isLoading };
};
