import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { queryClient } from '@/reactQueryClient';
import {
  ErrorMessage,
  extractServerErrorMsg,
  CONVERSATIONS_QUERY_KEY,
  UpdateConversationRequest,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

export const useUpdateConversation = () => {
  const { toast } = useToast();

  const updateConversationMutation = useMutation(
    ({ botId, userId, body, conversationId }: UpdateConversationRequest) => {
      return client.patch<any>(
        `${API_HOST}/${ApiRoutes.LiveChat}/bots/${botId}/history/users/${userId}/conversations/${conversationId}`,
        body,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update conversation'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { botId, userId }) => {
        queryClient.invalidateQueries([CONVERSATIONS_QUERY_KEY, botId, userId]);
        toast('Conversation updated.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateConversation,
    isLoading,
    error,
  } = updateConversationMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateConversation };
};
