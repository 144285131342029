import { combineReducers } from 'redux';
import { merge } from 'lodash/fp';
import { createReducer } from 'typesafe-actions';
import {
  fetchQuestionsStatsSummary,
  fetchQuestionsStatsAsked,
  fetchQuestionsStatsPopular,
  fetchQuestionsStatsPopularQuestion,
  fetchQuestionsStatsUnanswered,
  fetchQuestionsStatsPerConvo,
  deleteQuestionsStatsUnanswered,
} from './actions';
import { QuestionStatsPerConvoReducedResponse } from './types';

const summaryReducer = createReducer<null | Array<string>>(null).handleAction(
  fetchQuestionsStatsSummary.success,
  (state, { payload }) => payload,
);

const askedReducer = createReducer<null | Array<string>>(null).handleAction(
  fetchQuestionsStatsAsked.success,
  (state, { payload }) => payload,
);

const popularReducer = createReducer<null | Array<string>>(null).handleAction(
  fetchQuestionsStatsPopular.success,
  (state, { payload }) => payload,
);

const questionReducer = createReducer<null | Record<
  string,
  Record<string, Array<string>>
>>(null).handleAction(
  fetchQuestionsStatsPopularQuestion.success,
  (state, { payload }) => {
    const { questionId, ...stats } = payload;

    return merge(state, {
      [questionId]: {
        ...stats,
      },
    });
  },
);

const perConvoReducer = createReducer<null | QuestionStatsPerConvoReducedResponse>(
  null,
).handleAction(fetchQuestionsStatsPerConvo.success, (state, { payload }) => payload);

const unansweredReducer = createReducer<null | Array<string>>(null).handleAction(
  [fetchQuestionsStatsUnanswered.success, deleteQuestionsStatsUnanswered.success],
  (state, { payload }) => payload,
);

export default combineReducers({
  summary: summaryReducer,
  asked: askedReducer,
  popular: popularReducer,
  question: questionReducer,
  unanswered: unansweredReducer,
  perConvo: perConvoReducer,
});
