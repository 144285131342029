import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { useDashboardParams } from '@/hooks/useDashboardParams';
import { extractServerErrorMsg, FileType } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { StatsResponse } from '@/store/stats';
import { downloadCsv, downloadJson } from '@/utils';

export const STATS_BASE_URL = `${API_HOST}/${ApiRoutes.Dashboard}`;

export const useGetStatsInformation = (
  urlPath: string,
  queryKey: string,
  format?: string,
) => {
  const { toast } = useToast();
  const paramValues = useDashboardParams();
  const botId = paramValues.botId;
  const STATS_QUERY_KEY = [queryKey, paramValues];

  const { refetch } = useQuery(
    STATS_QUERY_KEY,
    useCallback(() => {
      return client.get<StatsResponse>(`${STATS_BASE_URL}/${botId}/${urlPath}`, {
        params: { ...paramValues, format },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlPath, queryKey, paramValues, format]),
    {
      enabled: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get stats information'), {
          variant: 'error',
        });
      },
      onSuccess: dataResult => {
        //@ts-ignore
        const csvData = dataResult?.data?.value;

        //@ts-ignore | This should eventually come from value
        const jsonData = dataResult?.data?.data;

        //@ts-ignore
        const title = dataResult?.data?.title ?? 'fallback title';

        switch (format) {
          case FileType.CSV:
            downloadCsv(csvData, title);
            break;
          case FileType.JSON:
            downloadJson(jsonData, title);
            break;
          default:
            return;
        }

        queryClient.removeQueries(STATS_QUERY_KEY);
      },
    },
  );

  const fetchStatsInformation = refetch;
  return { fetchStatsInformation };
};
