import { MessageBubble } from '@/store/messages';

export enum EmailFrequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
}

// General
export type GeneralSettings = {
  collectUserInfoEnabled?: boolean;
  conversationDownloadsEnabled?: boolean;
  conversationClearEnabled?: boolean;
  conversationTranscripts?: {
    emailEnabled?: boolean;
    emailAddress?: string;
    emailFrequency?: EmailFrequency;
  };
  showLiveChatIcon?: boolean;
};

export type UpdateGeneralSettingsRequest = {
  featureId: string;
  data?: GeneralSettings;
};

// User Blocking
export type UserBlockingSettings = {
  userBlockingEnabled?: boolean;
  blockedMessage?: {
    id?: string;
    bubbles?: Array<MessageBubble>;
  };
};

export type UpdateUserBlockingSettingsPayload = {
  featureId?: string;
  data?: UserBlockingSettings;
};

// Take Offline
export type TakeOfflineSettings = {
  offline?: boolean;
  offlineMessage?: {
    id?: string;
    bubbles?: Array<MessageBubble>;
  };
};

export type TakeOfflineSettingsPayload = {
  featureId?: string;
  data?: TakeOfflineSettings;
};

// Support Times
type SupportTime = {
  available?: boolean;
  startTime?: string;
  endTime?: string;
};

export type SupportTimesSettings = {
  enabled: boolean;
  supportTimes?: {
    monday?: SupportTime;
    tuesday?: SupportTime;
    wednesday?: SupportTime;
    thursday?: SupportTime;
    friday?: SupportTime;
    saturday?: SupportTime;
    sunday?: SupportTime;
  };
  outOfOfficeMessage?: {
    id?: string;
    bubbles?: Array<MessageBubble>;
  };
};

export type UpdateSupportTimesSettingsPayload = {
  featureId?: string;
  data?: SupportTimesSettings;
};

// Invitation Sending
export type InvitationSendingSettings = {
  invitationSendingEnabled: boolean;
  lastInvitationText: string | null;
};

export type SendInvitationData = {
  recipients: string[];
  invitationText: string;
};

export type SendInvitationPayload = {
  featureId: string;
  data: SendInvitationData;
};
