import React from 'react';
import { Redirect } from 'react-router-dom';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing';
import {
  LiveChatGeneralSettingsView,
  LiveChatSupportTimesView,
  LiveChatTakeOfflineView,
  LiveChatBlockUsersView,
} from '@/views';

export const liveChatSettings: RoutingConfig = [
  {
    path: '/general',
    exact: true,
    component: LiveChatGeneralSettingsView,
    customProps: { permissionArea: BotPermissionCode.LIVE_CHAT_ADMIN },
  },
  {
    path: '/support-times',
    exact: true,
    component: LiveChatSupportTimesView,
    customProps: { permissionArea: BotPermissionCode.LIVE_CHAT_ADMIN },
  },
  {
    path: '/take-offline',
    exact: true,
    component: LiveChatTakeOfflineView,
    customProps: { permissionArea: BotPermissionCode.LIVE_CHAT_ADMIN },
  },
  {
    path: '/block-users',
    exact: true,
    component: LiveChatBlockUsersView,
    customProps: { permissionArea: BotPermissionCode.LIVE_CHAT_ADMIN },
  },

  // This must be last
  {
    path: '',
    exact: true,
    render: ({ match }) => <Redirect to={`${match.url}/general`} />,
    customProps: { permissionArea: BotPermissionCode.LIVE_CHAT_ADMIN },
  },
];

export default liveChatSettings;
