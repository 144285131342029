import { omit } from 'lodash/fp';
import { orderBy, keyBy } from 'lodash';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { RootStatus } from '@/store/types';
import { splitStatus } from '@/store/utils';
import {
  addQuestionTag,
  createAnswer,
  createQuestion,
  createUtterance,
  deleteAnswer,
  deleteUtterance,
  featuredOrderChanged,
  fetchQuestions,
  fetchQuestion,
  publishQuestion,
  draftQuestion,
  questionsBulkUpload,
  removeQuestionTag,
  reorderAnswers,
  setFeaturedQuestion,
  syncQuestion,
  updateAnswer,
  updateQuestion,
  updateUtterance,
  deleteQuestion,
  fetchQuestionsStatsSummary,
  fetchQuestionsStatsAsked,
  fetchQuestionsStatsPopular,
  fetchQuestionsStatsPopularQuestion,
  fetchQuestionsStatsUnanswered,
  fetchQuestionsStatsPerConvo,
  setSearchQuery,
  unpublishQuestion,
} from './actions';
import { Questions } from './types';
import statsReducer from './stats/reducer';

export const statusReducer = createReducer<RootStatus>({}).handleAction(
  [
    fetchQuestions.request,
    fetchQuestions.failure,
    fetchQuestions.success,
    fetchQuestion.request,
    fetchQuestion.failure,
    fetchQuestion.success,
    syncQuestion.request,
    syncQuestion.failure,
    syncQuestion.success,
    setFeaturedQuestion.request,
    setFeaturedQuestion.failure,
    setFeaturedQuestion.success,
    featuredOrderChanged.request,
    featuredOrderChanged.failure,
    featuredOrderChanged.success,
    createQuestion.request,
    createQuestion.failure,
    createQuestion.success,
    updateQuestion.request,
    updateQuestion.failure,
    updateQuestion.success,
    deleteQuestion.request,
    deleteQuestion.failure,
    deleteQuestion.success,
    createUtterance.request,
    createUtterance.failure,
    createUtterance.success,
    updateUtterance.request,
    updateUtterance.failure,
    updateUtterance.success,
    deleteUtterance.request,
    deleteUtterance.failure,
    deleteUtterance.success,
    createAnswer.request,
    createAnswer.failure,
    createAnswer.success,
    updateAnswer.request,
    updateAnswer.failure,
    updateAnswer.success,
    deleteAnswer.request,
    deleteAnswer.failure,
    deleteAnswer.success,
    reorderAnswers.request,
    reorderAnswers.failure,
    reorderAnswers.success,
    addQuestionTag.request,
    addQuestionTag.failure,
    addQuestionTag.success,
    removeQuestionTag.request,
    removeQuestionTag.failure,
    removeQuestionTag.success,
    publishQuestion.request,
    publishQuestion.failure,
    publishQuestion.success,
    unpublishQuestion.request,
    unpublishQuestion.failure,
    unpublishQuestion.success,
    questionsBulkUpload.request,
    questionsBulkUpload.failure,
    questionsBulkUpload.success,
    fetchQuestionsStatsSummary.request,
    fetchQuestionsStatsSummary.failure,
    fetchQuestionsStatsSummary.success,
    fetchQuestionsStatsAsked.request,
    fetchQuestionsStatsAsked.failure,
    fetchQuestionsStatsAsked.success,
    fetchQuestionsStatsPopular.request,
    fetchQuestionsStatsPopular.failure,
    fetchQuestionsStatsPopular.success,
    fetchQuestionsStatsPopularQuestion.request,
    fetchQuestionsStatsPopularQuestion.failure,
    fetchQuestionsStatsPopularQuestion.success,
    fetchQuestionsStatsUnanswered.request,
    fetchQuestionsStatsUnanswered.failure,
    fetchQuestionsStatsUnanswered.success,
    fetchQuestionsStatsPerConvo.request,
    fetchQuestionsStatsPerConvo.failure,
    fetchQuestionsStatsPerConvo.success,
  ],
  (state, { type }) => {
    const [actionKey, status] = splitStatus(type);

    if (!actionKey) return state;

    return {
      ...state,
      [actionKey]: status,
    };
  },
);

export const questionsReducer = createReducer<null | Questions>(null)
  .handleAction(fetchQuestions.success, (state, { payload }) => keyBy(payload, 'id'))
  .handleAction(setFeaturedQuestion.success, (state, { payload }) => {
    const question = state?.[payload.id];

    if (!question) return state;

    return {
      ...state,
      [payload.id]: {
        ...question,
        featured: payload.featured,
      },
    };
  })
  .handleAction(
    [
      fetchQuestion.success,
      createQuestion.success,
      updateQuestion.success,
      publishQuestion.success,
      unpublishQuestion.success,
      draftQuestion.success,
      syncQuestion.success,
    ],
    (state, { payload }) => {
      return {
        ...state,
        [payload.id]: {
          ...payload,
          answer: {
            bubbles: orderBy(payload?.answer?.bubbles ?? [], 'order'),
          },
        },
      };
    },
  )
  .handleAction(reorderAnswers.success, (state, { payload }) => {
    if (!state?.[payload?.questionId]) return state;
    return {
      ...state,
      [payload.questionId]: {
        ...state[payload.questionId],
        answer: {
          bubbles: orderBy(payload.answers, 'order'),
        },
      },
    };
  })
  .handleAction(deleteQuestion.success, (state, { payload }) => omit([payload.id], state))
  .handleAction(createUtterance.success, (state, { payload }) => {
    const { questionId, ...utterance } = payload;

    const question = state?.[questionId];

    if (!question || !utterance?.id) return state;

    return {
      ...state,
      [questionId]: {
        ...question,
        utterances: [...question?.utterances, utterance],
      },
    };
  })
  .handleAction(updateUtterance.success, (state, { payload }) => {
    const { questionId, ...utterance } = payload;
    const { id } = utterance;

    const question = state?.[questionId];
    if (!question) return state;

    const utterances = state?.[questionId]?.utterances || [];
    const utteranceIndex = utterances.findIndex(utterance => utterance.id === id);

    if (utteranceIndex < 0) return state;

    utterances[utteranceIndex] = utterance;
    return {
      ...state,
      [questionId]: {
        ...question,
        utterances,
      },
    };
  })
  .handleAction(deleteUtterance.success, (state, { payload }) => {
    const { questionId, id } = payload;

    const question = state?.[questionId];
    if (!question) return state;

    const utterances = state?.[questionId]?.utterances || [];
    const utteranceIndex = utterances.findIndex(utterance => utterance.id === id);

    if (utteranceIndex < 0) return state;

    utterances.splice(utteranceIndex, 1);

    return {
      ...state,
      [questionId]: {
        ...question,
        utterances,
      },
    };
  })
  .handleAction(createAnswer.success, (state, { payload }) => {
    const { messageId, ...answer } = payload;

    const question = state?.[messageId];

    if (!question || !answer?.id) return state;

    return {
      ...state,
      [messageId]: {
        ...question,
        answers: [...question?.answers, answer],
      },
    };
  })
  .handleAction(updateAnswer.success, (state, { payload }) => {
    const { messageId, ...answer } = payload;
    const { id } = answer;

    const question = state?.[messageId];
    if (!question) return state;

    const answers = state?.[messageId]?.answers || [];
    const answerIndex = answers.findIndex(answer => answer.id === id);

    if (answerIndex < 0) return state;

    answers[answerIndex] = answer;

    return {
      ...state,
      [messageId]: {
        ...question,
        answers,
      },
    };
  })
  .handleAction(deleteAnswer.success, (state, { payload }) => {
    const { questionId, id } = payload;

    const question = state?.[questionId];
    if (!question) return state;

    const answers = state?.[questionId]?.answers || [];
    const answerIndex = answers.findIndex(answer => answer.id === id);

    if (answerIndex < 0) return state;

    answers.splice(answerIndex, 1);

    return {
      ...state,
      [questionId]: {
        ...question,
        answers,
      },
    };
  });

export const searchQueryReducer = createReducer<null | string>(null).handleAction(
  setSearchQuery,
  (state, { payload }) => payload,
);

export default combineReducers({
  data: combineReducers({
    questions: questionsReducer,
    stats: statsReducer,
  }),
  searchQuery: searchQueryReducer,
  status: statusReducer,
});
