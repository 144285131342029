import React, { Component, ComponentType } from 'react';
import hash from 'object-hash';

import {
  StyledErrorBoundary,
  ErrorBoundaryTitle,
  ErrorBoundaryPre,
  GlobalErrorBoundaryStyles,
} from './withErrorBoundary.styled';
import { ErrorBoundaryState } from './types';

const withErrorBoundary = (WrappedComponent: ComponentType) => {
  return class ErrorBoundary extends Component {
    state: ErrorBoundaryState = {
      id: undefined,
      hasError: false,
      error: undefined,
    };

    static getDerivedStateFromError(error: Error) {
      return { hasError: true, error, id: hash(error.toString()) };
    }

    renderError() {
      const { error, id } = this.state;

      return (
        <StyledErrorBoundary>
          <GlobalErrorBoundaryStyles />
          <ErrorBoundaryTitle>Something went wrong</ErrorBoundaryTitle>
          <ErrorBoundaryPre>{error?.toString()}</ErrorBoundaryPre>
          <ErrorBoundaryPre>Ref: {id}</ErrorBoundaryPre>
        </StyledErrorBoundary>
      );
    }

    render() {
      const { hasError } = this.state;

      if (hasError) {
        return this.renderError();
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withErrorBoundary;
