import { SimplePaletteColorOptions } from '@material-ui/core';
import { CustomTheme } from '@/styles/theme';
import { augmentColor, lightenDarkenColor, hexToRgba } from '@/styles/theme/utils';
import breakpoints from './breakpoints';
import colors from './colors';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';
import { size } from './size';

const defaultTheme: CustomTheme = {
  /** Standard theming (i.e. MUI) */
  breakpoints,
  palette,
  typography,
  shape: {
    borderRadius: 0.25,
  },
  spacing: factor =>
    ['0.25rem', '0.75rem', '0.5rem', '1rem', '1.5rem', '2rem', '3rem', '4rem', '5rem'][
      factor
    ],
  overrides,

  /** Custom theming */
  augmentColor,
  lightenDarkenColor,
  hexToRgba,
  colors,
  grid: ({ columns = 12, gutterWidth = 2, colWidth = 8, autoMaxGridWidth = true }) => ({
    maxGridWidth: !autoMaxGridWidth
      ? 'none'
      : size(columns * colWidth + (columns - 1) * gutterWidth),
    gutter: size(gutterWidth),
    minRowHeight: size(5),
    minColWidth: size(colWidth),
    numCols: columns,
  }),
  bot: {
    confidence: {
      min: {
        main: (palette.primary as SimplePaletteColorOptions).main,
        light: '#f9e2e8',
      },
      neutral: {
        main: '#4082bc',
        light: '#e2f1f7',
      },
      max: {
        main: '#428d55',
        light: '#e5f4ea',
      },
    },
  },
  status: {
    active: {
      main: (palette?.success as SimplePaletteColorOptions)?.light || '#feebd6',
    },
    draft: {
      main: '#feebd6',
    },
  },
};

export default defaultTheme;
