import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import { CreateOrUpdateRequest, Announcement } from '../types';
import { ErrorMessage, extractServerErrorMsg, ANNOUNCEMENTS_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import { AxiosError } from 'axios';

export const useCreateAnnouncement = () => {
  const { toast } = useToast();

  const createAnnouncementMutation = useMutation(
    ({ featureId, body }: CreateOrUpdateRequest) => {
      return client.post<Announcement>(
        `${API_HOST}/${ApiRoutes.Announcements}/${featureId}/announcements`,
        {
          ...(body ? body : {}),
        },
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to create announcement.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([ANNOUNCEMENTS_QUERY_KEY, featureId]);
        toast('Announcement created successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: createAnnouncement,
    isLoading,
    error,
  } = createAnnouncementMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, createAnnouncement };
};
