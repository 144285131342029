import axios from 'axios';
import { ClientCancelToken } from './types';

export const createCancelToken = () => axios.CancelToken.source();

export const cancelRequest = (cancelToken: ClientCancelToken, message?: string) => {
  cancelToken && cancelToken.cancel(message);
  cancelToken = createCancelToken();
  return cancelToken;
};

export const wasCancelled = axios.isCancel;
