import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg, SURVEYS_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { Survey, SurveyRequest } from '../types';

export const usePublishSurvey = () => {
  const { toast } = useToast();

  const publishSurveyMutation = useMutation(
    ({ featureId, surveyId }: SurveyRequest) => {
      return client.post<Survey>(
        `${API_HOST}/${ApiRoutes.Surveys}/${featureId}/surveys/${surveyId}/publish`,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to publish survey.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([SURVEYS_QUERY_KEY, featureId]);
        toast('Survey published successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: publishSurvey, isLoading, error } = publishSurveyMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, publishSurvey };
};
