import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg, ANNOUNCEMENTS_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { CreateOrUpdateRequest, Announcement } from '../types';

export const useUpdateAnnouncement = () => {
  const { toast } = useToast();

  const updateAnnouncementMutation = useMutation(
    ({ featureId, announcementId, body }: CreateOrUpdateRequest) => {
      return client.patch<Announcement>(
        `${API_HOST}/${ApiRoutes.Announcements}/${featureId}/announcements/${announcementId}`,
        {
          ...body,
        },
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update announcement.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([ANNOUNCEMENTS_QUERY_KEY, featureId]);
        toast('Announcement updated successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateAnnouncement,
    isLoading,
    error,
  } = updateAnnouncementMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateAnnouncement };
};
