import { Channel } from '@/store/luv';
import { Answer } from '@/store/questions';
import { DeepPartial } from 'react-hook-form';

export type BotRequest = {
  orgId?: string | null;
  botId?: string | null;
};

export type CreateBotRequest = BotRequest & {
  type: {
    code: string;
  };
  botName: string;
  website?: string;
  sector?: {
    id: string;
    name?: string;
  };
  organisationId: string;
  billingBand: { id: string };
  billingGroups?: { id: string }[];
};

export type UpdateBotRequest = BotRequest &
  DeepPartial<Bot> & {
    logoFile?: FileList;
  };

export type UploadBotLogoRequest = {
  orgId: string;
  botId: string;
  logoFile: File;
};

export enum BotChannelStatusCode {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
}

export type BotChannelStatus = {
  code: BotChannelStatusCode;
  description: string;
};

export enum BotChannelType {
  Teams = 'MSTEAMS',
  SMS = 'SMS',
  WebChat = 'WEBCHAT',
  Facebook = 'FACEBOOK',
  WhatsApp = 'WHATSAPP',
  Instagram = 'INSTAGRAM',
}

export type BotChannel = Channel & {
  status: BotChannelStatus;
  code: BotChannelType;
};

export type BotFeature = {
  id: string;
  botId: string;
  enabled: boolean;
  position: number;
  platformFeatureId: string;
  iconUrl: string;
  label: string;
  code: string;
  managementApiUrl: string;
  configurationUrl: string;
};

export type BotNavGroup = {
  id: string;
  code: string;
  label: string;
  order: number;
  items: Array<BotNavGroupItem>;
  enabled: boolean;
};

export type BotTypeCode = 'INSIDE' | 'OUTSIDE';

export type BotType = {
  code: BotTypeCode;
  description: 'Inside' | 'Outside';
};

export type RemoteUserDepartment = {
  id: string;
  value: string;
};

export enum BotPermissionCode {
  BOT_SETTINGS_ADMIN = 'BOT_SETTINGS:ADMIN',
  BOT_CHANNELS_ADMIN = 'BOT_CHANNELS:ADMIN',
  BOT_FEATURES_ADMIN = 'BOT_FEATURES:ADMIN',
  DASHBOARDS_VIEW_ALL = 'DASHBOARDS:VIEW_ALL',
  TRANSCRIPTS_VIEW = 'TRANSCRIPTS:VIEW',
  TRANSCRIPTS_DOWNLOAD = 'TRANSCRIPTS:DOWNLOAD',
  QANDA_ADMIN = 'QANDA:ADMIN',
  KNOWLEDGE_SEARCH_ADMIN = 'KNOWLEDGE_SEARCH:ADMIN',
  FORMS_ADMIN = 'FORMS:ADMIN',
  CHAT_ADMIN_ADMIN = 'CHAT_ADMIN:ADMIN',
  LIVE_CHAT_ADMIN = 'LIVE_CHAT:ADMIN',
  LIVE_CHAT_AGENT = 'LIVE_CHAT:AGENT',
  ANNOUNCEMENTS_ADMIN = 'ANNOUNCEMENTS:ADMIN',
  SURVEYS_ADMIN = 'SURVEYS:ADMIN',
  WEB_SEARCH_ADMIN = 'WEB_SEARCH:ADMIN',
}

export enum BotPermissionGroup {
  INSIDE_GENERAL = 'INSIDE:GENERAL',
  INSIDE_AUTOMATE = 'INSIDE:AUTOMATE',
  INSIDE_INTEGRATE = 'INSIDE:INTEGRATE',
  INSIDE_LIVECHAT = 'INSIDE:LIVECHAT',
  INSIDE_REACH = 'INSIDE:REACH',
  INSIDE_ANALYTICS = 'INSIDE:ANALYTICS',
  OUTSIDE_GENERAL = 'OUTSIDE:GENERAL',
  OUTSIDE_AUTOMATE = 'OUTSIDE:AUTOMATE',
  OUTSIDE_INTEGRATE = 'OUTSIDE:INTEGRATE',
  OUTSIDE_LIVECHAT = 'OUTSIDE:LIVECHAT',
  OUTSIDE_REACH = 'OUTSIDE:REACH',
  OUTSIDE_ANALYTICS = 'OUTSIDE:ANALYTICS',
}

export type BotPermission = {
  code: BotPermissionCode;
  description: string;
  group: BotPermissionGroup;
};

export type PermissionGroup = {
  code: BotPermissionCode;
  description?: string;
  permissions: Array<BotPermission>;
};

export type Bot = {
  id: string;
  botName: string;
  organisationId: string;
  organisationName: string;
  trialExpiryDate: string;
  website: string;
  sector: {
    id: string;
    name: string;
  };
  status: {
    code: 'ONLINE' | 'OFFLINE';
    description: 'Online' | 'Offline';
  };
  summary: string;
  useAllLanguages: boolean;
  logoUrl: string;
  minConfidence: number;
  maxConfidence: number;
  welcomeMessage: { id: string; bubbles: Array<Answer> } | null;
  lowConfidenceMessage: { id: string; bubbles: Array<Answer> } | null;
  midConfidenceMessage: { id: string; bubbles: Array<Answer> } | null;
  showMidConfidenceMessage: boolean | null;
  channels: Array<BotChannel>;
  features: Array<BotFeature>;
  navGroups: Array<BotNavGroup>;
  type: BotType;
  remoteUserDepartments: Array<RemoteUserDepartment>;
  permissionGroups: Array<PermissionGroup>;
  activeUsers: number;
};

export enum BotFilters {
  All = 'ALL',
  Inside = 'INSIDE',
  Outside = 'OUTSIDE',
}

export type BotNavGroupItem = {
  code: string;
  label: string;
  order: number;
  iconUrl: string;
  enabled: boolean;
  managementApiUrl?: string;
  configurationUrl: string;
  id: string;
  platformFeatureId: string;
  hasSubMenu?: boolean;
  subMenuComponent?: JSX.Element;
};

export type BotTagsRequest = BotRequest;
