import React from 'react';
import Helmet from 'react-helmet';

export const BrowserNotificationApproval = () => {
  return (
    <Helmet>
      <script>
        {`OneSignal.push(function() { OneSignal.showSlidedownPrompt(); });`}
      </script>
    </Helmet>
  );
};
