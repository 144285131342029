import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { createMuiTheme } from '@material-ui/core/styles';
import { size } from './size';

const theme = createMuiTheme({});

export default {
  fontFamily: '"Work Sans", Arial, sans-serif',
  fontSize: 16,
  fontWeightBold: 600,
  fontWeightLight: 300,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  htmlFontSize: 16,
  h1: {
    fontFamily: 'Telegraf, arial',
    fontSize: size(1.25),
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: size(1.75),
    },
  },
  h2: {
    fontFamily: 'Telegraf, arial',
    fontSize: size(1.125),
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: size(1.25),
    },
  },
  h3: {
    fontFamily: 'Telegraf, arial',
    fontSize: size(0.9375),
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: size(1),
    },
  },
  h4: {
    fontFamily: 'Telegraf, arial',
    fontSize: size(0.85),
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      fontSize: size(1),
    },
  },
  body1: {
    fontSize: size(0.9375),
    fontFamily: 'Work Sans, arial',
    fontWeight: 400,
    lineHeight: '1.2em',
  },
  body2: {
    fontSize: size(1),
    fontFamily: 'Work Sans, arial',
    fontWeight: 400,
  },
  caption: {
    fontSize: size(0.875),
    fontFamily: 'Work Sans, arial',
    lineHeight: '1.3',
  },
} as TypographyOptions;
