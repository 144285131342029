import { withStats } from '@/hoc';
import { WithStatsProps } from '@/hoc/withStats';
import { Statistic } from '@/store/stats';
import {
  fetchQuestionsStatsPopular,
  getQuestionsStatsPopular,
  getQuestionsStatuses,
} from '@/store/questions';
import { ComponentType } from 'react';

export const withQuestionsStatsPopular = <TProps extends WithQuestionsStatsPopularProps>(
  Component: ComponentType<TProps>,
) =>
  withStats<TProps, typeof fetchQuestionsStatsPopular.request>(
    Component,
    fetchQuestionsStatsPopular.request,
    getQuestionsStatsPopular,
    getQuestionsStatuses,
  );

export interface WithQuestionsStatsPopularProps extends WithStatsProps {
  stats: Array<Statistic>;
  fetchStats: typeof fetchQuestionsStatsPopular.request;
}
