import { createAsyncAction } from 'typesafe-actions';

export const ORGANISATION_SWITCH = 'ORGANISATION_SWITCH';
export const ORGANISATION_SWITCH_INITIATED = 'ORGANISATION_SWITCH_INITIATED';
export const ORGANISATION_SWITCH_SUCCESS = 'ORGANISATION_SWITCH_SUCCESS';
export const ORGANISATION_SWITCH_FAILED = 'ORGANISATION_SWITCH_FAILED';

export const switchOrganisation = createAsyncAction(
  ORGANISATION_SWITCH_INITIATED,
  ORGANISATION_SWITCH_SUCCESS,
  ORGANISATION_SWITCH_FAILED,
)<string, void, void | Error>();
