import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, NotesRequest, Note, NOTES_QUERY_KEY } from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getNotes = ({ botId, userId, channelName }: NotesRequest) => {
  return () => {
    return client.get<Note[]>(
      `${API_HOST}/${ApiRoutes.Notes}/bots/${botId}/channels/${channelName}/users/${userId}/notes?showAll=false`,
    );
  };
};

export const useGetNotes = ({ botId, userId, channelName }: NotesRequest) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [NOTES_QUERY_KEY, botId, userId],
    getNotes({ botId, userId, channelName }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get notes'), {
          variant: 'error',
        });
      },
    },
  );

  const notes = data?.data || [];
  return { notes, isLoading };
};
