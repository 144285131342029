import { useContext, useMemo } from 'react';

import { BotIdContext, OrgContext } from '@/contexts';
import { useGetBillingGroups } from '@/queries';

export const useBillingGroupEnabled = (billingGroupCodes: string[]) => {
  const botId = useContext(BotIdContext);
  const { orgId } = useContext(OrgContext);

  const { billingGroups, isLoading } = useGetBillingGroups({ orgId, botId });

  const botBillingGroups = useMemo(() => {
    if (billingGroups) {
      return billingGroups.reduce(
        (accumulator, group) => ({ ...accumulator, [group.id]: group }),
        billingGroups,
      );
    }
    return {};
  }, [billingGroups]);

  const { code, enabled } = useMemo(
    () =>
      Object.keys(botBillingGroups)
        .filter(key => billingGroupCodes.includes(botBillingGroups[key].code))
        .map(key => botBillingGroups[key])
        .filter(item => item?.enabled)?.[0] || {
        code: billingGroupCodes?.[0],
        enabled: false,
      },
    [botBillingGroups, billingGroupCodes],
  );

  return billingGroups && code
    ? { code, enabled, isLoading }
    : { code: billingGroupCodes?.[0], enabled: undefined, isLoading };
};
