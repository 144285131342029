import { createReducer } from 'typesafe-actions';
import { Statistics } from './types';
import { combineReducers } from 'redux';
import { setStats } from './actions';

const statusReducer = createReducer<null | Statistics>(null).handleAction(
  setStats,
  (state, { payload }) => ({ ...state, ...payload }),
);

export default combineReducers({
  data: statusReducer,
});
