import { AppState } from '@/store';
import { removeToast } from '@/store/toast';
import { noop } from '@/utils';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotifierItem } from './types';

let displayed: Array<string> = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: AppState) => state.toasts?.data ?? [],
  ) as Array<NotifierItem>;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: string) => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(
      ({ message, options = { key: 0, onClose: noop }, dismissed = false }) => {
        if (dismissed) {
          closeSnackbar(options.key);
          return;
        }

        const optionsKey = options?.key?.toString();

        if (displayed.includes(optionsKey)) return;

        enqueueSnackbar(message, {
          ...options,
          key: options.key,
          onClose: (event, reason, cKey) => {
            if (options.onClose && event && cKey) {
              options.onClose(event, reason, cKey);
            }
          },
          onExited: (event, eKey) => {
            const exitKey = eKey?.toString();
            dispatch(removeToast(exitKey));
            removeDisplayed(exitKey);
          },
        });

        storeDisplayed(optionsKey);
      },
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
