import { AppState } from '@/store';
import { connect } from 'react-redux';
import {
  fetchQuestionsStatsAsked,
  fetchQuestionsStatsPerConvo,
  fetchQuestionsStatsPopular,
  fetchQuestionsStatsSummary,
  fetchQuestionsStatsUnanswered,
  getQuestionsStatsAsked,
  getQuestionsStatsPerConvo,
  getQuestionsStatsPopular,
  getQuestionsStatsSummary,
  getQuestionsStatsUnanswered,
  getQuestionsStatuses,
  deleteQuestionsStatsUnanswered,
} from '@/store/questions';

const mapStateToProps = (state: AppState) => ({
  summaryStats: getQuestionsStatsSummary(state),
  askedStats: getQuestionsStatsAsked(state),
  popularStats: getQuestionsStatsPopular(state),
  questionsStatuses: getQuestionsStatuses(state),
  unansweredStats: getQuestionsStatsUnanswered(state),
  perConvoStats: getQuestionsStatsPerConvo(state),
});

const mapDispatchToProps = {
  getQuestionsStatsSummary: fetchQuestionsStatsSummary.request,
  getQuestionsStatsAsked: fetchQuestionsStatsAsked.request,
  getQuestionsStatsPopular: fetchQuestionsStatsPopular.request,
  getQuestionsStatsUnanswered: fetchQuestionsStatsUnanswered.request,
  getQuestionsStatsPerConvo: fetchQuestionsStatsPerConvo.request,
  deleteQuestionsStatsUnanswered: deleteQuestionsStatsUnanswered.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type WithQuestionsStatsProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
