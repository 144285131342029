import { format, isValid, parseISO, formatISO } from 'date-fns';

/**
 * Helper date formatter
 *
 * @param {string | number | Date} date Date to format
 * @param {string} pattern Pattern to format to
 * @param {string} unparsedPattern If a string is passed, then pass in the pattern of the unparsed string
 */
export const formatDate = (date: string | Date, pattern = 'dd/MM/yyyy') => {
  try {
    const isValidDate = isValid(date instanceof Date ? date : parseISO(date));
    const formattableDate = isValidDate ? new Date(date) : parseISO(date as string);
    return format(formattableDate, pattern);
  } catch (e) {
    return format(new Date(), pattern);
  }
};

/**
 * Formats date as ISO, defaulting without time
 *
 * @param {number | Date} date
 * @param {*} options
 */
export const formatDateAsISO: typeof formatISO = (
  date,
  options = { representation: 'date' },
) => formatISO(date, options);

export const arrayOfTimes = ({
  interval,
  trailingZeros = false,
}: {
  interval: number;
  trailingZeros?: boolean;
}) => {
  const arr = [] as Array<string>;
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      const hour = i.toString(10).length === 1 ? `0${i}` : i;
      arr.push(`${hour}:${j === 0 ? '00' : interval * j}${trailingZeros ? ':00' : ''}`);
    }
  }
  return arr;
};
