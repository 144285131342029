import styled, { css } from 'styled-components';
import { FormLabel as FormLabelBase } from '@material-ui/core';

export const StyledFormLabel = styled(FormLabelBase)<{ $bottomMargin?: boolean }>`
  && {
    ${({ $bottomMargin }) =>
      $bottomMargin === false &&
      css`
        margin-bottom: 0;
      `}
  }
`;
