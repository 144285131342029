import { connect } from 'react-redux';
import { AppState } from '@/store';
import { switchOrganisation } from '@/store/org';

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {
  switchOrg: switchOrganisation.request,
};

export default connect(null, mapDispatchToProps);

export type WithOrgProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;
