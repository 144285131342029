import { SelectValue } from '@/components/formElements';
import { InvitedUser } from '@/components/InvitedUsersTable/types';
import { InviteeUser } from '@/components/InviteeUsersTable/types';
import { LiveVideoSettings } from '@/queries';
import { proxy, useSnapshot } from 'valtio';

export type StepOneFormValues = {
  conversationType: string;
  language: { label: string; value: string };
};

export type StepTwoFormValues = {
  videoType?: SelectValue;
  expMinutes?: SelectValue;
  authCode?: string;
  invitationMessage?: string;
};

type InviteUsersState = {
  activeStep: number;
  availableSteps: number;
  internalUsers: Array<InviteeUser>;
  invitedUsers: Array<InvitedUser>;
  selectedVideoType: LiveVideoSettings;
  stepOneFormValues: StepOneFormValues;
  stepTwoFormValues: StepTwoFormValues;

  handleBack: () => void;
  handleNext: () => void;
  setAvailableSteps: (steps: number) => void;
  setActiveStep: (activeStep: number) => void;
  setSelectedVideoType: (selectedVideoType?: LiveVideoSettings) => void;
  setStepOneFormValues: (formValues: StepOneFormValues) => void;
  setStepTwoFormValues: (formValues: StepTwoFormValues) => void;
  setInternalUsers: (users: Array<InviteeUser>) => void;
  addInvitedUser: (user: InvitedUser) => void;
  removeInvitedUser: (id: string) => void;
  resetInviteUsersState: () => void;
  getInvitedUsers: () => Array<any>;
};

const state = proxy<InviteUsersState>({
  activeStep: 0,
  availableSteps: 0,
  internalUsers: [],
  invitedUsers: [],
  selectedVideoType: {},
  stepOneFormValues: {
    conversationType: 'chat',
    language: { label: '', value: '' },
  },

  stepTwoFormValues: {
    videoType: { label: '', value: '' },
    expMinutes: { label: '', value: '' },
    authCode: '',
  },

  handleBack: () => {
    state.activeStep--;
  },

  handleNext: () => {
    state.activeStep++;
  },

  setAvailableSteps: (steps: number) => {
    state.availableSteps = steps;
  },

  setActiveStep: (activeStep: number) => {
    state.activeStep = activeStep;
  },

  setSelectedVideoType: (videoType?: LiveVideoSettings) => {
    state.selectedVideoType = videoType ? videoType : {};
  },

  setStepOneFormValues: formValues => {
    state.stepOneFormValues = formValues;
  },

  setStepTwoFormValues: formValues => {
    state.stepTwoFormValues = formValues;
  },

  setInternalUsers: (users: InviteeUser[]) => {
    state.internalUsers = users;
  },

  addInvitedUser: (user: InvitedUser) => {
    if (!state.invitedUsers.some(currentUser => currentUser.id === user.id)) {
      state.invitedUsers.push(user);
    }
  },

  removeInvitedUser: (id: string) => {
    state.invitedUsers = state.invitedUsers.filter(user => user.id !== id);
  },

  resetInviteUsersState: () => {
    state.activeStep = 0;
    state.availableSteps = 0;
    state.internalUsers = [];
    state.invitedUsers = [];
    state.selectedVideoType = {};
    state.stepOneFormValues = {
      conversationType: 'chat',
      language: { label: '', value: '' },
    };
    state.stepTwoFormValues = {
      videoType: undefined,
      expMinutes: undefined,
      authCode: '',
    };
  },

  getInvitedUsers: () => {
    const myUsers: any[] = [];
    state.invitedUsers.forEach(user => {
      myUsers.push({
        channel: user.channel.value,
        name: user.displayName,
        phoneNumber: user.phoneNumber,
      });
    });
    return myUsers;
  },
});

export const useInviteUsersProxy = () => {
  return useSnapshot(state);
};
