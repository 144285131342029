import { isString } from 'lodash/fp';
import { ID_TOKEN_KEY } from '@/constants';

export const setLocalStorageItem = <TData extends {}>(key: string, data: TData) =>
  localStorage.setItem(key, JSON.stringify(data));

export const getLocalStorageItem = <TData extends {}>(key: string): TData | null => {
  try {
    const item = localStorage.getItem(key);

    if (!item) return null;
    if (isString(item)) return JSON.parse(item);

    return item;
  } catch (e) {
    return null;
  }
};

export const deleteLocalStorageItem = (key: string) => localStorage.removeItem(key);

export const clearLocalStorage = () => localStorage.clear();

export const setLocalIdToken = (token: string) =>
  setLocalStorageItem<string>(ID_TOKEN_KEY, token);

export const getLocalIdToken = () => getLocalStorageItem<string>(ID_TOKEN_KEY);

export const clearLocalIdToken = () => deleteLocalStorageItem(ID_TOKEN_KEY);
