import { proxy, useSnapshot } from 'valtio';
import { LiveVideoRecordingDetails } from '@/components/LiveVideoSettingsEditForm/types';

type RecordingDetailsState = {
  recordingDetails?: LiveVideoRecordingDetails;
  setRecordingDetails: (args?: LiveVideoRecordingDetails) => void;
};

const state = proxy<RecordingDetailsState>({
  recordingDetails: undefined,
  setRecordingDetails(recordingDetails) {
    state.recordingDetails = recordingDetails;
  },
});

export const useRecordingDetailsProxy = () => {
  return useSnapshot(state);
};
