import { Bot, BotPermissionCode, OrganisationRole } from '@/queries';
import { Channel } from '@/store/luv';

export type UserDepartment = {
  id: string;
  value: string;
};

export type UsersBotPermission = {
  code: BotPermissionCode;
  description: string;
};

export enum BotRoleCode {
  User = 'USER',
  Admin = 'ADMIN',
}

export type BotRole = {
  description?: string;
  code: BotRoleCode;
};

export interface UsersBot extends Bot {
  permissions: Array<UsersBotPermission>;
  botRole: BotRole;
}

export type PlatformUser = {
  bots: Array<UsersBot>;
  department: UserDepartment;
  displayName: string;
  email: string;
  firstName: string;
  id: string;
  jobTitle?: string;
  lastName: string;
  organisationId: string;
  organisationRole: OrganisationRole;
};

export type RemoteUser = {
  addedDate: string;
  botId: string;
  channel: Channel;
  createdOn: string;
  department: UserDepartment;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  mobilePhoneNo: string;
  displayName: string;
};

export type PlatformUsers = Record<string, PlatformUser>;
export type RemoteUsers = Record<string, RemoteUser>;

export type InvitePlatformUserRequest = Pick<
  PlatformUser,
  'email' | 'firstName' | 'lastName'
> & {
  orgId?: string | null;
  bots?: Array<Partial<Bot>>;
  organisationRole: Pick<OrganisationRole, 'code'>;
};

export type InviteRemoteUserRequest = Pick<RemoteUser, 'firstName' | 'lastName'> &
  Partial<Pick<RemoteUser, 'email' | 'mobilePhoneNo' | 'department'>> &
  RemoteUsersRequest;

export type RemoteUsersRequest = {
  orgId: string;
  botId: string;
};

export type RemoteUserBulkUploadRequest = RemoteUsersRequest & {
  files: FileList;
};

export type DeletePlatformUserRequest = {
  orgId: string;
  userId: string;
};

export type UpdatePlatformUserRequest = Pick<
  PlatformUser,
  'email' | 'firstName' | 'lastName'
> & {
  userId: string;
  orgId?: string;
  bots?: Array<Partial<Bot>>;
  organisationRole: Pick<OrganisationRole, 'code'>;
};
