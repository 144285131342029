import React, { useState, createContext } from 'react';
import { noop } from '@/utils';

type KnowledgeSearchContextProps = {
  searchFilterQuery: string;
  setSearchFilterQuery: (searchFilterQuery: string) => void;
};

// KnowledgeSearch context
export const KnowledgeSearchContext = createContext<KnowledgeSearchContextProps>({
  searchFilterQuery: '',
  setSearchFilterQuery: noop,
});

// KnowledgeSearch context provider
export const KnowledgeSearchContextProvider = ({ children }: { children?: any }) => {
  const [searchFilterQuery, setSearchFilterQuery] = useState<string>('');

  return (
    <KnowledgeSearchContext.Provider value={{ searchFilterQuery, setSearchFilterQuery }}>
      {children}
    </KnowledgeSearchContext.Provider>
  );
};
