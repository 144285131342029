import { createContext } from 'react';
import { ALGOLIA_API_KEY, ALGOLIA_APP_ID, DASHBOARD_TEST_DATA_DEFAULT } from '@/config';
import { Bot, BillingMethod, BotFeature, SurveysViewType, UserProfile } from '@/queries';
import { QuestionViewType } from '@/store/questions';
import { noop } from '@/utils';
import {
  AlgoliaConfigContext,
  LocaleContextProps,
  PaymentOptions,
  OrganisationContext,
} from './types';

// Organisation ID for current user
export const OrgContext = createContext<OrganisationContext>({
  orgId: undefined,
  setOrgId: noop,
});

// Current botId context
export const BotIdContext = createContext<string | null>(null);

// Current bot context
export const BotContext = createContext<Bot | null>(null);

// Current bot feature context
export const FeatureContext = createContext<BotFeature | null>(null);

// Algolia places context
export const AlgoliaContext = createContext<
  Pick<AlgoliaConfigContext, 'apiKey' | 'appId' | 'countries'>
>({
  appId: ALGOLIA_APP_ID,
  apiKey: ALGOLIA_API_KEY,
  countries: ['gb'],
});

// Available payment methods context
export const PaymentOptionsContext = createContext<PaymentOptions>([
  {
    icon: 'CreditCardOutlined',
    label: 'By card',
    method: BillingMethod.Card,
    enabled: true,
  },
  {
    icon: 'DescriptionOutlined',
    label: 'By invoice',
    method: BillingMethod.Invoice,
    enabled: true,
  },
]);

// Locale formatter context
export const LocaleContext = createContext<LocaleContextProps>({
  locale: 'en-GB',
  currency: 'GBP',
});

// Questions view context
export const QuestionsViewContext = createContext<QuestionViewType>('all');

// Surveys viuew context
export const SurveysViewContext = createContext<SurveysViewType>('all');

// User profile context
export const UserProfileContext = createContext<UserProfile | null | undefined>(
  undefined,
);

// Test data toggle context
export const TestDataContext = createContext<boolean>(DASHBOARD_TEST_DATA_DEFAULT);

export { AppDataProvider } from './app.context';
export { TickerProvider } from './ticker.context';
export * from './providers';
