import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { LIVE_VIDEO_SETTINGS_LIST_QUERY_KEY } from '../constants';
import { CreateLiveVideoSettingsRequest } from '../types';

export const useCreateLiveVideoSettings = () => {
  const { toast } = useToast();

  const createLiveVideoSettingsMutation = useMutation(
    ({ botId, payload }: CreateLiveVideoSettingsRequest) =>
      client.post<any>(`${API_HOST}/${ApiRoutes.Video}/bots/${botId}/settings`, payload),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to create settings.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { botId }) => {
        queryClient.invalidateQueries([LIVE_VIDEO_SETTINGS_LIST_QUERY_KEY, botId]);
        toast('Successfully created settings.', { variant: 'success' });
      },
    },
  );

  const {
    mutateAsync: createLiveVideoSettings,
    isLoading: isCreatingLiveVideoSettings,
    error,
  } = createLiveVideoSettingsMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isCreatingLiveVideoSettings, createLiveVideoSettings, serverErrors };
};
