import { BotChannel, BotChannelStatus, BotChannelType } from '@/queries';

export type TeamsChannelSettings = {
  byPerson: boolean;
  persons: Array<string>;
  byChannel: boolean;
  channels: Array<string>;
  byTeam: boolean;
  teams: Array<string>;
  fileId?: string;
};

export type SMSChannelSettings = {
  phoneNumber: number;
  fileId?: string;
};

export interface FacebookPage {
  id: string;
  name?: string;
  enabled?: boolean;
  authorizedAt?: string;
  authorizedBy?: {
    id: string;
    displayName: string;
  };
  openUrl?: string;
  accessToken?: string;
  fileId?: string;
  status?: 'ok' | 'error';
  statusMessage?: string;
}

export type MessengerChannelSettings = {
  connected: boolean;
  auth?: {
    userId: string;
    accessToken?: string;
  };
  pages: FacebookPage[];
  lastAuthUser?: {
    id: string;
    displayName: string;
  };
  fileId?: string;
  offlineMessage?: string;
};

export type WebChatChannelSettings = {
  mainColor: string;
  headerText?: string;
  userInputPlaceholder?: string;

  quickQuestions: boolean;
  banner: boolean;
  label: string;
  bigText: string;
  explainerText: string;

  allowedUrls: Array<string>;

  maxCharacterLimit: number;
  minCharacterLimit: number;
  characterTitle: string;
  characterText: string;
  maxCharacterLimitEnabled: boolean;
  fileId?: string;
};

export type ChannelSettings =
  | InstagramSettings
  | MessengerChannelSettings
  | SMSChannelSettings
  | TeamsChannelSettings
  | WebChatChannelSettings
  | WhatsAppSettings;

export enum WhatsAppApprovalStatus {
  APPROVED = 'APPROVED',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  SUBMITTED = 'SUBMITTED',
}

export type WhatsAppApprovalLogo = {
  filename: string;
  size: number;
  description: string;
  url: string;
};

export type WhatsAppApprovalForm = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  companyWebsite: string;
  fbBusinessManagerId: string;
  companySector: string;
  status?: WhatsAppApprovalStatus;
  logo?: WhatsAppApprovalLogo;
};

export type WhatsAppSettings = {
  enabled?: boolean;
  authorizeUsers?: boolean;
  unauthorizedMessage?: string;
  whatsAppNumber?: string;
  whatsAppNumberLink?: string;
  whatsAppNumberQRCode?: string;
  approvalForm?: WhatsAppApprovalForm;
  fileId?: string;
};

export interface InstagramAccount {
  id: string;
  username?: string;
  name?: string;
  pageName?: string;
  pageId?: string;
  enabled?: boolean;
  authorizedAt?: string;
  authorizedBy?: {
    id: string;
    displayName: string;
  };
  openUrl?: string;
  accessToken?: string;
  fileId?: string;
  status?: 'ok' | 'error';
  statusMessage?: string;
  profilePictureUrl?: string;
}

export type InstagramSettings = {
  connected: boolean;
  auth?: {
    userId: string;
    accessToken?: string;
  };
  accounts: InstagramAccount[];
  lastAuthUser?: {
    id: string;
    displayName: string;
  };
  fileId?: string;
  offlineMessage?: string;
};

export type UpdateChannelPayload = {
  url: string;
  id: string;
  settings: ChannelSettings;
};

export type ChannelRequest = {
  channel: BotChannel;
};

export type ChannelSettingsPayload = {
  status: string;
  settings: ChannelSettings;
};

export type UpdateChannelSettingsRequest = {
  id: string;
  url: string;
  settings: ChannelSettings;
};

export type Channel = {
  id: string;
  code: BotChannelType;
  name: string;
  description: string;
  iconUrl: string;
  managementApiUrl: string;
  configurationUrl: string;
  platformChannelId: string;
  status: BotChannelStatus;
};

export type ChannelSettingsResponse = {
  status: string;
  settings: ChannelSettings;
};
