import { Tooltip } from '@/components/core/Tooltip';
import { SelectValue } from '@/components/formElements/Select';
import { OrgContext } from '@/contexts';
import { orderBy } from 'lodash/fp';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { withOrg } from '@/hoc';
import { useGetOrganisations } from '@/queries';
import { OrganisationLocalStorageKeys, setLocalStorageItem } from '@/storage';
import {
  MoreIcon,
  StyledSelect,
  SwitcherOverlay,
  SwitcherOverlayHeader,
  CloseIcon,
  SwitcherSelectWrapper,
} from './OrganisationSwitch.styled';
import { OrganisationSwitchProps } from './types';

export const OrganisationSwitch: FunctionComponent<OrganisationSwitchProps> = ({
  collapsed,
  showOverlay,
  hideElement,
  canClose = true,
  switchOrg,
}) => {
  const { orgId, setOrgId } = useContext(OrgContext);
  const history = useHistory();
  const [overlayOpen, setOverlayOpen] = useState(showOverlay);

  const { organisations: orgs = [], isLoading } = useGetOrganisations();

  useEffect(() => {
    setOverlayOpen(showOverlay);
  }, [showOverlay]);

  const options = useMemo<Array<SelectValue>>(
    () =>
      orderBy(
        ['label'],
        ['asc'],
        orgs?.map(({ organisationName, id }) => ({ label: organisationName, value: id })),
      ) || [],
    [orgs],
  );

  const value = useMemo<SelectValue>(
    () => ({
      value: orgId || '',
      label: orgs?.find(org => org?.id === orgId)?.organisationName || '',
    }),
    [orgId, orgs],
  );

  const handleChange = useCallback(
    (selectValue: SelectValue) => {
      if (!selectValue?.value) return;
      setLocalStorageItem(OrganisationLocalStorageKeys.orgId, selectValue?.value);
      switchOrg(value?.value);
      setOrgId(selectValue?.value);
      setOverlayOpen(false);
      // Push the user back to the bots screen as they may be
      // inside a bot, causing a 404
      history.replace('/');
    },
    [setOrgId, history],
  );

  const handleOverlayOpen = useCallback(() => {
    setOverlayOpen(true);
  }, []);

  const handleOverlayClose = useCallback(() => {
    setOverlayOpen(false);
  }, []);

  const selectElement = (
    <StyledSelect
      isLoading={isLoading}
      options={options}
      value={value}
      disabled={isLoading}
      onChange={handleChange}
    />
  );

  return (
    <>
      {!hideElement &&
        (collapsed ? (
          <Tooltip title="Select organisation">
            <a onClick={handleOverlayOpen}>
              <MoreIcon />
            </a>
          </Tooltip>
        ) : (
          selectElement
        ))}
      {overlayOpen && (
        <SwitcherOverlay>
          {canClose && <CloseIcon onClick={handleOverlayClose} />}
          <SwitcherSelectWrapper>
            <SwitcherOverlayHeader variant="h1">
              Choose an organisation...
            </SwitcherOverlayHeader>
            {selectElement}
          </SwitcherSelectWrapper>
        </SwitcherOverlay>
      )}
    </>
  );
};

export default withOrg(OrganisationSwitch);
