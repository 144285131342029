import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { API_HOST } from '@/config';
import { useLogoutUser } from '@/hooks/useLogoutUser';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';

import { USER_PROFILE_QUERY_KEY } from '../constants';
import { UserProfile } from '../types';

const getUserProfile = () => {
  return () => {
    return client.get<UserProfile>(`${API_HOST}/${ApiRoutes.Core}/profile`);
  };
};

export const useGetUserProfile = () => {
  const { toast } = useToast();
  const { logoutIfForbidden } = useLogoutUser();

  const { data, isLoading } = useQuery(USER_PROFILE_QUERY_KEY, getUserProfile(), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onError: (errorResponse: AxiosError) => {
      toast(extractServerErrorMsg(errorResponse, 'Failed to get user profile'), {
        variant: 'error',
      });

      logoutIfForbidden(errorResponse);
    },
  });

  const userProfile = data?.data;
  return { userProfile, isLoading };
};
