import React, { useCallback } from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { useGoBack } from '@/hooks';

import {
  Description,
  IconWrapper,
  ParentContainer,
  StyledMainContentHeader,
  Title,
  TitleText,
} from './MainContentHeader.styled';
import { MainContentHeaderProps } from './types';
import { useHistory } from 'react-router-dom';

const MainContentHeader = ({
  Icon,
  title,
  back,
  goBackPath,
  description,
  handleIconClick,
  parent,
  secondaryTitle,
}: MainContentHeaderProps) => {
  const goBack = useGoBack();
  const history = useHistory();

  const handleBackClick = useCallback(() => {
    if (goBackPath) {
      return history.push(goBackPath);
    }

    return goBack();
  }, [goBack, goBackPath, history]);

  const handleIconOnClick = useCallback(e => handleIconClick && handleIconClick(e), [
    handleIconClick,
  ]);

  return (
    <StyledMainContentHeader>
      {parent && <ParentContainer>{parent} »</ParentContainer>}
      <Title>
        {Icon && (
          <IconWrapper
            back={back}
            onClick={back ? handleBackClick : handleIconOnClick}
            title={back ? 'Go back' : undefined}
          >
            {Icon}
          </IconWrapper>
        )}
        <Box display="flex" flexDirection="row" alignItems="center">
          <TitleText variant="h1">{title}</TitleText>
          {secondaryTitle}
        </Box>
      </Title>
      {description && (
        <Grid item xs={12} md={9}>
          <Description>
            <Typography variant="body2" component="div">
              {description}
            </Typography>
          </Description>
        </Grid>
      )}
    </StyledMainContentHeader>
  );
};

export default MainContentHeader;
