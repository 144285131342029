import styled, { css } from 'styled-components';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import MuiIconButton from '@material-ui/core/IconButton';
import Button from '@/components/formElements/Button';

export const Alert = styled(MuiAlert)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const GridContainer = styled.div`
  width: 100%;
`;

export const ButtonContainer = styled(Grid)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(2)};
    ${theme.breakpoints.up('md')} {
      padding-top: ${theme.spacing(3)};
    }
  `,
);

export const SignUpButton = styled(Button)`
  && {
    text-transform: inherit;
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;

export const IconButton = styled(MuiIconButton)<{ showPassword: boolean }>`
  path {
    fill: ${({ theme, showPassword }) =>
      showPassword ? theme.palette.primary.main : theme.palette.grey[400]};
  }
`;
