import { NotFoundPage, FeaturesPage } from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';
import announcements from './announcements';
import channels from './channels';
import dashboard from './dashboard';
import knowledgeSearch from './knowledgeSearch';
import liveChat from './liveChat';
import questions from './questions';
import settings from './settings';
import surveys from './surveys';
import transcripts from './transcripts';
import webSearch from './webSearch';

const bot: RoutingConfig = [
  {
    component: NotFoundPage,
    path: '/',
    exact: true,
  },
  ...questions,
  ...settings,
  ...dashboard,
  ...knowledgeSearch,
  ...channels,
  ...surveys,
  ...liveChat,
  ...announcements,
  ...transcripts,
  ...webSearch,
  {
    title: 'Surveys',
    component: NotFoundPage,
    path: '/surveys',
  },
  {
    title: 'Chat Admin',
    component: NotFoundPage,
    path: '/knowledge-search',
  },
  {
    title: 'Live Chat',
    component: NotFoundPage,
    path: '/live-chat',
  },
  {
    title: 'Chat Admin',
    component: NotFoundPage,
    path: '/chat-admin',
  },
  {
    title: 'Billing',
    component: NotFoundPage,
    path: '/billing',
  },
  {
    title: 'Features',
    path: '/features',
    exact: true,
    component: FeaturesPage,
    customProps: {
      permissionArea: BotPermissionCode.BOT_FEATURES_ADMIN,
    },
  },
  {
    title: 'Forms',
    path: '/forms',
    exact: true,
    component: NotFoundPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:INTEGRATE', 'INSIDE:INTEGRATE'],
      permissionArea: BotPermissionCode.FORMS_ADMIN,
    },
  },
];

export default bot;
