import { connect } from 'react-redux';
import { AppState } from '@/store';
import {
  getQuestions,
  getQuestionsStatuses,
  fetchQuestions,
  setFeaturedQuestion,
  featuredOrderChanged,
  createQuestion,
  getActiveQuestionsCount,
  getDraftQuestionsCount,
  questionsBulkUpload,
  getAllQuestionsCount,
  getFeaturedQuestionsCount,
  deleteQuestion,
  setSearchQuery,
  getQuestionsSearchQuery,
} from '@/store/questions';

const createMapStateToProps = <_TProps extends Record<string, unknown>>() => (
  state: AppState,
) => ({
  questions: getQuestions(state),
  questionsStatuses: getQuestionsStatuses(state),
  allQuestionsCount: getAllQuestionsCount(state),
  activeQuestionsCount: getActiveQuestionsCount(state),
  draftQuestionsCount: getDraftQuestionsCount(state),
  featuredQuestionsCount: getFeaturedQuestionsCount(state),
  questionsSearchQuery: getQuestionsSearchQuery(state),
});

const mapDispatchToProps = {
  createQuestion: createQuestion.request,
  featuredOrderChanged: featuredOrderChanged.request,
  getAllQuestions: fetchQuestions.request,
  setFeaturedQuestion: setFeaturedQuestion.request,
  deleteQuestion: deleteQuestion.request,
  questionsBulkUpload: questionsBulkUpload.request,
  setSearchQuery: setSearchQuery,
};

const createConnect = <TProps extends Record<string, unknown>>() =>
  connect(createMapStateToProps<TProps>(), mapDispatchToProps);

export default createConnect;

export type WithQuestionsProps = ReturnType<ReturnType<typeof createMapStateToProps>> &
  typeof mapDispatchToProps;
