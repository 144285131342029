import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { AppThemeProviderProps } from './types';

export const AppThemeProvider: FunctionComponent<AppThemeProviderProps> = ({
  theme,
  children,
}) => (
  <ThemeProvider theme={theme}>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </ThemeProvider>
);

export default AppThemeProvider;
