import { OrganisationRole, UsersBot } from '@/queries';

export enum UserLocalStorageKeys {
  Profile = 'AUTH_USER_PROFILE',
}

export enum PlatformRoleCode {
  SuperAdmin = 'SUPERADMIN',

  FirstTime = 'FIRSTTIME',
  User = 'USER',
}

export type UserProfile = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;
  jobTitle: string | null;
  department: { id: string; value: string } | null;
  bots: UsersBot[];
  organisationId: string | null;
  organisationRole: OrganisationRole | null;
  profilePhotoUrl: string | null;
  platformRole: {
    description: string;
    code: PlatformRoleCode;
  } | null;
  token?: string;
};

export type User = {
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  at_hash: string;
  nonce: string;
};

export type LoginUserRequest = {
  email: string;
  password: string;
};

export type LoginUserResponse = {
  access_token: string;
};

export type UserCreateRequest = {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  invitation?: string;
  token?: string;
};

export interface UserCreateError {
  statusCode: number;
  error: string;
  message: string;
  errors: Errors[];
}

export interface Errors {
  location: string;
  msg: string;
  param: string;
}

export type ForgotPasswordPayload = Pick<LoginUserRequest, 'email'>;

export type UpdateUserProfileRequest = Partial<UserProfile | undefined> & {
  logoFile?: File;
  department: { value: string; label: string };
};

export type ChangePasswordRequest = {
  oldPassword: string;
  newPassword: string;
};
