import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  MessagesPayload,
  Messages,
  WebsocketConnectPayload,
  WebsocketConnectedClientUpdated,
  WebsocketEvent,
  WebsocketMessage,
  WebsocketTyping,
  WebsocketUpdateMessage,
  WebsocketError,
  EndedPayload,
  EndedResponse,
  UnreadMessagePayload,
  UpdateUserPayload,
  GetUserPayload,
  UserResponse,
  GetConversationTagsPayload,
  ConversationTagsResponse,
  UpdateConversationTagsPayload,
  GetUsersPayload,
  GetUsersResponse,
  WebsocketTransferAgent,
  WebsocketSendTyping,
} from './types';

export const LIVE_CHAT_GET_MESSAGES = 'LIVE_CHAT_GET_MESSAGES';
export const LIVE_CHAT_GET_MESSAGES_INITIATED = 'LIVE_CHAT_GET_MESSAGES_INITIATED';
export const LIVE_CHAT_GET_MESSAGES_SUCCESS = 'LIVE_CHAT_GET_MESSAGES_SUCCESS';
export const LIVE_CHAT_GET_MESSAGES_FAILED = 'LIVE_CHAT_GET_MESSAGES_FAILED';

export const LIVE_CHAT_GET_ENDED_INITIATED = 'LIVE_CHAT_GET_ENDED_INITIATED';
export const LIVE_CHAT_GET_ENDED_SUCCESS = 'LIVE_CHAT_GET_ENDED_SUCCESS';
export const LIVE_CHAT_GET_ENDED_FAILED = 'LIVE_CHAT_GET_ENDED_FAILED';

export const LIVE_CHAT_WEBSOCKET_CONNECT = 'LIVE_CHAT_WEBSOCKET::CONNECT';
export const LIVE_CHAT_WEBSOCKET_EVENT = 'LIVE_CHAT_WEBSOCKET::EVENT';
export const LIVE_CHAT_WEBSOCKET_MESSAGE = 'LIVE_CHAT_WEBSOCKET::MESSAGE';
export const LIVE_CHAT_WEBSOCKET_UPDATE_MESSAGE = 'LIVE_CHAT_WEBSOCKET::UPDATE_MESSAGE';
export const LIVE_CHAT_WEBSOCKET_CONNECTED_CLIENT_UPDATED =
  'LIVE_CHAT_WEBSOCKET::CONNECTED_CLIENT_UPDATED';

export const LIVE_CHAT_GET_GENERAL_SETTINGS = 'LIVE_CHAT_GET_GENERAL_SETTINGS';
export const LIVE_CHAT_GET_GENERAL_SETTINGS_INITIATED =
  'LIVE_CHAT_GET_GENERAL_SETTINGS_INITIATED';
export const LIVE_CHAT_GET_GENERAL_SETTINGS_SUCCESS =
  'LIVE_CHAT_GET_GENERAL_SETTINGS_SUCCESS';
export const LIVE_CHAT_GET_GENERAL_SETTINGS_FAILED =
  'LIVE_CHAT_GET_GENERAL_SETTINGS_FAILED';

export const LIVE_CHAT_UPDATE_GENERAL_SETTINGS = 'LIVE_CHAT_UPDATE_GENERAL_SETTINGS';
export const LIVE_CHAT_UPDATE_GENERAL_SETTINGS_INITIATED =
  'LIVE_CHAT_UPDATE_GENERAL_SETTINGS_INITIATED';
export const LIVE_CHAT_UPDATE_GENERAL_SETTINGS_SUCCESS =
  'LIVE_CHAT_UPDATE_GENERAL_SETTINGS_SUCCESS';
export const LIVE_CHAT_UPDATE_GENERAL_SETTINGS_FAILED =
  'LIVE_CHAT_UPDATE_GENERAL_SETTINGS_FAILED';

export const LIVE_CHAT_GET_USER_BLOCKING_SETTINGS =
  'LIVE_CHAT_GET_USER_BLOCKING_SETTINGS';
export const LIVE_CHAT_GET_USER_BLOCKING_SETTINGS_INITIATED =
  'LIVE_CHAT_GET_USER_BLOCKING_SETTINGS_INITIATED';
export const LIVE_CHAT_GET_USER_BLOCKING_SETTINGS_SUCCESS =
  'LIVE_CHAT_GET_USER_BLOCKING_SETTINGS_SUCCESS';
export const LIVE_CHAT_GET_USER_BLOCKING_SETTINGS_FAILED =
  'LIVE_CHAT_GET_USER_BLOCKING_SETTINGS_FAILED';

export const LIVE_CHAT_UPDATE_USER_BLOCKING_SETTINGS =
  'LIVE_CHAT_UPDATE_USER_BLOCKING_SETTINGS';
export const LIVE_CHAT_UPDATE_USER_BLOCKING_SETTINGS_INITIATED =
  'LIVE_CHAT_UPDATE_USER_BLOCKING_SETTINGS_INITIATED';
export const LIVE_CHAT_UPDATE_USER_BLOCKING_SETTINGS_SUCCESS =
  'LIVE_CHAT_UPDATE_USER_BLOCKING_SETTINGS_SUCCESS';
export const LIVE_CHAT_UPDATE_USER_BLOCKING_SETTINGS_FAILED =
  'LIVE_CHAT_UPDATE_USER_BLOCKING_SETTINGS_FAILED';

export const LIVE_CHAT_GET_CONVERSATION_TAGS = 'LIVE_CHAT_GET_CONVERSATION_TAGS';
export const LIVE_CHAT_GET_CONVERSATION_TAGS_INITIATED =
  'LIVE_CHAT_GET_CONVERSATION_TAGS_INITIATED';
export const LIVE_CHAT_GET_CONVERSATION_TAGS_SUCCESS =
  'LIVE_CHAT_GET_CONVERSATION_TAGS_SUCCESS';
export const LIVE_CHAT_GET_CONVERSATION_TAGS_FAILED =
  'LIVE_CHAT_GET_CONVERSATION_TAGS_FAILED';

export const LIVE_CHAT_UPDATE_CONVERSATION_TAGS = 'LIVE_CHAT_UPDATE_CONVERSATION_TAGS';
export const LIVE_CHAT_UPDATE_CONVERSATION_TAGS_INITIATED =
  'LIVE_CHAT_UPDATE_CONVERSATION_TAGS_INITIATED';
export const LIVE_CHAT_UPDATE_CONVERSATION_TAGS_SUCCESS =
  'LIVE_CHAT_UPDATE_CONVERSATION_TAGS_SUCCESS';
export const LIVE_CHAT_UPDATE_CONVERSATION_TAGS_FAILED =
  'LIVE_CHAT_UPDATE_CONVERSATION_TAGS_FAILED';

export const LIVE_CHAT_WEBSOCKET_SEND_MESSAGE = 'LIVE_CHAT_WEBSOCKET::SEND_MESSAGE';
export const LIVE_CHAT_WEBSOCKET_SEND_TYPING = 'LIVE_CHAT_WEBSOCKET::SEND_TYPING';
export const LIVE_CHAT_WEBSOCKET_MESSAGE_RECEIPT = 'LIVE_CHAT_WEBSOCKET::MESSAGE_RECEIPT';
export const LIVE_CHAT_WEBSOCKET_TYPING_START = 'LIVE_CHAT_WEBSOCKET::TYPING_START';
export const LIVE_CHAT_WEBSOCKET_TYPING_END = 'LIVE_CHAT_WEBSOCKET::TYPING_END';
export const LIVE_CHAT_WEBSOCKET_TRANSFER_AGENT = 'LIVE_CHAT_WEBSOCKET::TRANSFER_AGENT';
export const LIVE_CHAT_WEBSOCKET_ERROR = 'LIVE_CHAT_WEBSOCKET::ERROR';
export const LIVE_CHAT_UNREAD_MESSAGES = 'LIVE_CHAT_UNREAD_MESSAGES';

export const LIVE_CHAT_UPDATE_USER = 'LIVE_CHAT_UPDATE_USER';
export const LIVE_CHAT_UPDATE_USER_INITIATED = 'LIVE_CHAT_UPDATE_USER_INITIATED';
export const LIVE_CHAT_UPDATE_USER_SUCCESS = 'LIVE_CHAT_UPDATE_USER_SUCCESS';
export const LIVE_CHAT_UPDATE_USER_FAILED = 'LIVE_CHAT_UPDATE_USER_FAILED';

export const LIVE_CHAT_GET_USER = 'LIVE_CHAT_GET_USER';
export const LIVE_CHAT_GET_USER_INITIATED = 'LIVE_CHAT_GET_USER_INITIATED';
export const LIVE_CHAT_GET_USER_SUCCESS = 'LIVE_CHAT_GET_USER_SUCCESS';
export const LIVE_CHAT_GET_USER_FAILED = 'LIVE_CHAT_GET_USER_FAILED';

export const LIVE_CHAT_GET_USERS = 'LIVE_CHAT_GET_USERS';
export const LIVE_CHAT_GET_USERS_INITIATED = 'LIVE_CHAT_GET_USERS_INITIATED';
export const LIVE_CHAT_GET_USERS_SUCCESS = 'LIVE_CHAT_GET_USERS_SUCCESS';
export const LIVE_CHAT_GET_USERS_FAILED = 'LIVE_CHAT_GET_USERS_FAILED';

export const getMessages = createAsyncAction(
  LIVE_CHAT_GET_MESSAGES_INITIATED,
  LIVE_CHAT_GET_MESSAGES_SUCCESS,
  LIVE_CHAT_GET_MESSAGES_FAILED,
)<MessagesPayload, Messages, Error | void>();

export const getConversationTags = createAsyncAction(
  LIVE_CHAT_GET_CONVERSATION_TAGS_INITIATED,
  LIVE_CHAT_GET_CONVERSATION_TAGS_SUCCESS,
  LIVE_CHAT_GET_CONVERSATION_TAGS_FAILED,
)<GetConversationTagsPayload, ConversationTagsResponse, Error | void>();

export const updateConversationTags = createAsyncAction(
  LIVE_CHAT_UPDATE_CONVERSATION_TAGS_INITIATED,
  LIVE_CHAT_UPDATE_CONVERSATION_TAGS_SUCCESS,
  LIVE_CHAT_UPDATE_CONVERSATION_TAGS_FAILED,
)<UpdateConversationTagsPayload, ConversationTagsResponse, Error | void>();

export const getEnded = createAsyncAction(
  LIVE_CHAT_GET_ENDED_INITIATED,
  LIVE_CHAT_GET_ENDED_SUCCESS,
  LIVE_CHAT_GET_ENDED_FAILED,
)<EndedPayload, EndedResponse, Error | void>();

export const updateUser = createAsyncAction(
  LIVE_CHAT_UPDATE_USER_INITIATED,
  LIVE_CHAT_UPDATE_USER_SUCCESS,
  LIVE_CHAT_UPDATE_USER_FAILED,
)<UpdateUserPayload, UserResponse, Error | void>();

export const getUser = createAsyncAction(
  LIVE_CHAT_GET_USER_INITIATED,
  LIVE_CHAT_GET_USER_SUCCESS,
  LIVE_CHAT_GET_USER_FAILED,
)<GetUserPayload, UserResponse, Error | void>();

export const getUsers = createAsyncAction(
  LIVE_CHAT_GET_USERS_INITIATED,
  LIVE_CHAT_GET_USERS_SUCCESS,
  LIVE_CHAT_GET_USERS_FAILED,
)<GetUsersPayload, GetUsersResponse, Error | void>();

export const websocketConnect = createAction(LIVE_CHAT_WEBSOCKET_CONNECT)<
  WebsocketConnectPayload
>();
export const websocketConnectedClientUpdated = createAction(
  LIVE_CHAT_WEBSOCKET_CONNECTED_CLIENT_UPDATED,
)<WebsocketConnectedClientUpdated>();
export const websocketEvent = createAction(LIVE_CHAT_WEBSOCKET_EVENT)<WebsocketEvent>();
export const websocketMessage = createAction(LIVE_CHAT_WEBSOCKET_MESSAGE)<
  WebsocketMessage
>();
export const websocketSendMessage = createAction(LIVE_CHAT_WEBSOCKET_SEND_MESSAGE)<
  WebsocketMessage
>();
export const websocketSendTyping = createAction(LIVE_CHAT_WEBSOCKET_SEND_TYPING)<
  WebsocketSendTyping
>();
export const websocketTypingStart = createAction(LIVE_CHAT_WEBSOCKET_TYPING_START)<
  WebsocketTyping
>();
export const websocketMessageReceipt = createAction(LIVE_CHAT_WEBSOCKET_MESSAGE_RECEIPT)<
  WebsocketMessage
>();
export const websocketTypingEnd = createAction(LIVE_CHAT_WEBSOCKET_TYPING_END)<
  WebsocketTyping
>();
export const websocketTransferAgent = createAction(LIVE_CHAT_WEBSOCKET_TRANSFER_AGENT)<
  WebsocketTransferAgent
>();
export const websocketUpdateMessage = createAction(LIVE_CHAT_WEBSOCKET_UPDATE_MESSAGE)<
  WebsocketUpdateMessage
>();
export const websocketError = createAction(LIVE_CHAT_WEBSOCKET_ERROR)<WebsocketError>();

export const updateUnreadMessages = createAction(LIVE_CHAT_UNREAD_MESSAGES)<
  UnreadMessagePayload
>();
