import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { Bot } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { AddRemoteUser } from '../types';

export const useAddRemoteUser = () => {
  const { toast } = useToast();

  const addRemoteUserMutation = useMutation(
    ({ orgId, botId, ...data }: AddRemoteUser) => {
      // https://dev.azure.com/futrplatform/Futr/_wiki/wikis/Futr%20Platform/21/Web-API?anchor=bot-remote-users
      return client.post<Bot>(
        `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/bots/${botId}/remoteusers`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError, data) => {
        toast(
          extractServerErrorMsg(
            errorResponse,
            `Failed to invite ${data?.firstName} ${data?.lastName}`,
          ),
          {
            variant: 'error',
          },
        );
      },
      onSuccess: () => {
        toast('Remote user invited.', {
          variant: 'success',
        });
      },
    },
  );
  const { mutateAsync: inviteRemoteUser, isLoading, error } = addRemoteUserMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, inviteRemoteUser };
};
