import { connect } from 'react-redux';
import { AppState } from '@/store';
import {
  getLivechatMessages,
  getMessages,
  getWebsockets,
  websocketConnect,
  getConnections,
  getUnassignedConnections,
  getAssignedConnections,
  getTyping,
  getEnded,
  getEndedData,
  SendMessagePayload,
  SendTypingPayload,
  updateUnreadMessages,
  getUnreadMessages,
  getLiveChatStatuses,
  updateUser,
  getUser,
  getUsersData,
  getConversationTags,
  getConversationTagData,
  updateConversationTags,
  getUsers,
  getActiveAgents,
} from '@/store/liveChat';
import {
  sendMessage,
  sendTyping,
  acceptLiveAgentRequest,
  agentEndChat,
  transferAgent,
} from '@/store/middleware/websocket';
import {
  AcceptLiveAgentRequestPayload,
  AgentEndChatPayload,
  TransferAgentPayload,
} from '@/store/middleware/websocket/types';

const mapStateToProps = (state: AppState) => ({
  livechatMessages: getLivechatMessages(state),
  websockets: getWebsockets(state),
  connections: getConnections(state),
  unassignedConnections: getUnassignedConnections(state),
  assignedConnections: getAssignedConnections(state),
  typing: getTyping(state),
  ended: getEndedData(state),
  unreadMessages: getUnreadMessages(state),
  liveChatStatuses: getLiveChatStatuses(state),
  usersData: getUsersData(state),
  conversationTags: getConversationTagData(state),
  activeAgents: getActiveAgents(state),
});

const mapDispatchToProps = {
  getMessages: getMessages.request,
  getEnded: getEnded.request,
  connect: websocketConnect,
  sendMessage: (data: SendMessagePayload) => sendMessage(data, 'LIVE_CHAT_WEBSOCKET'),
  acceptConnection: (data: AcceptLiveAgentRequestPayload) =>
    acceptLiveAgentRequest(data, 'LIVE_CHAT_WEBSOCKET'),
  endConnection: (data: AgentEndChatPayload) => agentEndChat(data, 'LIVE_CHAT_WEBSOCKET'),
  transferAgent: (data: TransferAgentPayload) =>
    transferAgent(data, 'LIVE_CHAT_WEBSOCKET'),
  sendTyping: (data: SendTypingPayload) => sendTyping(data, 'LIVE_CHAT_WEBSOCKET'),
  updateUnreadMessages: updateUnreadMessages,
  updateUser: updateUser.request,
  getUser: getUser.request,
  getUsers: getUsers.request,
  getConversationTags: getConversationTags.request,
  updateConversationTags: updateConversationTags.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type WithLiveChatProps = Partial<
  ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps
>;
