import React, { FunctionComponent, useContext } from 'react';
import Helmet from 'react-helmet';
import AsyncComponent from '@/components/AsyncComponent';
import Icon from '@/components/Icon';
import MainContentHeader from '@/components/pageLayout/MainContentHeader';
import { PageContent, PageHeader, PageNavigation, StyledPage } from './Page.styled';
import { PageProps } from './types';
import { BotContext } from '@/contexts';

export const Page: FunctionComponent<PageProps> = ({
  children,
  description,
  handleIconClick,
  icon,
  secondaryNav,
  secondaryContent,
  title,
  parent,
  goBackPath,
  secondaryTitle,
}) => {
  const context = useContext(BotContext);

  function getLiveChatTitle() {
    const insideMessage = 'Live chat to your staff and resolve issues';
    const outsideMessage = 'Live chat to your users and resolve issues';
    if (context) {
      return context.type.code === 'INSIDE' ? insideMessage : outsideMessage;
    }

    return insideMessage;
  }

  return (
    <StyledPage>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      {(title || secondaryContent) && (
        <PageHeader>
          {title && (
            <MainContentHeader
              back={icon === 'ChevronIcon'}
              goBackPath={goBackPath}
              Icon={icon ? <Icon type={icon} color="primary" /> : undefined}
              description={title === 'Live Chat' ? getLiveChatTitle() : description}
              handleIconClick={handleIconClick}
              title={title}
              parent={parent}
              secondaryTitle={secondaryTitle}
            />
          )}
          {secondaryContent}
        </PageHeader>
      )}
      {secondaryNav && <PageNavigation>{secondaryNav}</PageNavigation>}
      <PageContent>
        <AsyncComponent>{children}</AsyncComponent>
      </PageContent>
    </StyledPage>
  );
};

export default Page;
