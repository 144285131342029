import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { DOCUMENT_QUERY_KEY } from '../constants';
import { KnowledgeSearchDocument, GetDocumentRequest } from '../types';

const getDocument = ({ featureId, documentId }: GetDocumentRequest) => {
  return () => {
    return client.get<KnowledgeSearchDocument>(
      `${API_HOST}/${ApiRoutes.Search}/${featureId}/documents/${documentId}`,
    );
  };
};

export const useGetDocument = ({ featureId, documentId }: GetDocumentRequest) => {
  const { toast } = useToast();

  const { data, isLoading: isFetchingDocument } = useQuery(
    [DOCUMENT_QUERY_KEY, documentId],
    getDocument({ featureId, documentId }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to retrieve document.'), {
          variant: 'error',
        });
      },
    },
  );

  const document = data?.data;
  return { document, isFetchingDocument };
};
