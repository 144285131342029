import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { DOCUMENTS_QUERY_KEY } from '../constants';
import { CreateOrUpdateDocumentRequest, KnowledgeSearchDocument } from '../types';

export const useCreateDocument = () => {
  const { toast } = useToast();

  const createDocumentMutation = useMutation(
    ({ featureId, ...data }: CreateOrUpdateDocumentRequest) => {
      return client.post<KnowledgeSearchDocument>(
        `${API_HOST}/${ApiRoutes.Search}/${featureId}/documents`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, `Failed to add document.`), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([DOCUMENTS_QUERY_KEY, featureId]);
        toast('Successfully created document.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: createDocument, isLoading, error } = createDocumentMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, createDocument };
};
