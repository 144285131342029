import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { queryClient } from '@/reactQueryClient';
import { extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { REMOTE_USERS_QUERY_KEY } from '../constants';
import { RemoteUserRequest } from '../types';

export const useRemoveRemoteUser = () => {
  const USERS_API_URL = `${API_HOST}/${ApiRoutes.Core}/organisations`;
  const { toast } = useToast();

  const removeRemoteUserMutation = useMutation(
    ({ orgId, id, botId }: RemoteUserRequest) =>
      client.delete(`${USERS_API_URL}/${orgId}/bots/${botId}/remoteusers/${id}`),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to remove remote user'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(REMOTE_USERS_QUERY_KEY);
        toast('Successfully removed user.', { variant: 'success' });
      },
    },
  );

  const {
    mutateAsync: removeRemoteUser,
    isLoading: isRemovingRemoteUser,
  } = removeRemoteUserMutation;

  return { isRemovingRemoteUser, removeRemoteUser };
};
