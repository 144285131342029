export const DEFAULT_PREFIX = 'REDUX_WEBSOCKET';

export const WEBSOCKET_ERROR = 'ERROR';
export const WEBSOCKET_CLOSED = 'CLOSED';
export const WEBSOCKET_EVENT = 'EVENT';
export const WEBSOCKET_MESSAGE = 'MESSAGE';
export const WEBSOCKET_MESSAGE_RECEIPT = 'MESSAGE_RECEIPT';
export const WEBSOCKET_TYPING_START = 'TYPING_START';
export const WEBSOCKET_TYPING_END = 'TYPING_END';
export const WEBSOCKET_UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const WEBSOCKET_OPEN = 'OPEN';
export const WEBSOCKET_CONNECTED_CLIENT_UPDATED = 'CONNECTED_CLIENT_UPDATED';
export const WEBSOCKET_ACCEPT_LIVE_AGENT_REQUEST = 'ACCEPT_LIVE_AGENT_REQUEST';
export const WEBSOCKET_AGENT_END_CHAT = 'AGENT_END_CHAT';
export const WEBSOCKET_TRANSFER_AGENT = 'TRANSFER_AGENT';

export const WEBSOCKET_CONNECT = 'CONNECT';
export const WEBSOCKET_DISCONNECT = 'DISCONNECT';
export const WEBSOCKET_SEND_MESSAGE = 'SEND_MESSAGE';
export const WEBSOCKET_SEND_TYPING = 'SEND_TYPING';
