import React, { useMemo, FunctionComponent, useEffect } from 'react';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import { createTheme } from '@/styles/theme';
import Circle from '@/components/shapes/Circle';
import Curve from '@/components/shapes/Curve';
import LoyaltyFaceUpper from '@/components/shapes/LoyaltyFaceUpper';
import Rectangle from '@/components/shapes/Rectangle';
import { GlobalStyle } from '@/App.styled';
import Icon from '@/components/Icon';
import {
  OuterContainer,
  Content,
  CircleContainer,
  CurveContainer,
  RectangleContainer,
  LoyaltyFaceUpperContainer,
  LogoContainer,
} from './MinimalLayout.styled';

const MinimalLayout: FunctionComponent = ({ children }) => {
  const theme = useMemo(() => createTheme(), []);

  useEffect(() => {
    const popupRoot = document.getElementById('chatamo-embeddable-chat-root');

    if (popupRoot) {
      popupRoot.style.visibility = 'hidden';
    }

    return () => {
      if (popupRoot) {
        popupRoot.style.visibility = 'visible';
      }
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <OuterContainer>
        <LogoContainer>
          <Icon type="FutrLogo" />
        </LogoContainer>
        <CircleContainer>
          <Circle color={green[900]} />
        </CircleContainer>
        <CurveContainer>
          <Curve color={orange[400]} />
        </CurveContainer>
        <RectangleContainer>
          <Rectangle color="#c8d5ca" />
        </RectangleContainer>
        <LoyaltyFaceUpperContainer>
          <LoyaltyFaceUpper color={theme?.colors?.primary || ''} />
        </LoyaltyFaceUpperContainer>
        <Content>{children}</Content>
      </OuterContainer>
    </>
  );
};

export default MinimalLayout;
