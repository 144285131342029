import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, SURVEY_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { Survey } from '../types';

const getSurvey = (featureId: string | null, surveyId: string | null) => {
  return () => {
    return client.get<Survey>(
      `${API_HOST}/${ApiRoutes.Surveys}/${featureId}/surveys/${surveyId}`,
    );
  };
};

export const useGetSurvey = (featureId: string | null, surveyId: string | null) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [SURVEY_QUERY_KEY, featureId, surveyId],
    getSurvey(featureId, surveyId),
    {
      enabled: !!featureId && !!surveyId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        toast(extractServerErrorMsg(error, 'Failed to get survey.'), {
          variant: 'error',
        });
      },
    },
  );

  const survey = data?.data || {};
  return { survey, isLoading };
};

export const invalidateGetSurveyQuery = (featureId: string, surveyId?: string) => {
  queryClient.invalidateQueries([SURVEY_QUERY_KEY, featureId, surveyId]);
};
