import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import { DuplicateRequest, Announcement } from '../types';
import { ANNOUNCEMENTS_QUERY_KEY, ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import { AxiosError } from 'axios';

export const useDuplicateAnnouncement = () => {
  const { toast } = useToast();

  const duplicateAnnouncementMutation = useMutation(
    ({ featureId, announcementId }: DuplicateRequest) => {
      return client.post<Announcement>(
        `${API_HOST}/${ApiRoutes.Announcements}/${featureId}/announcements/${announcementId}/duplicate`,
        {},
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to duplicate announcement.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([ANNOUNCEMENTS_QUERY_KEY, featureId]);
        toast('Announcement duplicate successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: duplicateAnnouncement,
    isLoading,
    error,
  } = duplicateAnnouncementMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, duplicateAnnouncement };
};
