import { call, put, takeLatest, all } from 'typed-redux-saga';
import { equals } from 'lodash/fp';
import {
  createBubble,
  updateBubble,
  deleteBubble,
  getBubbles,
  saveBubbles,
  reorderBubbles,
} from './actions';
import api from './api';
import { enqueueToast } from '@/store/toast';
import { MessageTypes, CreateBubbleRequest } from './types';

const isImage = equals('IMAGE' as MessageTypes);
const isAttachment = equals('ATTACHMENT' as MessageTypes);

const handleAnswerUpload = async (payload: CreateBubbleRequest) => {
  const { botId, messagesId, bubble } = payload;
  const newPayload = payload.bubble;

  if (!botId) throw new Error('No Bot ID provided');
  if (!messagesId) throw new Error('No Messages ID provided');

  if (isImage(bubble.type) && bubble?.fileData) {
    const response = await api.uploadImage(botId, messagesId, bubble?.fileData);
    if (response?.fileId) newPayload.fileId = response.fileId;
    delete newPayload.file;
  }
  if (isAttachment(bubble.type) && bubble?.fileData) {
    const response = await api.uploadattachment(botId, messagesId, bubble?.fileData);
    if (response?.fileId) newPayload.fileId = response.fileId;
    delete newPayload.file;
  }

  return newPayload;
};

export function* createBubbleSaga({ payload }: ReturnType<typeof createBubble.request>) {
  const { botId, messagesId } = payload;

  try {
    const body = yield* call(() => handleAnswerUpload(payload));

    const response = yield* call(() => api.createBubble(botId, messagesId, body));

    yield put(createBubble.success({ messagesId, data: response.data }));

    if (payload?.onComplete) {
      payload?.onComplete();
    }

    yield put(
      enqueueToast({
        message: 'Bubble created',
        options: { variant: 'success' },
      }),
    );
  } catch (e) {
    yield put(createBubble.failure(e));
    yield put(
      enqueueToast({
        message: 'Bubble creation failed',
        options: { variant: 'error' },
      }),
    );
  }
}

export function* updateBubbleSaga({ payload }: ReturnType<typeof updateBubble.request>) {
  const { botId, messagesId, bubbleId } = payload;
  try {
    const body = yield* call(() => handleAnswerUpload(payload));

    const response = yield* call(() =>
      api.updateBubble(botId, messagesId, bubbleId, body),
    );

    yield put(updateBubble.success({ messagesId, data: response.data }));

    if (payload?.onComplete) {
      payload?.onComplete();
    }

    yield put(
      enqueueToast({
        message: 'Bubble updated',
        options: { variant: 'success' },
      }),
    );
  } catch (e) {
    yield put(updateBubble.failure(e));
    yield put(
      enqueueToast({
        message: 'Bubble failed to update',
        options: { variant: 'error' },
      }),
    );
  }
}

export function* deleteBubbleSaga({ payload }: ReturnType<typeof deleteBubble.request>) {
  const { botId, messagesId, bubbleId } = payload;
  try {
    yield* call(() => api.deleteBubble(botId, messagesId, bubbleId));

    yield put(deleteBubble.success({ messagesId, bubbleId }));
    yield put(
      enqueueToast({
        message: 'Bubble deleted',
        options: { variant: 'success' },
      }),
    );
  } catch (e) {
    yield put(deleteBubble.failure(e));
    yield put(
      enqueueToast({
        message: 'Bubble deletion failed',
        options: { variant: 'error' },
      }),
    );
  }
}

export function* getBubblesSaga({ payload }: ReturnType<typeof getBubbles.request>) {
  const { botId, messagesId } = payload;
  try {
    const response = yield api.getBubbles(botId, messagesId);
    yield put(getBubbles.success(response.data));
    if (payload?.onComplete) {
      yield payload.onComplete();
    }
  } catch (e) {
    yield put(getBubbles.failure(e));
  }
}

export function* saveBubblesSaga({ payload }: ReturnType<typeof saveBubbles.request>) {
  const { botId, messagesId, bubbles, order } = payload;

  try {
    // Setting order in create/update is not complete.
    // Do it with the reorder endpoint for now
    if (!!order && bubbles.filter(item => !item?.id)?.length === 0) {
      yield* call(() => api.reorderBubbles(botId, messagesId, order));
    }

    const responses = yield* all(
      bubbles.map((item, key) =>
        call(() => {
          const bubble = {
            ...item,
            order: key + 1,
          };

          if (!bubble?.id) {
            return api.createBubble(botId, messagesId, bubble);
          }
          if (bubble?.id) {
            return api.updateBubble(botId, messagesId, bubble?.id, bubble);
          }
        }),
      ),
    );

    yield all(responses);
    yield put(saveBubbles.success());
    yield put(
      enqueueToast({
        message: 'Bubbles saved',
        options: { variant: 'success' },
      }),
    );
  } catch (e) {
    yield put(saveBubbles.failure(e));
    yield put(
      enqueueToast({
        message: 'Bubble save failed',
        options: { variant: 'error' },
      }),
    );
  }
}

export function* reorderBubblesSaga({
  payload,
}: ReturnType<typeof reorderBubbles.request>) {
  const { botId, messagesId, bubblesIds } = payload;
  try {
    const response = yield* call(() => api.reorderBubbles(botId, messagesId, bubblesIds));

    yield put(reorderBubbles.success(response.data));

    if (payload?.onComplete) {
      payload?.onComplete();
    }

    yield put(
      enqueueToast({
        message: 'Bubble order saved',
        options: { variant: 'success' },
      }),
    );
  } catch (e) {
    yield put(reorderBubbles.failure(e));
    yield put(
      enqueueToast({
        message: 'Bubble reorder failed',
        options: { variant: 'error' },
      }),
    );
  }
}

export default [
  takeLatest(createBubble.request, createBubbleSaga),
  takeLatest(updateBubble.request, updateBubbleSaga),
  takeLatest(deleteBubble.request, deleteBubbleSaga),
  takeLatest(getBubbles.request, getBubblesSaga),
  takeLatest(saveBubbles.request, saveBubblesSaga),
  takeLatest(reorderBubbles.request, reorderBubblesSaga),
];
