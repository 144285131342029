import React from 'react';
import { Redirect } from 'react-router-dom';
import { BotBasicsView, BotProfileView, BotRemoteUsersView } from '@/views';
import { RoutingConfig } from '@/routing';

export const settings: RoutingConfig = [
  {
    path: '/bot-profile',
    exact: true,
    component: BotProfileView,
  },
  {
    path: '/bot-basics',
    exact: true,
    component: BotBasicsView,
  },
  {
    path: '/remote-users',
    exact: true,
    component: BotRemoteUsersView,
  },

  // This must be last
  {
    path: '',
    exact: true,
    render: ({ match }) => <Redirect to={`${match.url}/bot-profile`} />,
  },
];

export default settings;
