exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./fonts/Telegraf-Medium.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./fonts/Telegraf-Medium.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./fonts/Telegraf-Medium.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./fonts/Telegraf-Medium.eot"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./fonts/Telegraf-Medium.otf"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./fonts/Telegraf-Regular.woff2"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("./fonts/Telegraf-Regular.woff"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("./fonts/Telegraf-Regular.ttf"));
var ___CSS_LOADER_URL___8___ = urlEscape(require("./fonts/Telegraf-Regular.eot"));
var ___CSS_LOADER_URL___9___ = urlEscape(require("./fonts/Telegraf-Regular.otf"));

// Module
exports.push([module.id, "@font-face {\n  font-family: Telegraf;\n  src: url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL___1___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL___2___ + ") format(\"ttf\"), url(" + ___CSS_LOADER_URL___3___ + ") format(\"eot\"), url(" + ___CSS_LOADER_URL___4___ + ") format(\"otf\");\n  font-weight: 500;\n}\n@font-face {\n  font-family: Telegraf;\n  src: url(" + ___CSS_LOADER_URL___5___ + ") format(\"woff2\"), url(" + ___CSS_LOADER_URL___6___ + ") format(\"woff\"), url(" + ___CSS_LOADER_URL___7___ + ") format(\"ttf\"), url(" + ___CSS_LOADER_URL___8___ + ") format(\"eot\"), url(" + ___CSS_LOADER_URL___9___ + ") format(\"otf\");\n  font-weight: 400;\n}", ""]);

