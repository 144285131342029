import { Tooltip } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

export const StyledBaseTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.fontSize,
    padding: `${theme.spacing(2)}`,
    textAlign: 'center',
    borderRadius: `${theme.shape.borderRadius}rem`,
  },
}))(Tooltip);
