import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, WEB_SEARCH_QUERY_KEY } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { WebSearchSite } from '../types';

const getWebSearchSites = (featureId: string | null) => {
  return () => {
    return client.get<WebSearchSite[]>(
      `${API_HOST}/${ApiRoutes.WebSearch}/${featureId}/sites/`,
    );
  };
};

export const useGetWebSearchSites = (featureId: string | null) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    WEB_SEARCH_QUERY_KEY,
    getWebSearchSites(featureId),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(
            errorResponse,
            'Failed to get the list of web addresses.',
          ),
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const webSearchSites = data?.data;
  return { webSearchSites, isLoading };
};
