import { Bot, Tag } from '@/queries';
import { EmployeeRange, Sector } from '@/store/luv';

export type OrganisationAddress = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  county: string;
  postcode: string;
  country: string;
};

export type OrganisationLink = {
  code: string;
  label: string;
  order: number;
  iconUrl: string;
  managementApiUrl?: string;
  configurationUrl: string;
  id: string;
  platformFeatureId: string;
  hasSubMenu?: boolean;
  subMenuComponent?: JSX.Element;
};

export type OrganisationNavGroup = {
  code: string;
  label: string;
  order: number;
  items: Array<OrganisationLink>;
};

export type Organisation = {
  id: string;
  organisationName: string;
  website: string;
  logoUrl: string;
  sector: Sector;
  employeeCount: EmployeeRange;
  address: OrganisationAddress;
  tags: Array<Tag>;
  bots: Array<Bot>;
  navGroups: Array<OrganisationNavGroup>;
  approved: boolean;
};

export type UpdateOrganisationRequest = Partial<Organisation> & {
  orgId?: string;
  sector?: {
    id?: string;
  };
};

export type UploadOrganisationLogoRequest = {
  orgId: string;
  logoFile: File;
};

export type CreateOrganisationRequest = {
  organisationName: string;
  sector: {
    id: string;
  };
  website: string;
};

export enum OrganisationRoleCode {
  SuperAdmin = 'SUPERADMIN',
  Admin = 'ADMIN',
  User = 'USER',
  UnApprovedOrgAdmin = 'UNAPPROVEDORGADMIN',
}

export type OrganisationRole = {
  code: OrganisationRoleCode;
  description: string;
};
