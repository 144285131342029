import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { CHART_LIST_QUERY_KEY } from '../constants';
import { Charts } from '../types';

const getChartList = () => {
  return () => {
    return client.get<Array<Charts>>(`${API_HOST}/${ApiRoutes.Dashboard}/list`);
  };
};

export const useGetChartList = () => {
  const { toast } = useToast();

  const { data, isLoading: isFetchingChartList } = useQuery(
    CHART_LIST_QUERY_KEY,
    getChartList(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to fetch chart list.'), {
          variant: 'error',
        });
      },
    },
  );

  const chartList = data?.data;
  return { chartList, isFetchingChartList };
};
