import { Dispatch, MiddlewareAPI } from 'redux';
import { parseISO } from 'date-fns';
import * as signalR from '@microsoft/signalr';

import {
  error,
  event,
  message,
  connectedClientsUpdated,
  typingStart,
  typingEnd,
  closed,
  messageReceipt,
  updateMessage,
} from './actions';
import { Action } from './types';
import { negotiateSignalR } from './api';
import { queryClient } from '@/reactQueryClient';
import {
  LiveChatConsole,
  LIVE_CHAT_CONSOLE_QUERY_KEY,
  ORG_LIVE_CHAT_CONSOLE_QUERY_KEY,
} from '@/queries';

interface ReduxWebSocketOptions {
  prefix: string;
  onOpen?: (s: signalR.HubConnection) => void;
}

export default class ReduxWebSocket {
  private options: ReduxWebSocketOptions;
  private websocket: signalR.HubConnection | null = null;
  private botId: string | null = null;
  private orgId: string | null = null;

  constructor(options: ReduxWebSocketOptions) {
    this.options = options;
  }

  private accessTokenValid = (token: string): boolean => {
    if (!token.length) {
      return false;
    }

    try {
      const base64Url = token.split('.')[1];
      if (!base64Url) {
        return false;
      }
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const tokenExp = JSON.parse(atob(base64)).exp;
      return tokenExp && tokenExp > Date.now() / 1000;
    } catch (err) {
      return false;
    }
  };

  private getRandom = (min: number, max: number): number => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  };

  private getTime = (): string =>
    new Date()
      .toISOString()
      .split('T')[1]
      .split('.')[0];

  connect = async ({ dispatch }: MiddlewareAPI, { payload }: Action) => {
    this.close();

    const { prefix } = this.options;
    let accessToken = '';

    try {
      const negotiateResponse = await negotiateSignalR();

      accessToken = negotiateResponse.data.accessToken;

      this.websocket = new signalR.HubConnectionBuilder()
        .withUrl(negotiateResponse.data.url, {
          accessTokenFactory: async () => {
            if (!this.accessTokenValid(accessToken)) {
              const negotiate = await negotiateSignalR(accessToken);

              if (negotiate.data.accessToken) {
                accessToken = negotiate.data.accessToken;
              }
            }

            return accessToken;
          },
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext =>
            // In the docs it's recommended to connect at random intervals not to stress the server
            retryContext.previousRetryCount === 0 ? 0 : this.getRandom(3000, 5000),
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.botId = payload.botId;
      this.orgId = payload.orgId;

      this.websocket.onclose(err => {
        console.error(`>>> [${this.getTime()}] [SignalR onClose]`, err);

        this.handleClose(dispatch, prefix, 'Connection closed. [WS-100]');
      });

      this.websocket.onreconnecting(err => {
        console.error(`>>> [${this.getTime()}] [SignalR onReconnecting]`, err);

        this.handleError(
          dispatch,
          prefix,
          'Connection lost. Attempting to reconnect... [WS-3]',
        );
      });

      this.websocket.onreconnected(connectionId => {
        console.log(
          `>>> [${this.getTime()}] [SignalR onReconnected]: Successfully reconnected.`,
          connectionId,
        );

        this.handleOpen(this.options.onOpen);
      });

      this.websocket.on('connectedUsers', data => {
        this.handleConnectedClientUpdated(dispatch, prefix, data);
      });

      this.websocket.on('consoleStatistics', data => {
        /* fetch existing data from queryClient using the QueryKey ([LIVE_CHAT_CONSOLE_QUERY_KEY, this.botId]),
          cast it to an object (LiveChatConsole) and merge it with new data from the
          socket, then update the queryClient using the same QueryKey with new data. */

        // make sure we update the queryClient only with data for the correct bot.
        if (data?.botId === this.botId) {
          const currentQueryClientData = queryClient.getQueryData([
            LIVE_CHAT_CONSOLE_QUERY_KEY,
            this.botId,
          ]);
          const updatedData = { ...(currentQueryClientData as LiveChatConsole), ...data };

          // update queryClient with data relevant to the selected botId as part of the QueryKey key.
          queryClient.setQueryData(
            [LIVE_CHAT_CONSOLE_QUERY_KEY, this.botId],
            updatedData,
          );
        }
      });

      this.websocket.on('organisationConsoleStatistics', data => {
        /* fetch existing data from queryClient using the QueryKey ([ORG_LIVE_CHAT_CONSOLE_QUERY_KEY, this.orgId]),
          cast it to an object (LiveChatConsole) and merge it with new data from the
          socket, then update the queryClient using the same QueryKey with new data. */

        // make sure we update the queryClient only with data for the correct bot.
        if (data?.organisationId === this.orgId) {
          const currentQueryClientData = queryClient.getQueryData([
            ORG_LIVE_CHAT_CONSOLE_QUERY_KEY,
            this.orgId,
          ]);
          const updatedData = { ...(currentQueryClientData as LiveChatConsole), ...data };

          // update queryClient with data relevant to the selected orgId as part of the QueryKey key.
          queryClient.setQueryData(
            [ORG_LIVE_CHAT_CONSOLE_QUERY_KEY, this.orgId],
            updatedData,
          );
        }
      });

      this.websocket.on('event', data => this.handleEvent(dispatch, prefix, data));

      this.websocket.on('message', data =>
        this.handleMessage(dispatch, prefix, data, true),
      );

      this.websocket.on('messageConfirmation', data =>
        this.handleMessage(dispatch, prefix, data, false),
      );

      this.websocket.on('messageReceipt', data =>
        this.handleMessageReceipt(dispatch, prefix, data),
      );

      this.websocket.on('typing', data => {
        if (data.status === 'ON') {
          this.handleTypingStart(dispatch, prefix, data);
        } else {
          this.handleTypingEnd(dispatch, prefix, data);
        }
      });
      this.websocket.on('updateMessage', data =>
        this.handleUpdateMessage(dispatch, prefix, data),
      );

      await this.websocket.start();
      console.log(
        `>>> [${this.getTime()}] [SignalR start]: Successfully connected. Connection ID: ${
          this.websocket.connectionId
        }`,
      );

      this.checkConnectedState();
      await this.websocket.invoke('connectLivechat', payload);
    } catch (err) {
      console.error(`>>> [${this.getTime()}] [SignalR connect]`, err);

      this.handleError(dispatch, prefix, 'Unable to establish connection. [WS-0]');
    }
  };

  checkConnectedState = () => {
    // This method prevents the websocket invoke() methods to go through if not connected
    if (!this.websocket) {
      throw new Error('Problem establishing connection. Please try again. [WS-1]');
    }

    if (this.websocket?.state !== signalR.HubConnectionState.Connected)
      throw new Error('Problem establishing connection. Please try again. [WS-2]');
  };

  disconnect = () => {
    if (this.websocket) {
      this.close();
    } else {
      throw new Error(
        'Socket connection not initialized. Dispatch WEBSOCKET_CONNECT first',
      );
    }
  };

  sendMessage = async ({ dispatch }: MiddlewareAPI, { payload }: Action) => {
    if (this.websocket) {
      const { prefix } = this.options;

      try {
        this.checkConnectedState();
        await this.websocket.invoke('message', payload);
      } catch (err) {
        console.error('>>> [SignalR sendMessage]', err);

        this.handleError(dispatch, prefix, 'Unable to send message. [WS-4]');
      }
    } else {
      throw new Error(
        'Socket connection not initialized. Dispatch WEBSOCKET_CONNECT first',
      );
    }
  };

  sendTyping = async ({ dispatch }: MiddlewareAPI, { payload }: Action) => {
    if (this.websocket) {
      // const { prefix } = this.options;
      const { typing, userId, userChannel, botId } = payload;

      try {
        this.checkConnectedState();
        await this.websocket.invoke('typing', {
          status: typing ? 'ON' : 'OFF',
          userId,
          userChannel,
          botId,
        });
      } catch (err) {
        console.error(`>>> [${this.getTime()}] [SignalR sendTyping]`, err);

        // We don't need to show an error to the user here
        // this.handleError(dispatch, prefix, err);
      }
    } else {
      throw new Error(
        'Socket connection not initialized. Dispatch WEBSOCKET_CONNECT first',
      );
    }
  };

  acceptLiveAgentRequest = async ({ dispatch }: MiddlewareAPI, { payload }: Action) => {
    if (this.websocket) {
      const { prefix } = this.options;

      try {
        this.checkConnectedState();
        await this.websocket.invoke('assignAgent', payload);
      } catch (err) {
        console.error(`>>> [${this.getTime()}] [SignalR acceptLiveAgentRequest]`, err);

        this.handleError(dispatch, prefix, 'Unable to assign the request. [WS-5]');
      }
    } else {
      throw new Error(
        'Socket connection not initialized. Dispatch WEBSOCKET_CONNECT first',
      );
    }
  };

  agentEndChat = async ({ dispatch }: MiddlewareAPI, { payload }: Action) => {
    if (this.websocket) {
      const { prefix } = this.options;

      try {
        this.checkConnectedState();
        await this.websocket.invoke('agentEndChat', payload);
      } catch (err) {
        console.error(`>>> [${this.getTime()}] [SignalR agentEndChat]`, err);

        this.handleError(dispatch, prefix, 'Unable to end chat. [WS-6]');
      }
    } else {
      throw new Error(
        'Socket connection not initialized. Dispatch WEBSOCKET_CONNECT first',
      );
    }
  };

  transferAgent = async ({ dispatch }: MiddlewareAPI, { payload }: Action) => {
    if (this.websocket) {
      const { prefix } = this.options;

      try {
        this.checkConnectedState();
        await this.websocket.invoke('transferAgent', payload);
      } catch (err) {
        console.error(`>>> [${this.getTime()}] [SignalR transferAgent]`, err);

        this.handleError(dispatch, prefix, 'Unable to transfer agent. [WS-7]');
      }
    } else {
      throw new Error(
        'Socket connection not initialized. Dispatch WEBSOCKET_CONNECT first',
      );
    }
  };

  private handleClose = (dispatch: Dispatch, prefix: string, reason) => {
    dispatch(closed(reason, prefix));
  };

  private handleError = (dispatch: Dispatch, prefix: string, data) => {
    dispatch(error(null, new Error(data), prefix));
  };

  private handleOpen = (onOpen: ((s: signalR.HubConnection) => void) | undefined) => {
    if (onOpen && this.websocket !== null) {
      onOpen(this.websocket);
    }
  };

  private handleEvent = async (dispatch: Dispatch, prefix: string, data) => {
    if (data.botId !== this.botId) {
      return;
    }

    if (this.websocket) {
      try {
        this.checkConnectedState();
        await this.websocket.invoke('messageReceipt', {
          id: data?.id,
          botId: this.botId,
          userId: data?.userId,
          userChannel: data?.userChannel,
          status: 'DELIVERED',
        });
      } catch (err) {
        console.error(`>>> [${this.getTime()}] [SignalR handleEvent]`, err);

        // We don't need to show an error to the user here
        // this.handleError(dispatch, prefix, err);
      }
    }

    let timestamp: number | undefined;
    if (data.timestamp && typeof data.timestamp === 'string') {
      try {
        timestamp = parseISO(data.timestamp).valueOf();
      } catch (err) {
        // Ignore invalid timestamps.
      }
    }
    if (!timestamp) {
      timestamp = Date.now();
    }

    dispatch(
      event(
        {
          ...data,
          timestamp,
          botId: this.botId,
        },
        prefix,
      ),
    );
  };

  private handleMessage = async (
    dispatch: Dispatch,
    prefix: string,
    data,
    triggerReceipt: boolean,
  ) => {
    if (data.botId !== this.botId) {
      return;
    }

    if (triggerReceipt && this.websocket) {
      try {
        this.checkConnectedState();
        await this.websocket.invoke('messageReceipt', {
          id: data?.id,
          botId: this.botId,
          userId: data?.userId,
          userChannel: data?.userChannel,
          status: 'DELIVERED',
        });
      } catch (err) {
        console.error(`>>> [${this.getTime()}] [SignalR handleMessage]`, err);

        // We don't need to show an error to the user here
        // this.handleError(dispatch, prefix, err);
      }
    }

    let timestamp: number | undefined;
    if (data.timestamp && typeof data.timestamp === 'string') {
      try {
        timestamp = parseISO(data.timestamp).valueOf();
      } catch (err) {
        // Ignore invalid timestamps.
      }
    }
    if (!timestamp) {
      timestamp = Date.now();
    }

    dispatch(
      message(
        {
          ...data,
          timestamp,
          botId: this.botId,
        },
        prefix,
      ),
    );
  };

  private handleMessageReceipt = (dispatch: Dispatch, prefix: string, data) => {
    if (data.botId !== this.botId) {
      return;
    }

    dispatch(
      messageReceipt(
        {
          ...data,
          timestamp: Date.now(),
          botId: this.botId,
        },
        prefix,
      ),
    );
  };

  private handleTypingStart = (dispatch: Dispatch, prefix: string, data) => {
    if (data.botId !== this.botId) {
      return;
    }

    dispatch(typingStart({ userId: data.userId }, prefix));
  };

  private handleTypingEnd = (dispatch: Dispatch, prefix: string, data) => {
    if (data.botId !== this.botId) {
      return;
    }

    dispatch(typingEnd({ userId: data.userId }, prefix));
  };

  private handleUpdateMessage = (dispatch: Dispatch, prefix: string, data) => {
    if (data.botId !== this.botId) {
      return;
    }

    dispatch(updateMessage(data, prefix));
  };

  private handleConnectedClientUpdated = (dispatch: Dispatch, prefix: string, data) => {
    if (data.botId !== this.botId) {
      return;
    }

    dispatch(connectedClientsUpdated(data, prefix));
  };

  private close = () => {
    if (this.websocket) {
      this.websocket.stop();
      this.websocket = null;
    }
  };
}
