import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useMediaQuery } from '@material-ui/core';

export const useScreenSize = () => {
  const theme = useContext(ThemeContext);

  return {
    xsmallUp: useMediaQuery(theme.breakpoints.up('xs'), {
      defaultMatches: true,
    }),
    smallUp: useMediaQuery(theme.breakpoints.up('sm'), {
      defaultMatches: true,
    }),
    mediumUp: useMediaQuery(theme.breakpoints.up('md'), {
      defaultMatches: true,
    }),
    isSmall: useMediaQuery(theme.breakpoints.down('xs'), {
      defaultMatches: true,
    }),
    isMedium: useMediaQuery(theme.breakpoints.between('sm', 'md'), {
      defaultMatches: true,
    }),
    isLarge: useMediaQuery(theme.breakpoints.up('lg'), {
      defaultMatches: true,
    }),
  };
};
