import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  ConversationTag,
  ErrorMessage,
  extractServerErrorMsg,
  CONVERSATION_TAGS_QUERY_KEY,
  UpdateConversationTagsRequest,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useUpdateConversationTags = () => {
  const { toast } = useToast();

  const updateConversationTagsMutation = useMutation(
    ({ featureId, userId, payload, config }: UpdateConversationTagsRequest) => {
      return client.put<Array<ConversationTag>>(
        `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/history/users/${userId}/lastconversationtags`,
        payload,
        config,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(errorResponse, 'Failed to update conversation tags'),
          {
            variant: 'error',
          },
        );
      },
      onSuccess: (_, { featureId, userId }) => {
        queryClient.invalidateQueries([CONVERSATION_TAGS_QUERY_KEY, featureId, userId]);
        toast('Conversation tags updated.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateConversationTags,
    isLoading,
    error,
  } = updateConversationTagsMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateConversationTags };
};
