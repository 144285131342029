import { createGlobalStyle, DefaultTheme } from 'styled-components';
import '@/assets/fonts.scss';
import reset from '@/styles/reset';

export const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  ${reset}
  html, body {
    display: block;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    letter-spacing: -0.5px;
    height: 100%;
    min-height: 100vh;
    color: #484848;
    -webkit-font-smoothing: antialiased;
  }

  input {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }
  
  #root {
    display: block;
    height: 100%;
    min-height: 100vh;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  .css-117 {
    height: auto;
  }
`;
