export type SurveysViewType = 'all' | 'upcoming' | 'active' | 'draft' | 'archived';

export enum SurveyScheduleType {
  ByUsage = 'BY_USAGE',
  Schedule = 'RECURRING',
  Now = 'NOW',
  Once = 'ONCE',
}

export enum SurveyScheduleFrequency {
  Weekly = 'WEEKLY',
  Fortnightly = 'FORTNIGHTLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
}

export enum SurveyStatusCode {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Archived = 'ARCHIVED',
  Upcoming = 'UPCOMING',
  Sent = 'SENT',
}

export type SurveySchedule = {
  type: SurveyScheduleType;
  endDate?: string | null;
  scheduleTime?: string | null;
  frequency?: SurveyScheduleFrequency | null;
  minimumInteractions?: number | null;
  resendAfterDays?: number | null;
};

export enum SurveyQuestionType {
  OpinionScale = 'OPINION_SCALE',
  MultipleChoice = 'MULTIPLE_CHOICE',
}

export type SurveyQuestionOptions = {
  minimumLabel: string;
  maximumLabel: string;
  choices: Array<string>;
};

export type SurveyStatistics = {
  participationRate: number;
};

export type SurveyStatus = {
  code: SurveyStatusCode;
  description?: string;
};

export type SurveyStatusMap = Record<SurveysViewType, Array<SurveyStatusCode>>;

export type SurveyScheduleSettings = {
  schedule?: SurveySchedule;
};

export type SurveyQuestion = {
  id: string;
  question: string;
  type: SurveyQuestionType;
  order: number;
  options: SurveyQuestionOptions;
};

type Audience = {
  id: string;
  value: string;
};

export type SurveyBasics = {
  name?: string;
  description?: string;
  thankYouMessage?: string;
  audience?: Array<Audience>;
  templateId?: string;
  questions?: Array<SurveyQuestion>;
};

export type Survey = SurveyBasics &
  SurveyScheduleSettings & {
    id?: string;
    status?: SurveyStatus;
    statistics?: SurveyStatistics;
    updatedBy?: { displayName: string };
  };

export type Surveys = Record<string, Survey>;

export type SurveyRequest = {
  featureId: string;
  surveyId: string;
};

export type SurveyPayload = Omit<Survey, 'description'> & {
  description?: string;
};

export type CreateOrUpdateSurveyRequest = {
  featureId: string;
  survey: SurveyPayload;
};

export type SurveyTemplate = {
  id: string;
  name: string;
  description?: string;
  icon?: string;
};

export type Participation = {
  count: number;
  proportion: number;
  label: string;
};

export type CreatedBy = {
  displayName: string;
};

export type Question = {
  id: string;
  question: string;
  answers: Answer[];
};

export type Answer = {
  label: string;
  result: Participation;
};

export type SurveyResults = {
  id: string;
  participation: Participation;
  sentTime: string;
  recipients: number;
  recurrency: string;
  createdBy: CreatedBy;
  questions: Question[];
};

export enum SurveyType {
  Custom = 'Custom',
  eNPS = 'eNPS',
  Engagement = 'Engagement',
  Diversity = 'Diversity',
  Leadership = 'Leadership',
  PersonalGrowth = 'PersonalGrowth',
  CultureValues = 'CultureValues',
}
