import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  useLayoutEffect,
} from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Picker as EmojiPicker } from 'emoji-mart';
import { isEqual } from 'lodash/fp';
import memoize from 'memoize-one';
import { exec, init } from 'pell';
import { ThemeContext } from 'styled-components';
import { string } from 'yup';
import { allowedLinkSchemes, noop, sanitizeHtml, stripHtml } from '@/utils';
import {
  EmojiPickerContainer,
  PellContainer,
  RichInputContainer,
  CharacterCount,
} from './TextInput.styled';
import { RichActionsEnum, TextInputProps } from './types';
import { insertTextInEditor, setAttributes } from './utils';

declare global {
  interface Window {
    clipboardData?: any;
  }
  interface Event {
    clipboardData?: any;
  }
}

const emojiIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs></defs><path d="M12,2A10,10,0,1,1,2,12,10,10,0,0,1,12,2Zm0,1.67A8.33,8.33,0,1,0,20.33,12,8.33,8.33,0,0,0,12,3.67Z"/><circle cx="15.95" cy="10.13" r="1.26"/><circle cx="8.05" cy="10.13" r="1.26"/><path d="M12,17.41a4.48,4.48,0,0,0,4.52-4.13h-9A4.48,4.48,0,0,0,12,17.41Z"/></svg>';

const linkIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs></defs><path d="M3.79,12.09a2.42,2.42,0,0,1,2.44-2.4h4.85V8H6.23A4.18,4.18,0,0,0,2,12.09H2a4.18,4.18,0,0,0,4.22,4.16h4.86V14.5H6.23a2.41,2.41,0,0,1-2.44-2.4h0ZM17.77,8H13V9.69h4.81a2.41,2.41,0,1,1,.32,4.81H13v1.75h4.81A4.18,4.18,0,0,0,22,12.1h0A4.18,4.18,0,0,0,17.78,8ZM6.91,12.09a.91.91,0,0,0,.91.89h8.39a.89.89,0,1,0,.19-1.76H7.82A.91.91,0,0,0,6.91,12.09Z"/></svg>';

export function sanitizeLink(link: string): string {
  const uriSchemeMatch = link.match(/^([a-zA-Z][a-zA-Z0-9-+.]*):/);
  if (uriSchemeMatch && !allowedLinkSchemes.includes(uriSchemeMatch[1])) {
    throw new Error(
      `Invalid link (${uriSchemeMatch[1]}: links are not currently supported).`,
    );
  }

  if (uriSchemeMatch) {
    return link;
  }

  if (
    string()
      .email()
      .isValidSync(link)
  ) {
    return `mailto:${link}`;
  }

  return `http://${link}`;
}

export function promptForLink(): { url?: string; link?: string } {
  let messagePrefix = '';
  let link: string | null = null;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      link =
        window?.prompt &&
        window.prompt(`${messagePrefix}Enter the link URL`, link ?? undefined);

      if (!link) {
        return {};
      }

      return {
        link,
        url: sanitizeLink(link),
      };
    } catch (err) {
      if (!err.message) {
        throw err;
      }
      messagePrefix = `${err.message}\n\n`;
    }
  }
}

const initializeEditor = memoize(
  ({ actions, ...options }) =>
    init({
      actions: [...actions],
      ...options,
    }),
  isEqual,
);

export const RichInput: FunctionComponent<TextInputProps> = props => {
  const {
    onChange,
    onKeyPress,
    className,
    value: incomingValue,
    defaultValue,
    richBackground,
    richHeight,
    richBorder,
    richPadding,
    richActions,
    placeholder,
    disabled,
    richMaxLength,
    inputProps,
  } = props;

  const [initialValue] = useState<string>(
    (incomingValue || defaultValue || '') as string,
  );
  const [value, setValue] = useState<string>(initialValue);
  const refPell = useRef<HTMLDivElement>(null);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const theme = useContext(ThemeContext);
  const [editor, setEditor] = useState<{ content: HTMLDivElement }>();

  const sanitizeText = useCallback(text => {
    return sanitizeHtml(text)
      .trim()
      .replace(/(<br\s*\/?\s*>\s*)+$/, '');
  }, []);

  const characterCount = useMemo(() => {
    const processedValue = stripHtml(sanitizeText(value ?? ''));
    return processedValue.length;
  }, [value, sanitizeText]);

  useLayoutEffect(() => {
    document
      ?.querySelector('.pell-content')
      ?.addEventListener('paste', (event: Event) => {
        const clipboardData = event?.clipboardData ?? window?.clipboardData;
        const fileType = clipboardData?.files?.[0]?.type ?? '';
        if (fileType.includes('image')) {
          event.preventDefault();
        }
      });
  }, []);

  const handleEmojiSelect = useCallback(
    emoji => {
      const pellContainer = refPell.current;
      const pellContent = pellContainer?.querySelector('.pell-content') as HTMLElement;
      insertTextInEditor(emoji.native, pellContent);
      const { innerHTML } = pellContent || {
        innerHTML: '',
      };

      onChange?.(sanitizeText(innerHTML));
      setValue(sanitizeText(innerHTML));
      setEmojiPickerVisible(false);
    },
    [onChange, setEmojiPickerVisible, sanitizeText],
  );

  const handleOnChange = useCallback(
    newValue => {
      setValue(prevState => {
        if (prevState !== newValue) {
          return sanitizeText(newValue);
        }
      });
    },
    [sanitizeText],
  );

  useEffect(() => {
    onChange?.(value);
  }, [onChange, value, sanitizeText]);

  const handleClickAway = useCallback(() => {
    setEmojiPickerVisible(false);
  }, [setEmojiPickerVisible]);

  const handleResult = useCallback(() => {
    setEmojiPickerVisible(true);
  }, []);

  const emojiConfig = {
    name: 'emoji',
    title: 'Emoji',
    icon: emojiIcon,
    result: handleResult,
  };

  const linkConfig = {
    name: 'link',
    icon: linkIcon,
    result: () => {
      const { link, url } = promptForLink();

      if (link && url) {
        const selection = window.getSelection();
        const text = selection?.toString() === '' ? link : selection;
        exec(
          'insertHTML',
          `<a href="${url}" role="link" tabindex="-1" target="_blank">${text}</a>`,
        );
      }
    },
  };

  const getRichActionConfig = (action: string) => {
    switch (action) {
      case RichActionsEnum.EMOJI:
        return emojiConfig;
      case RichActionsEnum.BOLD:
        return 'bold';
      case RichActionsEnum.UNDERLINE:
        return 'underline';
      case RichActionsEnum.ITALIC:
        return 'italic';
      case RichActionsEnum.LINK:
        return linkConfig;
    }
  };

  useEffect(() => {
    if (editor) return;

    const pellEditor = initializeEditor({
      element: refPell.current,
      onChange: handleOnChange,
      actions: richActions?.map(getRichActionConfig),
    });

    if (incomingValue && typeof incomingValue === 'string') {
      pellEditor.content.innerHTML = sanitizeText(incomingValue);
    }

    setEditor(pellEditor);
  }, [
    handleResult,
    setValue,
    setEditor,
    editor,
    incomingValue,
    sanitizeText,
    handleOnChange,
  ]);

  useEffect(() => {
    if (!editor?.content || !inputProps) return;
    const inputElement = editor.content;
    setAttributes({ element: inputElement, attributes: inputProps });
  }, [editor, inputProps]);

  useEffect(() => {
    if (!editor?.content?.innerHTML) return;
    if (incomingValue === '') editor.content.innerHTML = '';
  }, [editor, incomingValue, refPell, disabled]);

  useEffect(() => {
    if (editor?.content) {
      editor.content.setAttribute('contentEditable', disabled ? 'false' : 'true');
      editor.content.setAttribute('tabIndex', '-1');
    }
  }, [editor, disabled]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <RichInputContainer className={className}>
        <PellContainer
          ref={refPell}
          $richBackground={richBackground}
          $richHeight={richHeight ?? '18.75rem'}
          $richBorder={richBorder === undefined ? true : false}
          $richPadding={richPadding === undefined ? true : false}
          $placeholder={value === '' ? placeholder : ''}
          onKeyPress={onKeyPress}
          $disabled={disabled}
        />
        {richMaxLength ? (
          <CharacterCount>
            <span>
              {characterCount}/{richMaxLength}
            </span>
          </CharacterCount>
        ) : null}
        {emojiPickerVisible && (
          <EmojiPickerContainer>
            <EmojiPicker
              emojiSize={20}
              showPreview={false}
              emojiTooltip={false}
              color={theme.palette.primary.main}
              onSelect={handleEmojiSelect}
            />
          </EmojiPickerContainer>
        )}
      </RichInputContainer>
    </ClickAwayListener>
  );
};

RichInput.defaultProps = {
  onChange: noop,
  placeholder: '',
  inputProps: {},
};

export default RichInput;
