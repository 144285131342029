import styled, { css } from 'styled-components';

import { StyledInput } from '@/components/core/Input';

type FieldProps = {
  disabled?: boolean;
};

export const Field = styled(StyledInput)<FieldProps>(
  ({ theme }) => css`
    transition: opacity 0.25s ease-in-out;
    margin-bottom: ${theme.spacing(5)};

    &:last-child {
      margin-bottom: 0;
    }
  `,

  ({ disabled }) =>
    disabled &&
    css`
      opacity: 0.55;
      pointer-events: none;
    `,
);

export const FieldSeparator = styled.div(
  ({ theme }) => css`
    outline: 0;
    background: 0;
    width: 100%;
    border: 0;
    margin: ${theme.spacing(3)} 0;
    border-bottom: 0.0625rem solid ${theme.palette.grey.A100};
  `,
);
