import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BotContext } from '../contexts';
import { subDays, parseISO, formatISO } from 'date-fns';
import { isEqual } from 'lodash';

const defaultTo = subDays(new Date(), 1);
const defaultFrom = subDays(new Date(), 7);

function extractUrlParams(botType, botId, search) {
  const queryParams = new URLSearchParams(search);
  const toDate = parseISO(queryParams.get('to') || defaultTo.toISOString());
  const fromDate = parseISO(queryParams.get('from') || defaultFrom.toISOString());

  return {
    from: formatISO(fromDate, { representation: 'date' }),
    to: formatISO(toDate, {
      representation: 'date',
    }),
    selected: queryParams.get('selected') || '7day',
    channel: queryParams.get('channel') || 'all',
    test: queryParams.get('test') === 'true' || false,
    type: botType,
    botId: botId,
    agentId: queryParams.get('agentId') || 'all',
  };
}

export function useDashboardParams() {
  const bot = useContext(BotContext);
  const search = useLocation().search;
  const botId = bot?.id || '';
  const botType = bot?.type.code || 'INSIDE';
  const [params, setParams] = useState(extractUrlParams(botType, botId, search));

  useEffect(() => {
    const urlParams = extractUrlParams(botType, botId, search);
    const hasMatchingParams = isEqual(urlParams, params);

    if (!hasMatchingParams) {
      setParams(urlParams);
    }
  }, [botId, botType, params, search]);

  return params;
}
