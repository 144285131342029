import React, { ComponentType, FunctionComponent } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { WithDropProps } from './types';

export const withDrop = <TProps extends WithDropProps>(
  Component: ComponentType<Omit<TProps, keyof WithDropProps>>,
): FunctionComponent<TProps> => {
  const WrappedComponent: FunctionComponent<TProps> = ({
    droppableid,
    direction,
    getContainerForClone,
    ignoreContainerClipping,
    isCombineEnabled,
    isDropDisabled,
    mode,
    renderClone,
    type,
    ...props
  }) => (
    <Droppable
      droppableId={droppableid}
      direction={direction}
      getContainerForClone={getContainerForClone}
      ignoreContainerClipping={ignoreContainerClipping}
      isCombineEnabled={isCombineEnabled}
      isDropDisabled={isDropDisabled}
      mode={mode}
      renderClone={renderClone}
      type={type}
    >
      {({ innerRef, droppableProps, placeholder }) => (
        <>
          <Component ref={innerRef} {...droppableProps} {...props} />
          {placeholder}
        </>
      )}
    </Droppable>
  );

  WrappedComponent.displayName = `WithDrop(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent;
};
