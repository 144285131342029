import { ComponentProps, ComponentType, FunctionComponent } from 'react';
import connect, { WithToastProps } from './withToast.state';

export const withToast = <TProps extends {}>(Component: ComponentType<TProps>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `WithToast(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent as FunctionComponent<
    Omit<ComponentProps<typeof Component>, keyof WithToastProps>
  >;
};
