import { Dictionary } from 'lodash';

export type MessageTypes = 'TEXT' | 'IMAGE' | 'VIDEO' | 'ATTACHMENT' | 'MAP' | 'BUTTONS';

export enum ActionTypes {
  LIVE_CHAT = 'connectLiveChat',
  URL = 'openUrl',
}

export enum MessageBubbleType {
  Text = 'TEXT',
  Image = 'IMAGE',
  Video = 'VIDEO',
  Attachment = 'ATTACHMENT',
  Buttons = 'BUTTONS',

  /** Unsupported */
  Map = 'MAP',
  List = 'LIST',
}

export type MessageBubbleButton = {
  type: ActionTypes;
  text: string;
  value: string;
  action?: ActionTypes;
  url?: string;
  attributes: string[];
};

export type MessageBubble = {
  id?: string;
  order?: number;
  type?: MessageTypes;
  title?: string;
  text?: string;
  url?: string;
  fileId?: string;
  buttons?: Array<MessageBubbleButton>;
  file?: {
    filename: string;
    size: string;
    description: string;
  };
  fileData?: File;
  value?: string;
};

export type MessageBubblesRequest = {
  botId?: string | null;
  featureId?: string;
  messageId: string;
};

export type MessageBubbles = {
  [messageId: string]: Dictionary<MessageBubble>;
};

export type CreateMessageBubbleRequest = {
  botId?: string;
  featureId?: string;
  messageId: string;
  bubble: MessageBubble;
};

export type UpdateMessageBubbleRequest = CreateMessageBubbleRequest;

export type ReorderMessageBubbleRequest = {
  bubblesIds: Array<{ id: string }>;
} & MessageBubblesRequest;

export type RemoveMessageBubbleRequest = {
  bubbleId: string;
} & MessageBubblesRequest;

export type UploadBubbleFileRequest = {
  file: File;
} & MessageBubblesRequest;
