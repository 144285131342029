import React from 'react';
import { Redirect } from 'react-router-dom';
import { QuestionPage, QuestionsBulkUploadPage, QuestionsPage } from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const questions: RoutingConfig = [
  {
    title: 'Manage Q&A',
    path: '/questions-and-answers/question/create',
    backPath: 'questions-and-answers',
    icon: 'ChevronIcon',
    exact: true,
    description:
      'Set up how your bot replies to messages. Your bot will understand similar questions to the ones you input and reply with text, images, videos and more.',
    component: QuestionPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:AUTOMATE', 'INSIDE:AUTOMATE'],
      permissionArea: BotPermissionCode.QANDA_ADMIN,
    },
  },
  {
    path: '/questions-and-answers/question',
    exact: true,
    render: ({ match }) => <Redirect to={`${match.url}/active`} />,
    customProps: {
      billingGroupCodes: ['OUTSIDE:AUTOMATE', 'INSIDE:AUTOMATE'],
      permissionArea: BotPermissionCode.QANDA_ADMIN,
    },
  },
  {
    title: 'Bulk upload Q&As',
    icon: 'ChevronIcon',
    path: '/questions-and-answers/upload',
    exact: true,
    component: QuestionsBulkUploadPage,
    customProps: {
      type: 'templates/questions',
      billingGroupCodes: ['OUTSIDE:AUTOMATE', 'INSIDE:AUTOMATE'],
      permissionArea: BotPermissionCode.QANDA_ADMIN,
    },
  },
  {
    title: 'Manage Q&A',
    description:
      'Set up how your bot replies to messages. Your bot will understand similar questions to the ones you input and reply with text, images, videos and more.',
    path: '/questions-and-answers/question/:questionId',
    backPath: 'questions-and-answers',
    exact: true,
    icon: 'ChevronIcon',
    component: QuestionPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:AUTOMATE', 'INSIDE:AUTOMATE'],
      permissionArea: BotPermissionCode.QANDA_ADMIN,
    },
  },
  {
    title: 'Q&A Library',
    icon: 'QALibraryIcon',
    description:
      'Automate the answers to the most commonly asked questions for instant resolution and happier employees.',
    path: ['/questions-and-answers', '/questions-and-answers/:status'],
    exact: true,
    component: QuestionsPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:AUTOMATE', 'INSIDE:AUTOMATE'],
      permissionArea: BotPermissionCode.QANDA_ADMIN,
    },
  },
];

export default questions;
