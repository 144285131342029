import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { ApiRoutes, fileUpload } from '@/store/client';
import { UploadBotLogoRequest } from '../types';

export const useUploadBotLogo = () => {
  const { toast } = useToast();

  const uploadBotLogoMutation = useMutation(
    ({ orgId, botId, logoFile }: UploadBotLogoRequest) => {
      return fileUpload(
        `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/bots/${botId}/logo`,
        logoFile,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update Bot logo'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast('Bot logo uploaded.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: uploadBotLogo, isLoading, error } = uploadBotLogoMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, error, uploadBotLogo, serverErrors };
};
