import { AxiosError } from 'axios';
import { clearLocalIdToken, clearLocalStorage } from '@/storage';
import { AxiosInteceptorHandler } from '../types';

function unauthorizedResponse(err: AxiosError) {
  if (err?.response?.status === 401) {
    clearLocalIdToken();
    clearLocalStorage();

    if (window?.location?.href && !window.location.href.includes('login')) {
      window.location.href = '/login';
    } else {
      return Promise.reject(err);
    }
  } else {
    return Promise.reject(err);
  }
}

export const unauthorizedMiddleware = [
  res => res,
  unauthorizedResponse,
] as AxiosInteceptorHandler;
