import { WithLiveChatAwareProps } from '@/hoc';

export type DeliveryStatus = 'SENT' | 'DELIVERED' | 'ERROR';

export type MessagesPayload = {
  botId: string;
  userId: string;
  locale: string;
};

export enum EventSeverity {
  DEBUG = 'debug',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export enum EventSource {
  AGENT = 'agent',
  SERVICE = 'service',
  USER = 'user',
}

export interface Message {
  id: string;
  iconUrl: string;
  timestamp: string;
  title: string;
  type: MessageTypes;
  locale: string;
  deliveryStatus: DeliveryStatus;
  deliveryErrorMessage: string;
  content: MessageContent[];
  eventSeverity: EventSeverity | null;
  eventSource: EventSource | null;
}

export interface MessageContent {
  type:
    | 'text'
    | 'button'
    | 'buttons'
    | 'list'
    | 'image'
    | 'video'
    | 'map'
    | 'attachment'
    | '';
  title: string;
  subtitle: string;
  text: string;
  image: string;
  video: string;
  map: string;
  buttons: MessageContentButton[] | null;
  list: MessageContent[] | null;
}

export interface MessageContentButton {
  text: string;
  type: 'imBack' | 'openUrl' | 'initChat' | 'endChat';
  value: string;
}

export enum MessageTypes {
  EVENT = 'event',
  INCOMING = 'receiver',
  OUTGOING = 'sender',
}

export type Messages = {
  data: Message[];
  botId: string;
  userId: string;
};

export type MessagesResponse = Message[];

export type MessagesSelectorProps = WithLiveChatAwareProps;

export type Connection = {
  userId: string;
  userChannel: string;
  userName?: string;
  locale?: string;
  connectedOn: string;
  disconnectedOn?: string;
  isConnectedToAgent?: boolean;
};

export type WebsocketConnectedClientUpdatedEvent = {
  agents: Array<{
    agentId: string;
    agentName?: string;
    locale?: string;
    connectedOn: string;
    disconnectedOn?: string;
  }>;
  assigned: Array<Connection>;
  unassigned: Array<Connection>;
};

export type WebsocketConnectedClientUpdated = {
  event: WebsocketConnectedClientUpdatedEvent;
};

export type WebsocketConnectPayload = {
  botId: string;
  orgId?: string | null;
  locale: string;
  agent: {
    agentName: string;
    displayName: string;
    firstName: string;
    profilePhotoUrl?: string;
  };
};

export type WebsocketEvent = {
  id: string;
  userId: string;
  userChannel: string;
  message: string;
  severity: EventSeverity;

  botId: string;
  timestamp: number;
};

export type WebsocketMessage = {
  id: string;
  userId: string;
  userChannel: string;
  agentId?: string;
  message: string;
  locale: string;
  translatedMessage?: string;

  botId: string;
  status: DeliveryStatus;
  errorMessage?: string;
  agentName?: string;
  timestamp: number;
};

export type WebsocketSendTyping = {
  userId: string;
  typing: boolean;
};

export type WebsocketTyping = {
  userId: string;
  status: boolean;
};

export type WebsocketUpdateMessage = {
  id: string;
  botId: string;
  userId: string;
  userChannel: string;
  message: string;
  locale: string;
  translatedMessage?: string;
};

export type WebsocketError = {
  type: string;
  meta: {
    timestamp: string;
    message: string;
    name: string;
    originalAction: string;
  };
  error: boolean;
};

export type EndedPayload = {
  botId: string;
  agentId: string;
};

export type Ended = {
  userId: string;
  botId: string;
  channel: string;
  displayName: string;
  dateEnd: number;
  dateStart: number;
  messageCount: number;
  avgConfidence: number;
  blocked: boolean;
};

export type EndedResponse = {
  botId: string;
  data: Array<Ended>;
};

export type SendMessagePayload = {
  id: string;
  botId: string;
  userId: string;
  userChannel: string;
  message: string;
  locale: string;
};

export type SendTypingPayload = {
  userId: string;
  typing: boolean;
  userChannel: string;
  botId: string;
};

export interface LivechatMessageContent extends MessageContent {
  translatedText?: string;
}

export type LivechatMessage = {
  botId: string;
  userId: string;
  userChannel?: string;

  id: string;
  timestamp: number;
  messageType: MessageTypes;

  content: LivechatMessageContent;

  translatedTo?: string;
  translatedFrom?: string;

  agentId?: string;
  agentName?: string;

  status: DeliveryStatus;
  deliveryErrorMessage?: string;

  eventSeverity?: EventSeverity | null;
  eventSource?: EventSource | null;
};

export type UnreadMessages = {
  messages: {
    [connectionId: string]: number;
  };
};

export type UnreadMessagePayload = {
  connectionId: string;
  unreadMessageCount: number;
};

export type WebsocketBubbleTypes = {
  type: 'button' | 'text';
};

export type UserHistory = {
  userId?: string;
  botId?: string;
  dateStart?: string;
  messageCount?: number;
  avgConfidence?: number;
  blocked?: boolean;
  lastConversationTags?: Array<{ value: string }>;
  channel?: string;
};

export type UpdateUserPayload = {
  data: UserHistory;
  userId: string;
  featureId: string;
  botId?: string;
};
export type GetConversationTagsPayload = {
  featureId: string;
  userId: string;
};

export type ConversationTagsResponseData = {
  id?: string;
  value: string;
}[];

export type ConversationTagsResponse = {
  data: ConversationTagsResponseData;
  userId: string;
  featureId: string;
};

export type UserResponse = {
  data: UserHistory;
  featureId: string;
};

export type GetUserPayload = {
  userId: string;
  featureId: string;
};

export type UpdateConversationTagsPayload = {
  botId?: string | null;
  featureId: string;
  userId: string;
  data: Array<{ value: string }>;
};

export type GetUsersPayload = {
  featureId: string;
};

export type GetUsersResponse = {
  data: UserHistory;
  featureId: string;
};

export type WebsocketTransferAgent = {
  event: {
    userId: string;
    agentId: string;
  };
};
