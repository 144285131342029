import { createSelector } from 'reselect';
import { AppState } from '@/store';

export const getLiveChatState = (state: AppState) => state.liveChat;

export const getLiveChatStatuses = createSelector(
  getLiveChatState,
  liveChat => liveChat.status || {},
);

export const getLivechatMessages = createSelector(
  getLiveChatState,
  liveChat => liveChat.livechatMessages,
);
export const getWebsockets = createSelector(
  getLiveChatState,
  liveChat => liveChat.websockets,
);
export const getConnections = createSelector(
  getLiveChatState,
  liveChat => liveChat.connections,
);
export const getUnassignedConnections = createSelector(
  getLiveChatState,
  liveChat => liveChat.unassignedConnections,
);
export const getAssignedConnections = createSelector(
  getLiveChatState,
  liveChat => liveChat.assignedConnections,
);
export const getActiveAgents = createSelector(
  getLiveChatState,
  liveChat => liveChat.activeAgents,
);
export const getUnreadMessages = createSelector(
  getLiveChatState,
  liveChat => liveChat.unreadMessages,
);
export const getTyping = createSelector(getLiveChatState, liveChat => liveChat.typing);
export const getEndedData = createSelector(getLiveChatState, liveChat => liveChat.ended);
export const getUsersData = createSelector(getLiveChatState, liveChat => liveChat.users);
export const getConversationTagData = createSelector(
  getLiveChatState,
  liveChat => liveChat.tags,
);
