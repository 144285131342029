import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import client, { ApiRoutes } from '@/store/client';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { queryClient } from '@/reactQueryClient';
import { ErrorMessage, extractServerErrorMsg, ORG_QUERY_KEY } from '@/queries';

import { BILLING_GROUPS_QUERY_KEY } from '../constants';
import { BillingGroupUpdateResponse, BillingGroupUpdateRequest } from '../types';

export const useUpdateBillingGroup = () => {
  const { toast } = useToast();

  const updateBillingGroupMutation = useMutation(
    ({ orgId, botId, billingGroupId, enabled }: BillingGroupUpdateRequest) => {
      return client.patch<BillingGroupUpdateResponse>(
        `${API_HOST}/${ApiRoutes.Billing}/organisations/${orgId}/bots/${botId}/billinggroups/${billingGroupId}`,
        { enabled },
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update billing group.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { botId, orgId }) => {
        queryClient.invalidateQueries([ORG_QUERY_KEY, orgId]);
        queryClient.invalidateQueries([BILLING_GROUPS_QUERY_KEY, orgId, botId]);
      },
    },
  );

  const {
    mutateAsync: updateBillingGroup,
    isLoading,
    error,
  } = updateBillingGroupMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateBillingGroup };
};
