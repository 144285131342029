import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  PlatformUser,
  PlatformUsers,
  PLATFORM_USERS_QUERY_KEY,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const USERS_API_URL = `${API_HOST}/${ApiRoutes.Core}/organisations`;

const getPlatformUsers = (orgId: string | null) => {
  return () => {
    return client.get<Array<PlatformUser>>(`${USERS_API_URL}/${orgId}/users`);
  };
};

export const useGetPlatformUsers = (orgId: string | null) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [PLATFORM_USERS_QUERY_KEY, orgId],
    getPlatformUsers(orgId),
    {
      enabled: !!orgId,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get users'), {
          variant: 'error',
        });
      },
    },
  );

  const platformUsers = data?.data?.reduce((accum, user) => {
    return { ...accum, [user.id]: user };
  }, {}) as PlatformUsers;
  return { platformUsers, isLoading };
};
