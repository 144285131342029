import { useMemo, useContext } from 'react';
import { keyBy } from 'lodash';
import { FeatureContext } from '@/contexts';
import { useGetAnnouncements } from '@/queries';
import {
  mapSentToActive,
  filterByActive,
  filterByArchived,
  filterByDraft,
  filterByUpcoming,
} from '@/queries/announcements/utils';

export const useAnnouncements = () => {
  const feature = useContext(FeatureContext);
  const { announcements: qResponse, isLoading } = useGetAnnouncements(feature?.id);

  const data = useMemo(() => {
    const newPayload = qResponse?.map(item => mapSentToActive(item));
    return keyBy(newPayload, 'id');
  }, [qResponse]);

  const announcements = Object.values(data);

  const announcementsAllCount = useMemo(() => {
    return announcements?.length;
  }, [announcements]);

  const announcementsUpcomingCount = useMemo(() => {
    return filterByUpcoming(announcements).length;
  }, [announcements]);

  const announcementsDraftCount = useMemo(() => {
    return filterByDraft(announcements).length;
  }, [announcements]);

  const announcementsActiveCount = useMemo(() => {
    return filterByActive(announcements).length;
  }, [announcements]);

  const announcementsArchivedCount = useMemo(() => {
    return filterByArchived(announcements).length;
  }, [announcements]);

  return {
    data,
    isLoading,
    announcementsAllCount,
    announcementsDraftCount,
    announcementsUpcomingCount,
    announcementsActiveCount,
    announcementsArchivedCount,
  };
};
