import { call, put, takeLatest } from 'typed-redux-saga';
import { getOnboardingStages } from './actions';
import api from './api';

function* fetchOnboardingStagesSaga(): Generator {
  try {
    const response = yield* call(() => api.getStages());
    yield put(getOnboardingStages.success(response.data));
  } catch (e) {
    yield put(getOnboardingStages.failure());
  }
}

export default [takeLatest(getOnboardingStages.request, fetchOnboardingStagesSaga)];
