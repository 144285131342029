import { AxiosError } from 'axios';
import { QueryError } from '@/queries';

export const extractServerErrorMsg = (
  error: Pick<AxiosError<QueryError>, 'message' | 'response'>,
  errorMessage?: string,
) => {
  const statusCode = error.response?.status || error.response?.data?.statusCode;
  const message = error.response?.data?.message || error.message;

  if (statusCode !== 422 && message) {
    return message;
  }

  return errorMessage ? errorMessage : 'An error occurred, please try again.';
};
