import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, WEB_SEARCH_SETTINGS_QUERY_KEY } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { WebSearchSettings } from '../types';

const getWebSearchSettings = (featureId: string | null) => {
  return () => {
    return client.get<WebSearchSettings>(
      `${API_HOST}/${ApiRoutes.WebSearch}/${featureId}/settings/general`,
    );
  };
};

export const useGetWebSearchSettings = (featureId: string | null) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    WEB_SEARCH_SETTINGS_QUERY_KEY,
    getWebSearchSettings(featureId),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(
            errorResponse,
            'Failed to get web search general settings.',
          ),
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const isEnabled = data?.data?.enabled;
  return { isEnabled, isLoading };
};
