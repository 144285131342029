import { useQuery } from 'react-query';
import { noop } from '@/utils';
import { LIVE_CHAT_CONSOLE_QUERY_KEY } from '../constants';
import { LiveChatConsole } from '@/queries';

export const useGetLiveChatConsole = (botId: string | null) => {
  // This query is invalidated / updated with socket data
  const { data, isLoading } = useQuery([LIVE_CHAT_CONSOLE_QUERY_KEY, botId], noop, {
    enabled: botId ? true : false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // since this query only returns data updated by the socket,
  // We cast this data here to the expected LiveChatConsole
  const liveChatConsoleData = (data as unknown) as LiveChatConsole;
  return { liveChatConsoleData, isLoading };
};
