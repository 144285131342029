import {
  DEFAULT_PREFIX,
  WEBSOCKET_CLOSED,
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_ERROR,
  WEBSOCKET_EVENT,
  WEBSOCKET_MESSAGE,
  WEBSOCKET_MESSAGE_RECEIPT,
  WEBSOCKET_TYPING_START,
  WEBSOCKET_TYPING_END,
  WEBSOCKET_UPDATE_MESSAGE,
  WEBSOCKET_OPEN,
  WEBSOCKET_SEND_MESSAGE,
  WEBSOCKET_CONNECTED_CLIENT_UPDATED,
  WEBSOCKET_ACCEPT_LIVE_AGENT_REQUEST,
  WEBSOCKET_AGENT_END_CHAT,
  WEBSOCKET_TRANSFER_AGENT,
  WEBSOCKET_SEND_TYPING,
} from './actionTypes';
import {
  Action,
  AcceptLiveAgentRequestPayload,
  AgentEndChatPayload,
  TransferAgentPayload,
} from './types';

type BuiltAction<T> = {
  type: string;
  meta: {
    timestamp: Date;
  };
  payload?: T;
};

function buildAction<T>(actionType: string, payload?: T, meta?: any): BuiltAction<T> {
  const base = {
    type: actionType,
    meta: {
      timestamp: new Date(),
      ...meta,
    },
    ...(payload instanceof Error ? { error: true } : null),
  };

  return payload ? { ...base, payload } : base;
}

export const connect = ({ url, options }: { url: string; options: unknown }) => {
  let prefix: string | undefined;

  return buildAction(`${prefix || DEFAULT_PREFIX}::${WEBSOCKET_CONNECT}`, {
    url,
    options,
  });
};

export const disconnect = (prefix?: string) =>
  buildAction(`${prefix || DEFAULT_PREFIX}::${WEBSOCKET_DISCONNECT}`);

export const sendMessage = (msg, prefix?: string) =>
  buildAction(`${prefix || DEFAULT_PREFIX}::${WEBSOCKET_SEND_MESSAGE}`, msg);

export const sendTyping = (typing, prefix?: string) =>
  buildAction(`${prefix || DEFAULT_PREFIX}::${WEBSOCKET_SEND_TYPING}`, typing);

export const open = (event: Event, prefix: string) =>
  buildAction(`${prefix}::${WEBSOCKET_OPEN}`, event);

export const closed = (event: Event, prefix: string) =>
  buildAction(`${prefix}::${WEBSOCKET_CLOSED}`, event);

export const event = (event: MessageEvent, prefix: string) =>
  buildAction(`${prefix}::${WEBSOCKET_EVENT}`, event);

export const message = (event: MessageEvent, prefix: string) =>
  buildAction(`${prefix}::${WEBSOCKET_MESSAGE}`, event);

export const messageReceipt = (event: MessageEvent, prefix: string) =>
  buildAction(`${prefix}::${WEBSOCKET_MESSAGE_RECEIPT}`, event);

export const typingStart = (event, prefix: string) => {
  return buildAction(`${prefix}::${WEBSOCKET_TYPING_START}`, event);
};

export const typingEnd = (event, prefix: string) =>
  buildAction(`${prefix}::${WEBSOCKET_TYPING_END}`, event);

export const updateMessage = (event: MessageEvent, prefix: string) =>
  buildAction(`${prefix}::${WEBSOCKET_UPDATE_MESSAGE}`, event);

export const connectedClientsUpdated = (event, prefix: string) => {
  return buildAction(`${prefix}::${WEBSOCKET_CONNECTED_CLIENT_UPDATED}`, {
    event,
    message: event.data,
  });
};

export const acceptLiveAgentRequest = (
  payload: AcceptLiveAgentRequestPayload,
  prefix?: string,
) =>
  buildAction(
    `${prefix || DEFAULT_PREFIX}::${WEBSOCKET_ACCEPT_LIVE_AGENT_REQUEST}`,
    payload,
  );

export const agentEndChat = (payload: AgentEndChatPayload, prefix?: string) =>
  buildAction(`${prefix || DEFAULT_PREFIX}::${WEBSOCKET_AGENT_END_CHAT}`, payload);

export const transferAgent = (payload: TransferAgentPayload, prefix?: string) => {
  return buildAction(`${prefix || DEFAULT_PREFIX}::${WEBSOCKET_TRANSFER_AGENT}`, payload);
};

export const error = (originalAction: Action | null, err: Error, prefix: string) =>
  buildAction(`${prefix}::${WEBSOCKET_ERROR}`, err, {
    message: err.message,
    name: err.name,
    originalAction,
  });
