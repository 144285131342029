import { useContext, useMemo } from 'react';
import { BotIdContext, OrgContext } from '@/contexts';
import { useGetBot } from '@/queries';

export const useBot = (_botId?: string) => {
  const { orgId = '' } = useContext(OrgContext);
  const id = useContext(BotIdContext);

  const botId = useMemo(() => _botId ?? id, [id]);

  const { bot, isLoading: isFetchingBot, isError } = useGetBot({
    botId,
    orgId,
  });

  const remoteUserDepartments = useMemo(() => bot?.remoteUserDepartments, [bot]);

  const features = useMemo(() => {
    return bot?.features?.reduce((accum, feature) => {
      return { ...accum, [feature.configurationUrl]: feature };
    }, {});
  }, [bot?.features]);

  return {
    bot,
    features,
    isError,
    isFetchingBot,
    remoteUserDepartments,
  };
};
