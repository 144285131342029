import { connect } from 'react-redux';
import { AppState } from '@/store';
import {
  changePassword,
  createUser,
  fetchUserProfile,
  forgotPassword,
  getAuthStatuses,
  getAuthUserProfile,
  isAdmin,
  isAuthenticated,
  isSuperAdmin,
  loginUser,
  populateUser,
  setUserAuthenticated,
  setUserIdToken,
  updateUserProfile,
  userLogout,
} from '@/store/auth';

const mapStateToProps = (state: AppState) => ({
  authenticated: isAuthenticated(state),
  profile: getAuthUserProfile(state),
  authStatuses: getAuthStatuses(state),
  isAdmin: isAdmin(state),
  isSuperAdmin: isSuperAdmin(state),
});

const mapDispatchToProps = {
  changePassword: changePassword.request,
  createUser: createUser.request,
  forgotPassword,
  getUserProfile: fetchUserProfile.request,
  loginUser: loginUser.request,
  logout: userLogout,
  populateUser,
  setUserAuthenticated,
  setUserIdToken,
  updateUserProfile: updateUserProfile.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type WithAuthProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
