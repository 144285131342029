import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextInput } from '@/components/formElements';
import Button from '@/components/formElements/Button';
import { ActionGridContainer, ActionGridItem } from '@/components/core/ActionGrid';
import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { ResetPasswordFormValues } from './types';
import validationSchema from './validation';
import { ApiError } from '@/store/client';

export const ResetPasswordForm: React.FC<{
  onSubmit: (data: unknown) => void;
  apiError: ApiError | undefined;
}> = ({ onSubmit, apiError }) => {
  const { register, handleSubmit, errors, setError } = useForm<ResetPasswordFormValues>({
    validationSchema,
    mode: 'onSubmit',
  });

  const hasTokenError = useMemo(() => {
    if (!apiError) return false;

    const tokenErrors = apiError?.errors?.filter(error => error.param === 'token');

    return tokenErrors?.length > 0;
  }, [apiError]);

  useEffect(() => {
    if (apiError) {
      apiError?.errors?.forEach(error => {
        if (error.param === 'password') {
          setError(error.param, 'notMatch', error.msg);
        }
      });
    }
  }, [apiError, setError]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [setShowPassword, showPassword]);

  return (
    <form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {hasTokenError ? null : (
          <>
            <Grid item xs={12}>
              <TextInput
                data-testid="create-password-input"
                label="Create Password"
                name="password"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : null}
                inputRef={register({ required: 'Required' })}
                fullWidth
                variant="outlined"
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                data-testid="confirm-password-input"
                label="Confirm Password"
                name="confirmPassword"
                error={!!errors.confirmPassword}
                helperText={
                  errors.confirmPassword ? errors.confirmPassword.message : null
                }
                inputRef={register({ required: 'Required' })}
                fullWidth
                variant="outlined"
                placeholder="Confirm Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <ActionGridContainer>
            <ActionGridItem>
              <Button variant="text" href="/login">
                Go back to Login
              </Button>
            </ActionGridItem>
            <ActionGridItem>
              {hasTokenError ? (
                <Link to="/forgot-password">
                  <Button data-testid="request-new-password-button">
                    Request New Password
                  </Button>
                </Link>
              ) : (
                <Button type="submit" data-testid="save-new-password-button">
                  Save New Password
                </Button>
              )}
            </ActionGridItem>
          </ActionGridContainer>
        </Grid>
      </Grid>
    </form>
  );
};
