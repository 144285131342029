import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg, SURVEYS_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import { SurveyRequest, Survey } from '../types';
import { AxiosError } from 'axios';

export const useDeleteSurvey = () => {
  const { toast } = useToast();

  const deleteSurveyMutation = useMutation(
    ({ featureId, surveyId }: SurveyRequest) => {
      return client.delete<Array<Survey>>(
        `${API_HOST}/${ApiRoutes.Surveys}/${featureId}/surveys/${surveyId}`,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to delete survey.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([SURVEYS_QUERY_KEY, featureId]);
        toast('Survey deleted successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: deleteSurvey, isLoading, error } = deleteSurveyMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, deleteSurvey };
};
