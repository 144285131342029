import { Statistic } from '@/store/stats';
import { ComponentType, FunctionComponent } from 'react';
import { WithStatsSelector, WithStatusesSelector } from './types';
import createConnect, { WithStatsProps } from './withStats.state';

export const withStats = <
  TProps extends WithStatsProps,
  TFetcherType,
  TStatsShape = Array<Statistic>
>(
  Component: ComponentType<TProps>,
  fetcher: TFetcherType,
  dataSelector: WithStatsSelector<TProps, TStatsShape>,
  statusSelector: WithStatusesSelector<TProps>,
) => {
  const connect = createConnect<TFetcherType, TStatsShape, TProps>(
    fetcher,
    dataSelector,
    statusSelector,
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `withStats(${WrappedComponent.displayName ||
    'Component'})`;

  return (WrappedComponent as unknown) as FunctionComponent<
    Omit<TProps, keyof WithStatsProps>
  >;
};
