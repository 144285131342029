import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, Tag, ORG_TAGS_QUERY_KEY } from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getOrgTags = (orgId?: string | null) => {
  return () => {
    return client.get<Array<Tag>>(
      `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/tags`,
    );
  };
};

export const useGetOrgTags = (orgId?: string | null) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery([ORG_TAGS_QUERY_KEY, orgId], getOrgTags(orgId), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onError: (errorResponse: AxiosError) => {
      toast(extractServerErrorMsg(errorResponse, 'Failed to get organisation tags'), {
        variant: 'error',
      });
    },
  });

  const organisationTags = data?.data || [];
  return { organisationTags, isLoading };
};
