import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import client from '@/store/client';
import { queryClient } from '@/reactQueryClient';
import { CHANNEL_SETTINGS_QUERY_KEY } from '../constants';
import { ChannelSettingsResponse, UpdateChannelSettingsRequest } from '../types';

export const useUpdateChannelSettings = () => {
  const { toast } = useToast();

  const updateChannelSettingsMutation = useMutation(
    ({ id, url, settings }: UpdateChannelSettingsRequest) => {
      return client.put<ChannelSettingsResponse>(`${url}${id}/settings`, {
        settings,
        ...(settings?.fileId ? { fileId: settings?.fileId } : {}),
      });
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update channel settings'), {
          variant: 'error',
        });
      },

      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([CHANNEL_SETTINGS_QUERY_KEY, id]);
        toast('channel settings updated.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateChannelSettings,
    isLoading,
    error,
  } = updateChannelSettingsMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateChannelSettings };
};
