import { useGetChartList } from '@/queries';
import { keyBy } from 'lodash';

export const useChartList = () => {
  const { chartList } = useGetChartList();

  const chartLists = {
    charts: keyBy(
      chartList,
      chartListItem => chartListItem?.name?.replace(/\s+/g, '') ?? '',
    ),
  };

  return { chartLists };
};
