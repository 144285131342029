import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { API_HOST } from '@/config';
import { useLogoutUser } from '@/hooks/useLogoutUser';
import { useToast } from '@/hooks/useToast';
import { Bot, extractServerErrorMsg } from '@/queries';
import client, {
  ApiRoutes,
  ClientCancelToken,
  createCancelToken,
  wasCancelled,
} from '@/store/client';

import { BOT_QUERY_KEY } from '../constants';
import { BotRequest } from '../types';

let botCancelToken: ClientCancelToken | undefined = undefined;

const cancelBotRequest = (message?: string) => {
  botCancelToken && botCancelToken.cancel(message);
  botCancelToken = createCancelToken();
};

const getBot = ({ orgId, botId }: BotRequest) => {
  cancelBotRequest('New bot request');

  const params = { relations: 'welcomeMessage,lowConfidenceMessage' };

  return () => {
    return client.get<Bot>(
      `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/bots/${botId}`,
      {
        cancelToken: botCancelToken?.token,
        params,
      },
    );
  };
};

export const useGetBot = ({ orgId, botId }: BotRequest) => {
  const { toast } = useToast();
  const { logoutIfForbidden } = useLogoutUser();

  const { data, isLoading, isError } = useQuery(
    [BOT_QUERY_KEY, orgId, botId],
    getBot({ orgId, botId }),
    {
      // disable the query from automatically running when 'enabled' is false
      enabled: !!orgId && !!botId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        if (wasCancelled(errorResponse)) return;
        toast(extractServerErrorMsg(errorResponse, 'Failed to get bot profile'), {
          variant: 'error',
        });

        logoutIfForbidden(errorResponse);
      },
    },
  );

  const bot = data?.data;
  return { bot, isLoading, isError };
};
