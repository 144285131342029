import React, { FunctionComponent } from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { TextField, TextFieldProps, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const genericStyle = (theme: DefaultTheme, width = '20rem') => {
  return {
    '& .MuiTextField-root': {
      width: `${width}`,
      margin: '0',
      '& .MuiInputBase-input': {
        '&::placeholder': {
          fontSize: theme.typography.body2.fontSize,
        },
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: `solid 1px ${theme.augmentColor(theme?.colors?.midGrey as string, 70)}`,
        },
        '&:hover fieldset': {
          borderColor: `${theme.palette.grey.A200}`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `solid 3px ${theme.palette.secondary.main}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.augmentColor(theme.palette.secondary.main, 20)}`,
        },
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: `solid 1px ${theme.palette.error.main}`,
    },
  };
};

export const StyledInput = styled.div<{ fullWidth?: boolean }>`
  margin: 0 0 ${({ theme }) => theme.spacing(1.3125)};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  &:last-child {
    margin: 0;
  }
`;

const StyledTextField = styled(TextField)`
  display: block;
`;

export const useInputClasses = makeStyles(theme => ({
  root: {
    width: 371,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Input: FunctionComponent<TextFieldProps> = ({
  label,
  InputLabelProps,
  fullWidth,
  ...props
}) => {
  const inputClasses = useInputClasses();

  const id = props.id || props.name;
  return (
    <StyledInput fullWidth={fullWidth}>
      {label && (
        <FormLabel htmlFor={id} {...InputLabelProps}>
          {label}
        </FormLabel>
      )}
      <StyledTextField id={id} classes={inputClasses} fullWidth={fullWidth} {...props} />
    </StyledInput>
  );
};
Input.defaultProps = {
  variant: 'outlined',
};

export default Input;
