export function insertTextAtCursor(selection, text) {
  const featuresSupported = selection?.getRangeAt && selection?.rangeCount;

  if (selection && featuresSupported) {
    const range = selection.getRangeAt(0);
    const newNode = document.createTextNode(text);

    range.deleteContents();
    range.insertNode(newNode);
    selection.collapse(newNode, text.length);
  }
}

function moveCursorToEndOfTextInEditor(
  editorElement: HTMLElement | null,
  childNodes: ChildNode[],
) {
  const featuresSupported = window.getSelection && document.createRange;
  const putCursorAtEndOfRange = (range: Range) => range.collapse(false);
  const firstChildNode = childNodes[0];
  const elementToFocus =
    firstChildNode && firstChildNode.nodeName === 'DIV' ? firstChildNode : editorElement;

  if (elementToFocus && editorElement && featuresSupported) {
    (elementToFocus as HTMLElement).focus();

    const range = document.createRange();
    range.selectNodeContents(elementToFocus);
    putCursorAtEndOfRange(range);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
  }
}

export function insertTextInEditor(text: string, editor: HTMLElement) {
  if (window.getSelection) {
    const selection = window.getSelection();
    const parentNode = selection?.anchorNode?.parentNode as Node;
    const childNodes = Array.from(editor.childNodes);
    const firstChild = childNodes[0] || editor;
    const isEditorChildSameAsParent = firstChild.isEqualNode(parentNode);
    const hasOneChildOrLess = childNodes.length <= 1;
    const isEditorSelected = hasOneChildOrLess && parentNode === editor;
    const isSelectionInEditor = isEditorChildSameAsParent || isEditorSelected;

    if (isSelectionInEditor) {
      insertTextAtCursor(selection, text);
    } else {
      moveCursorToEndOfTextInEditor(editor, childNodes);
      insertTextAtCursor(selection, text);
    }
  }
}

export const setAttributes = ({
  element,
  attributes,
}: {
  element: HTMLElement;
  attributes: { [name: string]: string };
}) => {
  const attributesKeys = Object.keys(attributes);
  attributesKeys.forEach(name => {
    const value = attributes[name];
    element.setAttribute(name, value);
  });
};
