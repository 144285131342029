import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { AuthenticationResult } from '@azure/msal-browser';
import { Grid } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ActionGridContainer, ActionGridItem } from '@/components/core/ActionGrid';
import Button from '@/components/formElements/Button';
import { Page } from '@/components/Page';
import { msalInstance } from '@/contexts/sso.context';
import { WithAuthProps, withAuth } from '@/hoc';
import { useAuthV1Post } from '@/hooks/mutations/useAuthV1Post';
import { setLocalIdToken } from '@/storage';
import { loginUser, UserCreateRequest, UserProfile } from '@/store/auth';
import { Title, Content, Container, Heading } from './SignUpSuccessPage.styled';

export interface SSOState {
  newUser: 'true' | 'false';
  error?: Error;
  invitation?: string;
}
export const SignUpSuccessPage: React.FC<WithAuthProps> = ({
  authenticated,
  setUserAuthenticated,
  setUserIdToken,
  getUserProfile,
}) => {
  const {
    mutateAsync: createNewUserWithToken,
    isError,
    error,
    isLoading,
    isSuccess,
  } = useAuthV1Post<UserProfile, UserCreateRequest>('/users/signup');

  const dispatch = useDispatch();

  const [appError, setAppError] = useState<string | null>(null);

  const signIn = useCallback(
    (tokenResponse: AuthenticationResult) => {
      setLocalIdToken(tokenResponse?.idToken);
      getUserProfile();
      setUserIdToken(tokenResponse?.idToken);
      setUserAuthenticated(true);
      dispatch(loginUser.success());
    },
    [getUserProfile, setUserIdToken, setUserAuthenticated, dispatch],
  );
  const signUp = useCallback(
    async (tokenResponse: AuthenticationResult, state?: SSOState) => {
      const idTokenClaims = tokenResponse.idTokenClaims as {
        given_name: string;
        family_name: string;
        email: string;
      };
      const newUserRequest = state?.invitation
        ? {
            firstName: idTokenClaims.given_name,
            lastName: idTokenClaims.family_name,
            email: idTokenClaims.email,
            token: tokenResponse.idToken,
            invitation: state?.invitation,
          }
        : {
            firstName: idTokenClaims.given_name,
            lastName: idTokenClaims.family_name,
            email: idTokenClaims.email,
            token: tokenResponse.idToken,
          };
      const createResponse = await createNewUserWithToken(newUserRequest);

      if (createResponse?.status === 201) {
        signIn(tokenResponse);
      }
    },
    [createNewUserWithToken, signIn],
  );

  const getState = useCallback<(tokenResponse: AuthenticationResult) => SSOState>(
    tokenResponse => {
      if (tokenResponse?.state) {
        try {
          return JSON.parse(atob(tokenResponse.state));
        } catch (error) {
          setAppError(error);
          return { newUser: 'false' };
        }
      }
      return { newUser: 'false' };
    },
    [],
  );

  const handleTokenResponse = useCallback(async () => {
    try {
      const tokenResponse = await msalInstance.handleRedirectPromise();

      if (tokenResponse?.idToken) {
        const state = getState(tokenResponse);
        const isNewUser = state.newUser === 'true';
        isNewUser ? signUp(tokenResponse, state) : signIn(tokenResponse);
      } else {
        setAppError('Callback Token not found');
      }
    } catch (errorFromMSL) {
      setAppError(
        errorFromMSL.message === 'access_denied'
          ? 'Was unable to sign in your account'
          : errorFromMSL.message,
      );
    }
  }, [getState, signIn, signUp]);

  const heading = useMemo(() => {
    if (isLoading) {
      return (
        <Heading variant="h2" style={{ textAlign: 'center' }}>
          Creating your Futr Account.
        </Heading>
      );
    }
    if (isError) {
      return (
        <Heading variant="h2" style={{ textAlign: 'center' }}>
          There was an error creating your Futr Account
        </Heading>
      );
    }

    if (isSuccess) {
      return (
        <Heading variant="h2" style={{ textAlign: 'center' }}>
          Created your Futr Account
        </Heading>
      );
    }
  }, [isError, isLoading, isSuccess]);

  useEffect(() => {
    if (!authenticated) {
      handleTokenResponse();
    }
  }, [authenticated, handleTokenResponse]);

  const renderRedirectOrLoggingIn = () => {
    return authenticated ? <Redirect to={'/bots/all'} /> : <p>Logging In...</p>;
  };

  return (
    <Page>
      <Content>
        <Title variant="h2" gutterBottom>
          Almost There...
        </Title>
        <Container>
          {heading}
          <Grid container justify="center">
            <Grid item xs={12}>
              {isError || appError ? (
                <div style={{ paddingBottom: '1rem' }}>
                  {error?.response?.data.errors.map((currentError, index) => (
                    <Alert key={index} severity="error" style={{ marginBottom: '1rem' }}>
                      {currentError?.msg}
                    </Alert>
                  ))}
                  {appError && (
                    <Alert severity="error">
                      <AlertTitle>Sign in failed: </AlertTitle>
                      {appError}
                    </Alert>
                  )}
                </div>
              ) : (
                renderRedirectOrLoggingIn()
              )}
            </Grid>

            {(isError || appError) && (
              <Grid item xs={12}>
                <ActionGridContainer>
                  <ActionGridItem>
                    <Button variant="text" href="/login" fullWidth>
                      Go Back To Login
                    </Button>
                  </ActionGridItem>
                </ActionGridContainer>
              </Grid>
            )}
          </Grid>
        </Container>
      </Content>
    </Page>
  );
};

export default withAuth(SignUpSuccessPage);
