import {
  AnnouncementsPage,
  AnnouncementMessagePage,
  AnnouncementSchedulePage,
} from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const announcements: RoutingConfig = [
  {
    title: 'Create an announcement',
    icon: 'ChevronIcon',
    path: '/announcements/message/:id?',
    component: AnnouncementMessagePage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.ANNOUNCEMENTS_ADMIN,
    },
  },
  {
    title: 'Create an announcement',
    icon: 'ChevronIcon',
    path: '/announcements/schedule/:id?',
    component: AnnouncementSchedulePage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.ANNOUNCEMENTS_ADMIN,
    },
  },
  {
    title: 'Announcements',
    icon: 'SurveysIcon',
    description:
      'Create, schedule and manage announcements that engage with your employees and other teams.',
    path: ['/announcements', '/announcements/:status'],
    exact: true,
    component: AnnouncementsPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.ANNOUNCEMENTS_ADMIN,
    },
  },
];

export default announcements;
