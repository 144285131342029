import styled from 'styled-components';

export const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PageHeader = styled.div`
  flex: 0;
  min-height: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PageNavigation = styled.div`
  flex: 0;
`;

export const PageContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  flex-direction: column;

  > div {
    flex: 1;
  }
`;
