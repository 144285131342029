import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  PlatformUser,
  PLATFORM_USERS_QUERY_KEY,
  UpdatePlatformUserRequest,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useUpdatePlatformUser = () => {
  const { toast } = useToast();

  const updatePlatformUserMutation = useMutation(
    ({ orgId, userId, ...body }: UpdatePlatformUserRequest) => {
      return client.patch<PlatformUser>(
        `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/users/${userId}`,
        body,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update user'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { orgId }) => {
        queryClient.invalidateQueries([PLATFORM_USERS_QUERY_KEY, orgId]);
        toast('User updated successfully', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updatePlatformUser,
    isLoading,
    error,
  } = updatePlatformUserMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updatePlatformUser };
};
