import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  BotScreen,
  BotsScreen,
  ForgotPasswordScreen,
  LoginScreen,
  LogoutScreen,
  SignupScreen,
  NotFoundScreen,
  OnboardingScreen,
  SettingsScreen,
  BillingScreen,
  UserProfileScreen,
  OrganisationScreen,
  CreateBotScreen,
  VideoCallScreen,
  OrgLiveChatConsoleScreen,
} from '@/screens';
import { SignupCallbackScreen } from '@/screens/SignUpCallbackScreen';
import { ResetPasswordScreen } from '@/screens/ResetPasswordScreen';
import { RoutingConfig } from './types';

export const root: RoutingConfig = [
  {
    path: '/',
    exact: true,
    open: true,
    render: () => <Redirect to="/bots" />,
  },
  {
    title: 'Create Outside Bot',
    path: '/bots/outside/create',
    component: CreateBotScreen,
    customProps: {
      botType: 'outside',
    },
  },
  {
    title: 'Create Inside Bot',
    path: '/bots/inside/create',
    component: CreateBotScreen,
    customProps: {
      botType: 'inside',
    },
  },
  {
    title: 'All Bots',
    icon: 'FutrIcon',
    description: 'Create and manage bots for your staff and your customers',
    path: '/bots',
    component: BotsScreen,
  },
  {
    title: 'Company Billing',
    path: '/billing',
    component: BillingScreen,
  },
  {
    title: 'My Profile',
    description: 'Please provide your basic profile information below.',
    path: '/profile',
    icon: 'UserIcon',
    component: UserProfileScreen,
  },
  {
    title: 'Settings',
    path: '/settings',
    component: SettingsScreen,
  },
  {
    title: 'Live Chat Console (Organisation Level)',
    description: 'Manage your support team and help plan resource requirements',
    icon: 'LiveChatIcon',
    component: OrgLiveChatConsoleScreen,
    path: '/live-chat/org-console',
  },
  {
    path: '/not-found',
    exact: true,
    component: NotFoundScreen,
  },
  {
    path: '/login',
    exact: true,
    component: LoginScreen,
    open: true,
  },
  {
    path: '/logout',
    exact: true,
    component: LogoutScreen,
    open: true,
  },
  {
    path: '/signin/callback',
    exact: true,
    component: SignupCallbackScreen,
    open: true,
  },
  {
    path: '/signup',
    component: SignupScreen,
    open: true,
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPasswordScreen,
    open: true,
  },
  {
    path: '/reset-password',
    exact: true,
    component: ResetPasswordScreen,
    open: true,
  },
  {
    path: '/onboarding',
    component: OnboardingScreen,
    open: true,
  },
  {
    path: '/video-call',
    component: VideoCallScreen,
    open: true,
  },
  {
    path: '/organisation',
    component: OrganisationScreen,
  },
  {
    path: '/:botId',
    component: BotScreen,
  },
  {
    path: '/bots',
    component: BotsScreen,
  },
];

export default root;
