import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  UserBlockingSettings,
  USER_BLOCKING_SETTINGS_QUERY_KEY,
} from '@/queries';

const getUserBlockingSettings = (featureId?: string) => {
  return () => {
    return client.get<UserBlockingSettings>(
      `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/settings/userblocking`,
    );
  };
};

export const useGetUserBlockingSettings = (featureId?: string) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [USER_BLOCKING_SETTINGS_QUERY_KEY, featureId],
    getUserBlockingSettings(featureId),
    {
      refetchOnMount: 'always',
      refetchOnWindowFocus: 'always',
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(errorResponse, 'Failed to get user blocking settings.'),
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const userBlockingSettings = data?.data;
  return { userBlockingSettings, isLoading };
};
