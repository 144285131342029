import { useMemo } from 'react';
import { useGetBillingParameters } from '@/queries';
import { keyBy } from 'lodash';

export const useBillingParameters = () => {
  const { billingParameters } = useGetBillingParameters();

  const billingParameterDetails = useMemo(() => {
    return {
      inside: {
        billingGroups: keyBy(billingParameters?.inside?.billingGroups, 'id'),
        billingBands: keyBy(billingParameters?.inside?.billingBands, 'id'),
      },
      outside: {
        billingGroups: keyBy(billingParameters?.outside?.billingGroups, 'id'),
        billingBands: keyBy(billingParameters?.outside?.billingBands, 'id'),
      },
    };
  }, [billingParameters]);

  return { billingParameterDetails };
};
