import { useEffect, useRef } from 'react';
import { noop } from '@/utils';

export const useInterval = (callback: () => void, delay = 1000, pause?: boolean) => {
  const savedCallback = useRef<typeof noop>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      !pause && savedCallback?.current?.();
    }
    if (delay !== null && !pause) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, pause]);
};
