import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { ApiRoutes, fileUpload } from '@/store/client';

export const useUploadUserProfilePhoto = () => {
  const { toast } = useToast();

  const uploadUserProfilePhotoMutation = useMutation(
    (file: File) => {
      return fileUpload(`${API_HOST}/${ApiRoutes.Core}/profile/photo`, file);
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(errorResponse, 'Failed to upload user profile picture.'),
          {
            variant: 'error',
          },
        );
      },
      onSuccess: () => {
        toast('User profile picture uploaded.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: uploadUserProfilePhoto,
    isLoading,
    error,
  } = uploadUserProfilePhotoMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, error, uploadUserProfilePhoto, serverErrors };
};
