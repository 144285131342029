import { formatISO, subDays } from 'date-fns';

export const TRANSCRIPTS_QUERY_KEY = 'transcriptMessages';

export const SEARCH_CRITERIA_QUERY_KEY = 'searchCriteria';

export const TRANSCRIPTS_SEARCH_QUERY_KEY = 'searchTranscripts';

// default date range is 7 days for searching transcripts

export const FROM_DATE = formatISO(subDays(new Date(), 7));

export const TO_DATE = formatISO(new Date());
