import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { InviteUsersRequest } from '../types';

export const useInviteUsers = () => {
  const { toast } = useToast();

  const inviteUsersMutation = useMutation(
    (payload: InviteUsersRequest) =>
      client.post<InviteUsersRequest>(
        `${API_HOST}/${ApiRoutes.Call}/invitation`,
        payload,
      ),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to invite user(s).'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast('Successfully invited user(s)', { variant: 'success' });
      },
    },
  );

  const {
    mutateAsync: sendInvitation,
    isLoading: isSendingInvitation,
    error,
  } = inviteUsersMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isSendingInvitation, sendInvitation, serverErrors };
};
