import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { BILLING_BOT_STATUS } from '../constants';
import { BillingBotStatus, BillingRequest } from '../types';

const getBillingBotStatus = ({ orgId, botId }: BillingRequest) => {
  return () => {
    return client.get<BillingBotStatus>(
      `${API_HOST}/${ApiRoutes.Billing}/organisations/${orgId}/bots/${botId}/status`,
    );
  };
};

export const useGetBillingBotStatus = ({ orgId, botId }: BillingRequest) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [BILLING_BOT_STATUS, botId],
    getBillingBotStatus({ orgId, botId }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get billing bot status.'), {
          variant: 'error',
        });
      },
    },
  );

  const billingBotStatus = data?.data;

  return { billingBotStatus, isLoading };
};
