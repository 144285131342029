import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  TakeOfflineSettings,
  TAKE_OFFLINE_SETTINGS_QUERY_KEY,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getTakeOfflineSettings = (featureId?: string) => {
  return () => {
    return client.get<TakeOfflineSettings>(
      `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/settings/takeoffline`,
    );
  };
};

export const useGetTakeOfflineSettings = (featureId?: string) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [TAKE_OFFLINE_SETTINGS_QUERY_KEY, featureId],
    getTakeOfflineSettings(featureId),
    {
      enabled: featureId ? true : false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(errorResponse, 'Failed to get take offline settings.'),
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const takeOfflineSettings = data?.data;
  return { takeOfflineSettings, isLoading };
};
