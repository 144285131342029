import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  GeneralSettings,
  UpdateGeneralSettingsRequest,
  LIVE_CHAT_GENERAL_SETTINGS_QUERY_KEY,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useUpdateGeneralSettings = () => {
  const { toast } = useToast();

  const updateGeneralSettingsMutation = useMutation(
    ({ featureId, data }: UpdateGeneralSettingsRequest) => {
      return client.patch<GeneralSettings>(
        `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/settings/general`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update settings'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([LIVE_CHAT_GENERAL_SETTINGS_QUERY_KEY, featureId]);
        toast('Settings updated successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateGeneralSettings,
    isLoading,
    error,
  } = updateGeneralSettingsMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateGeneralSettings };
};
