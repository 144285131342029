import { RootStatus } from '@/store/types';
import { splitStatus } from '@/store/utils';
import { extractMessages } from '@/utils/extractMessages';
import { keyBy, orderBy } from 'lodash';
import hash from 'object-hash';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  getEnded,
  getMessages,
  websocketConnect,
  websocketConnectedClientUpdated,
  websocketEvent,
  websocketMessage,
  websocketMessageReceipt,
  websocketTypingEnd,
  websocketTypingStart,
  websocketUpdateMessage,
  updateUnreadMessages,
  updateUser,
  getUser,
  getUsers,
  getConversationTags,
  updateConversationTags,
} from './actions';
import {
  Message,
  MessageTypes,
  WebsocketConnectPayload,
  LivechatMessage,
  UserResponse,
  ConversationTagsResponseData,
  WebsocketConnectedClientUpdatedEvent,
  Connection,
  Ended,
} from './types';

const statusReducer = createReducer<RootStatus>({}).handleAction(
  [
    getMessages.failure,
    getMessages.request,
    getMessages.success,
    updateUser.failure,
    updateUser.request,
    updateUser.success,
    getUser.failure,
    getUser.request,
    getUser.success,
    getUsers.failure,
    getUsers.request,
    getUsers.success,
    getConversationTags.failure,
    getConversationTags.request,
    getConversationTags.success,
    updateConversationTags.failure,
    updateConversationTags.request,
    updateConversationTags.success,
  ],
  (state, { type }) => {
    const [actionKey, status] = splitStatus(type);
    return {
      ...state,
      [actionKey]: status,
    };
  },
);

export const livechatMessagesReducer = createReducer<Record<string, LivechatMessage[]>>(
  {},
)
  .handleAction(getMessages.success, (state, { payload }) => {
    const botUserId = hash(`${payload.botId}${payload.userId}`);

    const sortedMessages = orderBy(payload.data, 'timestamp');

    /** Mapping of message IDs to array indexes in the processed messages
     * array. */
    const messageIndexes: Map<string, number> = new Map();

    const livechatMessages = sortedMessages.reduce<
      (Omit<Partial<Message>, 'timestamp' | 'content'> & LivechatMessage)[]
    >((messages, item) => {
      // Attempt to associate translated message content with original
      // (untranslated) message content. This assumes that the original
      // message is before the translated message in the sorted API
      // response.
      if (item.id.endsWith('~translated') && item.content.length === 1) {
        const originalMessageId = item.id.substring(0, item.id.length - 11);
        const originalMessageIdx = messageIndexes.get(originalMessageId);

        if (originalMessageIdx !== undefined) {
          const originalMessage = messages[originalMessageIdx];

          originalMessage.content.translatedText = item.content[0].text;

          originalMessage.translatedTo =
            originalMessage.messageType === MessageTypes.OUTGOING ? item.locale : 'en';
          originalMessage.translatedFrom =
            originalMessage.messageType === MessageTypes.OUTGOING
              ? 'en'
              : originalMessage.locale;

          return messages;
        }
      }

      let messageType = item.type;
      if (messageType == MessageTypes.OUTGOING) {
        messageType = MessageTypes.INCOMING;
      } else if (messageType == MessageTypes.INCOMING) {
        messageType = MessageTypes.OUTGOING;
      }

      const livechatMessage: Omit<Message, 'timestamp' | 'content'> &
        Omit<LivechatMessage, 'content'> & {
          content: Array<LivechatMessage['content']>;
        } = {
        ...item,
        messageType,
        botId: payload.botId,
        userId: payload.userId,
        timestamp: Number.parseInt(item.timestamp),
        status: item.deliveryStatus,
        deliveryErrorMessage: item.deliveryErrorMessage,
      };

      const splitMessages = extractMessages([livechatMessage]);
      messages.push(...splitMessages);

      if (splitMessages.length === 1) {
        messageIndexes.set(item.id, messages.length - 1);
      }

      return messages;
    }, []);

    const lastNewMessage = livechatMessages[livechatMessages.length - 1];

    // Check if there are any messages in the current list after the last
    // new message in the list of transcript messages received, in which
    // case keep them. This may happen if messages are received across the
    // socket while the transcript is being retrieved.
    if (
      lastNewMessage &&
      typeof lastNewMessage.timestamp === 'number' &&
      state[botUserId]?.length
    ) {
      const extraMessages: LivechatMessage[] = [];
      for (let i = state[botUserId].length - 1; i >= 0; i--) {
        if (typeof state[botUserId][i].timestamp !== 'number') {
          continue;
        }
        // Allow for small differences in message timestamps between
        // message sources.
        if (state[botUserId][i].timestamp < lastNewMessage.timestamp - 100) {
          // Assume that the current list is sorted, so no need to look
          // for existing messages further back.
          break;
        }
        if (state[botUserId][i].id === lastNewMessage.id) {
          // Found the last new message in the current list. Assume that the
          // current list is sorted, so no need to look for existing messages
          // further back.
          break;
        }
        // This message was in the original list, but not in the new list,
        // and occurs after the last message in the new list, so keep it.
        extraMessages.unshift(state[botUserId][i]);
      }

      if (extraMessages.length) {
        livechatMessages.push(...extraMessages);
      }
    }

    return {
      ...state,
      [botUserId]: livechatMessages,
    };
  })
  .handleAction([websocketEvent], (state, { payload }) => {
    const botUserId = hash(`${payload.botId}${payload.userId}`);

    // Check if the event already exists in the transcript history.
    if (typeof payload.timestamp === 'number' && state[botUserId]?.length) {
      for (let i = state[botUserId].length - 1; i >= 0; i--) {
        if (typeof state[botUserId][i].timestamp !== 'number') {
          continue;
        }
        if (state[botUserId][i].id === payload.id) {
          return state;
        }

        // Allow for small differences in timestamps between message sources.
        if (state[botUserId][i].timestamp < payload.timestamp - 100) {
          // Assume that the current list is sorted, so no need to look
          // for duplicates further back.
          break;
        }
      }
    }

    const livechatMessage: LivechatMessage = {
      ...payload,
      status: 'DELIVERED',
      messageType: MessageTypes.EVENT,
      eventSeverity: payload.severity,
      content: {
        text: payload.message,
        type: 'text',
        translatedText: '',
        title: '',
        subtitle: '',
        image: '',
        map: '',
        video: '',
        buttons: null,
        list: null,
      },
    };

    const newMessages = [...(state[botUserId] ?? []), livechatMessage];

    return {
      ...state,
      [botUserId]: orderBy(newMessages, 'timestamp'),
    };
  })
  .handleAction([websocketMessage], (state, { payload }) => {
    const botUserId = hash(`${payload.botId}${payload.userId}`);

    const messageType = payload?.agentId ? MessageTypes.OUTGOING : MessageTypes.INCOMING;

    // Check if the message already exists in the transcript history.
    if (typeof payload.timestamp === 'number' && state[botUserId]?.length) {
      for (let i = state[botUserId].length - 1; i >= 0; i--) {
        if (typeof state[botUserId][i].timestamp !== 'number') {
          continue;
        }
        if (state[botUserId][i].id === payload.id) {
          return state;
        }

        // Allow for small differences in message timestamps between
        // message sources.
        if (state[botUserId][i].timestamp < payload.timestamp - 100) {
          // Assume that the current list is sorted, so no need to look
          // for duplicates further back.
          break;
        }
      }
    }

    const livechatMessage: LivechatMessage = {
      ...payload,
      messageType,
      deliveryErrorMessage: payload.errorMessage,
      content: {
        text: payload.message,
        type: 'text',
        translatedText: payload.translatedMessage,
        title: '',
        subtitle: '',
        image: '',
        map: '',
        video: '',
        buttons: null,
        list: null,
      },
    };

    if (payload.translatedMessage) {
      livechatMessage.translatedTo =
        messageType === MessageTypes.OUTGOING ? payload.locale : 'en';
      livechatMessage.translatedFrom =
        messageType === MessageTypes.OUTGOING ? 'en' : payload.locale;
    }

    const newMessages = [...(state[botUserId] ?? []), livechatMessage];

    return {
      ...state,
      [botUserId]: orderBy(newMessages, 'timestamp'),
    };
  })
  .handleAction([websocketUpdateMessage], (state, { payload }) => {
    const botUserId = hash(`${payload.botId}${payload.userId}`);

    if (payload?.userId) {
      return {
        ...state,
        [botUserId]: (state[botUserId] ?? []).map(item => {
          if (item.id === payload.id) {
            const updatedMessage: LivechatMessage = {
              ...item,
              content: {
                text: payload.message,
                type: 'text',
                translatedText:
                  payload.locale !== 'en' ? payload.translatedMessage : undefined,
                title: '',
                subtitle: '',
                image: '',
                map: '',
                video: '',
                buttons: null,
                list: null,
              },
            };

            if (payload.translatedMessage && payload.locale !== 'en') {
              updatedMessage.translatedTo =
                item.messageType === MessageTypes.OUTGOING ? payload.locale : 'en';
              updatedMessage.translatedFrom =
                item.messageType === MessageTypes.OUTGOING ? 'en' : payload.locale;
            } else {
              updatedMessage.translatedTo = undefined;
              updatedMessage.translatedFrom = undefined;
            }

            return updatedMessage;
          }

          return item;
        }),
      };
    }

    return state;
  })
  .handleAction([websocketMessageReceipt], (state, { payload }) => {
    const botUserId = hash(`${payload.botId}${payload.userId}`);

    if (payload?.userId) {
      return {
        ...state,
        [botUserId]: (state[botUserId] ?? []).map(item => {
          if (item.id === payload.id) {
            return {
              ...item,
              status: payload.status,
            };
          }

          return item;
        }),
      };
    }

    return state;
  });

export const endedReducer = createReducer<Record<string, Ended[]>>({}).handleAction(
  getEnded.success,
  (state, { payload }) => ({
    ...state,
    [payload.botId]: payload.data,
  }),
);

export const websocketReducer = createReducer<null | Record<
  string,
  WebsocketConnectPayload
>>(null).handleAction(websocketConnect, (state, { payload }) => {
  if (!payload?.botId) return state;

  return {
    ...state,
    [payload.botId]: payload,
  };
});

export const connectionsReducer = createReducer<Record<string, Connection>>(
  {},
).handleAction(websocketConnectedClientUpdated, (state, { payload }) =>
  keyBy(
    [
      ...(payload?.event?.unassigned
        ? payload.event.unassigned.map(c => ({ ...c, isConnectedToAgent: false }))
        : []),
      ...(payload?.event?.assigned
        ? payload.event.assigned.map(c => ({ ...c, isConnectedToAgent: true }))
        : []),
    ],
    'userId',
  ),
);

export const unassignedConnectionsReducer = createReducer<Record<string, Connection>>(
  {},
).handleAction(websocketConnectedClientUpdated, (state, { payload }) =>
  keyBy(payload?.event?.unassigned ? payload.event.unassigned : [], 'userId'),
);

export const assignedConnectionsReducer = createReducer<Record<string, Connection>>(
  {},
).handleAction(websocketConnectedClientUpdated, (state, { payload }) =>
  keyBy(payload?.event?.assigned ? payload.event.assigned : [], 'userId'),
);

export const activeAgentsReducer = createReducer<
  WebsocketConnectedClientUpdatedEvent['agents'] | null
>(null).handleAction(websocketConnectedClientUpdated, (state, { payload }) => [
  ...(payload?.event?.agents ? payload?.event?.agents : []),
]);

export const typingReducer = createReducer<null | Record<string, boolean>>({})
  .handleAction(websocketTypingStart, (state, { payload }) => {
    return {
      ...state,
      [payload.userId]: true,
    };
  })
  .handleAction(websocketTypingEnd, (state, { payload }) => {
    return {
      ...state,
      [payload.userId]: false,
    };
  });

export const unreadMessagesReducer = createReducer<Record<string, number>>({})
  .handleAction(updateUnreadMessages, (state, { payload }) => {
    return {
      ...state,
      [payload?.connectionId]: payload?.unreadMessageCount,
    };
  })
  .handleAction([websocketMessage], (state, { payload }) => {
    if (payload?.agentId) {
      return state;
    }

    return {
      ...state,
      ...(state[payload?.userId]
        ? { [payload?.userId]: state[payload?.userId] + 1 }
        : { [payload?.userId]: 1 }),
    };
  });

export const usersReducer = createReducer<{} | UserResponse>({})
  .handleAction(
    [getUser.success, updateUser.success],
    (state, { payload: { featureId, data } }) => ({
      ...state,
      [featureId]: {
        ...state[featureId],
        ...(data?.userId ? { [data.userId]: data } : {}),
      },
    }),
  )
  .handleAction([getUsers.success], (state, { payload: { featureId, data } }) => ({
    ...state,
    [featureId]: {
      ...state[featureId],
      ...keyBy(data, 'userId'),
    },
  }));

export const conversationTagReducer = createReducer<
  Record<string, Record<string, ConversationTagsResponseData>>
>({}).handleAction(
  [getConversationTags.success, updateConversationTags.success],
  (state, { payload: { featureId, userId, data } }) => ({
    ...state,
    [featureId]: {
      ...state[featureId],
      [userId]: data,
    },
  }),
);

export default combineReducers({
  livechatMessages: livechatMessagesReducer,
  ended: endedReducer,
  unreadMessages: unreadMessagesReducer,
  status: statusReducer,
  websockets: websocketReducer,
  connections: connectionsReducer,
  unassignedConnections: unassignedConnectionsReducer,
  assignedConnections: assignedConnectionsReducer,
  typing: typingReducer,
  users: usersReducer,
  tags: conversationTagReducer,
  activeAgents: activeAgentsReducer,
});
