import { createAsyncAction } from 'typesafe-actions';
import {
  CreateBubbleRequest,
  UpdateBubbleRequest,
  BubbleResponse,
  DeleteBubbleRequest,
  DeleteBubbleResponse,
  GetBubblesRequest,
  GetBubblesResponse,
  SaveBubblesRequest,
  MessageBubble,
  ReorderBubblesRequest,
} from './types';

export const MESSAGES_CREATE_BUBBLE = 'MESSAGES_CREATE_BUBBLE';
export const MESSAGES_CREATE_BUBBLE_INITIATED = 'MESSAGES_CREATE_BUBBLE_INITIATED';
export const MESSAGES_CREATE_BUBBLE_SUCCESS = 'MESSAGES_CREATE_BUBBLE_SUCCESS';
export const MESSAGES_CREATE_BUBBLE_FAILED = 'MESSAGES_CREATE_BUBBLE_FAILED';

export const MESSAGES_UPDATE_BUBBLE = 'MESSAGES_UPDATE_BUBBLE';
export const MESSAGES_UPDATE_BUBBLE_INITIATED = 'MESSAGES_UPDATE_BUBBLE_INITIATED';
export const MESSAGES_UPDATE_BUBBLE_SUCCESS = 'MESSAGES_UPDATE_BUBBLE_SUCCESS';
export const MESSAGES_UPDATE_BUBBLE_FAILED = 'MESSAGES_UPDATE_BUBBLE_FAILED';

export const MESSAGES_DELETE_BUBBLE = 'MESSAGES_DELETE_BUBBLE';
export const MESSAGES_DELETE_BUBBLE_INITIATED = 'MESSAGES_DELETE_BUBBLE_INITIATED';
export const MESSAGES_DELETE_BUBBLE_SUCCESS = 'MESSAGES_DELETE_BUBBLE_SUCCESS';
export const MESSAGES_DELETE_BUBBLE_FAILED = 'MESSAGES_DELETE_BUBBLE_FAILED';

export const MESSAGES_GET_BUBBLES = 'MESSAGES_GET_BUBBLES';
export const MESSAGES_GET_BUBBLES_INITIATED = 'MESSAGES_GET_BUBBLES_INITIATED';
export const MESSAGES_GET_BUBBLES_SUCCESS = 'MESSAGES_GET_BUBBLES_SUCCESS';
export const MESSAGES_GET_BUBBLES_FAILED = 'MESSAGES_GET_BUBBLES_FAILED';

export const MESSAGES_SAVE_BUBBLES = 'MESSAGES_SAVE_BUBBLES';
export const MESSAGES_SAVE_BUBBLES_INITIATED = 'MESSAGES_SAVE_BUBBLES_INITIATED';
export const MESSAGES_SAVE_BUBBLES_SUCCESS = 'MESSAGES_SAVE_BUBBLES_SUCCESS';
export const MESSAGES_SAVE_BUBBLES_FAILED = 'MESSAGES_SAVE_BUBBLES_FAILED';

export const MESSAGES_REORDER_BUBBLES = 'MESSAGES_REORDER_BUBBLES';
export const MESSAGES_REORDER_BUBBLES_INITIATED = 'MESSAGES_REORDER_BUBBLES_INITIATED';
export const MESSAGES_REORDER_BUBBLES_SUCCESS = 'MESSAGES_REORDER_BUBBLES_SUCCESS';
export const MESSAGES_REORDER_BUBBLES_FAILED = 'MESSAGES_REORDER_BUBBLES_FAILED';

export const createBubble = createAsyncAction(
  MESSAGES_CREATE_BUBBLE_INITIATED,
  MESSAGES_CREATE_BUBBLE_SUCCESS,
  MESSAGES_CREATE_BUBBLE_FAILED,
)<CreateBubbleRequest, BubbleResponse, void | Error>();

export const updateBubble = createAsyncAction(
  MESSAGES_UPDATE_BUBBLE_INITIATED,
  MESSAGES_UPDATE_BUBBLE_SUCCESS,
  MESSAGES_UPDATE_BUBBLE_FAILED,
)<UpdateBubbleRequest, BubbleResponse, void | Error>();

export const deleteBubble = createAsyncAction(
  MESSAGES_DELETE_BUBBLE_INITIATED,
  MESSAGES_DELETE_BUBBLE_SUCCESS,
  MESSAGES_DELETE_BUBBLE_FAILED,
)<DeleteBubbleRequest, DeleteBubbleResponse, void | Error>();

export const getBubbles = createAsyncAction(
  MESSAGES_GET_BUBBLES_INITIATED,
  MESSAGES_GET_BUBBLES_SUCCESS,
  MESSAGES_GET_BUBBLES_FAILED,
)<GetBubblesRequest, GetBubblesResponse, void | Error>();

export const saveBubbles = createAsyncAction(
  MESSAGES_SAVE_BUBBLES_INITIATED,
  MESSAGES_SAVE_BUBBLES_SUCCESS,
  MESSAGES_SAVE_BUBBLES_FAILED,
)<SaveBubblesRequest, void, void | Error>();

export const reorderBubbles = createAsyncAction(
  MESSAGES_REORDER_BUBBLES_INITIATED,
  MESSAGES_REORDER_BUBBLES_SUCCESS,
  MESSAGES_REORDER_BUBBLES_FAILED,
)<ReorderBubblesRequest, Array<MessageBubble>, void | Error>();
