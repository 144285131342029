import React from 'react';
import { StyledFormLabel as FormLabelBase } from './FormLabel.styled';
import { FormLabelProps } from './types';

const FormLabel = (props: FormLabelProps) => {
  const { bottomMargin, ...formLabelProps } = props;
  return <FormLabelBase {...formLabelProps} $bottomMargin={bottomMargin} />;
};

FormLabel.defaultProps = {
  component: 'label',
  bottomMargin: true,
};

export default FormLabel;
