import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  LiveVideoSettings,
  LIVE_VIDEO_SETTINGS_QUERY_KEY,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getLiveVideoSettings = (botId?: string, settingsId?: string) => {
  return () => {
    return client.get<LiveVideoSettings>(
      `${API_HOST}/${ApiRoutes.Video}/bots/${botId}/settings/${settingsId}`,
    );
  };
};

export const useGetLiveVideoSettings = (botId?: string, settingsId?: string) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [LIVE_VIDEO_SETTINGS_QUERY_KEY, botId, settingsId],
    getLiveVideoSettings(botId, settingsId),
    {
      enabled: !!botId && !!settingsId,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(errorResponse, 'Failed to get live video settings.'),
          {
            variant: 'error',
          },
        );
      },
    },
  );

  const liveVideoSettings = data?.data || ({} as LiveVideoSettings);
  return { liveVideoSettings, isLoading };
};
