import styled from 'styled-components';
import ViewContainer from '@/components/core/ViewContainer';
import { Typography } from '@material-ui/core';

export const StyledViewContainer = styled(ViewContainer)`
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const PagePermissionDisallowedMessage = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DefaultMessageText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.grey[600]};
    font-size: 1.5rem;
    margin-bottom: ${({ theme }) => theme.spacing(5)};
  }
`;

export const PagePermissionDisallowedLogo = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)};

  img,
  svg {
    width: 5rem;
    height: 5rem;
  }
`;

export const IconContainer = styled.div`
  svg * {
    fill: ${({ theme }) => theme.palette.grey[400]};
  }
`;
