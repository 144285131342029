import React, { ComponentType, FunctionComponent } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { WithDragProps } from './types';

export const withDrag = <TProps extends WithDragProps>(
  Component: ComponentType<Omit<TProps, keyof WithDragProps>>,
): FunctionComponent<TProps> => {
  const WrappedComponent: FunctionComponent<TProps> = ({
    draggableid,
    index,
    disableInteractiveElementBlocking,
    shouldRespectForcePress,
    isDragDisabled,
    ...props
  }) => (
    <Draggable
      draggableId={draggableid}
      index={index}
      disableInteractiveElementBlocking={disableInteractiveElementBlocking}
      shouldRespectForcePress={shouldRespectForcePress}
      isDragDisabled={isDragDisabled}
    >
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <Component ref={innerRef} {...draggableProps} {...dragHandleProps} {...props} />
      )}
    </Draggable>
  );

  WrappedComponent.displayName = `WithDrag(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent;
};
