import React, { createContext, useState } from 'react';
import { QuestionViewType } from '@/store/questions';
import { mergeWith, isArray } from 'lodash';
import { SurveysViewType } from '@/queries';
import { AnnouncementsViewType } from '@/queries';
import { sortOrders } from '@/utils/getSortOrdersArrayFromMap';

export interface AppDataTableFilters {
  activeTab?: QuestionViewType | SurveysViewType | AnnouncementsViewType;
  pageNo?: number;
  orders?: sortOrders;
  initialSortOrder?: string[];
  tagsFilters?: string[];
}
interface AppData {
  question?: AppDataTableFilters;
  survey?: AppDataTableFilters;
  announcement?: AppDataTableFilters;
}
type AppDataOrNull = AppData | null;
export const AppDataContext = createContext<AppDataOrNull>(null);
export const AppDataContextUpdate = createContext<(value: AppDataOrNull) => void>(() => {
  throw new Error('not implemented yet');
});

function customEmptyArrayRemover(_objValue, srcValue) {
  if (isArray(srcValue) && !srcValue.length) {
    return srcValue;
  }
}

export const AppDataProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<AppDataOrNull>(null);

  function updateState(value: AppDataOrNull) {
    const newState = mergeWith(state, value, customEmptyArrayRemover);
    setState(newState);
  }

  return (
    <AppDataContext.Provider value={state}>
      <AppDataContextUpdate.Provider value={updateState}>
        {children}
      </AppDataContextUpdate.Provider>
    </AppDataContext.Provider>
  );
};
