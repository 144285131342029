export const getChartTitle = (defaultTitle: string, title?: string) => {
  if (title === undefined || title === '') {
    return defaultTitle;
  }
  return title;
};

export const getToolTipConfig = () => {
  return {
    label: (tooltipItems, data) => {
      // Get the dataset label, global label or fall back to empty label
      return data?.labels[tooltipItems?.datasetIndex] ?? '';
    },
  };
};
