import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useScreenSize } from '@/hooks';
import { ActionGridContainerProps } from './types';

const ActionGridContainer = ({
  children,
  justify,
  disableReversing,
  ...props
}: ActionGridContainerProps) => {
  const { isSmall } = useScreenSize();

  const justifyContent = useMemo(() => {
    const numChildren = React.Children.count(children);
    if (justify) {
      return justify;
    }
    if (isSmall) {
      return 'center';
    }
    if (numChildren === 1) {
      return 'flex-end';
    }
    if (numChildren > 1) {
      return 'space-between';
    }
  }, [children, justify, isSmall]);

  return (
    <Grid
      container
      justify={justifyContent}
      direction={!disableReversing && isSmall ? 'column-reverse' : undefined}
      wrap={isSmall ? 'wrap' : undefined}
      {...props}
    >
      {children}
    </Grid>
  );
};

ActionGridContainer.defaultProps = {
  spacing: 3,
  disableReversing: false,
};

export default ActionGridContainer;
