import styled, { css } from 'styled-components';
import { LoaderProps } from './types';

export const StyledLoader = styled.div<LoaderProps>(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,

  ({ showPrev }) =>
    showPrev &&
    css`
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(248, 248, 248, 0.85);
      z-index: 9999;
    `,

  ({ fullScreen }) =>
    fullScreen &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `,
);

export const Spinner = styled.div<LoaderProps>`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  position: sticky;
  bottom: 1rem;
  top: 1rem;
`;

export const LoaderText = styled.p`
  margin: ${({ theme }) => theme.spacing(4)};
  padding: 0;
`;

export const PrevChildrenContainer = styled.div<LoaderProps>(
  ({ showPrev }) =>
    showPrev &&
    css`
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9998;
    `,
);
