import { AxiosError, AxiosResponse } from 'axios';
import { QueryObserverResult } from 'react-query';
import { BotTypeCode } from '@/queries';
import { APIErrorResponse } from '@/types/error';

export type DashboardParams = {
  from: string;
  to: string;
  selected: string;
  channel: string;
  test: boolean;
  type: BotTypeCode;
  botId: string;
  agentId: string;
};

export type GetStatsResponse<T> = DashboardParams & T;

export type GetStatsQueryResponse<T> = QueryObserverResult<
  AxiosResponse<T>,
  AxiosError<APIErrorResponse>
>;

export enum FileType {
  CSV = 'csv',
  JSON = 'json',
}

type chart = {
  id?: string | number;
  name?: string;
};

export type Charts = {
  id?: string | number;
  name?: string;
  charts?: Array<chart>;
};

export type ExportChartsRequest = {
  botId?: string;
  from?: string;
  to?: string;
  test?: boolean;
  format?: FileType;
};
