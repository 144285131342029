import { BillingPage } from '@/pages';
import { RoutingConfig } from '@/routing';

export const billing: RoutingConfig = [
  {
    title: 'Edit card details',
    path: '/card',
    exact: true,
    component: BillingPage,
    icon: 'ChevronIcon',
  },
  {
    title: 'Edit invoice details',
    path: '/invoice',
    exact: true,
    component: BillingPage,
    icon: 'ChevronIcon',
  },
  {
    title: 'Company Billing',
    exact: true,
    path: '/',
    component: BillingPage,
  },
];

export default billing;
