import { useAppDataContext } from '@/hooks/useAppDataContext';
import { AppDataTableFilters } from '@/contexts/app.context';

type pageNames = 'surveys' | 'questions-and-answers' | 'announcements' | string;

export function useBackPath(pageName: pageNames) {
  const appData = useAppDataContext();

  const appDataMap = new Map<pageNames, AppDataTableFilters | undefined>();
  appDataMap.set('surveys', appData?.survey);
  appDataMap.set('questions-and-answers', appData?.question);
  appDataMap.set('announcements', appData?.announcement);

  if (appDataMap.has(pageName)) {
    const activeTab = appDataMap.get(pageName)?.activeTab;
    return `${pageName}${activeTab ? '/' + activeTab : ''}`;
  }

  return pageName;
}
