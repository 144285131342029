export * from './links';

export const {
  APP_HOST,
  MS_SSO_APP_ID,
  API_HOST,
  API_SUBSCRIPTION_KEY,
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
  BOT_PREVIEW_URL = '',
  BOT_PREVIEW_CODE = '',
  EMBEDDED_CHAT_URL,
  NODE_ENV,
  CI,
  ONE_SIGNAL_APP_ID,
  CHAT_CODE_URL,
  SENTRY_DSN,
  FACEBOOK_APP_ID,
} = process.env;

const {
  DASHBOARD_TEST_DATA_DEFAULT: DOTENV_DASHBOARD_TEST_DATA_DEFAULT = 'true',
} = process.env;

export const DASHBOARD_TEST_DATA_DEFAULT = DOTENV_DASHBOARD_TEST_DATA_DEFAULT === 'true';
