import { useQuery } from 'react-query';
import { noop } from '@/utils';
import { LiveChatConsole, ORG_LIVE_CHAT_CONSOLE_QUERY_KEY } from '@/queries';

export const useGetOrgLiveChatConsole = (orgId?: string | null) => {
  // This query is invalidated / updated with socket data
  const { data, isLoading } = useQuery([ORG_LIVE_CHAT_CONSOLE_QUERY_KEY, orgId], noop, {
    enabled: orgId ? true : false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // since this query only returns data updated by the socket,
  // We cast this data here to the expected LiveChatConsole
  const orgLiveChatConsoleData = (data as unknown) as LiveChatConsole;
  return { orgLiveChatConsoleData, isLoading };
};
