import { noop } from '@/utils';
import React, { useState, createContext, Dispatch, SetStateAction } from 'react';
import { SearchTranscriptValues } from '@/components/TranscriptSearchPanel/types';
import { FROM_DATE, TO_DATE, TranscriptUser } from '@/queries';

type TranscriptsContextProps = {
  isTranscriptFiltered: boolean;
  setIsTranscriptFiltered: Dispatch<SetStateAction<boolean>>;
  newTranscripts: TranscriptUser[];
  setNewTranscripts: Dispatch<SetStateAction<TranscriptUser[]>>;
  searchFormData: SearchTranscriptValues;
  setSearchFormData: Dispatch<SetStateAction<SearchTranscriptValues>>;
  transcriptSearchQuery: string;
  setTranscriptSearchQuery: Dispatch<SetStateAction<string>>;
  numSearchResults: number;
  setNumSearchResults: Dispatch<SetStateAction<number>>;
};

// Transcripts context
export const TranscriptsContext = createContext<TranscriptsContextProps>({
  isTranscriptFiltered: false,
  setIsTranscriptFiltered: noop,
  newTranscripts: [],
  setNewTranscripts: noop,
  searchFormData: {},
  setSearchFormData: noop,
  transcriptSearchQuery: '',
  setTranscriptSearchQuery: noop,
  numSearchResults: 0,
  setNumSearchResults: noop,
});

// Transcripts context provider
export const TranscriptsContextProvider = ({ children }: { children?: any }) => {
  const [isTranscriptFiltered, setIsTranscriptFiltered] = useState(false);
  const [newTranscripts, setNewTranscripts] = useState<TranscriptUser[]>([]);
  const [searchFormData, setSearchFormData] = useState<SearchTranscriptValues>({
    startDate: FROM_DATE,
    endDate: TO_DATE,
  });
  const [transcriptSearchQuery, setTranscriptSearchQuery] = useState('');
  const [numSearchResults, setNumSearchResults] = useState(0);

  return (
    <TranscriptsContext.Provider
      value={{
        isTranscriptFiltered,
        setIsTranscriptFiltered,
        newTranscripts,
        setNewTranscripts,
        searchFormData,
        setSearchFormData,
        transcriptSearchQuery,
        setTranscriptSearchQuery,
        numSearchResults,
        setNumSearchResults,
      }}
    >
      {children}
    </TranscriptsContext.Provider>
  );
};
