import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import { ApiRoutes, fileUpload } from '@/store/client';
import { REMOTE_USERS_QUERY_KEY } from '../constants';
import { UploadRemoteUserRequest } from '../types';

export const useUploadBulkRemoteUsers = () => {
  const { toast } = useToast();

  const USERS_API_URL = `${API_HOST}/${ApiRoutes.Core}/organisations`;

  const uploadBulkRemoteUsersMutation = useMutation(
    ({ orgId, botId, file }: UploadRemoteUserRequest) => {
      return fileUpload(
        `${USERS_API_URL}/${orgId}/bots/${botId}/remoteusers/upload`,
        file,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to upload document.'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([REMOTE_USERS_QUERY_KEY]);
        toast('Document uploaded successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: uploadBulkRemoteUsers,
    isLoading,
    error,
  } = uploadBulkRemoteUsersMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, uploadBulkRemoteUsers, serverErrors };
};
