import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { AUTH_USER_LOGOUT } from './auth';
import authReducer from './auth/reducer';
import formsReducer from './forms/reducer';
import liveChatReducer from './liveChat/reducer';
import luvReducer from './luv/reducer';
import onboardingReducer from './onboarding/reducer';
import { ORGANISATION_SWITCH_INITIATED } from './org';
import questionsReducer from './questions/reducer';
import { RootAction } from './rootAction';
import statsReducer from './stats/reducer';
import toastReducer from './toast/reducer';
import messagesReducer from './messages/reducer';

const appReducer = combineReducers({
  auth: authReducer,
  forms: formsReducer,
  luv: luvReducer,
  onboarding: onboardingReducer,
  questions: questionsReducer,
  stats: statsReducer,
  toasts: toastReducer,
  liveChat: liveChatReducer,
  messages: messagesReducer,
});

export default (state: StateType<typeof appReducer>, action: RootAction) => {
  // When the user logouts, we need to clear the redux state
  if ([AUTH_USER_LOGOUT].includes(action?.type)) {
    return appReducer((undefined as unknown) as typeof state, action);
  }

  // When an organisation is switched, we need to null certain keys so they are
  // repopulated.
  if ([ORGANISATION_SWITCH_INITIATED].includes(action?.type)) {
    return appReducer(
      ({
        ...state,
        admin: undefined,
        forms: undefined,
        luv: undefined,
        questions: undefined,
        stats: undefined,
        liveChat: undefined,
        messages: undefined,
      } as unknown) as typeof state,
      action,
    );
  }

  return appReducer(state, action);
};
