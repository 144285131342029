import { AxiosError } from 'axios';
import { all, put, takeLatest } from 'typed-redux-saga';
import { PayloadActionCreator, TypeConstant } from 'typesafe-actions';
import { createUser } from '@/store/auth';
import { ApiError } from '@/store/client';
import {
  addQuestionTag,
  createAnswer,
  createQuestion,
  createUtterance,
  deleteAnswer,
  deleteUtterance,
  featuredOrderChanged,
  publishQuestion,
  removeQuestionTag,
  reorderAnswers,
  setFeaturedQuestion,
  syncQuestion,
  updateAnswer,
  updateQuestion,
  updateUtterance,
  unpublishQuestion,
} from '@/store/questions';
import { enqueueToast } from './actions';
import { NODE_ENV } from '@/config';

/**
 * Add actions that send errors to this array
 * and a toast will appear automatically with the error.message
 *
 * You will need to add `typeof myAction` to `ToastableErrors`
 */
const errorActions = [
  /** Auth */
  createUser.failure,

  /** Question */
  addQuestionTag.failure,
  createAnswer.failure,
  createQuestion.failure,
  createUtterance.failure,
  deleteAnswer.failure,
  deleteUtterance.failure,
  featuredOrderChanged.failure,
  publishQuestion.failure,
  unpublishQuestion.failure,
  removeQuestionTag.failure,
  reorderAnswers.failure,
  setFeaturedQuestion.failure,
  syncQuestion.failure,
  updateAnswer.failure,
  updateQuestion.failure,
  updateUtterance.failure,
];

const UNKNOWN_ERROR = 'There was an unknown error';

const enqueueError = (message?: string) =>
  enqueueToast({
    message: message || UNKNOWN_ERROR,
    options: {
      variant: 'error',
    },
  });

function createEnqueueErrorsSaga<
  TAction extends PayloadActionCreator<TypeConstant, void | Error | AxiosError>
>() {
  return function* enqueueErrorsSaga({ payload }: ReturnType<TAction>) {
    if (!payload) return;

    const apiError = payload as AxiosError<ApiError>;
    const errors = apiError?.response?.data?.errors || [];

    const error = apiError?.response?.data?.error ? apiError.response.data.message : null;

    if (error) {
      yield put(enqueueError(error));
    }

    if (errors.length) {
      yield all([...errors.map(currentError => put(enqueueError(currentError?.msg)))]);
    }

    if (NODE_ENV === 'development') {
      yield put(enqueueError(payload?.message));
    }
  };
}

export default [
  ...errorActions.map(action =>
    takeLatest<typeof action>(action, createEnqueueErrorsSaga<typeof action>()),
  ),
];
