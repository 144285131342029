import { connect } from 'react-redux';
import { enqueueToast } from '@/store/toast';

const mapDispatchToProps = {
  enqueueToast,
};

export default connect(undefined, mapDispatchToProps);

export type WithToastProps = Partial<typeof mapDispatchToProps>;
