import { useMemo, useContext } from 'react';
import {
  mapSentToActive,
  filterByActive,
  filterByArchived,
  filterByDraft,
  filterByUpcoming,
} from '@/queries/surveys/utils';
import { FeatureContext } from '@/contexts';
import { useGetSurveys } from '@/queries';

export const useSurveys = () => {
  const { id: featureId } = useContext(FeatureContext) ?? { id: null };
  const { surveys: surveysResponseData, isLoading } = useGetSurveys(featureId);

  const surveys = useMemo(() => {
    return surveysResponseData?.map(item => mapSentToActive(item));
  }, [surveysResponseData]);

  const surveysAllCount = useMemo(() => {
    return surveys?.length || 0;
  }, [surveys]);

  const surveysUpcomingCount = useMemo(() => {
    return filterByUpcoming(surveys).length;
  }, [surveys]);

  const surveysDraftCount = useMemo(() => {
    return filterByDraft(surveys).length;
  }, [surveys]);

  const surveysActiveCount = useMemo(() => {
    return filterByActive(surveys).length;
  }, [surveys]);

  const surveysArchivedCount = useMemo(() => {
    return filterByArchived(surveys).length;
  }, [surveys]);

  return {
    surveys,
    isLoading,
    surveysAllCount,
    surveysDraftCount,
    surveysUpcomingCount,
    surveysActiveCount,
    surveysArchivedCount,
  };
};
