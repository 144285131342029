import { update } from 'lodash/fp';
import { ClientMiddleware } from '@/store/client';
import { API_SUBSCRIPTION_KEY } from '@/config';

const apiMiddleware = update('headers', headers => ({
  ...headers,
  'Ocp-Apim-Subscription-Key': API_SUBSCRIPTION_KEY,
})) as ClientMiddleware;

export default apiMiddleware;
