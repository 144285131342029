import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { API_HOST } from '@/config';
import { useLogoutUser } from '@/hooks/useLogoutUser';
import { useToast } from '@/hooks/useToast';
import { Bot, extractServerErrorMsg } from '@/queries';
import client, {
  ApiRoutes,
  ClientCancelToken,
  createCancelToken,
  wasCancelled,
} from '@/store/client';

import { BOTS_QUERY_KEY } from '../constants';

let botCancelToken: ClientCancelToken | undefined = undefined;

const cancelBotRequest = (message?: string) => {
  botCancelToken && botCancelToken.cancel(message);
  botCancelToken = createCancelToken();
};

const getBots = (orgId: string | null) => {
  cancelBotRequest('New bot request');

  return () => {
    return client.get<Array<Bot>>(
      `${API_HOST}/${ApiRoutes.Core}/organisations/${orgId}/bots`,
      {
        cancelToken: botCancelToken?.token,
      },
    );
  };
};

export const useGetBots = (orgId: string | null) => {
  const { toast } = useToast();
  const { logoutIfForbidden } = useLogoutUser();

  const { data, isLoading } = useQuery([BOTS_QUERY_KEY, orgId], getBots(orgId), {
    enabled: !!orgId,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onError: (errorResponse: AxiosError) => {
      if (wasCancelled(errorResponse)) return;
      toast(extractServerErrorMsg(errorResponse, 'Failed to gets bots'), {
        variant: 'error',
      });

      logoutIfForbidden(errorResponse);
    },
  });

  const bots = data?.data;
  return { bots, isLoading };
};
