import React from 'react';
import { Redirect } from 'react-router-dom';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing';
import {
  DashboardAdminView,
  DashboardKnowledgeSearchView,
  DashboardLiveChatView,
  DashboardQuestionsView,
  DashboardUsersView,
  NotFoundView,
} from '@/views';

export const dashboard: RoutingConfig = [
  {
    path: '/users',
    exact: true,
    component: DashboardUsersView,
    customProps: { permissionArea: BotPermissionCode.DASHBOARDS_VIEW_ALL },
  },
  {
    path: '/questions-and-answers',
    exact: true,
    component: DashboardQuestionsView,
    customProps: { permissionArea: BotPermissionCode.DASHBOARDS_VIEW_ALL },
  },
  {
    path: '/knowledge-search',
    exact: true,
    component: DashboardKnowledgeSearchView,
    customProps: { permissionArea: BotPermissionCode.DASHBOARDS_VIEW_ALL },
  },
  {
    path: '/surveys',
    exact: true,
    component: NotFoundView,
    customProps: { permissionArea: BotPermissionCode.DASHBOARDS_VIEW_ALL },
  },
  {
    path: '/admin',
    exact: true,
    component: DashboardAdminView,
    customProps: { permissionArea: BotPermissionCode.DASHBOARDS_VIEW_ALL },
  },
  {
    path: '/live-chat',
    exact: true,
    component: DashboardLiveChatView,
    customProps: { permissionArea: BotPermissionCode.DASHBOARDS_VIEW_ALL },
  },

  // This must be last
  {
    path: ['', '/users'],
    exact: true,
    render: () => <Redirect to={`users`} />,
    customProps: { permissionArea: BotPermissionCode.DASHBOARDS_VIEW_ALL },
  },
];

export default dashboard;
