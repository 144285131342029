import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, WEB_SEARCH_QUERY_KEY } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { RemoveWebSearchSettings } from '../types';

export const useRemoveWebSearchSite = () => {
  const { toast } = useToast();

  const removeWebSearchSiteMutation = useMutation(
    ({ featureId, siteId }: RemoveWebSearchSettings) =>
      client.delete(`${API_HOST}/${ApiRoutes.WebSearch}/${featureId}/sites/${siteId}/`),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to remove web address.'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(WEB_SEARCH_QUERY_KEY);
        // nice to have, maybe add the web url on the toast message.
        toast('successfully removed website.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: removeWebSearchSite,
    isLoading,
    isSuccess,
  } = removeWebSearchSiteMutation;

  return { isLoading, removeWebSearchSite, isSuccess };
};
