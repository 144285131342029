import styled, { css } from 'styled-components';

type ViewContainerProps = {
  fillBackground?: boolean;
};

export const ViewContainer = styled.div<ViewContainerProps>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.palette.grey[100]};
    padding: ${theme.spacing(5)};
  `,

  ({ fillBackground }) =>
    fillBackground &&
    css`
      height: 100%;
    `,

  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(3)} ${theme.spacing(1)};
    }
  `,
);

export default ViewContainer;
