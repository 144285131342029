import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import client, { ApiRoutes } from '@/store/client';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  TakeOfflineSettingsPayload,
  TakeOfflineSettings,
  TAKE_OFFLINE_SETTINGS_QUERY_KEY,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';

export const useUpdateTakeOfflineSettings = () => {
  const { toast } = useToast();

  const updateTakeOfflineSettingsMutation = useMutation(
    ({ featureId, data }: TakeOfflineSettingsPayload) => {
      return client.patch<TakeOfflineSettings>(
        `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/settings/takeoffline`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update settings'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([TAKE_OFFLINE_SETTINGS_QUERY_KEY, featureId]);
        toast('Settings updated successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateTakeOfflineSettings,
    isLoading,
    error,
  } = updateTakeOfflineSettingsMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateTakeOfflineSettings };
};
