import { BillingView, BillingCardDetailsView, BillingInvoiceDetailsView } from '@/views';
import { RoutingConfig } from '@/routing';

export const billingViews: RoutingConfig = [
  {
    path: '/card',
    exact: true,
    component: BillingCardDetailsView,
  },
  {
    path: '/invoice',
    exact: true,
    component: BillingInvoiceDetailsView,
  },
  {
    path: '/',
    exact: true,
    component: BillingView,
  },
];

export default billingViews;
