/**
 * This file contains reference to every color defined in the core design system
 * All color usage should reference identifiers within this file.
 */
/* eslint no-unused-vars: 0 */

enum Colors {
  // Core
  primary = '#ef5a5e', // primary red
  secondary = '#4082bc', // Windows '98 blue

  // Action colors
  success = '#52bfbb',
  error = '#fb9c33',
  warning = '#ffa500',

  green = '#10ca6c',
  purple = '#5671f0',

  botOnlineColor = '#dcf2f1',
  botOfflineColor = '#f3f2f2',

  lighterRed = '#ff0000d4',
  // Greyscale
  darkGrey = '#484848',
  midGrey = '#787878',
  midPaleGrey = '#cccccc',
  paleGrey = '#e9e8e8',
  lightGrey = '#e9e8e845',
  white = '#ffffff',
  fluentUILight = '#dadada',
  fluentUIDark = '#252423',

  // Almost Greyscale
  veryLightAccent = '#f3f2f2', // pink
  veryLightAccent2 = '#e9e8e8', // pink
  veryLightAccent3 = '#f5f9fb', // blue
  veryLightAccent4 = '#a3a3a3', // blue
}

/**
 * Adds an alpha value, to a given color, and returns a hex value
 * @param color Color to modify, from the pallet
 * @param alpha Amount of transparency to add, min=0 and max=1
 */
export const addAlpha = (color: Colors, alpha: number) => {
  let calcAlpha = Math.floor(alpha * 255).toString(16); // Convert to Hex
  if (calcAlpha.length < 16) calcAlpha = `0${calcAlpha}`; // Pad with 0 if <2
  return color + calcAlpha; // Append calculated xx alpha onto #xxxxxx col
};

/**
 * Brightens or darkens a given color
 * Can be used for hover, focus, shadow colors...
 * in order to avoid additional color definitions
 * @param color a color specified in the ColorPallet
 * @param percent amount of change, in range of -99 and +100,
 * Where negative wil darken, positive will brighten
 */
export const shadeColor = (color: Colors, percent: number) => {
  // Get the RGB components of the hex code
  let r = parseInt(color.substring(1, 3), 16);
  let g = parseInt(color.substring(3, 5), 16);
  let b = parseInt(color.substring(5, 7), 16);

  // Apply brightening/ darkening
  r = Math.round((r * (100 + percent)) / 100);
  g = Math.round((g * (100 + percent)) / 100);
  b = Math.round((b * (100 + percent)) / 100);

  // Ensure RGB values are within valid range
  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  // Convert RGB back into hex
  const rr = r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16);
  const gg = g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16);
  const bb = b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16);
  return `#${rr}${gg}${bb}`;
};

export default Colors;
