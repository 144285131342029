import { anyPass, filter, propEq } from 'lodash/fp';
import { QuestionStatusCode, QuestionStatusMap, Question } from './types';

export const statusMap: QuestionStatusMap = Object.freeze({
  all: [
    QuestionStatusCode.Draft,
    QuestionStatusCode.DraftPublished,
    QuestionStatusCode.Published,
  ],
  active: [QuestionStatusCode.DraftPublished, QuestionStatusCode.Published],
  draft: [QuestionStatusCode.Draft],
});

export const filterByDraft = filter<Question>(
  anyPass([
    propEq<QuestionStatusCode>('status.code', QuestionStatusCode.Draft),
    propEq<QuestionStatusCode>('status.code', QuestionStatusCode.DraftPublished),
  ]),
);

export const filterByPublished = filter<Question>(
  anyPass([
    propEq<QuestionStatusCode>('status.code', QuestionStatusCode.Published),
    propEq<QuestionStatusCode>('status.code', QuestionStatusCode.DraftPublished),
  ]),
);

export const filterByFeatured = filter<Question>(propEq<boolean>('featured', true));
