import { withStats } from '@/hoc';
import { WithStatsProps } from '@/hoc/withStats';
import { Statistic } from '@/store/stats';
import {
  fetchQuestionsStatsUnanswered,
  getQuestionsStatsUnanswered,
  getQuestionsStatuses,
} from '@/store/questions';
import { ComponentType } from 'react';

export const withQuestionsStatsUnanswered = <
  TProps extends WithQuestionsStatsUnansweredProps
>(
  Component: ComponentType<TProps>,
) =>
  withStats<TProps, typeof fetchQuestionsStatsUnanswered.request>(
    Component,
    fetchQuestionsStatsUnanswered.request,
    getQuestionsStatsUnanswered,
    getQuestionsStatuses,
  );

export interface WithQuestionsStatsUnansweredProps extends WithStatsProps {
  stats: Array<Statistic>;
  fetchStats: typeof fetchQuestionsStatsUnanswered.request;
}
