import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  extractServerErrorMsg,
  ConversationTag,
  CONVERSATION_TAGS_QUERY_KEY,
  GetConversationTagsRequest,
  ConversationTagsResponse,
} from '@/queries';
import client, { ApiRoutes } from '@/store/client';

const getConversationTags = ({ featureId, userId }: GetConversationTagsRequest) => {
  return () => {
    return client.get<ConversationTag[]>(
      `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/history/users/${userId}/lastconversationtags`,
    );
  };
};

export const useGetConversationTags = ({
  featureId,
  userId,
}: GetConversationTagsRequest) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [CONVERSATION_TAGS_QUERY_KEY, featureId, userId],
    getConversationTags({ featureId, userId }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to get conversation tags'), {
          variant: 'error',
        });
      },
    },
  );

  const conversationTags: ConversationTagsResponse = {
    [`${featureId}`]: {
      [`${userId}`]: data?.data || [],
    },
  };

  return { conversationTags, isLoading };
};
