import { all, call, put, takeLatest } from 'typed-redux-saga';
import { OrganisationRoleCode } from '@/queries';
import {
  clearLocalIdToken,
  clearLocalStorage,
  getLocalIdToken,
  setLocalIdToken,
  setLocalStorageItem,
} from '@/storage';
import { setOnboardingForm } from '@/store/forms';
import { enqueueToast } from '@/store/toast';
import {
  changePassword,
  createUser,
  fetchUserProfile,
  forgotPassword,
  loginUser,
  populateUser,
  setUserAuthenticated,
  setUserIdToken,
  updateUserProfile,
  userLogout,
} from './actions';
import api from './api';
import { PlatformRoleCode, UserLocalStorageKeys } from './types';

export function* loginUserSaga({ payload }: ReturnType<typeof loginUser.request>) {
  try {
    const response = yield* call(() => api.signIn(payload));

    setLocalIdToken(response.data.access_token);

    yield all([
      put(loginUser.success()),
      put(setUserAuthenticated(true)),
      put(setUserIdToken(response.data.access_token)),
    ]);
    yield put(fetchUserProfile.request());
  } catch (e) {
    yield put(loginUser.failure());
  }
}

export function* populateUserSaga() {
  const localIdToken = getLocalIdToken();

  if (!localIdToken) return;

  yield put(setUserIdToken(localIdToken));
  yield put(setUserAuthenticated(true));
  yield put(fetchUserProfile.request());
}

export function* forgotPasswordSaga() {
  yield* call(() => api.forgotPassword());
}

export function* userLogoutSaga() {
  yield call(clearLocalIdToken);
  yield call(() => clearLocalStorage());
  if (window?.location?.href) window.location.href = '/login';
}

export function* fetchUserProfileSaga() {
  try {
    const response = yield* call(() => api.userProfile());
    const userProfile = response?.data;

    const isAdmin = userProfile?.organisationRole?.code === OrganisationRoleCode.Admin;
    const isUnApprovedOrgAdmin =
      userProfile?.organisationRole?.code === OrganisationRoleCode.UnApprovedOrgAdmin;

    const isPlatformSuperAdmin =
      userProfile?.platformRole?.code === PlatformRoleCode.SuperAdmin;

    if (
      userProfile?.bots?.length === 0 &&
      !isAdmin &&
      !isUnApprovedOrgAdmin &&
      !isPlatformSuperAdmin
    ) {
      yield put(userLogout());
    } else {
      yield put(fetchUserProfile.success(response.data));
    }
  } catch (e) {
    yield put(fetchUserProfile.failure());
    if (e.request?.status && e.request?.status === 403) {
      yield put(userLogout());
    }
  }
}

export function* createUserSaga(
  action: ReturnType<typeof createUser.request>,
): Generator {
  const { payload } = action;
  try {
    const response = yield* call(() => api.createUser(payload));

    if (!response?.data?.token) throw Error('Token not returned.');

    yield put(setUserIdToken(response.data.token));
    yield put(setUserAuthenticated(true));
    setLocalIdToken(response.data.token);
    yield put(createUser.success(response.data));
    yield put(fetchUserProfile.request());
  } catch (e) {
    if (e.request?.response) {
      const errorResponse = JSON.parse(e.request.response);
      yield put(createUser.failure(errorResponse));
      yield put(setOnboardingForm(errorResponse));
    }
  }
}

export function* updateUserProfileSaga({
  payload,
}: ReturnType<typeof updateUserProfile.request>) {
  const { logoFile, ...body } = payload;
  try {
    if (logoFile?.[0]) {
      yield api.uploadProfilePhoto(logoFile?.[0]);
    }

    const response = yield* call(() => api.updateUser(body));

    yield put(updateUserProfile.success(response.data));
    yield put(
      enqueueToast({
        message: 'Profile successfully updated.',
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    yield put(updateUserProfile.failure());
  }
}

export function* changePasswordSaga({
  payload,
}: ReturnType<typeof changePassword.request>) {
  try {
    yield* call(() => api.changePassword(payload));

    yield put(changePassword.success());
    yield put(
      enqueueToast({
        message: 'Password successfully changed.',
        options: {
          variant: 'success',
        },
      }),
    );
  } catch (e) {
    yield put(changePassword.failure(e));
  }
}

/** Local storage sagas */
export function* persistProfileSaga({
  payload,
}: ReturnType<typeof fetchUserProfile.success | typeof updateUserProfile.success>) {
  yield call(() => setLocalStorageItem(UserLocalStorageKeys.Profile, payload));
}

export default [
  takeLatest(forgotPassword, forgotPasswordSaga),
  takeLatest(loginUser.request, loginUserSaga),
  takeLatest(userLogout, userLogoutSaga),
  takeLatest(fetchUserProfile.request, fetchUserProfileSaga),
  takeLatest(createUser.request, createUserSaga),
  takeLatest(updateUserProfile.request, updateUserProfileSaga),
  takeLatest(populateUser, populateUserSaga),
  takeLatest(changePassword.request, changePasswordSaga),

  /** Local storage sagas */
  takeLatest(fetchUserProfile.success, persistProfileSaga),
  takeLatest(updateUserProfile.success, persistProfileSaga),
];
