import React from 'react';
import { compose } from 'lodash/fp';
import { render } from 'react-dom';
import { setConfig } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import { QueryClientProvider } from 'react-query';
import 'regenerator-runtime/runtime.js';

import App from '@/App';
import { CI } from '@/config';
import '@/utils/wdyr';

import { withProviders, withStore } from './hoc';
import { queryClient } from './reactQueryClient';

setConfig({ ErrorOverlay: () => null });

const AppHot = hot(App);

const DecoratedApp = compose(withStore, withProviders)(CI ? App : AppHot);

const AppToRoot = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DecoratedApp />
    </QueryClientProvider>
  );
};

render(<AppToRoot />, document.getElementById('root'));
