import { AxiosRequestConfig } from 'axios';
import { API_HOST } from '@/config';
import { Tag } from '@/queries';
import client, { ApiRoutes, fileUpload } from '@/store/client';
import { Answer, Question, QuestionRequest, Utterance } from './types';

const QA_API_URL = `${API_HOST}/${ApiRoutes.Qa}`;
const MESSAGES_API_URL = `${API_HOST}/${ApiRoutes.Messages}`;

/** Questions */
const getQuestions = (featureId: string, config?: AxiosRequestConfig) =>
  client.get<Array<Question>>(`${QA_API_URL}/${featureId}/questions`, config);

const reorderQuestions = (
  featureId: string,
  payload: Array<{ id: string }>,
  config?: AxiosRequestConfig,
) =>
  client.patch<Array<string>>(
    `${QA_API_URL}/${featureId}/questions/reorder`,
    payload,
    config,
  );

const getQuestion = (featureId: string, id: string, config?: AxiosRequestConfig) =>
  client.get<Question>(`${QA_API_URL}/${featureId}/questions/${id}`, config);

const setFeatured = (
  featureId: string,
  id: string,
  featured: boolean,
  config?: AxiosRequestConfig,
) =>
  client.patch<Array<Question>>(
    `${QA_API_URL}/${featureId}/questions/${id}/featured`,
    { featured },
    config,
  );

/** Question */

const createQuestion = (
  featureId: string,
  payload: Omit<QuestionRequest, 'featureId'>,
  config?: AxiosRequestConfig,
) => client.post<Question>(`${QA_API_URL}/${featureId}/questions`, payload, config);

const updateQuestion = (
  featureId: string,
  id: string,
  payload: Omit<QuestionRequest, 'featureId'>,
  config?: AxiosRequestConfig,
) =>
  client.patch<Question>(`${QA_API_URL}/${featureId}/questions/${id}`, payload, config);

const deleteQuestion = (featureId: string, id: string, config?: AxiosRequestConfig) =>
  client.delete(`${QA_API_URL}/${featureId}/questions/${id}`, config);

const publishQuestion = (featureId: string, id: string, config?: AxiosRequestConfig) =>
  client.post<Question>(`${QA_API_URL}/${featureId}/questions/${id}/publish`, {}, config);

const unpublishQuestion = (featureId: string, id: string, config?: AxiosRequestConfig) =>
  client.post<Question>(
    `${QA_API_URL}/${featureId}/questions/${id}/unpublish`,
    {},
    config,
  );

const draftQuestion = (featureId: string, id: string, config?: AxiosRequestConfig) =>
  client.post<Question>(`${QA_API_URL}/${featureId}/questions/${id}/draft`, {}, config);

/** Utterance */

const createUtterance = (
  featureId: string,
  questionId: string,
  payload: Partial<Utterance>,
  config?: AxiosRequestConfig,
) =>
  client.post<Utterance>(
    `${QA_API_URL}/${featureId}/questions/${questionId}/utterances`,
    payload,
    config,
  );

const updateUtterance = (
  featureId: string,
  questionId: string,
  id: string,
  payload: Partial<Utterance>,
  config?: AxiosRequestConfig,
) =>
  client.put<Utterance>(
    `${QA_API_URL}/${featureId}/questions/${questionId}/utterances/${id}`,
    payload,
    config,
  );

const deleteUtterance = (
  featureId: string,
  questionId: string,
  id: string,
  config?: AxiosRequestConfig,
) =>
  client.delete(
    `${QA_API_URL}/${featureId}/questions/${questionId}/utterances/${id}`,
    config,
  );

/** Answers */

const uploadImage = (featureId: string, messagesId: string, file: File) =>
  fileUpload(
    `${API_HOST}/${ApiRoutes.Messages}/botfeatures/${featureId}/messages/${messagesId}/image`,
    file,
    'BUBBLE',
  );

const uploadattachment = (featureId: string, messagesId: string, file: File) =>
  fileUpload(
    `${API_HOST}/${ApiRoutes.Messages}/botfeatures/${featureId}/messages/${messagesId}/attachment`,
    file,
    'BUBBLE',
  );

const createAnswer = (
  featureId: string,
  messageId: string,
  payload: Partial<Answer>,
  config?: AxiosRequestConfig,
) =>
  client.post<Answer>(
    `${MESSAGES_API_URL}/botfeatures/${featureId}/messages/${messageId}/bubbles`,
    payload,
    config,
  );

const updateAnswer = (
  featureId: string,
  messageId: string,
  id: string,
  payload: Partial<Answer>,
  config?: AxiosRequestConfig,
) =>
  client.patch<Answer>(
    `${MESSAGES_API_URL}/botfeatures/${featureId}/messages/${messageId}/bubbles/${id}`,
    payload,
    config,
  );

const deleteAnswer = (
  featureId: string,
  messageId: string,
  id: string,
  config?: AxiosRequestConfig,
) =>
  client.delete(
    `${MESSAGES_API_URL}/botfeatures/${featureId}/messages/${messageId}/bubbles/${id}`,
    config,
  );

const reorderAnswers = (
  featureId: string,
  messageId: string,
  payload: Array<{ id: string }>,
  config?: AxiosRequestConfig,
) =>
  client.post<Array<Answer>>(
    `${MESSAGES_API_URL}/botfeatures/${featureId}/messages/${messageId}/bubbles/reorder`,
    payload,
    config,
  );

/** Tags */

const addQuestionTag = (
  featureId: string,
  questionId: string,
  payload: Partial<Tag>,
  config?: AxiosRequestConfig,
) =>
  client.post<Tag>(
    `${QA_API_URL}/${featureId}/questions/${questionId}/tags`,
    payload.id && payload.id !== '' ? { id: payload.id } : { value: payload.value },
    config,
  );

const removeQuestionTag = (
  featureId: string,
  questionId: string,
  id: string,
  config?: AxiosRequestConfig,
) =>
  client.delete(`${QA_API_URL}/${featureId}/questions/${questionId}/tags/${id}`, config);

/** Bulk */

const uploadBulkTemplate = (featureId: string, file: File) =>
  fileUpload(`${QA_API_URL}/${featureId}/upload`, file);

export default {
  /** Questions */
  getQuestions,
  reorderQuestions,
  getQuestion,
  setFeatured,

  /** Question */
  createQuestion,
  updateQuestion,
  deleteQuestion,
  publishQuestion,
  draftQuestion,
  unpublishQuestion,

  /** Utterances */
  createUtterance,
  updateUtterance,
  deleteUtterance,

  /** Answers */
  uploadImage,
  uploadattachment,
  createAnswer,
  updateAnswer,
  deleteAnswer,
  reorderAnswers,

  /** Tags */
  addQuestionTag,
  removeQuestionTag,

  /** Bulk */
  uploadBulkTemplate,
};
