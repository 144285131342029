import { createAsyncAction, createAction } from 'typesafe-actions';
import { LookupValuesTypes, LookupValuesPayload } from './types';

export const LOOKUP_VALUES_FETCH = 'LOOKUP_VALUES_FETCH';
export const LOOKUP_VALUES_FETCH_INITIATED = 'LOOKUP_VALUES_FETCH_INITIATED';
export const LOOKUP_VALUES_FETCH_SUCCESS = 'LOOKUP_VALUES_FETCH_SUCCESS';
export const LOOKUP_VALUES_FETCH_FAILED = 'LOOKUP_VALUES_FETCH_FAILED';

export const LOOKUP_VALUES_FETCH_COMPLETE = 'LOOKUP_VALUES_FETCH_COMPLETE';

export const fetchLookupValues = createAsyncAction(
  LOOKUP_VALUES_FETCH_INITIATED,
  LOOKUP_VALUES_FETCH_SUCCESS,
  LOOKUP_VALUES_FETCH_FAILED,
)<LookupValuesTypes | Array<LookupValuesTypes>, LookupValuesPayload, void>();

export const lookupValuesComplete = createAction(LOOKUP_VALUES_FETCH_COMPLETE)();
