import { useRouteMatch } from 'react-router-dom';
import { extractPathParts } from '@/routing';

export const useRoutePath = () => {
  const { path } = useRouteMatch();
  const parts = extractPathParts(path);
  const parentParts = [...parts];
  parentParts.pop();

  return {
    parts,
    parent: `/${parentParts.join('/')}`,
    root: `/${parts[0]}`,
  };
};
