import React, { useContext, useMemo } from 'react';
import { BotIdContext, UserProfileContext } from '@/contexts';
import { BotPermissionCode, BotRoleCode, OrganisationRoleCode } from '@/queries';
import { PlatformRoleCode } from '@/store/auth';
import { PagePermissionDisallowed } from './PagePermissionDisallowed/PagePermissionDisallowed';

export const PagePermissionCheck: React.FC<{
  permissionArea: BotPermissionCode;
}> = ({ children, permissionArea }) => {
  const userProfile = useContext(UserProfileContext);
  const botId = useContext(BotIdContext);
  const isOrgAdmin = OrganisationRoleCode.Admin === userProfile?.organisationRole?.code;
  const isPlatformAdmin = userProfile?.platformRole?.code === PlatformRoleCode.SuperAdmin;

  const currentBot = useMemo(() => {
    return userProfile?.bots?.find(bot => bot.id === botId);
  }, [botId, userProfile]);

  const isBotAdmin = currentBot?.botRole?.code === BotRoleCode.Admin;
  const isAdmin = isOrgAdmin || isPlatformAdmin || isBotAdmin;

  const botPagePermissionList = useMemo(() => {
    const botPermissions = currentBot?.permissions?.map(permission => permission.code);

    return botPermissions || [];
  }, [currentBot]);

  const hasPermissionToViewPage = isAdmin
    ? true
    : botPagePermissionList.includes(permissionArea);

  return <>{hasPermissionToViewPage ? children : <PagePermissionDisallowed />}</>;
};
