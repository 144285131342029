import { ComponentProps, ComponentType, FunctionComponent } from 'react';
import connect, { WithLiveChatProps } from './withLiveChat.state';

export const withLiveChat = <TProps extends {}>(
  Component: ComponentType<TProps>,
): FunctionComponent<WithLiveChatProps & ComponentProps<typeof Component>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `WithLiveChat(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent;
};
