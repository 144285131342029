import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';
import { LIVE_VIDEO_SETTINGS_LIST_QUERY_KEY } from '../constants';
import { DeleteLiveVideoSettingsRequest } from '../types';

export const useDeleteLiveVideoSettings = () => {
  const { toast } = useToast();

  const deleteLiveVideoSettingsMutation = useMutation(
    ({ botId, settingsId }: DeleteLiveVideoSettingsRequest) =>
      client.delete<any>(
        `${API_HOST}/${ApiRoutes.Video}/bots/${botId}/settings/${settingsId}`,
      ),
    {
      onError: (errorResponse: AxiosError) => {
        toast(
          extractServerErrorMsg(errorResponse, 'Failed to delete selected settings.'),
          {
            variant: 'error',
          },
        );
      },
      onSuccess: (_, { botId }) => {
        queryClient.invalidateQueries([LIVE_VIDEO_SETTINGS_LIST_QUERY_KEY, botId]);
        toast('Successfully deleted selected settings.', { variant: 'success' });
      },
    },
  );

  const {
    mutateAsync: deleteLiveVideoSettings,
    isLoading: isDeletingLiveVideoSettings,
  } = deleteLiveVideoSettingsMutation;

  return { isDeletingLiveVideoSettings, deleteLiveVideoSettings };
};
