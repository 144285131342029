import { connect } from 'react-redux';
import { AppState } from '@/store';
import { getForm } from '@/store/forms';
import { WithFormAwareProps } from './types';

const createMapStateToProps = <TProps extends WithFormAwareProps>() => (
  state: AppState,
  props: TProps,
) => ({
  form: getForm(state, props),
});

const createConnect = <TProps extends WithFormAwareProps>() =>
  connect(createMapStateToProps<TProps>());

export default createConnect;

export type WithFormProps = ReturnType<ReturnType<typeof createMapStateToProps>>;
