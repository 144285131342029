import { AxiosError } from 'axios';
import client, { ApiRoutes } from '@/store/client';
import { API_HOST } from '@/config';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import { queryClient } from '@/reactQueryClient';
import { ErrorMessage, extractServerErrorMsg } from '@/queries';
import { Billing, BillingUpdateRequest } from '../types';
import { BILLING_DETAILS_QUERY_KEY } from '../constants';

export const useUpdateBilling = () => {
  const { toast } = useToast();

  const updateBillingMutation = useMutation(
    ({ orgId, payload }: BillingUpdateRequest) => {
      return client.patch<Billing>(
        `${API_HOST}/${ApiRoutes.Billing}/organisations/${orgId}/billing`,
        { payload },
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update billing details.'), {
          variant: 'error',
        });
      },
      onSuccess: (_, orgId) => {
        queryClient.invalidateQueries([BILLING_DETAILS_QUERY_KEY, orgId]);
        toast('Billing details updated successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: updateBilling, isLoading, error } = updateBillingMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateBilling };
};
