import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  LoginUserRequest,
  ForgotPasswordPayload,
  UserProfile,
  UpdateUserProfileRequest,
  UserCreateRequest,
  ChangePasswordRequest,
} from './types';

export const AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';

export const AUTH_GET_USER_PROFILE = 'AUTH_GET_USER_PROFILE';
export const AUTH_GET_USER_PROFILE_INITIATED = 'AUTH_GET_USER_PROFILE_INITIATED';
export const AUTH_GET_USER_PROFILE_SUCCESS = 'AUTH_GET_USER_PROFILE_SUCCESS';
export const AUTH_GET_USER_PROFILE_FAILED = 'AUTH_GET_USER_PROFILE_FAILED';

export const AUTH_CREATE_USER = 'AUTH_CREATE_USER';
export const AUTH_CREATE_USER_INITIATED = 'AUTH_CREATE_USER_INITIATED';
export const AUTH_CREATE_USER_SUCCESS = 'AUTH_CREATE_USER_SUCCESS';
export const AUTH_CREATE_USER_FAILED = 'AUTH_CREATE_USER_FAILED';

export const AUTH_UPDATE_PROFILE = 'AUTH_UPDATE_PROFILE';
export const AUTH_UPDATE_PROFILE_INITIATED = 'AUTH_UPDATE_PROFILE_INITIATED';
export const AUTH_UPDATE_PROFILE_SUCCESS = 'AUTH_UPDATE_PROFILE_SUCCESS';
export const AUTH_UPDATE_PROFILE_FAILED = 'AUTH_UPDATE_PROFILE_FAILED';

export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_CHANGE_PASSWORD_INITIATED = 'AUTH_CHANGE_PASSWORD_INITIATED';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_FAILED = 'AUTH_CHANGE_PASSWORD_FAILED';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_INITIATED = 'AUTH_LOGIN_INITIATED';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';

export const AUTH_SET_AUTHENTICATED = 'AUTH_SET_AUTHENTICATED';

export const AUTH_SET_ID_TOKEN = 'AUTH_SET_ID_TOKEN';

export const AUTH_POPULATE_USER = 'AUTH_POPULATE_USER';

export const updateUserProfile = createAsyncAction(
  AUTH_UPDATE_PROFILE_INITIATED,
  AUTH_UPDATE_PROFILE_SUCCESS,
  AUTH_UPDATE_PROFILE_FAILED,
)<UpdateUserProfileRequest, UserProfile, void>();

export const userLogout = createAction(AUTH_USER_LOGOUT)();

export const forgotPassword = createAction(AUTH_FORGOT_PASSWORD)<ForgotPasswordPayload>();

export const setUserAuthenticated = createAction(AUTH_SET_AUTHENTICATED)<boolean>();

export const setUserIdToken = createAction(AUTH_SET_ID_TOKEN)<string>();

export const populateUser = createAction(AUTH_POPULATE_USER)();

export const fetchUserProfile = createAsyncAction(
  AUTH_GET_USER_PROFILE_INITIATED,
  AUTH_GET_USER_PROFILE_SUCCESS,
  AUTH_GET_USER_PROFILE_FAILED,
)<void, UserProfile, void>();

export const createUser = createAsyncAction(
  AUTH_CREATE_USER_INITIATED,
  AUTH_CREATE_USER_SUCCESS,
  AUTH_CREATE_USER_FAILED,
)<UserCreateRequest, UserProfile, Error | void>();

export const loginUser = createAsyncAction(
  AUTH_LOGIN_INITIATED,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILED,
)<LoginUserRequest, void, void>();

export const changePassword = createAsyncAction(
  AUTH_CHANGE_PASSWORD_INITIATED,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAILED,
)<ChangePasswordRequest, void, Error | void>();
