import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing';
import { QuestionCreateView, QuestionEditView } from '@/views';

export const question: RoutingConfig = [
  {
    path: '/create',
    exact: true,
    component: QuestionCreateView,
    customProps: {
      permissionArea: BotPermissionCode.QANDA_ADMIN,
    },
  },
  {
    path: '/:questionId',
    exact: true,
    component: QuestionEditView,
    customProps: {
      permissionArea: BotPermissionCode.QANDA_ADMIN,
    },
  },
];

export default question;
