import { combineComponents } from '@/utils';
import {
  AnnouncementsContextProvider,
  BotGridViewContextProvider,
  BotRemoteUsersViewContextProvider,
  KnowledgeSearchContextProvider,
  NotesContextProvider,
  PlatformUsersContextProvider,
  SurveysContextProvider,
  TranscriptsContextProvider,
} from './';

const providers = [
  AnnouncementsContextProvider,
  BotGridViewContextProvider,
  BotRemoteUsersViewContextProvider,
  KnowledgeSearchContextProvider,
  NotesContextProvider,
  PlatformUsersContextProvider,
  TranscriptsContextProvider,
  SurveysContextProvider,
];

export const AppContextProvider = combineComponents(...providers);
