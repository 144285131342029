import { ComponentProps, ComponentType, FunctionComponent } from 'react';
import createConnect, { WithQuestionProps } from './withQuestion.state';
import { WithQuestionAwareProps } from './types';

export const withQuestion = <TProps extends WithQuestionAwareProps>(
  Component: ComponentType<TProps>,
) => {
  const connect = createConnect<TProps>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = connect(Component as any);

  WrappedComponent.displayName = `WithQuestion(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent as FunctionComponent<
    WithQuestionAwareProps &
      Omit<ComponentProps<typeof Component>, keyof WithQuestionProps>
  >;
};
