import React, { FunctionComponent, useEffect, useState } from 'react';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import {
  StyledLoader,
  LoaderText,
  Spinner,
  PrevChildrenContainer,
} from './Loader.styled';
import { LoaderProps } from './types';

export const Loader: FunctionComponent<LoaderProps> = ({
  children,
  active,
  showPrev,
  text,
  linear,
  fullScreen,
  flexDirection,
  justifyContent,
  gracePeriod,
  ...props
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(!!active), gracePeriod);
    return () => clearTimeout(timer);
  }, [active, gracePeriod]);

  const renderLinearOrCircularProgress = () => {
    return linear ? <LinearProgress {...props} /> : <CircularProgress {...props} />;
  };

  return (
    <>
      {show ? (
        <>
          {showPrev && <PrevChildrenContainer>{children}</PrevChildrenContainer>}

          <StyledLoader showPrev={showPrev} fullScreen={fullScreen}>
            <Spinner flexDirection={flexDirection} justifyContent={justifyContent}>
              {renderLinearOrCircularProgress()}
              {text && <LoaderText>{text}</LoaderText>}
            </Spinner>
          </StyledLoader>
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

Loader.defaultProps = {
  text: '',
  flexDirection: 'column',
  gracePeriod: 1000,
};

export default Loader;
