import * as msal from '@azure/msal-browser';
import { MS_SSO_APP_ID } from '@/config';

//'c3c1fdb2-5e0a-424f-af43-c936b65cbf00'
export const msalInstance = MS_SSO_APP_ID
  ? new msal.PublicClientApplication({
      auth: {
        clientId: MS_SSO_APP_ID,
        redirectUri: '/signin/callback',
        authority: 'https://login.microsoftonline.com/common/',
        navigateToLoginRequestUrl: false,
      },
    })
  : msal.stubbedPublicClientApplication;
