import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';

export const breakpoints: BreakpointsOptions = {
  values: {
    xl: 1920,
    lg: 1280,
    md: 960,
    sm: 600,
    xs: 0,
  },
};

export default breakpoints;
