import {
  SurveysPage,
  NewSurveyPage,
  SurveyBasicsPage,
  SurveySchedulePage,
  SurveyReportPage,
  SurveyQuestionsPage,
} from '@/pages';
import { BotPermissionCode } from '@/queries';
import { RoutingConfig } from '@/routing/types';

const surveys: RoutingConfig = [
  {
    title: 'New survey',
    description: 'Select the type of survey you want to create',
    icon: 'ChevronIcon',
    path: '/surveys/new',
    exact: true,
    component: NewSurveyPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.SURVEYS_ADMIN,
    },
  },
  {
    title: 'Create a survey',
    icon: 'ChevronIcon',
    path: '/surveys/basics/:id?/:isNewSurvey?',
    exact: true,
    component: SurveyBasicsPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.SURVEYS_ADMIN,
    },
  },
  {
    title: 'Create a survey',
    icon: 'ChevronIcon',
    path: '/surveys/basics/:isTemplate?/:id?/:isNewSurvey?',
    exact: true,
    component: SurveyBasicsPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.SURVEYS_ADMIN,
    },
  },
  {
    title: 'Create a survey',
    icon: 'ChevronIcon',
    path: '/surveys/schedule/:id',
    exact: true,
    component: SurveySchedulePage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.SURVEYS_ADMIN,
    },
  },
  {
    icon: 'ChevronIcon',
    path: '/surveys/report/:id',
    exact: true,
    component: SurveyReportPage,
    title: 'Create a survey',
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.SURVEYS_ADMIN,
    },
  },
  {
    icon: 'ChevronIcon',
    path: '/surveys/questions/:id/:isNewSurvey?',
    exact: true,
    component: SurveyQuestionsPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.SURVEYS_ADMIN,
    },
  },
  {
    title: 'Surveys',
    icon: 'SurveysIcon',
    description:
      'Measure employee loyalty, satisfaction and other employee engagement drivers.',
    path: ['/surveys', '/surveys/:status'],
    exact: true,
    component: SurveysPage,
    customProps: {
      billingGroupCodes: ['OUTSIDE:REACH', 'INSIDE:REACH'],
      permissionArea: BotPermissionCode.SURVEYS_ADMIN,
    },
  },
];

export default surveys;
