import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { setOnboardingForm, setQuestionForm } from './actions';
import { OnboardingForm } from './types';

const initialOnboardingForm = {
  errors: [],
  botType: undefined,
  steps: [],
  botName: undefined,
};

export const onboardingFormReducer = createReducer<OnboardingForm>(
  initialOnboardingForm,
).handleAction(setOnboardingForm, (state, { payload }) => {
  return {
    ...state,
    ...payload,
  };
});

export const questionReducer = createReducer<string | null>(null).handleAction(
  setQuestionForm,
  (state, { payload }) => payload,
);

export default combineReducers({
  onboarding: onboardingFormReducer,
  question: questionReducer,
});
