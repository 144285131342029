import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import client, { ApiRoutes, ApiError } from '@/store/client';
import { AxiosError, AxiosResponse } from 'axios';

const API_AUTH_V1 = `${API_HOST}/${ApiRoutes.Auth}`;

export function useAuthV1Post<TResponse, TVariables>(path: string) {
  return useMutation<AxiosResponse<TResponse>, AxiosError<ApiError>, TVariables>(
    payload => {
      return client.post(`${API_AUTH_V1}/${path}`, payload);
    },
  );
}
