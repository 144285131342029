import { OrganisationRole, UsersBot } from '@/queries';

export enum PlatformRoleCode {
  SuperAdmin = 'SUPERADMIN',

  FirstTime = 'FIRSTTIME',
  User = 'USER',
}

export type UserProfile = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  displayName: string;
  jobTitle: string | null;
  department: { id: string; value: string } | null;
  bots: UsersBot[];
  organisationId: string | null;
  organisationRole: OrganisationRole | null;
  profilePhotoUrl: string | null;
  platformRole: {
    description: string;
    code: PlatformRoleCode;
  } | null;
  token?: string;
};

export type UpdateUserProfileRequest = {
  logoFile?: File;
  body?: Partial<UserProfile>;
  department?: { value: string; label: string };
};
