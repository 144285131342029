import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import { ButtonContainerProps } from './types';

export const ButtonBase = styled(MuiButton)`
  && {
    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 0;
    }
  }
`;

export const IconContainer = styled.span<ButtonContainerProps>`
  svg {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.7142857142857142rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    & * {
      ${({ theme, color, contrast, fillIcon }) =>
        fillIcon &&
        `
        fill: ${
          contrast ? theme.palette[color].contrastText : theme.palette[color].main
        };}
      `}
    }
  }
`;
