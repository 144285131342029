import { API_HOST } from '@/config';
import { MessageBubble, GetBubblesResponse } from '@/store/messages';
import client, { ApiRoutes, fileUpload } from '@/store/client';
import { AxiosRequestConfig } from 'axios';

const createBubble = (botId: string, messagesId: string, config?: AxiosRequestConfig) =>
  client.post<MessageBubble>(
    `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messagesId}/bubbles`,
    config,
  );

const updateBubble = (
  botId: string,
  messagesId: string,
  bubbleId: string,
  config?: AxiosRequestConfig,
) =>
  client.patch<MessageBubble>(
    `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messagesId}/bubbles/${bubbleId}`,
    config,
  );

const deleteBubble = (
  botId: string,
  messagesId: string,
  bubbleId: string,
  config?: AxiosRequestConfig,
) =>
  client.delete<void>(
    `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messagesId}/bubbles/${bubbleId}`,
    config,
  );

const getBubbles = (botId: string, messagesId: string, config?: AxiosRequestConfig) =>
  client.get<GetBubblesResponse>(
    `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messagesId}`,
    config,
  );

const reorderBubbles = (
  botId: string,
  messagesId: string,
  bubbles: Array<{ id: string }>,
) =>
  client.post<Array<MessageBubble>>(
    `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messagesId}/bubbles/reorder`,
    bubbles,
  );

const uploadImage = (botId: string, messagesId: string, file: File) =>
  fileUpload(
    `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messagesId}/image`,
    file,
    'BUBBLE',
  );

const uploadattachment = (botId: string, messagesId: string, file: File) =>
  fileUpload(
    `${API_HOST}/${ApiRoutes.Messages}/bots/${botId}/messages/${messagesId}/attachment`,
    file,
    'BUBBLE',
  );

export default {
  createBubble,
  updateBubble,
  deleteBubble,
  getBubbles,
  reorderBubbles,
  uploadImage,
  uploadattachment,
};
