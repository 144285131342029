import { useEffect, useRef } from 'react';

// Hook
// Parameter is the boolean, with default ''

export const useStatic = (initialValue = ''): any => {
  const ref = useRef('');
  useEffect(() => {
    ref.current = initialValue;
  });
  return ref.current;
};
