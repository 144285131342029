import cardValidator from 'card-validator';
import validator from 'validator';
import { pipe, replace } from 'lodash/fp';

export const GENERIC_REQUIRED_MESSAGE = 'This field is required';
export const PASSWORD_MESSAGE = 'Use at least 8 characters, 1 uppercase and 1 number.';

export const REGEX_PASSWORD = /^((?=.*\d)(?=.*[A-Z])(?=.*[a-zA-Z0-9]).{8,})$/;

const replaceSpaces = replace(/ /g, '');

export const validateCardNumber = pipe(replaceSpaces, validator.isCreditCard);

export const validateCvv = (value: number) => {
  const { isValid } = cardValidator.cvv(value.toString());
  return isValid;
};

export const validatePostcode = (locale: 'any' | validator.PostalCodeLocale = 'any') => (
  value: string,
) => validator.isPostalCode(value, locale);

export const validateEmail = validator.isEmail;

export const validateUrl = (value: string) =>
  validator.isURL(value, { require_tld: false, require_protocol: true });

export const validateMobilePhone = (
  locale: 'any' | validator.MobilePhoneLocale = 'any',
) => (value: string) => {
  const cleanValue = replaceSpaces(value);
  return validator.isMobilePhone(cleanValue, locale);
};

export const validatePassword = (value: string) =>
  validator.matches(value, REGEX_PASSWORD);
