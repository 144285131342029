/**
 * Based on redux-websocket (https://github.com/giantmachines/redux-websocket)
 */

import { Middleware, MiddlewareAPI } from 'redux';
import { Action, Options } from './types';
import { error } from './actions';
import * as actionTypes from './actionTypes';
import ReduxWebSocket from './ReduxWebSocket';

const defaultOptions = {
  prefix: actionTypes.DEFAULT_PREFIX,
};

export default (rawOptions?: Options): Middleware => {
  const options = { ...defaultOptions, ...rawOptions };
  const { prefix } = options;
  const actionPrefixExp = RegExp(`^${prefix}::`);

  const reduxWebsocket = new ReduxWebSocket(options);

  const handlers = {
    [actionTypes.WEBSOCKET_CONNECT]: reduxWebsocket.connect,
    [actionTypes.WEBSOCKET_DISCONNECT]: reduxWebsocket.disconnect,
    [actionTypes.WEBSOCKET_SEND_MESSAGE]: reduxWebsocket.sendMessage,
    [actionTypes.WEBSOCKET_SEND_TYPING]: reduxWebsocket.sendTyping,
    [actionTypes.WEBSOCKET_ACCEPT_LIVE_AGENT_REQUEST]:
      reduxWebsocket.acceptLiveAgentRequest,
    [actionTypes.WEBSOCKET_AGENT_END_CHAT]: reduxWebsocket.agentEndChat,
    [actionTypes.WEBSOCKET_TRANSFER_AGENT]: reduxWebsocket.transferAgent,
  };

  return (store: MiddlewareAPI) => next => (action: Action) => {
    const { dispatch } = store;
    const { type: actionType } = action;

    if (actionType && actionType.match(actionPrefixExp)) {
      const baseActionType = action.type.replace(actionPrefixExp, '');
      const handler = Reflect.get(handlers, baseActionType);

      if (handler) {
        try {
          handler(store, action);
        } catch (err) {
          dispatch(error(action, err, prefix));
        }
      }
    }

    return next(action);
  };
};
