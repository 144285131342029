import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ResetPasswordProps } from './types';
import MinimalLayout from '@/components/MinimalLayout';
import { Alert, GridContainer } from './ResetPassword.styled';
import { Grid } from '@material-ui/core';
import { hasTokenExpired } from '@/utils/hasTokenExpired';
import { ResetPasswordTokenExpired } from './ResetPasswordTokenExpired';
import { ResetPasswordForm } from './ResetPasswordForm';
import Button from '@/components/formElements/Button';
import { useAuthV1Post } from '@/hooks/mutations/useAuthV1Post';
import { Container, Content, Title } from '../Onboarding.styled';

export const ResetPassword: FunctionComponent<ResetPasswordProps> = ({ token }) => {
  const { mutate: saveNewPassword, isError, isSuccess, error } = useAuthV1Post<
    null,
    { password: string; token: string | null }
  >('users/resetpassword');

  const hasTokenExpiredCallBack = useCallback(hasTokenExpired, []);

  const serverErrorsToDisplay = useMemo(() => {
    const errors = error?.response?.data?.errors || [];

    if (errors.length) {
      return errors
        .filter(serverError => serverError.param !== 'password')
        .map((currentError, index) => (
          <Alert key={index} severity="error">
            {currentError?.msg}
          </Alert>
        ));
    }

    return [];
  }, [error]);

  const isTokenExpired = useMemo(() => {
    return hasTokenExpiredCallBack(token);
  }, [hasTokenExpiredCallBack, token]);

  const handleSubmit = useCallback(
    data => {
      !isTokenExpired && saveNewPassword({ password: data.password, token: token });
    },
    [isTokenExpired, saveNewPassword, token],
  );

  if (isTokenExpired) {
    return <ResetPasswordTokenExpired />;
  }

  return (
    <MinimalLayout>
      <Content>
        <Title data-testid="main-title" variant="h2" gutterBottom>
          Reset Password
        </Title>

        <GridContainer>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6} lg={5} xl={4}>
              <Container>
                {isError && serverErrorsToDisplay}

                {isSuccess ? (
                  <>
                    <Alert severity="success">
                      Your password has now been reset. Please use the link below to
                      return to the login page.
                    </Alert>
                    <Button variant="text" href="/login">
                      Go back to Login
                    </Button>
                  </>
                ) : (
                  <ResetPasswordForm
                    onSubmit={handleSubmit}
                    apiError={error?.response?.data}
                  />
                )}
              </Container>
            </Grid>
          </Grid>
        </GridContainer>
      </Content>
    </MinimalLayout>
  );
};
