import React, { ComponentType, FunctionComponent, useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { WithDragDropContextProps } from './types';

export const withDragDropContext = <TProps extends {}>(
  Component: ComponentType<TProps>,
) => {
  const WrappedComponent: FunctionComponent<TProps & WithDragDropContextProps> = ({
    onDragEnd,
    onBeforeCapture,
    onBeforeDragStart,
    onDragStart,
    onDragUpdate,
    ...props
  }) => {
    const dragDropProps = useMemo(
      () => ({
        onDragEnd,
        onBeforeCapture,
        onBeforeDragStart,
        onDragStart,
        onDragUpdate,
      }),
      [onDragEnd, onBeforeCapture, onBeforeDragStart, onDragStart, onDragUpdate],
    );

    return (
      <DragDropContext {...dragDropProps}>
        <Component {...(props as TProps)} />
      </DragDropContext>
    );
  };

  WrappedComponent.displayName = `WithDragDropContext(${WrappedComponent.displayName ||
    'Component'})`;

  return WrappedComponent;
};
