import { MessageBubble } from '@/store/messages';

export type AnnouncementsViewType = 'all' | 'upcoming' | 'active' | 'draft' | 'archived';

export enum AnnouncementStatusCode {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Archived = 'ARCHIVED',
  Upcoming = 'UPCOMING',
  Sent = 'SENT',
}

export enum AnnouncementScheduleType {
  Schedule = 'RECURRING',
  Now = 'NOW',
  Once = 'ONCE',
}

export enum AnnouncementScheduleFrequency {
  Weekly = 'WEEKLY',
  Fortnightly = 'FORTNIGHTLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
}

export type AnnouncementStatus = {
  code: AnnouncementStatusCode;
  description?: string;
};

export enum ScheduleStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type AnnouncementSchedule = {
  type: AnnouncementScheduleType;
  status?: ScheduleStatus;
  scheduleTime?: string | null;
  endDate?: string | null;
  frequency?: AnnouncementScheduleFrequency | null;
};

type Audience = {
  id: string;
  value: string;
};

export type AnnouncementBasics = {
  name?: string;
  message?: {
    id?: string;
    bubbles: Array<MessageBubble>;
  };
  audience?: Array<Audience>;
};

export type AnnouncementScheduleSettings = {
  schedule?: AnnouncementSchedule;
};

export type Announcement = AnnouncementBasics &
  AnnouncementScheduleSettings & {
    id?: string;
    status?: AnnouncementStatus;
    updatedBy?: { displayName: string };
  };

export type AnnouncementPayload = Omit<Announcement, 'description'> & {
  description?: string;
};

export type AnnouncementStatusMap = Record<
  AnnouncementsViewType,
  Array<AnnouncementStatusCode>
>;

export type Announcements = Record<string, Announcement>;

export type AnnouncementsFilterProps = {
  filter?: AnnouncementsViewType;
};

export type CreateOrUpdateRequest = {
  featureId?: string;
  announcementId?: string;
  body?: AnnouncementPayload;
};

export type PublishRequest = {
  featureId: string;
  announcementId: string;
};

export type ArchiveRequest = {
  featureId: string;
  announcementId: string;
};

export type DeleteRequest = {
  featureId: string;
  announcementId: string;
};

// featureId is only set to optional to accommodate values
// coming from context api which are possibly null upon definition
export type GetAnnouncementRequest = {
  featureId?: string | null;
  announcementId?: string;
};

export type DuplicateRequest = {
  featureId: string;
  announcementId: string;
};
