import React from 'react';
import { Color } from '@material-ui/core';

interface CurveProps {
  color: number | string | Partial<Color>;
}

const Curve = (props: CurveProps) => {
  const { color: baseColor } = props;
  const color = String(baseColor);

  return (
    <svg viewBox="0 0 982.82 380.01">
      <title>Curve</title>
      <path
        d="M844.49 5.18a390.37 390.37 0 01-141.18 159.91 387.76 387.76 0 01-210.2 61.56c-151.52 0-290.4-89-353.81-226.65L0 64.14C88.41 256 281.93 380 493.11 380a540.69 540.69 0 00293.12-85.92A543.63 543.63 0 00982.82 71.39z"
        fill={color}
      />
    </svg>
  );
};

export default Curve;
