import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, NOTES_QUERY_KEY, DeleteNoteRequest } from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useDeleteNote = () => {
  const { toast } = useToast();

  const deleteNoteMutation = useMutation(
    ({ botId, userId, noteId, channelName }: DeleteNoteRequest) =>
      client.delete(
        `${API_HOST}/${ApiRoutes.Notes}/bots/${botId}/channels/${channelName}/users/${userId}/notes/${noteId}?hardDelete=false`,
      ),
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to delete note'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { botId, userId }) => {
        queryClient.invalidateQueries([NOTES_QUERY_KEY, botId, userId]);
        toast('successfully deleted note.', {
          variant: 'success',
        });
      },
    },
  );

  const { mutateAsync: deleteNote, isLoading } = deleteNoteMutation;
  return { isLoading, deleteNote };
};
