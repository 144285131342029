import { AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';
import { createCancelToken } from './utils';

export type UploadInitResponse = {
  fileId: string;
  uploadUrl: string;
};

export type UploadFinalResponse = {
  url: string;
  fileId: string;
};

export type ClientCancelExecutor = (cancel: Canceler) => void;
export type ClientCancelToken = ReturnType<typeof createCancelToken>;

export type ClientMiddlewareType = 'request' | 'response';

export interface ClientMiddleware {
  (config?: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig>;
  <T extends void>(value?: AxiosResponse<T>):
    | AxiosResponse<T>
    | Promise<AxiosResponse<T>>;
}

export type AxiosInteceptorHandler = [
  (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
  (error: Error) => void,
];

export type ClientMiddlewares = {
  request: Array<ClientMiddleware>;
  response: Array<AxiosInteceptorHandler>;
};

export type ApiErrorObject = {
  value: string;
  msg: string;
  param: string;
  location: string;
};

export type ApiError<TError = ApiErrorObject> = {
  statusCode: number;
  error: string;
  message: string;
  errors: Array<TError>;
};

export enum ApiRoutes {
  Announcements = 'announcements/v1',
  Auth = 'auth/v1',
  Billing = 'billing/v1',
  Channels = 'channels/v1',
  Core = 'core/v1',
  Dashboard = 'dashboards/v1',
  LiveChat = 'livechat/v1',
  Messages = 'messages/v1',
  Notes = 'notes/v1',
  Qa = 'qa/v1',
  Search = 'search/v1',
  Surveys = 'surveys/v1',
  UserInterface = 'users/v1',
  WebSearch = 'websearch/v1',
  Video = 'video/v1',
  Call = 'call/v1',
}

export type FacebookPage = {
  access_token: string;
  category: string;
  category_list?: Array<string>;
  name: string;
  id: string;
};

export type facebookPageListResponse = {
  data: Array<FacebookPage>;
};

export type FileUploadType = 'STANDARD' | 'BUBBLE';
