import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import {
  ErrorMessage,
  extractServerErrorMsg,
  UpdateUserBlockingSettingsPayload,
  UserBlockingSettings,
  USER_BLOCKING_SETTINGS_QUERY_KEY,
} from '@/queries';
import { queryClient } from '@/reactQueryClient';
import client, { ApiRoutes } from '@/store/client';

export const useUpdateUserBlockingSettings = () => {
  const { toast } = useToast();

  const updateUserBlockingSettingsMutation = useMutation(
    ({ featureId, data }: UpdateUserBlockingSettingsPayload) => {
      return client.patch<UserBlockingSettings>(
        `${API_HOST}/${ApiRoutes.LiveChat}/${featureId}/settings/userblocking`,
        data,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to update settings'), {
          variant: 'error',
        });
      },
      onSuccess: (_, { featureId }) => {
        queryClient.invalidateQueries([USER_BLOCKING_SETTINGS_QUERY_KEY, featureId]);
        toast('Settings updated successfully.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: updateUserBlockingSettings,
    isLoading,
    error,
  } = updateUserBlockingSettingsMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, updateUserBlockingSettings };
};
