import { withStats } from '@/hoc';
import { WithStatsProps } from '@/hoc/withStats';
import { Statistic } from '@/store/stats';
import {
  getQuestionsStatsPopularQuestion,
  getQuestionsStatuses,
  fetchQuestionsStatsPopularQuestion,
} from '@/store/questions';
import { ComponentType } from 'react';

type QuestionsStatsPopularQuestionProps = {
  metrics: Array<Statistic>;
  alternatives: Array<Statistic>;
};

export const withQuestionsStatsPopularItem = <
  TProps extends WithQuestionsStatsPopularItemProps
>(
  Component: ComponentType<TProps>,
) =>
  withStats<
    TProps,
    typeof fetchQuestionsStatsPopularQuestion.request,
    QuestionsStatsPopularQuestionProps
  >(
    Component,
    fetchQuestionsStatsPopularQuestion.request,
    getQuestionsStatsPopularQuestion,
    getQuestionsStatuses,
  );

export interface WithQuestionsStatsPopularItemProps extends WithStatsProps {
  questionId?: string;
  stats: QuestionsStatsPopularQuestionProps;
  fetchStats: typeof fetchQuestionsStatsPopularQuestion.request;
}
