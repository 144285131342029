import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { useToast } from '@/hooks/useToast';
import {
  CreateMessageBubbleRequest,
  ErrorMessage,
  extractServerErrorMsg,
  getCreateMessageContextUrl,
  MessageBubble,
} from '@/queries';
import client from '@/store/client';
import { uploadAnswerFile } from './uploadAnswerFile';

export const useCreateMessageBubble = () => {
  const { toast } = useToast();

  const createMessageBubbleMutation = useMutation(
    async ({ botId, featureId, messageId, bubble }: CreateMessageBubbleRequest) => {
      const body = await uploadAnswerFile({ botId, featureId, messageId, bubble });
      return client.post<MessageBubble>(
        getCreateMessageContextUrl(messageId, botId, featureId),
        body,
      );
    },
    {
      onError: (errorResponse: AxiosError) => {
        toast(extractServerErrorMsg(errorResponse, 'Failed to create bubble'), {
          variant: 'error',
        });
      },
      onSuccess: () => {
        toast('Message bubble created.', {
          variant: 'success',
        });
      },
    },
  );

  const {
    mutateAsync: createMessageBubble,
    isLoading,
    error,
  } = createMessageBubbleMutation;
  const serverErrors: ErrorMessage[] = error?.response?.data?.errors || [];

  return { isLoading, serverErrors, createMessageBubble };
};
