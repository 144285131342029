export interface TokenJSON {
  expiryDate: number;
  email: string;
  code: string;
}
export function hasTokenExpired(token: string | null) {
  if (token) {
    try {
      const decodedString = atob(token);
      const tokenJSON: TokenJSON = JSON.parse(decodedString);

      if (!tokenJSON.expiryDate) return false;

      const currentTimestamp = Date.now();

      return tokenJSON.expiryDate < currentTimestamp;
    } catch (e) {
      return true;
    }
  }
  return true;
}
