import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { API_HOST } from '@/config';
import { useToast } from '@/hooks/useToast';
import { extractServerErrorMsg, SURVEYS_QUERY_KEY } from '@/queries';
import client, { ApiRoutes } from '@/store/client';
import { Survey } from '../types';

const getSurveys = (featureId: string | null) => {
  return () => {
    return client.get<Array<Survey>>(
      `${API_HOST}/${ApiRoutes.Surveys}/${featureId}/surveys`,
    );
  };
};

export const useGetSurveys = (featureId: string | null) => {
  const { toast } = useToast();

  const { data, isLoading } = useQuery(
    [SURVEYS_QUERY_KEY, featureId],
    getSurveys(featureId),
    {
      enabled: !!featureId,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        toast(extractServerErrorMsg(error, 'Failed to get surveys.'), {
          variant: 'error',
        });
      },
    },
  );

  const surveys = data?.data;
  return { surveys, isLoading };
};
